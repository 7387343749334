<div class="wrapper">
    <img
        class="head-bg"
        src="/assets/images/avail-notice-sidenav-bg.png"
        alt="avail notice sidenav background"
    >
    <div class="buttons">
        <button
            class="button"
            (click)="onCreateNewProject()"
        >
            <mat-icon
                class="button-icon"
                svgIcon="plus-box-without-paddings"
            ></mat-icon>
            <span>New Project</span>
        </button>
        <a
            class="button position-relative"
            routerLink="previous-projects"
            (click)="onClose()"
        >
            <mat-icon
                class="button-icon"
                svgIcon="file-chart-rounded"
            ></mat-icon>
            <span>Previous Projects</span>
            <span class="counter-box">{{ (counters | async)?.previousActiveProjects || 0 }}</span>
        </a>
    </div>

    <avl-other-tools
        class="apps"
        [excludeTool]="tools.notice"
    ></avl-other-tools>
</div>
