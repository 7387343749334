<div class="reports-page">
    <div class="collection__container">
        <div class="collection__head">
            <h2 class="collection__title avl-h2">Previous Projects</h2>
            <avl-search
                [placeholder]="'Search reports'"
                (searchChanged)="onSearch($event)"
            ></avl-search>
        </div>
        <avl-reports-table
            [loading]="loading$ | async"
            [reports]="reports$ | async"
            (projectOpened)="onProjectOpened($event)"
            [pagination]="pagination"
            (pageChanged)="onPageChanged($event)"
            (reportDownloaded)="onReportDownloaded($event)"
        ></avl-reports-table>
    </div>
</div>
