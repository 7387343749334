import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { AuthService } from '@auth/services';
import { LoggerService } from '@services';

import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class SignupCodeResolver implements Resolve<any> {

    constructor(
        private readonly authService: AuthService,
        private readonly log: LoggerService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): any {
        const oobCode = route.queryParams.oobCode;
        if (oobCode) {
            return this.authService.checkSignupCode(oobCode)
                .pipe(
                    tap((r) => {
                        this.log.info('SignupCodeResolver.checkSignupCode', r);
                    }),
                    map((emailAddr) => {
                        if (emailAddr) {
                            return {
                                email: emailAddr,
                                code: oobCode,
                                errorMessage: null,
                                valid: true,
                            };
                        }

                        return this.errorActionCode('Invalid signup code, please check the link that has been sent to your email again.');
                    }),
                    catchError((err) => {
                        this.log.info('catchError', err);

                        if (err.status === 403 || err.status === 401) {
                            return of(this.errorActionCode('Invalid signup code, please check the link that has been sent to your email again.'));
                        }

                        return of(this.errorActionCode(err.message));
                    }),
                );
        }
        return of(this.errorActionCode('Invalid signup code, please check the link that has been sent to your email again.'));
    }

    private errorActionCode(message: string): any {
        return {
            email: null,
            code: null,
            errorMessage: message,
            valid: false,
        };
    }
}
