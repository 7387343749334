import { Subject } from 'rxjs';
import {
    FilePickerQueueErrors,
    FileUploaderEventTypes,
    FileValidationErrors,
    IErrorsEmitPayload,
    IFileItem,
    IFileUploaderEvents,
    IFileUploaderOptions,
} from '../types';
import { FileUploaderHttpRequestManager } from '../services/file-uploader-http-request-manager.service';
import { validateFileOnAdd } from '../validators/validators';
import { FileItem } from './file-item.class';
import { FileUploaderOptions } from './file-uploader-options.class';
import { FilesQueue } from './files-queue.class';

export class FileUploaderBase {
    /**
     * Subject{IFileUploaderEvents}
     *
     * emits all events from file uploader except validation errors
     */
    public events$: Subject<IFileUploaderEvents> = new Subject<IFileUploaderEvents>();

    /**
     * Subject{IErrorsEmitPayload}
     *
     * emits validation errors
     */
    public errors$: Subject<IErrorsEmitPayload> = new Subject<IErrorsEmitPayload>();
    public onAddedFiles$: Subject<number> = new Subject<number>();
    public successfulAddedCounter = 0;

    protected readonly options: IFileUploaderOptions;
    protected readonly queue: FilesQueue;
    protected readonly httpRequestManager: FileUploaderHttpRequestManager;

    /**
     * @param {IFileUploaderOptions} options
     * @param {FileUploaderHttpRequestManager} httpRequestManager
     */
    protected constructor(
        options: IFileUploaderOptions,
        httpRequestManager: FileUploaderHttpRequestManager,
    ) {
        this.options = new FileUploaderOptions(options);
        this.queue = new FilesQueue();
        this.httpRequestManager = httpRequestManager;
    }


    /**
     * @param {File[]} files
     * @returns {File[]} list of files after validation
     *
     * if specific option set, it would remove files if selection quantity is bigger then queue size limit
     */
    protected getFilesAfterValidation(files: File[]): File[] {
        let resultFilesList = files;

        if (!this.options.queue.maxFilesAmount) {
            return resultFilesList;
        }

        const isFilesAmountValid = files.length + this.queue.getListLength() <= this.options.queue.maxFilesAmount;
        if (!isFilesAmountValid) {
            if (this.options.queue.removeOnMaxQueueSizeReach) {
                const newFilesLength = this.options.queue.maxFilesAmount - this.queue.getListLength();
                resultFilesList = files.slice(0, newFilesLength);
            } else {
                this.errors$.next({
                    errorType: FilePickerQueueErrors.validationMaxQueueLimitExceeded,
                });
            }
        }

        return resultFilesList;
    }

    /**
     * @param {File} file
     * @returns {void}
     *
     * validate file and add it to queue
     *
     * if specific option is set, it would ignore files which failed validation
     *
     * if specific option is set, starts uploading right after adding file
     */
    protected addFile(file: File): void {
        const fileItem = new FileItem(file);
        const validationError: FileValidationErrors = validateFileOnAdd(fileItem, this.options);
        fileItem.setValidationStatus(!validationError, validationError);

        if (validationError) {
            this.errors$.next({
                errorType: validationError,
                file: fileItem.getPublicCopy(),
            });

            if (this.options.queue.removeOnValidationFail) {
                return;
            }
        }

        this.queue.addFile(fileItem);
        this.events$
            .next({
                type: FileUploaderEventTypes.fileAdded,
                file: fileItem.getPublicCopy(),
            });
        this.successfulAddedCounter++;

        if (this.options.uploader.autoUpload) {
            this.startSingleFileUpload(fileItem);
        }
    }

    /**
     * @param {FileItem} file
     * @returns {void}
     *
     * check if file still available and if file state appropriate for uploading
     *
     * call upload queue validation if previous checks complete successfully
     */
    protected startSingleFileUpload(file: FileItem): void {
        if (typeof file.fileAsObject.size !== 'number') {
            this.errors$.next({
                errorType: FileValidationErrors.fileNoLongerValid,
                file: file.getPublicCopy(),
            });
            return;
        }

        if (file.isUploadInProgress || file.isUploadSucceeded || !file.isValidationSuccessful) {
            return;
        }
        this.validateUploadQueueBeforeStart(file);
    }

    /**
     * @param {FileItem} file
     * @returns {void}
     *
     * validate upload queue before initializing upload
     *
     * if upload queue is already filled, will set specific 'waiting' status for the file
     */
    protected validateUploadQueueBeforeStart(file: FileItem): void {
        const uploadQueue = this.queue.getOriginalFilesWithActiveUpload().length;
        const uploadQueueLimit = this.options.uploader.simultaneousUploadQuantityLimit;
        if (uploadQueue < uploadQueueLimit) {
            this.initializeUploadRequest(file);
        } else if (uploadQueue >= uploadQueueLimit && !file.isUploadQueued) {
            file.setUploadingQueue();
            this.queue.emitListUpdate();
        }
    }

    /**
     * @param {FileItem} file
     * @returns {void}
     *
     * initializes file uploading via http manager, with specified callbacks
     */
    protected initializeUploadRequest(file: FileItem): void {

        const request = this.httpRequestManager.sendRequest(
            file.getPublicCopy(),
            this.options,
            this.onFileUploadProgress.bind(this),
            this.onFileUploadSuccess.bind(this),
            this.onFileUploadFail.bind(this),
        );

        file.setUploadingStarted(request);
        this.queue.emitListUpdate();
        this.events$.next({
            type: FileUploaderEventTypes.fileUploadingStarted,
            file: file.getPublicCopy(),
        });
    }

    /**
     * @param {FileItem} file
     * @param {boolean} checkQueueForUpload
     * @returns {void}
     *
     * cancel file uploading if it is already queued or in progress
     */
    // eslint-disable-next-line @typescript-eslint/typedef
    protected cancelSingleFileUpload(file: FileItem, checkQueueForUpload = false): void {
        if ((!file.isUploadInProgress && !file.isUploadQueued) || !file.isValidationSuccessful) {
            return;
        }

        if (file.isUploadInProgress) {
            this.httpRequestManager.cancelRequest(file.requestRemover);
        }

        file.setUploadingCanceled();
        this.queue.emitListUpdate();
        this.events$.next({
            type: FileUploaderEventTypes.fileUploadingCanceled,
            file: file.getPublicCopy(),
        });

        if (checkQueueForUpload) {
            this.checkNextFilesForUpload();
        }
    }

    /**
     * @returns {void}
     *
     * checks if there are any files queued for uploading
     */
    protected checkNextFilesForUpload(): void {
        const nextFileInUploadQueue = this.queue.getFirstOriginalFileItemForUpload();

        if (nextFileInUploadQueue) {
            this.startSingleFileUpload(nextFileInUploadQueue);
        }
    }

    /**
     * @param {IFileItem} file
     * @param {number} progress
     * @returns {void}
     *
     * callback for handling file uploading progress
     */
    protected onFileUploadProgress(file: IFileItem, progress: number): void {
        const originalFile = this.queue.getOriginalFileItem(file.uuid);

        originalFile.setUploadingProgress(progress);
        this.queue.emitListUpdate();
    }

    /**
     * @param {IFileItem} file
     * @param {any} response
     * @returns {void}
     *
     * callback for handling file uploading success
     */
    protected onFileUploadSuccess(file: IFileItem, response: any): void {
        const originalFile = this.queue.getOriginalFileItem(file.uuid);
        originalFile.setUploadingSucceeded();

        this.events$.next({
            type: FileUploaderEventTypes.fileUploadingSucceeded,
            file: originalFile.getPublicCopy(),
            response,
        });

        if (this.options.queue.removeAfterUploadComplete) {
            this.queue.removeFile(file);
        } else {
            this.queue.emitListUpdate();
        }

        this.checkNextFilesForUpload();
    }

    /**
     * @param {IFileItem} file
     * @param {any} error
     * @returns {void}
     *
     * callback for handling file uploading failure
     */
    protected onFileUploadFail(file: IFileItem, error: any): void {
        const originalFile = this.queue.getOriginalFileItem(file.uuid);

        originalFile.setUploadingFailed();
        this.queue.emitListUpdate();

        this.events$.next({
            type: FileUploaderEventTypes.fileUploadingFailed,
            file: originalFile.getPublicCopy(),
            error,
        });

        this.checkNextFilesForUpload();
    }
}
