import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ISchedule } from 'app/titles/types';

export type SchedulesState = EntityState<ISchedule>;

@Injectable()
@StoreConfig({ name: 'schedules', resettable: true })
export class SchedulesStore extends EntityStore<SchedulesState> {
    constructor() {
        super({ loading: false });
    }
}

