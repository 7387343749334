import { ElementRef, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LightingLayoutsService {
    private readonly lightingElements = new Map<string, ElementRef>();

    public registerLightedElement(name: string, element: ElementRef): void {
        this.lightingElements.set(name, element);
    }

    public getLightedElement(name: string): ElementRef {
        return this.lightingElements.get(name);
    }
}
