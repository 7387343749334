import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ISchedulePurchaseParams, IScheduleType } from '../types';
import { Observable } from 'rxjs';

@Injectable()
export class SchedulesApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public getSchedulesTypeList(folderId: string): Observable<IScheduleType[]> {
        return this.http.get<IScheduleType[]>(`api/titles/schedules/${folderId}`);
    }

    public getScheduleTypeInfo(url: string): Observable<any> {
        return this.http.get<any>(url);
    }

    public startSchedulePurchase(folderId: string, key: string, params: ISchedulePurchaseParams[]): Observable<any> {
        return this.http.post<any>(`api/schedules/purchase/${key}/${folderId}`, params, { observe: 'response' });
    }

    public getSchedulePurchaseStatus(url: string): Observable<HttpResponse<ISchedulePurchaseParams[]>> {
        return this.http.get<ISchedulePurchaseParams[]>(url, { observe: 'response' });
    }

    public getFiledCopiesDialogInfoItems(scheduleKey: string, folderId: string): Observable<HttpResponse<any>> {
        return this.http.get<any>(`api/titles/schedules/${scheduleKey}/${folderId}`, { observe: 'response' });
    }

}
