<div
    *ngIf="isFiltersVisible | async"
    class="map-filter-controls"
>
    <div class="toggle-controls">
        <avl-toggle-control
            text="Freeholds"
            idName="freeholdsId"
            [ngModel]="isFreeholdsOn$ | async"
            [ngModelOptions]="{standalone: true}"
            (ngModelChange)="onFreeholdsFilterChanged($event)"
        ></avl-toggle-control>

        <avl-toggle-control
            text="Leaseholds"
            idName="leaseholdsId"
            [ngModel]="isLeaseholdsOn$ | async"
            [ngModelOptions]="{standalone: true}"
            (ngModelChange)="onLeaseholdsFilterChanged($event)"
        ></avl-toggle-control>
    </div>
</div>
<div class="map-zoom-controls">
    <button
        class="map-filter-button"
        data-testid="zoom-in-button"
        type="button"
        [ngClass]="{ 'disabled': isMaxZoomAchieved | async}"
        [disabled]="isMaxZoomAchieved | async"
        (click)="zoomIn()"
    >
        <i class="icon icon-zoom-in"></i>
    </button>
    <button
        class="map-filter-button"
        data-testid="zoom-out-button"
        type="button"
        [ngClass]="{ 'disabled': isMinZoomAchieved | async}"
        [disabled]="isMinZoomAchieved | async"
        (click)="zoomOut()"
    >
        <i class="icon icon-zoom-out"></i>
    </button>
</div>
