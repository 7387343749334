import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { MapSearchQuery, MapSearchStore } from '../../../../store';
import { ShortTitleInfo } from '../../../../types';
import { Subscription } from 'rxjs';

@Component({
    selector: 'avl-map-land-registry-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapLandRegistryListComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input()
    public list: ShortTitleInfo[];

    @Output()
    public showDetails = new EventEmitter<string>();

    @Output()
    public itemClicked = new EventEmitter<string>();

    @Output()
    public itemHovered = new EventEmitter<string>();

    @Output()
    public itemUnhovered = new EventEmitter<string>();

    @ViewChild('scrollContainer', { static: false })
    private readonly scrollContainer: ElementRef;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly mapSearchStore: MapSearchStore,
        private readonly mapSearchQuery: MapSearchQuery,
        public renderer: Renderer2,
    ) {
    }

    public ngOnInit(): void {
        const sub = this.mapSearchQuery.sidePanelContentIsUpdated()
            .subscribe(() => {
                const highlightedTitleNumber = this.mapSearchStore.getValue().permanentlyHighlightedTitleNumber;
                setTimeout(() => this.scrollIntoContainerIfElementHidden(highlightedTitleNumber), 300);
            });

        this.subscriptions.add(sub);
    }

    public ngAfterViewInit(): void {
        // TODO check on this.activatedRoute.queryParams didnt return actular query params
        const previousShowTitleId = this.mapSearchStore.getValue().previousShowTitleId;
        if (previousShowTitleId) {
            this.scrollToAnchorAndHighlight(`${previousShowTitleId}`);
            this.mapSearchStore.update({ previousShowTitleId: null });
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public scrollToAnchorAndHighlight(anchorId: string): void {
        const container = this.scrollContainer?.nativeElement;
        const anchorElement = container?.querySelector(`#title-number-${anchorId}`);

        if (anchorElement) {
            anchorElement.scrollIntoView();
            this.renderer.addClass(anchorElement, 'highlight');

            setTimeout(() => {
                this.renderer.removeClass(anchorElement, 'highlight');
            }, 4000);
        }
    }

    public scrollIntoContainerIfElementHidden(anchorId: string): void {
        const container = this.scrollContainer?.nativeElement;
        const anchorElement = container?.querySelector(`#title-number-${anchorId}`);

        if (!this.isElementVisible(anchorElement)) {
            anchorElement?.scrollIntoView({ behavior: 'smooth' });
        }
    }

    public onShowDetails(item: ShortTitleInfo): void {
        this.showDetails.emit(item.titleNumber);
    }

    public onItemClicked(item: ShortTitleInfo): void {
        this.itemClicked.emit(item.titleNumber);
    }

    public onItemHovered(item: ShortTitleInfo): void {
        this.itemHovered.emit(item.titleNumber);
    }

    public onItemUnhovered(item: ShortTitleInfo): void {
        this.itemUnhovered.emit(item.titleNumber);
    }

    public itemTrackBy(index: number, item: ShortTitleInfo): string {
        return item.titleNumber;
    }

    private isElementVisible(element: HTMLElement): boolean {
        return !!(element?.offsetWidth || element?.offsetHeight);
    }
}
