import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IDocument } from 'app/titles/types';

export function createPlaceholderDocument(uid: string): IDocument {
    return {
        id: uid,
        dataPoints: null,
        address: '',
        fileName: '',
        issueDate: null,
        isError: false,
        isAccepted: false,
        isProcessed: false,
        messages: [],
        titleNumber: '',
        ownership: '',
        type: '',
        source: null,
    };
}

export type DocumentsState = EntityState<IDocument>;

@Injectable()
@StoreConfig({ name: 'documents', resettable: true })
export class DocumentsStore extends EntityStore<DocumentsState> {
    constructor() {
        super({ loading: false });
    }
}

