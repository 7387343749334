<button
    class="clear-btn-styles toggle"
    (click)="toggle()"
>
    <div
        class="label"
        [class.checked]="!isChecked"
        [class.unchecked]="isChecked"
    >
        <ng-content select="[unchecked-label]"></ng-content>
    </div>
    <div
        class="label"
        [class.checked]="isChecked"
        [class.unchecked]="!isChecked"
    >
        <ng-content select="[checked-label]"></ng-content>
    </div>
</button>
