<div class="list-container">
    <h1 class="list-title">
        Multiple titles
    </h1>
    <div class="list-description">
        Click on any title below to highlight it on the map
    </div>
</div>

<div
    #scrollContainer
    class="scroll-container"
>
    <ng-container *ngFor="let item of list; trackBy: itemTrackBy">
        <div
            class="list-item-container"
            [id]="'title-number-' + item.titleNumber"
        >
            <avl-map-land-registry-list-item
                [titleNumber]="item.titleNumber"
                [titleTenure]="item.tenure"
                (click)="onItemClicked(item)"
                (mouseenter)="onItemHovered(item)"
                (mouseleave)="onItemUnhovered(item)"
                (infoButtonClick)="onShowDetails(item)"
            ></avl-map-land-registry-list-item>
        </div>
    </ng-container>
</div>
