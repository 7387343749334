import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SideNavCounters } from '../../types/side-nav-counters.type';


@Injectable()
@StoreConfig({ name: '[notice] side-nav-counters' })
export class SideNavCountersStore extends Store<SideNavCounters> {

    constructor() {
        super({
            previousActiveProjects: 0,
        });
    }

}
