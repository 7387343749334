<div
    #container
    class="documents-table__container"
    [@tableAnimation]=""
>
    <table
        class="documents-table__table"
        matSort
        (matSortChange)="sortDocuments($event)"
    >
        <thead>
            <tr class="documents-table__row">
                <th class="documents-table__col documents-table__col--head file-icon--width"></th>
                <th
                    class="documents-table__col documents-table__col--head file-name--width"
                    mat-sort-header="fileName"
                    [disabled]="isLoading"
                >
                    File Name
                </th>
                <th class="documents-table__col documents-table__col--head file-type--width">
                    File Type
                </th>
                <th
                    class="documents-table__col documents-table__col--head upload-time--width"
                    mat-sort-header="uploadTime"
                    [disabled]="isLoading"
                >
                    Upload Time
                </th>
                <th
                    class="documents-table__col documents-table__col--head upload-date--width"
                    disableClear
                    mat-sort-header="uploadDate"
                    [disabled]="isLoading"
                >
                    Upload Date
                </th>
                <th
                    class="documents-table__col documents-table__col--head size--width"
                    mat-sort-header="size"
                    [disabled]="isLoading"
                >
                    Size
                </th>
                <th
                    class="documents-table__col documents-table__col--head pages--width"
                    [disabled]="isLoading"
                    mat-sort-header="pages"
                >
                    Pages
                </th>
                <th class="documents-table__col documents-table__col--head"></th>
            </tr>
        </thead>
        <tbody
            cdkDropList
            (cdkDropListDropped)="changeOrder($event)"
            [cdkDropListDisabled]="!isDocumentsUploadingFinished"
        >
            <ng-container *ngFor="let document of dataSource; trackBy: trackByDocuments">
                <tr
                    *ngIf="!document.isUploadFinished; else documentRow"
                    class="documents-table__row documents-table__row--loading"
                >
                    <td class="documents-table__col file-icon">
                        <mat-icon svgIcon="file-attachment"></mat-icon>
                    </td>
                    <td
                        class="documents-table__col progress-bar"
                        colspan="6"
                    >
                        <avl-loading-progress-bar
                            [fileName]="document.fileName || 'File name'"
                            [value]="document.loadingPercentage"
                        ></avl-loading-progress-bar>
                    </td>
                    <td class="documents-table__col remove-icon">
                        <button
                            type="button"
                            class="clear-btn-styles documents-table__col-remove"
                        >
                            <mat-icon
                                class="remove-icon"
                                svgIcon="close"
                                (click)="cancelLoading(document)"
                            ></mat-icon>
                        </button>
                    </td>
                </tr>
                <ng-template #documentRow>
                    <tr
                        cdkDrag
                        class="documents-table__row"
                        [cdkDragDisabled]="!isDragDropEnabled"
                    >
                        <div
                            *cdkDragPreview
                            class="drag-drop__preview preview"
                            [style]="{'width': getPreviewWidth()}"
                        >
                            <div>
                                <mat-icon
                                    class="file-icon"
                                    svgIcon="file-attachment"
                                ></mat-icon>
                            </div>
                            <div>
                                <span>{{ document.fileName }}</span>
                            </div>
                            <div>
                                <span>{{ document.type }}</span>
                            </div>
                            <div>
                                <span>{{ getUploadTime(document) }}</span>
                            </div>
                            <div>
                                <span>{{ getUploadDate(document) }}</span>
                            </div>
                            <div>
                                <span>{{ document.metadata.size || '-' }}</span>
                            </div>
                            <div>
                                <span>{{ document.metadata.pages || '-' }}</span>
                            </div>
                        </div>

                        <td
                            *cdkDragPlaceholder
                            colspan="8"
                            class="drag-drop__placeholder placeholder"
                        ></td>

                        <td class="documents-table__col file-icon file-icon--width">
                            <mat-icon
                                svgIcon="file-attachment"
                                [ngClass]="{ 'file-icon_regular': isDragDropEnabled }"
                            ></mat-icon>
                            <mat-icon
                                *ngIf="isDragDropEnabled"
                                cdkDragHandle
                                class="file-icon__reorder"
                                svgIcon="reorder"
                            ></mat-icon>
                        </td>
                        <td class="documents-table__col file-name file-name--width">
                            <span>{{ document.fileName }}</span>
                        </td>
                        <td class="documents-table__col file-type file-type--width">
                            <span>{{ document.type }}</span>
                        </td>
                        <td class="documents-table__col upload-time upload-time--width">
                            <span>{{ getUploadTime(document) }}</span>
                        </td>
                        <td class="documents-table__col upload-date upload-date--width">
                            <span>{{ getUploadDate(document) }}</span>
                        </td>
                        <td class="documents-table__col size size--width">
                            <span>{{ document.metadata.size || '-' }}</span>
                        </td>
                        <td class="documents-table__col pages pages--width">
                            <span>{{ document.metadata.pages || '-' }}</span>
                        </td>
                        <td class="documents-table__col">
                            <button
                                type="button"
                                class="clear-btn-styles documents-table__col-remove"
                                (click)="removeDocument(document)"
                            >
                                <mat-icon
                                    class="remove-icon"
                                    svgIcon="close"
                                ></mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </ng-container>
        </tbody>
    </table>
</div>
