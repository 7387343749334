import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IFailedDocument } from '@core/types';

export type FailedDocumentsState = EntityState<IFailedDocument>;

@Injectable()
@StoreConfig({ name: 'failed-documents', resettable: true })
export class FailedDocumentsStore extends EntityStore<FailedDocumentsState> {
    constructor() {
        super();
    }
}
