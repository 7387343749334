import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ClientNoticeDocument } from 'app/notices/types';

export type NoticeDocumentsState = EntityState<ClientNoticeDocument>;

@Injectable()
@StoreConfig({ name: 'notice-documents', resettable: true })
export class NoticeDocumentsStore extends EntityStore<NoticeDocumentsState> {
    constructor() {
        super();
    }
}

