import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/guards';
import { NoticesComponent } from './notices.component';
import { NoticesResolver } from './resolvers/notices.resolver';
import { routingNotice } from '@constants';
import { ToolAccessGuard } from '../core/guards/tool-access.guard';
import { AvailToolKey } from '@enums';
import { NoticeDocumentsComponent } from './components/notice-documents/notice-documents.component';
import { PreviousFoldersComponent } from './components/previous-folders/previous-folders.component';


const routes: Routes = [
    {
        path: routingNotice,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                data: { tool: AvailToolKey.notice, title: 'Avail Notice' },
                canActivate: [ToolAccessGuard],
                component: NoticesComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'upload',
                        pathMatch: 'full',
                    },
                    {
                        path: 'upload',
                        component: NoticeDocumentsComponent,
                        resolve: {
                            noticeDocuments: NoticesResolver,
                        },
                    },
                    {
                        path: 'previous-projects',
                        component: PreviousFoldersComponent,
                    }
                ]
            }
        ]
    },
    {
        path: 'notices',
        redirectTo: routingNotice,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class NoticesRoutingModule {
}
