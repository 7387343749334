import { Injectable } from '@angular/core';
import { BaseLandRegistryService } from './base-land-registry.service';
import { HmlrApi } from '../../api';
import { Observable, of, Subject } from 'rxjs';
import { ITileNumberForPurchase, ITitleInfo, LandRegistry } from '../../types';
import { switchMap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { PurchasedTitleDetails } from '../../types/purchased-title-details.type';
import { LandRegistrySearchSource } from '../../enums/land-registry-search-source.enum';
import { LandRegistryPurchaseEntity } from '../../enums/land-registry-purchase-entity.enum';

@Injectable()
export class HmLandRegistryService extends BaseLandRegistryService implements LandRegistry {

    constructor(
        private readonly hmlrApi: HmlrApi,
    ) {
        super(hmlrApi);
    }

    public getPurchaseEntity(): LandRegistryPurchaseEntity {
        return LandRegistryPurchaseEntity.hmlr;
    }

    public getIsAvailable(): Observable<boolean> {
        return of(true);
    }

    public searchTitle(folder: string, kind: string, query: string, source: LandRegistrySearchSource, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        return this.search(folder, kind, query, source, statusUrl);
    }

    public refreshTitles(folder: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        return this.refresh(folder, statusUrl);
    }

    public purchaseTitles(folder: string, titles: ITitleInfo[]): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        const titleNumbers: ITileNumberForPurchase[] = titles.map((item) => ({
            kind: 'title-register',
            reference: item.titleNumber,
        }));

        return this.hmlrApi.purchase(folder, titleNumbers)
            .pipe(
                switchMap((url) => this.getPurchaseStatus(url)),
            );
    }

    public registryUnavailable(): Observable<void> {
        return of();
    }
}
