import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePickerDirective } from './directives/file-picker.directive';
import { FileDropDirective } from './directives/file-drop.directive';
import { FileUploaderHttpClient } from './services/file-uploader-http-client.service';
import { FileUploaderHttpRequestManager } from './services/file-uploader-http-request-manager.service';
import { FileUploaderService } from './services/file-uploader.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [
        FilePickerDirective,
        FileDropDirective
    ],
    exports: [
        HttpClientModule,
        FilePickerDirective,
        FileDropDirective
    ],
    providers: [
        { provide: FileUploaderHttpRequestManager, useClass: FileUploaderHttpClient },
        FileUploaderService
    ]
})
export class FileUploaderModule {}
