import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ProfileService } from '@services';
import { AvailToolKey } from '@enums';

@Injectable({
    providedIn: 'root',
})
export class SelectAvailableToolResolver implements Resolve<boolean> {
    constructor(
        private readonly profileService: ProfileService,
        private readonly router: Router,
    ) {
    }

    public resolve(): Observable<boolean> {
        this.profileService.loadConfig();

        return this.profileService.apps$
            .pipe(
                filter((apps) => !!apps),
                take(1),
                map((availableApps) => {
                    if (!availableApps.length) {
                        void this.router.navigate(['zero-subscriptions']);

                        return true;
                    }

                    const titleApp = availableApps.find((app) => app.key === AvailToolKey.title);
                    const firstAvailableAppKey = titleApp?.key || availableApps[0].key;

                    void this.router.navigate([firstAvailableAppKey]);

                    return false;
                }),
            );
    }
}
