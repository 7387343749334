<button
    type="button"
    class="dialog__close"
    mat-dialog-close
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>
<form [formGroup]="form">
    <h4 class="select-client__title">Select your client</h4>
    <div class="select-client__options">
        <div class="radio-option">
            <input
                id="landlord"
                class="radio-option-input"
                type="radio"
                value="landlord"
                name="type"
                formControlName="type"
            >
            <label
                class="radio-option-label"
                for="landlord"
            >
                <img
                    class="radio-option-icon"
                    src="assets/images/landlord-select.png"
                    alt="landlord select"
                >
                <span class="radio-option-name">Landlord</span>
            </label>
        </div>
        <div class="radio-option">
            <input
                id="tenant"
                class="radio-option-input"
                type="radio"
                value="tenant"
                name="type"
                formControlName="type"
            >
            <label
                class="radio-option-label"
                for="tenant"
            >
                <img
                    class="radio-option-icon"
                    src="assets/images/tenant-select.png"
                    alt="tenant select"
                >
                <span class="radio-option-name">Tenant</span>
            </label>
        </div>
    </div>
    <button
        class="avl-btn avl-btn--notice-yellow avl-btn--wide dialog__bottom-btn"
        type="button"
        [disabled]="!form.value.type"
        (click)="downloadReport()"
    >
        <span class="avl-btn__text">Select</span>
    </button>
</form>
