import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../notification-center/services/notification.service';
import { FolderQuery } from '../../../../states';

export type ReportGenerationPartialSuccessData = {
    title: string;
    linkToReport: string;
    linkMessage: string;
    firstMessage: string;
    secondMessage: string;
    linkPlaceholder: string;
};

@Component({
    selector: 'avl-report-generation-partial-success',
    templateUrl: './report-generation-partial-success.component.html',
    styleUrls: ['./report-generation-partial-success.component.scss'],
})
export class ReportGenerationPartialSuccessComponent implements OnInit {

    public notificationBell: Element;

    public title: string;
    public linkToReport: string;
    public linkMessage: string;
    public firstMessageBeforeLink: string;
    public firstMessageAfterLink: string;
    public secondMessageBeforeLink: string;
    public secondMessageAfterLink: string;

    public isFirstMessageContainLink: boolean;
    public isSecondMessageContainLink: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private readonly data: ReportGenerationPartialSuccessData,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<ReportGenerationPartialSuccessComponent>,
        private readonly folderQuery: FolderQuery,
    ) {
        this.title = data.title;
        this.linkToReport = data.linkToReport;
        this.linkMessage = data.linkMessage;

        const placeholder = data.linkPlaceholder;
        const firstMessage = data.firstMessage;
        const secondMessage = data.secondMessage;

        this.isFirstMessageContainLink = firstMessage.includes(placeholder);
        this.isSecondMessageContainLink = secondMessage.includes(placeholder);

        this.firstMessageBeforeLink = firstMessage.split(placeholder)[0];
        this.firstMessageAfterLink = firstMessage.split(placeholder)[1];
        this.secondMessageBeforeLink = secondMessage.split(placeholder)[0];
        this.secondMessageAfterLink = secondMessage.split(placeholder)[1];
    }

    public ngOnInit(): void {
        this.notificationBell = document.getElementsByClassName('notifications-bell').item(0);
    }

    public openNotificationDialog(): void {
        const folderId = this.folderQuery.getId();

        this.dialogRef.close();
        this.notificationService.showDialog(folderId);
    }

    public downloadReport(): void {
        this.dialogRef.close(this.linkToReport);
    }
}
