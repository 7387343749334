import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';

import firebase from 'firebase/compat/app';

import { LoggerService } from '@services';
import { AuthService, RoutingService } from '@auth/services';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import UserCredential = firebase.auth.UserCredential;

@Injectable({
    providedIn: 'root',
})
export class RedirectGuard implements CanActivate {

    constructor(
        private readonly authService: AuthService,
        private readonly routingService: RoutingService,
        private readonly log: LoggerService) {
    }

    public canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return fromPromise(this.authService.firebaseAuth.getRedirectResult())
            .pipe(
                catchError((err) => {
                    this.log.info('getRedirectResult error', err);
                    return of(null);
                }),
                switchMap((result) => {
                    if (result && result.user) {
                        if (result.additionalUserInfo) {
                            this.updateUserInformation(result);
                        }

                        return this.routingService.parseHomeUrl(next);
                    }

                    return of(true);
                }),
            );
    }

    public updateUserInformation(redirectResult: UserCredential): void {
        const additionalUserInfo = redirectResult.additionalUserInfo;
        if (additionalUserInfo.providerId.startsWith('saml.')) {
            if (!this.isValidDisplayName(redirectResult.user.displayName)) {
                this.authService.submitProfileInfo(additionalUserInfo.profile).subscribe();
            } else {
                this.log.info('!updateUserInformation already has a display name');
            }
        } else {
            this.log.info('!updateUserInformation provider is not SSO');
        }
    }

    public isValidDisplayName(name: string): boolean {
        this.log.info('isValidDisplayName', name);

        if (!name) {
            this.log.info('isValidDisplayName1', name);
            return false;
        }

        if (name.includes('undefined')) {
            return false;
        }

        if (name.match(/^[A-Z] [A-Za-z-']+$/g)) {
            return false;
        }

        const r = name.trim().includes(' ');
        this.log.info('isValidDisplayName2', name, r);

        return r;
    }
}
