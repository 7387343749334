/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompilingStatus } from '../types/compiling-status.type';
import { map } from 'rxjs/operators';

@Injectable()
export class CompileApi {

    constructor(private readonly http: HttpClient) {
    }

    public getStatus(folderId: string, processId: string): Observable<CompilingStatus> {
        const params = new HttpParams()
            .set('tid', processId);

        return this.http.get<{
            'is_failure': boolean;
            'is_finished': boolean;
            'is_partial_success': boolean;
            'is_success': boolean;
            'progress': string;
            'progress%': string;
            'show_email_toast': boolean;
            'supporting_text': string[][];
            'title_text': string;
            'report_text': string;
            'report_link': string;
        }>(`/api/filed-documents/compile/${folderId}/status`, {
            params,
            observe: 'response',
        })
            .pipe(
                map((response) => {
                    const body = response.body;
                    const totalProgressInPercent = body['progress%'];
                    const filesCompiledPerTotalFiles = body.progress;

                    const filesCompiled = Number(filesCompiledPerTotalFiles.split('/')[0]);
                    const totalFiles = Number(filesCompiledPerTotalFiles.split('/')[1]);
                    const progress = Number(totalProgressInPercent);
                    const isFinished = body.is_finished;
                    const isPartialSuccess = body.is_partial_success;
                    const isSuccess = body.is_success;
                    const isFailure = body.is_failure;
                    const isEmailToastShouldBeShown = body.show_email_toast;
                    const title = body.title_text;
                    const message = body.supporting_text;
                    const linkMessage = body.report_text;
                    const linkToReport = body.report_link;

                    return {
                        totalFiles,
                        filesCompiled,
                        progress,
                        isFinished,
                        isSuccess,
                        isFailure,
                        isEmailToastShouldBeShown,
                        isPartialSuccess,
                        title,
                        message,
                        linkMessage,
                        linkToReport,
                    };
                }),
            );
    }

    public startCompile(folderId: string): Observable<string> {
        return this.http.put<string>(`/api/filed-documents/${folderId}/compile`, null);
    }

    public loadReport(url: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
    }
}
