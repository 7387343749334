import {
    ICustomHandlers,
    IFilePickerOptions,
    IFileUploaderOptions,
    IQueueOptions,
    IUploaderOptions
} from '../types';

export class FileUploaderOptions implements IFileUploaderOptions {
    /**
   * filePicker default options
   */
    public filePicker: IFilePickerOptions = {
        allowedFileExtension: [],
        allowedMimeClass: [],
        allowedMultipleFiles: false,
        maxFileSize: null
    };

    /**
   * uploader default options
   */
    public uploader: IUploaderOptions = {
        autoUpload: false,
        customHeaders: {},
        url: '',
        withCredentials: false,
        fileAlias: '[file]',
        simultaneousUpload: false,
        simultaneousUploadQuantityLimit: 1
    };

    /**
   * queue default options
   */
    public queue: IQueueOptions = {
        maxFilesAmount: null,
        removeAfterUploadComplete: false,
        removeOnValidationFail: true,
        removeOnMaxQueueSizeReach: true
    };

    /**
   * customHandlers default options
   */
    public customHandlers: ICustomHandlers = {
        customFileValidator: undefined
    };

    /**
   * @param {IFileUploaderOptions} options
   */
    constructor(options: IFileUploaderOptions) {
        this.extendOptions(options);
    }

    /**
   * @param {IFileUploaderOptions} options
   * @returns {void}
   */
    private extendOptions(options: IFileUploaderOptions): void {
        if (options.filePicker) {
            this.filePicker = Object.assign({}, this.filePicker, options.filePicker);
        }

        if (options.uploader) {
            this.uploader = Object.assign({}, this.uploader, options.uploader);

            if (this.uploader.simultaneousUpload) {
                this.uploader.simultaneousUploadQuantityLimit = options.uploader.simultaneousUploadQuantityLimit || 1;
            } else {
                this.uploader.simultaneousUploadQuantityLimit = 1;
            }
        }

        if (options.queue) {
            this.queue = Object.assign({}, this.queue, options.queue);

            if (this.filePicker.allowedMultipleFiles && options.queue.maxFilesAmount) {
                this.queue.maxFilesAmount = options.queue.maxFilesAmount;
            }
        }

        if (options.customHandlers) {
            this.customHandlers = Object.assign({}, this.customHandlers, options.customHandlers);
        }
    }

}
