import { Injectable } from '@angular/core';
import { LandRegistryDialogStore, PurchasingStatus } from './land-registry-dialog.store';
import { catchError, finalize, switchMap, take, takeLast, tap } from 'rxjs/operators';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsService, LandRegistryService } from '../../services';
import { ITitleInfo } from '../../types';
import { UrlParamsService } from '@services';
import { SearchRegistry } from '../../enums/search-register.enum';
import { RegistrySearchType } from '@enums';
import { LandRegistryDialogQuery } from './land-registry-dialog.query';
import { landRegistriesColumns } from '../../constants';
import { SearchResultsStore } from '../search-results';
import { LandRegistryResultTableColumnsSet } from '../../enums/land-registry-result-table-columns-set.enum';

@Injectable()
export class LandRegistryDialogService {

    constructor(
        private readonly store: LandRegistryDialogStore,
        private readonly query: LandRegistryDialogQuery,
        private readonly dialog: MatDialog,
        private readonly documentsService: DocumentsService,
        private readonly registryService: LandRegistryService,
        private readonly searchResultsStore: SearchResultsStore,
        private readonly urlParams: UrlParamsService,
    ) {
        this.listenToStorageInitialization();
    }

    public changeSearchRegistry(registry: SearchRegistry): void {
        this.store.update({ registry });
        this.registryService.setRegistry(registry);
        this.checkRegistryAvailability();
    }

    public checkRegistryAvailability(): void {
        this.registryService.checkRegistryAvailability()
            .subscribe((isAvailable) => {
                if (!isAvailable) {
                    this.changeSearchRegistry(SearchRegistry.hmlr);
                }
            });
    }

    public changeSearchType(searchType: RegistrySearchType): void {
        this.urlParams.addParams({ searchType });
        this.defineResultTableColumns(searchType);
    }

    public addTitleToBasket(targetTitle: ITitleInfo): void {
        const allTitles = this.store.getValue().basketTitles;
        const isTitleInStore = allTitles.some((title) => title.titleNumber === targetTitle.titleNumber);

        if (!isTitleInStore) {
            this.store.update({ basketTitles: [...allTitles, targetTitle] });
        }
    }

    public removeTitleFromBasket(targetTitleNumber: string): void {
        const allTitles = this.store.getValue().basketTitles;
        const titlesForPurchase = allTitles.filter((title) => title.titleNumber !== targetTitleNumber);

        this.store.update({ basketTitles: titlesForPurchase });
    }

    public updateBasketList(titles: ITitleInfo[]): void {
        this.store.update({ basketTitles: titles });
    }

    public clearBasketList(): void {
        this.store.update({ basketTitles: [] });
    }

    public finishTitlePurchaseWithSuccess(message: string): void {
        const status = PurchasingStatus.success;
        this.store.emitPurchaseFinished({ status, message });
    }

    public finishTitlePurchaseWithFail(message: string): void {
        const status = PurchasingStatus.failure;
        this.store.emitPurchaseFinished({ status, message });
    }

    public resetState(): void {
        this.store.reset();
    }

    public doPurchaseTitles(folderId: string): void {
        const titles = this.store.getValue().basketTitles;

        this.documentsService.clearFailedPurchases();
        this.store.setLoading(true);

        this.registryService.purchaseTitles(folderId, titles)
            .pipe(
                takeLast(1),
                tap(() => this.finishTitlePurchaseWithSuccess('Title registers purchased successfully')),
                catchError((error) => {
                    const status = error && error.status;
                    let message = error.error || '';
                    let withClosing = true;

                    switch (status) {
                        case 503:
                            message = message || 'You can only obtain official copies from HM Land Registry during their working hours. Please try again between 6:30 and 23:00';
                            withClosing = false;
                            break;
                        case 400:
                            message = 'Something\'s gone wrong. Please clear your temporary internet files and try again.';
                            break;
                        case 401:
                        case 403:
                            message = 'Your account doesn\'t seem to be authorised to do this. Please try again or contact our support live chat.';
                            break;
                        case 402:
                            message = 'You\'ve run out of credit. Please contact your head of innovation or LegalTech for more information.';
                            withClosing = false;
                            break;
                        case 409:
                            message = 'Another transaction of this project is in progress. '
                                + 'Please wait until the other transaction is complete to avoid purchasing the same items twice.';
                            withClosing = false;
                            break;
                        default:
                            message = 'Something\'s gone wrong. Please try again, or contact our support live chat.';
                            break;
                    }

                    if (withClosing) {
                        this.finishTitlePurchaseWithFail(message);
                    } else {
                        this.dialog.open(AlertOkDialogComponent, {
                            panelClass: 'report-dialog',
                            width: '400px',
                            data: {
                                title: 'Oops!',
                                message,
                                button: 'OK',
                            },
                        });
                    }

                    return throwError(error);
                }),
                tap((res) => this.documentsService.updateFailedPurchases(res.body, this.extractTransactionId(res.url))),
                switchMap(() => this.documentsService.refreshDocuments()),
                finalize(() => this.store.setLoading(false)),
            )
            .subscribe();
    }

    private extractTransactionId(url: string): string {
        const tokens = url.split('/');

        if (tokens.length > 0) {
            return tokens[tokens.length - 1];
        }

        return '';
    }

    private listenToStorageInitialization(): void {
        this.query.select('registry')
            .pipe(take(1))
            .subscribe((registry) => this.changeSearchRegistry(registry));
    }

    private defineResultTableColumns(searchType: RegistrySearchType): void {
        const register = this.query.getValue().registry;
        let columnsSet: LandRegistryResultTableColumnsSet;

        if (register === SearchRegistry.ros) {
            columnsSet = LandRegistryResultTableColumnsSet.ros;
        } else if (searchType === RegistrySearchType.what3Words) {
            columnsSet = LandRegistryResultTableColumnsSet.hmlrWithWhat3Words;
        } else {
            columnsSet = LandRegistryResultTableColumnsSet.hmlr;
        }

        const possibleColumns = landRegistriesColumns[columnsSet];
        this.searchResultsStore.update({ columns: possibleColumns });
    }
}
