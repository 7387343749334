import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'avl-fixed-toggle',
    templateUrl: './fixed-toggle.component.html',
    styleUrls: ['./fixed-toggle.component.scss'],
})
export class FixedToggleComponent {
    @Input()
    public isChecked = false;

    @Output()
    public toggled = new EventEmitter<boolean>();

    public toggle(): void {
        this.isChecked = !this.isChecked;
        this.toggled.emit(this.isChecked);
    }
}
