<button
    class="dialog__close"
    type="button"
    mat-dialog-close="close"
    [disabled]="disableClose"
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>
<h4 mat-dialog-title>Enter Project Details</h4>
<form
    [formGroup]="projectDetailsForm"
    (submit)="confirmDetails()"
>
    <mat-dialog-content>
        <div
            class="form-group"
            [class.has-error]="fieldHasError('projectName')"
        >
            <input
                class="form-control"
                type="text"
                name="projectName"
                value=""
                placeholder="Project Name"
                autocomplete="off"
                formControlName="projectName"
            />
            <img
                class="form-icon"
                src="../../../../../assets/images/login/icon_email.png"
                alt="Project Name"
            >
            <span class="error-message">{{projectNameErrorMessage}}</span>
        </div>
        <div
            class="form-group"
            [class.has-error]="fieldHasError('matterNumber')"
        >
            <input
                class="form-control"
                type="text"
                name="matterNumber"
                value=""
                placeholder="Client / Matter Number"
                autocomplete="off"
                formControlName="matterNumber"
            />
            <img
                class="form-icon"
                src="../../../../../assets/images/paper-icon.png"
                alt="Client / Matter Number"
            >
            <span class="error-message">{{matterNumberErrorMessage}}</span>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button
            class="avl-btn avl-btn--blue avl-btn--wide"
            type="submit"
            [disabled]="projectDetailsForm.invalid"
            [promiseBtn]="projectCreated$"
        >
            Let’s Go
        </button>
    </div>
</form>
