import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FolderQuery, MapSearchQuery, MapSearchService } from 'app/titles/store';
import { finalize, switchMap } from 'rxjs/operators';
import { LandRegistryDialogQuery, LandRegistryDialogService } from '../../../../../store/land-registry-dialog';
import { of, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'avl-map-land-registry-list-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
})
export class MapLandRegistryListItemComponent implements OnInit, OnDestroy {
    @Input()
    public titleNumber: string;

    @Input()
    public titleTenure: string;

    @Input()
    public isHighlighted = false;

    @Output()
    public infoButtonClick = new EventEmitter();

    public isBasketLoading = new Subject<boolean>();
    public isInBasket = false;
    public isTitleNumberHighlighted = false;

    private readonly sub = new Subscription();

    constructor(
        private readonly folderQuery: FolderQuery,
        private readonly landRegistryDialogQuery: LandRegistryDialogQuery,
        private readonly landRegistryDialogService: LandRegistryDialogService,
        private readonly mapSearchQuery: MapSearchQuery,
        private readonly mapSearchService: MapSearchService,
        private readonly ref: ChangeDetectorRef,
    ) {
    }

    public ngOnInit(): void {
        this.sub.add(
            this.landRegistryDialogQuery.isTitleAddedToBasket$(this.titleNumber)
                .subscribe((isInBasket) => this.isInBasket = isInBasket),
        );
        this.sub.add(
            this.mapSearchQuery.isTitleNumberPermanentlyHighlighted(this.titleNumber)
                .subscribe((isHighlighted) => {
                    this.isTitleNumberHighlighted = isHighlighted;
                    this.ref.detectChanges();
                }),
        );
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public basketToggle(): void {
        const titleNumber = this.titleNumber;
        const folderId = this.folderQuery.getFolderId();

        if (this.isInBasket) {
            this.landRegistryDialogService.removeTitleFromBasket(titleNumber);
        } else {
            this.isBasketLoading.next(true);
            this.mapSearchService.getTitleDetails(folderId, titleNumber)
                .pipe(
                    switchMap((titlesSearchResult) => of(titlesSearchResult[0])),
                    finalize(() => this.isBasketLoading.next(false)),
                )
                .subscribe((title) => this.landRegistryDialogService.addTitleToBasket(title));
        }
    }
}
