import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PurchasedSelectedFilesState, PurchasedSelectedFilesStore } from './purchased-selected-files.store';

@Injectable()
export class PurchasedSelectedFilesQuery extends QueryEntity<PurchasedSelectedFilesState> {
    constructor(protected store: PurchasedSelectedFilesStore) {
        super(store);
    }
}
