<div
    *ngIf="isAlertOpened"
    class="error-alert__multiple"
    @fadeRowAnimation
>
    <mat-expansion-panel
        class="error-alert__multiple-panel"
        [expanded]="titles.length > 1"
        [hideToggle]="titles.length === 1"
        [disabled]="titles.length === 1"
    >
        <mat-expansion-panel-header
            [collapsedHeight]="'50px'"
            [expandedHeight]="'50px'"
        >
            <mat-icon
                class="error-alert__icon"
                svgIcon="alert"
            ></mat-icon>
            <p
                class="error-alert__text"
                [ngPlural]="titles.length"
            >
                <ng-template ngPluralCase="=1">
                    {{ titles[0]?.messages?.[0]?.message || 'Oops! Something went wrong' }}
                </ng-template>
                <ng-template ngPluralCase="other">
                    Oops! A few title numbers that you’ve entered could not be found. Please double check them.
                </ng-template>
            </p>
            <div
                *ngIf="titles.length === 1"
                class="error-alert__remove-icon"
                (click)="removeOneDocument()"
            >
                <mat-icon
                    class="remove-icon"
                    svgIcon="close-white"
                ></mat-icon>
            </div>
        </mat-expansion-panel-header>
        <ng-container *ngIf="titles.length > 1">
            <mat-panel-description *ngFor="let title of titles; trackBy: trackByDocuments">
                <div class="error-alert__icon">
                    <mat-icon
                        class="alert-icon"
                        svgIcon="alert"
                    ></mat-icon>
                </div>
                <p class="error-alert__text">
                    {{ title.messages[0].message }}
                <div
                    (click)="removeDocument(title.titleNumber)"
                    class="error-alert__remove-icon"
                >
                    <mat-icon
                        class="remove-icon"
                        svgIcon="close-white"
                    ></mat-icon>
                </div>
            </mat-panel-description>
        </ng-container>
    </mat-expansion-panel>
</div>
