import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export type FolderState = {
    id: string;
    matterNumber?: string;
    projectName?: string;
}

function createInitialState(): FolderState {
    return { id: '' };
}

@Injectable()
@StoreConfig({ name: 'folder', resettable: true })
export class FolderStore extends Store<FolderState> {
    constructor() {
        super(createInitialState());
    }
}
