<div
    *ngIf="!failedChange && !succeedSend"
    class="auth-page__form-title"
>
    <h2>Choose a New Password</h2>
</div>
<form
    *ngIf="!failedChange && !succeedSend"
    class="auth-page__form"
    [formGroup]="changeForm"
    (ngSubmit)="change()"
>
    <div class="form-field">
        <label>Email Address</label>
        <input
            [ngClass]="{ 'error': emailControl?.touched && emailControl?.invalid }"
            tabindex="1"
            name="email"
            value=""
            type="text"
            placeholder="Email Address"
            autofocus
            autocomplete="new-password"
            formControlName="email"
        />
        <mat-icon [svgIcon]="emailControl?.touched && emailControl?.invalid ? 'error' : 'envelope'"></mat-icon>
        <mat-error *ngIf="emailControl?.touched && emailControl?.invalid">
            {{ getEmailErrorMessage() }}
        </mat-error>
    </div>
    <div class="form-field">
        <label>Password</label>
        <input
            tabindex="2"
            name="password"
            [type]="isPasswordVisible ? 'text' : 'password'"
            placeholder="Password"
            autocomplete="new-password"
            formControlName="password"
        />
        <mat-icon
            class="button"
            [svgIcon]="isPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isPasswordVisible = !isPasswordVisible"
        ></mat-icon>
        <mat-error *ngIf="passwordControl?.touched &&passwordControl?.invalid">
            Current password is required
        </mat-error>
    </div>
    <div class="form-field">
        <label>New Password</label>
        <input
            tabindex="3"
            name="newPassword"
            [type]="isNewPasswordVisible ? 'text' : 'password'"
            placeholder="New Password"
            autocomplete="new-password"
            formControlName="newPassword"
        />
        <mat-icon
            class="button"
            [svgIcon]="isNewPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isNewPasswordVisible = !isNewPasswordVisible"
        >
        </mat-icon>
        <mat-error *ngIf="newPasswordControl?.touched &&newPasswordControl?.invalid">
            New password is required
        </mat-error>
    </div>
    <div class="form-field">
        <label>Confirm Password</label>
        <input
            tabindex="4"
            name="confirmNewPassword"
            [type]="isConfirmPasswordVisible ? 'text' : 'password'"
            placeholder="Confirm Password"
            autocomplete="new-password"
            formControlName="confirmNewPassword"
        />
        <mat-icon
            class="button"
            [svgIcon]="isConfirmPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible"
        >
        </mat-icon>
        <mat-error *ngIf="confirmNewPasswordControl?.touched && confirmNewPasswordControl.invalid">
            Confirm password is required
        </mat-error>
    </div>
    <button
        class="avl-btn avl-btn--blue avl-btn--wide"
        tabindex="5"
        name="change"
        value="Change password"
        type="submit"
        [disabled]="changeForm.invalid"
    >
        Change password
    </button>
</form>
<avl-error-form
    *ngIf="failedChange"
    [message]="changeError"
    (tryAgain)="onTryAgain()"
></avl-error-form>
<avl-succeed-form
    *ngIf="succeedSend"
    [message]="succeedMessage"
    [isResendButton]="false"
    (resend)="onTryAgain()"
></avl-succeed-form>
