import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { ContentSidebarComponent } from './content-sidebar.component';
import { MapLandRegistryModule } from './detail/detail.module';
import { MapLandRegistryListModule } from './list/list.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MapLandRegistryModule,
        MapLandRegistryListModule,
    ],
    exports: [
        ContentSidebarComponent,
    ],
    declarations: [
        ContentSidebarComponent,
    ],
})
export class ContentSidebarModule {
}
