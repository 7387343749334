import { LandRegistryResultTableColumnsSet } from '../enums/land-registry-result-table-columns-set.enum';

export type Column = {
    name: string;
    width: string;
};

export type ColumnsSet = {
    [key: string]: Column[];
};

export const landRegistriesColumns: ColumnsSet = {
    [LandRegistryResultTableColumnsSet.hmlrWithWhat3Words]: [
        {
            name: 'reference',
            width: '11%',
        },
        {
            name: 'type',
            width: '11%',
        },
        {
            name: 'address',
            width: '23%',
        },
        {
            name: 'what3words',
            width: '13%',
        },
        {
            name: 'proprietors',
            width: '23%',
        },
        {
            name: 'companyNumbers',
            width: '18%',
        },
        {
            name: 'select',
            width: '1%',
        },
    ],
    [LandRegistryResultTableColumnsSet.hmlr]: [
        {
            name: 'reference',
            width: '13%',
        },
        {
            name: 'type',
            width: '13%',
        },
        {
            name: 'address',
            width: '28%',
        },
        {
            name: 'proprietors',
            width: '27%',
        },
        {
            name: 'companyNumbers',
            width: '18%',
        },
        {
            name: 'select',
            width: '1%',
        },
    ],
    [LandRegistryResultTableColumnsSet.ros]: [
        {
            name: 'reference',
            width: '100px',
        },
        {
            name: 'address',
            width: '29%',
        },
        {
            name: 'rightType',
            width: '100px',
        },
        {
            name: 'rightDescription',
            width: '70%',
        },
        {
            name: 'select',
            width: '1%',
        },
    ],
};
