import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { MapLandRegistryListComponent } from './list.component';
import { MapLandRegistryListItemModule } from './item/item.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MapLandRegistryListItemModule,
    ],
    exports: [
        MapLandRegistryListComponent,
    ],
    declarations: [
        MapLandRegistryListComponent,
    ],
})
export class MapLandRegistryListModule {
}
