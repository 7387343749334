import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FolderDetails, IResponseStatus } from '@core/types';
import { INoticeDocument } from '../types';

@Injectable()
export class NoticeApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public createFolder(): Observable<string> {
        return this.http.post('/api/notice/folder', null, { responseType: 'text' });
    }

    public getFolderDetails(folderId: string): Observable<FolderDetails> {
        return this.http.get<FolderDetails>(`api/notice/project/${folderId}`);
    }

    public updateFolderDetails(folderId: string, details: FolderDetails): Observable<void> {
        return this.http.put<void>(`api/notice/project/${folderId}`, details);
    }

    public getFolderStatus(folderId: string): Observable<IResponseStatus<INoticeDocument>> {
        return this.http.get<IResponseStatus<INoticeDocument>>(`/api/notice/status/${folderId}`);
    }

    public removeDocument(folderId: string, documentId: string): Observable<void> {
        return this.http.delete<void>(`/api/notice/document/${folderId}/${documentId}`);
    }

    public generateNotice(folderId: string, noticeType: string): Observable<HttpResponse<void>> {
        const url = `/api/notice/generate/${folderId}/${noticeType}`;

        return this.http.post<void>(url, null, { observe: 'response' });
    }

    public getGenerationStatus(url: string): Observable<HttpResponse<void>> {
        return this.http.get<void>(url, { observe: 'response' });
    }
}
