import { Observable, Subject } from 'rxjs';
import { getFileListCopyAsArray } from '../helpers/copy.helpers';
import { FileUploaderEventTypes, IFileItem, IFileUploaderOptions } from '../types';
import { FileUploaderHttpRequestManager } from '../services/file-uploader-http-request-manager.service';
import { FileItem } from './file-item.class';
import { FileUploaderBase } from './file-uploader-base.class';

export class FileUploader extends FileUploaderBase {
    /**
     * @param {IFileUploaderOptions} options
     * @param {FileUploaderHttpRequestManager} httpRequestManager
     */
    constructor(
        options: IFileUploaderOptions,
        httpRequestManager: FileUploaderHttpRequestManager,
    ) {
        super(options, httpRequestManager);
    }

    /**
     * @returns {Observable<IFileItem[]>}
     */
    public get filesList$(): Observable<IFileItem[]> {
        return this.queue.list$;
    }

    /**
     * @param {File[]} files
     * @returns {void}
     *
     * validate and add selected files into queue
     *
     * if some files are invalid, they can be removed from selection or added into queue with exact status.
     * the behavior is defined in options
     */
    public addFiles(files: File[]): void {
        // files origin type is FileList. It doesn't contain some array methods, that's why 'getFileListCopyAsArray' is used
        if (!files.length) {
            return;
        }

        let filesList: File[] = getFileListCopyAsArray(files);
        filesList = this.getFilesAfterValidation(filesList);

        if (!filesList.length) {
            return;
        }


        for (let i = 0; i < filesList.length; i++) {
            this.addFile(filesList[i]);
        }
        this.onAddedFiles$.next(this.queue.getListLength());
    }

    public onBeforeUpload(): Subject<number> {
        return this.onAddedFiles$;
    }

    /**
     * @returns {boolean}
     *
     * check if multiple file selection specified in options
     */
    public isMultipleFilesAllowed(): boolean {
        return this.options.filePicker.allowedMultipleFiles;
    }

    /**
     * @returns {void}
     *
     * trigger uploading of all valid files from queue
     */
    public uploadAll(): void {
        this.queue.loopOriginalFileItems((item: FileItem) => {
            this.startSingleFileUpload(item);
        });
    }

    /**
     * @returns {void}
     *
     * cancel uploading of all valid files
     */
    public cancelAll(): void {
        this.queue.loopOriginalFileItems((item: FileItem) => {
            this.cancelSingleFileUpload(item);
        });
    }

    /**
     * @returns {void}
     *
     * cancel uploading of all valid files and remove all (including invalid) files
     */
    public removeAll(): void {
        if (!this.queue.getListLength()) {
            return;
        }

        if (this.queue.getOriginalFilesWithActiveUpload().length) {
            this.cancelAll();
        }

        this.queue.removeAll();
        this.successfulAddedCounter = 0;
        this.events$.next({ type: FileUploaderEventTypes.allFilesRemoved });
    }

    public updateUploaderUrl(url: string): void {
        this.options.uploader.url = url;
    }

    /**
     * @param {IFileItem} file
     * @returns {void}
     *
     * upload single file
     */
    public uploadFile(file: IFileItem): void {
        const originalFileItem: FileItem = this.queue.getOriginalFileItem(file.uuid);

        this.startSingleFileUpload(originalFileItem);
    }

    /**
     * @param {IFileItem} file
     * @returns {void}
     *
     * cancel single file uploading
     */
    public cancelUpload(file: IFileItem): void {
        const originalFileItem: FileItem = this.queue.getOriginalFileItem(file.uuid);

        this.cancelSingleFileUpload(originalFileItem, true);
    }

    /**
     * @param {IFileItem} file
     * @returns {void}
     *
     * cancel single file uploading if active and remove it
     */
    public removeFile(file: IFileItem): void {
        if (file.isUploadInProgress) {
            this.cancelUpload(file);
        }

        this.queue.removeFile(file);
        this.events$
            .next({
                type: FileUploaderEventTypes.fileRemoved,
                file,
            });
    }

    /**
     * @returns {Observable<number>} uploading progress calculated for all files (range: 0 - 100)
     */
    public getTotalUploadProgress(): Observable<number> {
        return this.queue.getTotalUploadProgress();
    }

    /**
     * @param {number} index  file index in array
     * @returns {Observable<IFileItem>}
     */
    public getFileByIndex(index: number): Observable<IFileItem> {
        return this.queue.getFileByIndex(index);
    }

    /**
     * @param {string} uuid  file uniq id
     * @returns {Observable<IFileItem>}
     */
    public getFileByUuid(uuid: string): Observable<IFileItem> {
        return this.queue.getFileByUuid(uuid);
    }

    /**
     * @returns {Observable<IFileItem[]>} files which are currently uploading
     */
    public getUploadingFiles(): Observable<IFileItem[]> {
        return this.queue.getUploadingFiles();
    }

    /**
     * @returns {Observable<IFileItem[]>} files which upload has failed
     */
    public getUploadFailedFiles(): Observable<IFileItem[]> {
        return this.queue.getUploadFailedFiles();
    }

    /**
     * @returns {Observable<IFileItem[]>} files which upload has succeeded
     */
    public getUploadedFiles(): Observable<IFileItem[]> {
        return this.queue.getUploadedFiles();
    }

    /**
     * @param {(list: IFileItem[]) => IFileItem[]} searchFn  custom search function
     * @returns {Observable<IFileItem[]>}
     *
     * @example
     * myFileUploader.findBy(
     *                  function (list: IFileItem[]): IFileItem[] {
     *                    return list.filter(function (item: IFileItem) { return !item.isValidationSuccessful; })
     *                  }
     * )
     */
    public findBy(
        searchFn: (list: IFileItem[]) => IFileItem[],
    ): Observable<IFileItem[]> {
        return this.queue.findBy(searchFn);
    }

}
