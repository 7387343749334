import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'avl-snackbar-info-message',
    templateUrl: './snackbar-info-message.component.html',
    styleUrls: ['./snackbar-info-message.component.scss'],
})
export class SnackbarInfoMessageComponent {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: string,
        protected snackRef: MatSnackBarRef<SnackbarInfoMessageComponent>,
    ) {
    }

    public dismiss(): void {
        this.snackRef.dismiss();
    }
}
