import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFileItem } from '../types';
import { FileItem } from './file-item.class';

export class FilesQueue {
    public list$: BehaviorSubject<IFileItem[]> = new BehaviorSubject<IFileItem[]>([]);
    private list: FileItem[] = [];

    /**
     * @param {FileItem} file
     * @returns {void}
     *
     * add file to the list
     */
    public addFile(file: FileItem): void {
        this.list.push(file);
        this.emitListUpdate();
    }

    /**
     * @param {FileItem} file
     * @returns {void}
     *
     * remove file from the list
     */
    public removeFile(file: IFileItem): void {
        const fileIndex = this.list.findIndex((item: FileItem) => item.uuid === file.uuid);

        this.list.splice(fileIndex, 1);
        this.emitListUpdate();
    }

    /**
     * @returns {void}
     *
     * remove all files
     */
    public removeAll(): void {
        this.list = [];
        this.emitListUpdate();
    }

    /**
     * @returns {number}
     *
     * get files amount in a list
     */
    public getListLength(): number {
        return this.list.length;
    }

    /**
     * @returns {FileItem[]} files with uploading in progress
     */
    public getOriginalFilesWithActiveUpload(): FileItem[] {
        return this.list
            .filter((item: FileItem) => item.isUploadInProgress);
    }

    /**
     * @param {string} uuid
     * @returns {FileItem}
     *
     * search by uuid for original fileItem class in a list
     */
    public getOriginalFileItem(uuid: string): FileItem {
        return this.list
            .find((item: FileItem) => item.uuid === uuid);
    }

    /**
     * @param {(item: FileItem) => void} fn
     * @returns {void}
     *
     * loops through each item in files list (only for inner usage)
     */
    public loopOriginalFileItems(
        fn: (item: FileItem) => void,
    ): void {
        const listLength = this.list.length;

        for (let i = 0; i < listLength; i++) {
            fn(this.list[i]);
        }
    }

    /**
     * @returns {FileItem} first FileItem which is queued for upload
     */
    public getFirstOriginalFileItemForUpload(): FileItem {
        return this.list.find((item: FileItem) => item.isUploadQueued);
    }

    /**
     * @returns {Observable<number>}
     */
    public getTotalUploadProgress(): Observable<number> {
        return this.list$
            .pipe(
                map((list: IFileItem[]) => FilesQueue.calculateTotalUploadProgress(list)),
            );
    }

    /**
     * @param {number} index
     * @returns {Observable<IFileItem>}
     *
     * search for file by index
     */
    public getFileByIndex(index: number): Observable<IFileItem> {
        return this.list$
            .pipe(
                map((list: IFileItem[]) => index !== undefined && list[index] ? list[index] : null),
            );
    }

    /**
     * @param {string} uuid
     * @returns {Observable<IFileItem>}
     *
     * search for file by uuid
     */
    public getFileByUuid(uuid: string): Observable<IFileItem> {
        return this.list$
            .pipe(
                map((list: IFileItem[]) => {
                    if (!uuid) {
                        return null;
                    }

                    return list.find((item: IFileItem) => item.uuid === uuid);
                }),
            );
    }

    /**
     * @returns {Observable<IFileItem[]>} files which are uploading
     */
    public getUploadingFiles(): Observable<IFileItem[]> {
        return this.list$
            .pipe(
                map((list: IFileItem[]) => list.filter((item: IFileItem) => item.isUploadInProgress)),
            );
    }

    /**
     * @returns {Observable<IFileItem[]>} files which uploading has failed
     */
    public getUploadFailedFiles(): Observable<IFileItem[]> {
        return this.list$
            .pipe(
                map((list: IFileItem[]) => list.filter((item: IFileItem) => item.isUploadFailed)),
            );
    }

    /**
     * @returns {Observable<IFileItem[]>} files which uploading has succeeded
     */
    public getUploadedFiles(): Observable<IFileItem[]> {
        return this.list$
            .pipe(
                map((list: IFileItem[]) => list.filter((item: IFileItem) => item.isUploadSucceeded)),
            );
    }

    /**
     * @param {(list: IFileItem[]) => IFileItem[]} searchFn  custom search function
     * @returns {Observable<IFileItem[]>}
     *
     * @example
     * myFileUploader.findBy(
     *                  function (list: IFileItem[]): IFileItem[] {
     *                    return list.filter(function (item: IFileItem) { return !item.isValidationSuccessful; })
     *                  }
     * )
     */
    public findBy(
        searchFn: (list: IFileItem[]) => IFileItem[],
    ): Observable<IFileItem[]> {
        return this.list$
            .pipe(
                map((list: IFileItem[]) => searchFn(list)),
            );
    }

    /**
     * @returns {void}
     *
     * emits new value for the 'list$'
     */
    public emitListUpdate(): void {
        this.list$.next(this.getPublicCopy());
    }

    /**
     * @returns {IFileItem[]} public copy of files with cleared js references
     */
    private getPublicCopy(): IFileItem[] {
        return this.list.length
            ? this.list.map((item: FileItem) => item.getPublicCopy())
            : [];
    }

    /**
     * @ignore
     */
    private static calculateTotalUploadProgress(list: IFileItem[]): number {
        let progressIndex = 0;

        if (!list.length) {
            return progressIndex;
        }

        const ratio = 100 / list.length;

        list.forEach((item: IFileItem) => {
            progressIndex += (item.uploadingProgress / 100) * ratio;
        });

        return Math.round(progressIndex);
    }
}
