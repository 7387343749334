<div *ngIf="failedDocuments as documents">
    <div
        *ngIf="documents.length === 1"
        class="error-alert error-alert--single"
        [@fadeRowAnimation]=""
    >
        <div class="error-alert__icon">
            <mat-icon
                class="alert-animated-icon"
                svgIcon="alert"
            ></mat-icon>
            <a
                target="_blank"
                [href]="getDocumentLink(documents[0].id)"
            >
                <mat-icon
                    class="download-icon"
                    svgIcon="download-white"
                ></mat-icon>
            </a>
        </div>
        <p class="error-alert__text">{{ documents[0].message }}</p>
        <button
            class="error-alert__close-btn"
            type="button"
            (click)="removeDocument(documents[0].id)"
        >
            <mat-icon
                class="remove-icon"
                svgIcon="close-white"
            ></mat-icon>
        </button>
    </div>
    <div
        *ngIf="documents.length > 1"
        class="error-alert__multiple"
    >
        <mat-expansion-panel class="error-alert__multiple-panel">
            <mat-expansion-panel-header
                [collapsedHeight]="'45px'"
                [expandedHeight]="'45px'"
            >
                <mat-icon
                    class="error-alert__icon"
                    svgIcon="alert"
                ></mat-icon>
                <p class="error-alert__text">Oops!
                    <b [ngPlural]="documents.length">
                        <ng-template ngPluralCase="=1">1 file</ng-template>
                        <ng-template ngPluralCase="other">{{ documents.length }} files</ng-template>
                    </b>
                    &nbsp;you've uploaded have caused problems
                </p>
            </mat-expansion-panel-header>
            <mat-panel-description *ngFor="let document of documents; trackBy: trackByDocuments">
                <div class="error-alert__icon">
                    <mat-icon
                        class="alert-animated-icon"
                        svgIcon="alert"
                    ></mat-icon>
                    <a
                        target="_blank"
                        [href]="getDocumentLink(document.id)"
                    >
                        <mat-icon
                            class="download-icon"
                            svgIcon="download-white"
                        ></mat-icon>
                    </a>
                </div>
                <p class="error-alert__text">{{  document.message }}</p>
                <div
                    class="error-alert__remove-icon"
                    (click)="removeDocument(document.id)"
                >
                    <mat-icon
                        class="remove-icon"
                        svgIcon="close-white"
                    ></mat-icon>
                </div>
            </mat-panel-description>
        </mat-expansion-panel>
    </div>
</div>
