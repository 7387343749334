import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { animate, animation, keyframes, style, transition, trigger } from '@angular/animations';

import { Subscription } from 'rxjs';
import {
    clearSelectedFeatureList,
    fetchTitleData,
    focusFeature,
    highlightFeaturePermanently,
    highlightTitleTemporary,
    MapSearchQuery,
    resetTitleData,
    updateMapMarkerPosition,
} from 'app/titles/store/map-search';
import { Actions } from '@datorama/akita-ng-effects';
import { ITitleInfo, ShortTitleInfo } from '../../../types';

@Component({
    selector: 'avl-content-sidebar',
    templateUrl: './content-sidebar.component.html',
    styleUrls: ['./content-sidebar.component.scss'],
    animations: [
        trigger('fadeInLeft', [
            transition(':enter', [
                style({ visibility: 'hidden' }),
                animation([
                    animate(
                        '{{duration}}ms {{delay}}ms',
                        keyframes([
                            style({
                                visibility: 'visible',
                                opacity: 0,
                                transform: 'translate3d(-{{translate}}, 0, 0)',
                                easing: 'ease',
                                offset: 0,
                            }),
                            style({ opacity: 1, transform: 'translate3d(0, 0, 0)', easing: 'ease', offset: 1 }),
                        ]),
                    ),
                ]),
            ], {
                params: {
                    delay: 0,
                    duration: 500,
                    translate: '100%',
                },
            }),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentSidebarComponent implements OnInit, OnDestroy {
    public isSidebarOpened = false;
    public detailsData?: ITitleInfo;
    public isLoading = false;
    public list: ShortTitleInfo[];

    private readonly sub = new Subscription();

    constructor(
        private readonly mapSearchQuery: MapSearchQuery,
        private readonly ref: ChangeDetectorRef,
        private readonly actions: Actions,
    ) {
    }

    public ngOnInit(): void {
        this.sub.add(
            this.mapSearchQuery.select(['details'])
                .subscribe(({ details }) => {
                    this.detailsData = details;
                    this.ref.detectChanges();
                }),
        );
        this.sub.add(
            this.mapSearchQuery.isSidePanelLoading()
                .subscribe((isLoading) => {
                    this.isLoading = isLoading;
                    this.ref.detectChanges();
                }),
        );
        this.sub.add(
            this.mapSearchQuery.getUniqueSelectedTitles()
                .subscribe((selectedFeatures) => {
                    this.list = selectedFeatures;
                    this.ref.detectChanges();
                }),
        );
        this.sub.add(
            this.mapSearchQuery.select(['isContentSidebarVisible'])
                .subscribe(({ isContentSidebarVisible }) => {
                    this.isSidebarOpened = isContentSidebarVisible;
                    this.ref.detectChanges();
                }),
        );
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public closeSidebar(): void {
        this.actions.dispatch(resetTitleData());
        this.actions.dispatch(clearSelectedFeatureList());
        this.actions.dispatch(updateMapMarkerPosition({}));
    }

    public showRegistryDetails(titleNumber: string): void {
        this.actions.dispatch(fetchTitleData({ titleNumber }));
        this.actions.dispatch(focusFeature({ titleNumber }));
    }

    public onEmitToBack(): void {
        this.detailsData = null;
    }

    public onTitleClicked(titleNumber: string): void {
        this.actions.dispatch(highlightFeaturePermanently({ titleNumber }));
    }

    public onTitleHovered(titleNumber: string): void {
        this.actions.dispatch(highlightTitleTemporary({ titleNumber }));
    }

    public onTitleUnhovered(): void {
        this.removeHover();
    }

    public removeHover(): void {
        this.actions.dispatch(highlightTitleTemporary({ titleNumber: '' }));
    }
}
