import { v1 } from 'uuid';
import { FileValidationErrors, IFileAsObject, IFileItem } from '../types';
import { FileAsObject } from './file-as-object.class';

/**
 * represents interface {IFileItem} with extra methods for inner usage
 */
export class FileItem implements IFileItem {
    /**
     * progress of uploading in range 0-100
     */
    public uploadingProgress = 0;

    /**
     * validity status: <b>true</b> - if valid, <b>false</b> - if not
     */
    public isValidationSuccessful = false;

    /**
     * <b>true</b> - if file is queued for the upload but uploading has not yet started
     */
    public isUploadQueued = false;

    /**
     * <b>true</b> - if uploading has started
     */
    public isUploadInProgress = false;

    /**
     * <b>true</b> - if uploading has succeeded
     */
    public isUploadSucceeded = false;

    /**
     * <b>true</b> - if uploading has failed
     */
    public isUploadFailed = false;

    /**
     * <b>true</b> - if uploading has been canceled by user
     */
    public isUploadCanceled = false;

    /**
     * uniq identifier for the file in the list
     */
    public uuid: string;

    /**
     * {File} as object representation with original data source
     */
    public fileAsObject: IFileAsObject;

    /**
     * contains validation error if such was detected
     */
    public validationError: FileValidationErrors;

    /**
     * container for the request, to provide 'cancel request' possibility (only inner usage)
     */
    public requestRemover: any;

    /**
     * @param {File} file
     */
    constructor(file: File) {
        this.uuid = v1();
        this.fileAsObject = new FileAsObject(file);
    }

    /**
     * @returns {IFileItem} shallow class copy for the usage outside of the FileUploader
     *
     * <b>doesn't</b> contain any methods
     *
     * the copy can be safely used outside, any changes to the object outside <b>won't</b> be applied to the class by js references
     */
    public getPublicCopy(): IFileItem {
        const copy = Object.assign({}, this, {
            fileAsObject: { ...this.fileAsObject },
        });
        delete copy.requestRemover;

        return copy;
    }

    /**
     * @param {boolean} isValid
     * @param {FileValidationErrors} error
     * @returns {void}
     *
     * sets file validation status
     */
    public setValidationStatus(isValid: boolean | undefined = false, error: FileValidationErrors = null): void {
        this.isValidationSuccessful = isValid;
        this.validationError = error;
    }

    /**
     * @param {number} value
     * @returns {void}
     *
     * sets uploading progress value
     */
    public setUploadingProgress(value: number | undefined = 0): void {
        this.uploadingProgress = value;
    }

    /**
     * @returns {void}
     *
     * sets all file properties to exact state 'uploading queued'
     */
    public setUploadingQueue(): void {
        this.setUploadingProgress();
        this.isUploadQueued = true;
        this.isUploadInProgress = false;
        this.isUploadSucceeded = false;
        this.isUploadFailed = false;
        this.isUploadCanceled = false;
    }

    /**
     * @param {any} requestRemover
     * @returns {void}
     *
     * sets all file properties to exact state 'uploading started'
     *
     * stores request for further usage
     */
    public setUploadingStarted(requestRemover: any): void {
        this.setUploadingProgress();
        this.isUploadQueued = false;
        this.isUploadInProgress = true;
        this.isUploadSucceeded = false;
        this.isUploadFailed = false;
        this.isUploadCanceled = false;
        this.requestRemover = requestRemover;
    }

    /**
     * @returns {void}
     *
     * sets all file properties to exact state 'uploading succeeded'
     */
    public setUploadingSucceeded(): void {
        this.setUploadingProgress(100);
        this.isUploadQueued = false;
        this.isUploadInProgress = false;
        this.isUploadSucceeded = true;
        this.isUploadFailed = false;
        this.isUploadCanceled = false;
        this.requestRemover = null;
    }

    /**
     * @returns {void}
     *
     * sets all file properties to exact state 'uploading failed'
     */
    public setUploadingFailed(): void {
        this.setUploadingProgress();
        this.isUploadQueued = false;
        this.isUploadInProgress = false;
        this.isUploadSucceeded = false;
        this.isUploadFailed = true;
        this.isUploadCanceled = false;
        this.requestRemover = null;
    }

    /**
     * @returns {void}
     *
     * sets all file properties to exact state 'uploading canceled'
     *
     * removes stored request if was set
     */
    public setUploadingCanceled(): void {
        this.setUploadingProgress();
        this.isUploadQueued = false;
        this.isUploadInProgress = false;
        this.isUploadSucceeded = false;
        this.isUploadFailed = false;
        this.isUploadCanceled = true;
        this.requestRemover = null;
    }

}
