import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@datorama/akita-ng-effects';

import { of } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { MapSearchApi } from '../../api';
import {
    clearHighlightedFeatures,
    clearSelectedFeatureList,
    clearState,
    fetchMapGeoJson,
    fetchOutsideFeatures,
    fetchSelectedTitles,
    fetchTitleData,
    focusFeature,
    highlightFeaturePermanently,
    highlightTitleTemporary,
    initUrlParams,
    loadTitleFeatureLocationAndSearchByTitleNumber,
    resetTitleData,
    restoreStateBeforeFocusing,
    searchByAddress,
    searchByTitleNumber,
    setMapComponentInitialized,
    toggleSidePanel,
    updateMapCenter,
    updateMapFilter,
    updateMapMarkerPosition,
    updateMapZoom,
    updateTitleDetails,
} from './map-search.actions';
import { FolderQuery } from '../folder';
import { MapSearchStore } from './map-search.store';
import { GeoSearchApi } from '../../api';
import { ITitleInfo } from '../../types';
import { mapSearchMaxZoom, mapSearchMaxZoomFeatureVisibility, mapSearchMinZoom, mapSearchZoomAfterSearch } from '@constants';
import { UrlParamsService } from '@services';
import { MapService } from '../../services/map.service';
import { FeatureUtilsService } from '../../services/feature-utils.service';
import { GeoJSONFeature, LngLat } from 'maplibre-gl';
import { MapSearchService } from './map-search.service';

@Injectable()
export class MapSearchEffects {
    @Effect()
    public initUrlParams$ = this.actions$.pipe(
            ofType(initUrlParams),
            tap(() => {
                const storedValues = this.store.getValue();
                const params = {
                    titleNumber: storedValues.details?.titleNumber,
                    isFreeholdsOn: storedValues.isFreeholdsOn,
                    isLeaseholdsOn: storedValues.isLeaseholdsOn,
                    zoom: storedValues.zoom,
                    lat: storedValues.center.lat,
                    lng: storedValues.center.lng,
                };

                this.urlParams.addParams({ ...params });
            }),
        );

    @Effect()
    public updateMapZoom$ = this.actions$.pipe(
            ofType(updateMapZoom),
            filter(({ zoom }) => mapSearchMinZoom <= zoom && zoom <= mapSearchMaxZoom),
            tap(({ zoom }) => this.store.update({ zoom })),
            tap(({ zoom }) => {
                const isFiltersVisible = zoom >= mapSearchMaxZoomFeatureVisibility;
                this.store.update({ isFiltersVisible });
            }),
            tap(({ zoom }) => this.urlParams.addParams({ zoom })),
        );

    @Effect()
    public updateMapCenter$ = this.actions$.pipe(
            ofType(updateMapCenter),
            debounceTime(500),
            tap(({ center }) => this.store.update({ center })),
            tap(({ center }) => this.urlParams.addParams({ lat: center.lat, lng: center.lng })),
        );

    @Effect()
    public updateMapMarkerPosition$ = this.actions$.pipe(
            ofType(updateMapMarkerPosition),
            tap(({ point }) => {
                if (!point) {
                    return this.store.update({ markerPosition: null });
                }

                this.store.update({ markerPosition: point });
                this.urlParams.addParams({ markerLat: point.lat, markerLng: point.lng });
            }),
        );

    @Effect()
    public updateMapFilter$ = this.actions$.pipe(
            ofType(updateMapFilter),
            tap(({ isFreeholdsOn, isLeaseholdsOn }) => this.store.update({ isLeaseholdsOn, isFreeholdsOn })),
            tap(({ isFreeholdsOn, isLeaseholdsOn }) => this.urlParams.addParams({ isFreeholdsOn, isLeaseholdsOn })),
        );

    @Effect()
    public fetchMapGeoJson$ = this.actions$.pipe(
            ofType(fetchMapGeoJson),
            filter(() => this.store.getValue().zoom >= mapSearchMaxZoomFeatureVisibility),
            filter(({ bounds }) => {
                const featuresMap = this.store.getValue().featuresMap;
                const previousBounds = this.store.getValue().bounds;

                return previousBounds && bounds && Object.keys(featuresMap).length
                    ? !this.mapService.isPreviousBoundsIncludeNew(previousBounds, bounds)
                    : true;
            }),
            tap(({ bounds }) => this.store.update({ bounds })),
            tap(() => this.store.setLoading(true)),
            switchMap(({ bounds }) => this.geoSearchApi.geoSearch(bounds, { zoom: this.store.getValue().zoom })),
            tap((geoJson) => {
                const selectedFeatures = this.store.getValue().selectedFeatures;
                const selectedAndNewFeatures = this.featuresUtils.joinUniqByPolyId(geoJson.features, selectedFeatures);
                const featuresMap = this.featuresUtils.convertToMap(selectedAndNewFeatures);

                this.store.update({ featuresMap: featuresMap });
            }),
            tap(() => this.store.setLoading(false)),
        );

    @Effect({ dispatch: true })
    public fetchTitleData$ = this.actions$.pipe(
            ofType(fetchTitleData),
            tap(() => this.store.update({ isSidePanelLoading: true })),
            switchMap(({ titleNumber }) => {
                const previousDetails = this.store.getValue().details;
                const folderId = this.folderQuery.getFolderId();

                return previousDetails && previousDetails.titleNumber === titleNumber
                    ? of([previousDetails])
                    : this.mapSearchService.getTitleDetails(folderId, titleNumber, { isCacheEnabled: true });
            }),
            map((data) => updateTitleDetails(data)),
        );

    @Effect()
    public focusFeature$ = this.actions$.pipe(
            ofType(focusFeature),
            tap(({ titleNumber }) => {
                const features = this.store.getValue().featuresMap[titleNumber];

                if (features) {
                    const mappedFeature = Array.isArray(features) ? features : [features];
                    const zoom = this.store.getValue().zoom;
                    const center = this.store.getValue().center;

                    this.store.update({
                        focusedFeatures: mappedFeature,
                        stateBeforeFocusing: { zoom, center },
                        isSelectionUnderPinBlocked: true,
                    });
                }
            }),
        );

    @Effect()
    public restoreStateBeforeFocusing$ = this.actions$.pipe(
            ofType(restoreStateBeforeFocusing),
            tap(() => {
                const state = this.store.getValue().stateBeforeFocusing;

                if (!state) {
                    return;
                }

                this.store.navigateTo(state.center, state.zoom);
                this.store.update({
                    focusedFeatures: null,
                    stateBeforeFocusing: null,
                    isSelectionUnderPinBlocked: false,
                });
            }),
        );

    @Effect()
    public updateTitleDetails$ = this.actions$.pipe(
            ofType(updateTitleDetails),
            filter((data) => !!data),
            map((data: ITitleInfo[]) => data[0]),
            tap((titleInfo) => this.store.update({ details: titleInfo, isSidePanelLoading: false })),
            tap((titleInfo) => this.urlParams.addParams({ 'titleNumber': titleInfo.titleNumber })),
        );

    @Effect()
    public resetTitleData$ = this.actions$.pipe(
            ofType(resetTitleData),
            tap(() => {
                this.mapSearchService.resetTitleData();
            }),
        );

    @Effect()
    public clearSelectedFeaturesList$ = this.actions$.pipe(
            ofType(clearSelectedFeatureList),
            tap(() => this.actions$.dispatch(toggleSidePanel({ isVisible: false }))),
            tap(() => this.actions$.dispatch(clearHighlightedFeatures())),
        );

    @Effect()
    public toggleSidePanel$ = this.actions$.pipe(
            ofType(toggleSidePanel),
            tap(({ isVisible }) => this.store.update({ isContentSidebarVisible: isVisible })),
            tap(({ isVisible }) => {
                const permanentlyHighlightedTitleNumber = this.store.getValue().permanentlyHighlightedTitleNumber;
                if (!isVisible && permanentlyHighlightedTitleNumber) {
                    this.store.update({ permanentlyHighlightedTitleNumber: null });
                }
            }),
        );

    @Effect()
    public highlightFeatureTemporary$ = this.actions$.pipe(
            ofType(highlightTitleTemporary),
            debounceTime(100),
            tap(({ titleNumber }) => {
                this.store.update({ temporaryHighlightedTitleNumber: titleNumber });
            }),
        );

    @Effect()
    public highlightFeaturePermanently$ = this.actions$.pipe(
            ofType(highlightFeaturePermanently),
            tap(({ titleNumber }) => {
                this.store.update({
                    permanentlyHighlightedTitleNumber: titleNumber,
                    predefinedTitleNumberForHighlight: null,
                });
            }),
        );

    @Effect()
    public searchByAddress$ = this.actions$.pipe(
            ofType(searchByAddress),
            tap(() => {
                this.actions$.dispatch(toggleSidePanel({ isVisible: false }));
                this.mapSearchService.resetTitleData();
            }),
            switchMap(({ placeId }) =>
                this.geoSearchApi.getDetailsByPlaceId(placeId)
                    .pipe(
                        map((addressInfo) => addressInfo.geometry.location),
                    ),
            ),
            tap((location) => {
                this.store.update({
                    zoom: mapSearchZoomAfterSearch,
                    isSelectionUnderPinBlocked: false,
                    temporaryHighlightedTitleNumber: null,
                });
                this.actions$.dispatch(updateMapFilter({ isFreeholdsOn: false, isLeaseholdsOn: false }));
                this.store.navigateTo(location);
                this.actions$.dispatch(updateMapMarkerPosition({ point: location }));
                this.actions$.dispatch(resetTitleData());
            }),
        );

    @Effect()
    public searchByTitleNumber$ = this.actions$.pipe(
            ofType(searchByTitleNumber),
            filter(({ titleNumber }) => !!titleNumber),
            tap(({ location, titleNumber }) => {
                const currentTitleNumber = this.store.getValue().details?.titleNumber;
                const currentLocation = this.store.getValue().center;
                const markerLocation = this.store.getValue().markerPosition;

                const isTitleNumberDifferent = currentTitleNumber !== titleNumber;
                if (isTitleNumberDifferent) {
                    this.mapSearchService.resetTitleData();
                    this.store.update({ isSidePanelLoading: true });
                }

                const isLocationDifferent = this.mapService.isPointsDifferent(currentLocation, location);
                const isMarkerLocationDifferent = this.mapService.isPointsDifferent(currentLocation, markerLocation);
                if (isLocationDifferent || isMarkerLocationDifferent) {
                    this.store.navigateTo(location);
                    this.actions$.dispatch(updateMapMarkerPosition({ point: location }));
                }

                const isZoomLessThanMin = this.store.getValue().zoom < mapSearchZoomAfterSearch;
                if (isZoomLessThanMin) {
                    this.store.update({ zoom: mapSearchZoomAfterSearch });
                }

                this.store.update({
                    predefinedTitleNumberForHighlight: titleNumber,
                    isSelectionUnderPinBlocked: false,
                    temporaryHighlightedTitleNumber: null,
                });
                this.actions$.dispatch(toggleSidePanel({ isVisible: true }));
                this.actions$.dispatch(updateMapFilter({ isFreeholdsOn: false, isLeaseholdsOn: false }));
            }),
        );

    @Effect()
    public loadTitleFeatureLocationAndSearchByTitleNumber$ = this.actions$.pipe(
            ofType(loadTitleFeatureLocationAndSearchByTitleNumber),
            filter(({ markerPosition }) => !!markerPosition?.lng && !!markerPosition?.lat),
            tap(({ titleNumber, markerPosition }) => {
                const point = new LngLat(markerPosition?.lng, markerPosition?.lat);
                this.actions$.dispatch(updateMapMarkerPosition({ point }));

                if (titleNumber) {
                    this.actions$.dispatch(fetchTitleData({ titleNumber }));
                }
            }),
        );

    @Effect()
    public clearState$ = this.actions$.pipe(
            ofType(clearState),
            tap(() => {
                this.store.update({
                    details: null,
                    focusedFeatures: null,
                    stateBeforeFocusing: null,
                    isSelectionUnderPinBlocked: false,
                    selectedFeatures: [],
                    isContentSidebarVisible: false,
                    bounds: null,
                    predefinedTitleNumberForHighlight: null,
                    markerPosition: null,
                    featuresMap: {},
                    previousShowTitleId: null,
                    temporaryHighlightedTitleNumber: null,
                    permanentlyHighlightedTitleNumber: null,
                    sidePanelTitles: [],
                    isSidePanelLoading: false,
                    isFreeholdsOn: true,
                    isLeaseholdsOn: true,
                    isMapComponentInitialized: false,
                });
                this.urlParams.removeParams(this.urlMapParams);
            }),
        );

    @Effect()
    public clearHighlightedFeatures$ = this.actions$.pipe(
            ofType(clearHighlightedFeatures),
            tap(() => this.store.update({
                temporaryHighlightedTitleNumber: null,
                permanentlyHighlightedTitleNumber: null,
            })),
        );

    @Effect()
    public fetchSelectedTitles$ = this.actions$.pipe(
            ofType(fetchSelectedTitles),
            tap(() => this.store.update({ isSidePanelLoading: true })),
            switchMap(({ titleNumber, point }) => this.mapSearchApi.selection({ isCacheEnabled: true, point, titleNumber })),
            // Choose all selected features from loaded list of features
            tap((selectedTitles) => {
                const loadedFeatures = this.store.getValue().featuresMap;
                const selectedFeatures: GeoJSONFeature[] = [];

                selectedTitles.forEach((title) => {
                    const titleNumber = title.titleNumber;
                    const feature = loadedFeatures[titleNumber];

                    if (feature) {
                        if (Array.isArray(feature)) {
                            selectedFeatures.push(...feature);
                        } else {
                            selectedFeatures.push(feature);
                        }
                    }
                });

                this.store.update({ sidePanelTitles: selectedTitles, selectedFeatures: selectedFeatures });
                this.actions$.dispatch(fetchOutsideFeatures());
            }),
            // Load a title details if only a title was selected
            tap((selectedTitles) => {
                if (selectedTitles.length === 1) {
                    const titleNumber = selectedTitles[0].titleNumber;
                    this.actions$.dispatch(fetchTitleData({ titleNumber }));
                } else {
                    this.mapSearchService.resetTitleData();
                    this.store.update({ isSidePanelLoading: false });
                }
            }),
            // Highlight title
            tap(() => {
                const predefinedTitleNumberForHighlight = this.store.getValue().predefinedTitleNumberForHighlight;
                if (predefinedTitleNumberForHighlight) {
                    this.actions$.dispatch(highlightFeaturePermanently({ titleNumber: predefinedTitleNumberForHighlight }));
                }
            }),
        );

    @Effect()
    public setMapComponentInitialized$ = this.actions$.pipe(
            ofType(setMapComponentInitialized),
            tap(() => this.store.update({ isMapComponentInitialized: true })),
        );

    @Effect()
    public fetchOutsideFeatures$ = this.actions$.pipe(
            ofType(fetchOutsideFeatures),
            filter(() => this.store.getValue().zoom >= mapSearchMaxZoomFeatureVisibility),
            filter(() => !!this.store.getValue().bounds),
            switchMap(() => {
                const zoom = this.store.getValue().zoom;
                const features = this.store.getValue().featuresMap;
                const titlesNumbersFromSidePanel = this.store.getValue().sidePanelTitles
                    .map((el) => el.titleNumber);
                const titleNumbersWithoutPolygons = titlesNumbersFromSidePanel.filter((titleNumber) => !features[titleNumber]);

                return titleNumbersWithoutPolygons.length
                    ? this.geoSearchApi.geoSearch(null, { zoom, includeTitles: titleNumbersWithoutPolygons })
                    : of(null);
            }),
            filter((geoJson) => !!geoJson),
            tap((geoJson) => {
                const features = this.store.getValue().featuresMap;
                const newFeatures = geoJson.features;
                const resultFeatures = { ...features };

                newFeatures.forEach((newFeature) => {
                    const newFeatureTitleNumber = newFeature.properties.title_number;
                    const newFeaturePolyId = newFeature.properties.poly_id;
                    const existedFeatures = resultFeatures[newFeatureTitleNumber];

                    if (!existedFeatures) {
                        resultFeatures[newFeatureTitleNumber] = newFeature;

                        return;
                    }

                    if (Array.isArray(existedFeatures)) {
                        const isFeatureExists = existedFeatures.some((el) => el.properties.poly_id === newFeaturePolyId);
                        if (!isFeatureExists) {
                            existedFeatures.push(newFeature);
                        }
                    } else {
                        const isFeatureExists = existedFeatures.properties.poly_id === newFeaturePolyId;
                        if (!isFeatureExists) {
                            resultFeatures[existedFeatures.properties.title_number] = [existedFeatures, newFeature];
                        }
                    }
                });

                this.store.update({ featuresMap: resultFeatures });
            }),
            tap(() => this.store.setLoading(false)),
        );

    private readonly urlMapParams = ['zoom', 'lat', 'lng', 'titleNumber', 'markerLat', 'markerLng', 'isFreeholdsOn', 'isLeaseholdsOn', 'searchType'];

    constructor(
        private readonly actions$: Actions,
        private readonly store: MapSearchStore,
        private readonly folderQuery: FolderQuery,
        private readonly mapSearchApi: MapSearchApi,
        private readonly geoSearchApi: GeoSearchApi,
        private readonly urlParams: UrlParamsService,
        private readonly mapService: MapService,
        private readonly featuresUtils: FeatureUtilsService,
        private readonly mapSearchService: MapSearchService,
    ) {
    }
}
