<div
    *ngIf="isLoading"
    class="loading-spinner-container"
>
    <div class="loading-spinner">
        <avl-spinner
            [strokeWidth]="3"
            [diameter]="30"
        ></avl-spinner>
    </div>
</div>

<avl-maplibre
    *ngIf="isProxyAccessKeyLoaded"
    class="map"
    [style]="styleLink"
    [zoom]="zoom"
    [maxZoom]="mapSearchMaxZoom"
    [minZoom]="mapSearchMinZoom"
    [center]="[center.lng, center.lat]"
    (mapLoad)="onMapInit($event)"
    (zoomEnd)="onZoomChanged($event)"
    (moveEnd)="onCenterChanged($event)"
    [transformRequest]="transformRequest"
    (mapResize)="onMapResize()"
    (mapError)="mapError($event)"
></avl-maplibre>

<div
    id="popup-template"
    class="hidden"
>
    <div class="popup-content">
        <div
            *ngIf="popupData?.freehold?.length"
            class="map-info-item"
        >
            <div class="popup-item-head">
                Freehold
            </div>
            <div
                *ngFor="let item of popupData?.freehold"
                class="popup-title-number"
            >
                {{ item.properties.title_number }}
            </div>
        </div>
        <div
            *ngIf="popupData?.leasehold?.length"
            class="map-info-item"
        >
            <div class="popup-item-head">
                Leasehold
            </div>
            <div
                *ngFor="let item of popupData?.leasehold"
                class="popup-title-number"
            >
                {{ item.properties.title_number }}
            </div>
        </div>
    </div>
</div>
