import { Component, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'avl-selection-toolbar',
    templateUrl: './selection-toolbar.component.html',
    styleUrls: ['./selection-toolbar.component.scss'],
})
export class SelectionToolbarComponent {
    @Input()
    public total: number;

    @Input()
    public allFilesSelected = new EventEmitter<void>();
}
