import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[avlElementOver]',
})
export class ElementOverDirective implements OnInit {

    @Input()
    private readonly template: Element;

    @Input()
    private readonly xShift = 0;

    @Input()
    private readonly yShift = 0;

    private readonly targetElement: HTMLElement;

    constructor(private readonly elementRef: ElementRef) {
        this.targetElement = elementRef.nativeElement;
        this.targetElement.style.position = 'fixed';
    }

    public ngOnInit(): void {
        this.render();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        this.render();
    }

    private render(): void {
        if (!this.targetElement) {
            return;
        }

        const templateRect = this.template.getBoundingClientRect();

        this.targetElement.style.left = `${templateRect.x + this.xShift}px`;
        this.targetElement.style.top = `${templateRect.y + this.yShift}px`;
    }
}
