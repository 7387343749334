import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type IConfirmDialogData = {
    color: 'red' | 'blue' | 'green' | 'yellow' | 'bright-red' | 'light-yellow' | 'notice-yellow';
    icon: string;
    class?: string;
    title: string;
    subtitle?: string;
    confirmButton: string;
    unConfirmButton: string;
}

@Component({
    selector: 'avl-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: IConfirmDialogData,
    ) {
    }
}
