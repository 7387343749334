/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Intercom } from 'ng-intercom';
import { AuthService } from '@auth/services';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root',
})
export class IntercomService {

    constructor(
        private readonly intercom: Intercom,
        private readonly authService: AuthService,
        private readonly log: LoggerService,
    ) {
    }

    public show(): void {
        this.intercom.show();
    }

    public load(): void {
        if (!environment.enableIntercom) {
            return this.log.warn('Intercom is disabled by env variable');
        }

        this.authService.user$
            .subscribe((user) => {
                const intercomUserData = {
                    app_id: environment.intercomData.appId,
                    widget: environment.intercomData.widget,
                    custom_launcher_selector: environment.intercomData.customLauncherSelector,
                    hide_default_launcher: environment.intercomData.hideDefaultLauncher,
                    user_hash: environment.intercomData.userHash,
                    email: null,
                    created_at: null,
                    user_id: null,
                };

                if (user) {
                    intercomUserData.email = user.email;
                    intercomUserData.user_id = user.uid;
                    intercomUserData.created_at = user.metadata.creationTime;

                    user.getIdTokenResult()
                        .then((idTokenResult) => {
                            intercomUserData.user_hash = idTokenResult.claims.hash;
                            this.intercom.boot(intercomUserData);
                        })
                        .catch((error) => {
                            this.log.error('Unable to set user\'s hash code.', error);
                            this.intercom.boot(intercomUserData);
                        });
                }
            });
    }
}
