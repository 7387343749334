<ng-template #uploadingTools>
    <avl-drop-area
        [loading$]="documentsLoading$"
        [landRegistryDisabled]="landRegistryButtonDisabled"
        [imanageDisabled]="imanageButtonDisabled"
        (landRegistryOpened)="onLandRegistryOpened()"
        (imanageOpened)="onImanageOpened()"
        (newFolderCreated)="navigateToNewFolder($event)"
    ></avl-drop-area>
    <avl-error-alert
        *ngIf="!documentsLoading"
        [folderId]="folderId"
        [failedDocuments]="failedDocuments$ | async"
        (documentRemoved)="onFailedDocumentRemove($event)"
    ></avl-error-alert>
    <avl-purchase-error-alert
        *ngIf="!documentsLoading"
        [folderId]="folderId"
        [failedPurchases]="failedPurchases$ | async"
        (purchaseRemoved)="onFailedPurchaseRemove($event)"
    ></avl-purchase-error-alert>
    <avl-documents
        *ngIf="succeedDocuments.length"
        avlElevate
        name="titles-table"
        [isDragDropEnabled]="isDragDropEnabled"
        [loading]="documentsLoading"
        [class.titles-documents]="succeedDocuments?.length"
        [initialSort]="sortType"
        [initialSortDirection]="orderType"
        [documents]="succeedDocuments"
    ></avl-documents>
    <button
        *ngIf="succeedDocuments?.length"
        avlElevate
        class="avl-btn avl-btn--blue avl-btn--wide titles-btn"
        name="next-button"
        [disabled]="documentsLoading || isDisabledBtn || !hasTitles.getValue()"
        (click)="onNextButtonClick()"
    >
        Next
    </button>
    <!--Imanage Iframe  -->
    <ng-container *ngIf="showImanageIframe">
        <avl-imanage-auth
            [folderId]="folderId"
            [loading$]="documentsLoading$"
        ></avl-imanage-auth>
    </ng-container>
</ng-template>

<ng-container *ngIf="isOldTheme">
    <ng-template [ngTemplateOutlet]="uploadingTools"></ng-template>
</ng-container>

<div
    *ngIf="!isOldTheme"
    class="page-container"
>
    <div class="upload-tools">
        <ng-template [ngTemplateOutlet]="uploadingTools"></ng-template>
    </div>
    <avl-search-tools-side-menu
        class="other-options"
        [searchRegistry]="searchRegistry$ | async"
        (searchRegistryChanged)="changeSearchRegistry($event)"
        (searchModalOpened)="showRegistrySearchDialog($event)"
    ></avl-search-tools-side-menu>
</div>
