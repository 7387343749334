/* eslint-disable @typescript-eslint/naming-convention */
import { MimeClassName } from '../types';

const MimeTypeVariations: {
    [key: string]: string[];
} = {
    /*  MS Office  */
    mimeDoc: [
        'application/msword',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-word.document.macroEnabled.12',
        'application/vnd.ms-word.template.macroEnabled.12',
    ],
    mimeXsl: [
        'application/vnd.ms-excel',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    ],
    mimePpt: [
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    ],
    /* PSD */
    mimePsd: [
        'image/photoshop',
        'image/x-photoshop',
        'image/psd',
        'application/photoshop',
        'application/psd',
        'zz-application/zz-winassoc-psd',
    ],
    /* Compressed files */
    mimeCompress: [
        'application/x-gtar',
        'application/x-gcompress',
        'application/compress',
        'application/x-tar',
        'application/x-rar-compressed',
        'application/octet-stream',
    ],
};

const FileExtensionMimeClass: {
    [key: string]: MimeClassName;
} = {
    'jpg': 'image',
    'jpeg': 'image',
    'tif': 'image',
    'psd': 'image',
    'bmp': 'image',
    'png': 'image',
    'nef': 'image',
    'tiff': 'image',
    'cr2': 'image',
    'dwg': 'image',
    'cdr': 'image',
    'ai': 'image',
    'indd': 'image',
    'pin': 'image',
    'cdp': 'image',
    'skp': 'image',
    'stp': 'image',
    '3dm': 'image',
    'mp3': 'audio',
    'wav': 'audio',
    'wma': 'audio',
    'mod': 'audio',
    'm4a': 'audio',
    'compress': 'compress',
    'rar': 'compress',
    '7z': 'compress',
    'lz': 'compress',
    'z01': 'compress',
    'pdf': 'pdf',
    'xls': 'xls',
    'xlsx': 'xls',
    'ods': 'xls',
    'mp4': 'video',
    'avi': 'video',
    'wmv': 'video',
    'mpg': 'video',
    'mts': 'video',
    'flv': 'video',
    '3gp': 'video',
    'vob': 'video',
    'm4v': 'video',
    'mpeg': 'video',
    'm2ts': 'video',
    'mov': 'video',
    'doc': 'doc',
    'docx': 'doc',
    'eps': 'doc',
    'txt': 'doc',
    'odt': 'doc',
    'rtf': 'doc',
    'ppt': 'ppt',
    'pptx': 'ppt',
    'pps': 'ppt',
    'ppsx': 'ppt',
    'odp': 'ppt',
};

/**
 * @param {string} fileName
 * @returns {MimeClassName} matched mime class name by file extension
 */
function detectFileType(fileName: string): MimeClassName {
    const chunks = fileName.split('.');

    if (chunks.length < 2) {
        return 'application';
    }

    const extension = chunks[chunks.length - 1].toLowerCase();

    return FileExtensionMimeClass[extension] ? FileExtensionMimeClass[extension] : 'application';
}

/**
 * @param {File} file
 * @returns {MimeClassName} matched mime class name by file type or extension
 */
export function getFileMimeClassMatch(file: File): MimeClassName {
    let mimeClass: MimeClassName = 'application';

    if (MimeTypeVariations.mimePsd.includes(file.type)) {
        mimeClass = 'image';
    } else if (file.type.match('image.*')) {
        mimeClass = 'image';
    } else if (file.type.match('video.*')) {
        mimeClass = 'video';
    } else if (file.type.match('audio.*')) {
        mimeClass = 'audio';
    } else if (file.type === 'application/pdf') {
        mimeClass = 'pdf';
    } else if (MimeTypeVariations.mimeCompress.includes(file.type)) {
        mimeClass = 'compress';
    } else if (MimeTypeVariations.mimeDoc.includes(file.type)) {
        mimeClass = 'doc';
    } else if (MimeTypeVariations.mimeXsl.includes(file.type)) {
        mimeClass = 'xls';
    } else if (MimeTypeVariations.mimePpt.includes(file.type)) {
        mimeClass = 'ppt';
    }

    if (mimeClass === 'application') {
        mimeClass = detectFileType(file.name);
    }

    return mimeClass;
}
