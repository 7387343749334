import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { REMOVE_PURCHASED_TITLE_DOCUMENT_CONFIRM_DATA, REMOVE_UPLOADED_TITLE_DOCUMENT_CONFIRM_DATA } from '@constants';

import { ID, Order } from '@datorama/akita';
import { DocumentsService } from '../../services';
import { OnboardingManageService } from '../../../onboarding/services';

import { DocumentsQuery, FolderQuery } from '../../store';
import { convertOrderTypeToOrder, convertSortTypeToColumnName, convertToSortType, DocumentSource, IDocument, SortType } from '../../types';

import { ConfirmDialogComponent, IConfirmDialogData } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';

import { tableAnimation } from '../../../core/animations/table-rows.animation';

import { map, switchMap } from 'rxjs/operators';
import { NEVER } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OrderType } from '@enums';

@Component({
    selector: 'avl-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    animations: [tableAnimation],
})
export class DocumentsComponent implements AfterViewInit, OnChanges {
    public readonly convertSortTypeToColumnName = convertSortTypeToColumnName;
    public readonly convertOrderTypeToOrder = convertOrderTypeToOrder;

    @Input()
    public isDragDropEnabled = false;

    @Input()
    public initialSort: SortType = SortType.manual;

    @Input()
    public initialSortDirection: OrderType = OrderType.asc;

    @Input()
    public loading: boolean;

    public isDocumentsUploadingFinished = false;
    public rowWidth = 0;

    @ViewChild('container', { static: true })
    private readonly container: ElementRef;

    private _documents: IDocument[];
    private lastSortParams?: Sort;

    constructor(
        private readonly documentsService: DocumentsService,
        private readonly documentsQuery: DocumentsQuery,
        private readonly folderQuery: FolderQuery,
        private readonly dialog: MatDialog,
        private readonly onboarding: OnboardingManageService,
    ) {
    }

    public get documents(): IDocument[] {
        return this._documents;
    }

    @Input()
    public set documents(value: IDocument[]) {
        if (this.lastSortParams) {
            this.sortDocuments(this.lastSortParams);
        } else {
            this._documents = value;
        }
    }

    public ngAfterViewInit(): void {
        this.rowWidth = this.container.nativeElement.offsetWidth;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.documents) {
            this.isDocumentsUploadingFinished = this.documents.every((doc) => doc.isProcessed);
        }
    }

    public openNewPageWithDocument(documentId: string): void {
        const folderId = this.folderQuery.getValue().id;
        this.documentsService.openDocumentInNewTab(folderId, documentId);
    }

    public sortDocuments(sort: Sort): void {
        this.lastSortParams = sort;
        const fixedActive = sort.active === 'document' ? 'type' : sort.active;
        const sortBy = fixedActive as keyof IDocument;
        const sortByOrder = sort.direction as Order;
        const sortType = convertToSortType(sortBy);

        this.documentsQuery
            .selectAll({ sortBy, sortByOrder })
            .pipe(
                map((documents) => documents.sort((el) => el.isProcessed ? -1 : 1)),
            )
            .subscribe((documents) => {
                this._documents = documents;
            });
        this.updateDocumentsOrderOnServer(sortType, sortByOrder);
    }

    public removeDocument(documentId: string, source: DocumentSource): void {
        if (this.onboarding.isActive) {
            return;
        }

        let dialogData: IConfirmDialogData;

        switch (source) {
            case DocumentSource.scotlis:
            case DocumentSource.landRegistry:
                dialogData = REMOVE_PURCHASED_TITLE_DOCUMENT_CONFIRM_DATA;
                break;
            case DocumentSource.upload:
                dialogData = REMOVE_UPLOADED_TITLE_DOCUMENT_CONFIRM_DATA;
                break;
        }

        if (dialogData) {
            this.showRemoveConfirmDialog(dialogData)
                .afterClosed()
                .pipe(
                    switchMap((isRemoved) => isRemoved ? this.documentsService.removeDocument(documentId) : NEVER),
                )
                .subscribe();
        }
    }

    public trackByDocuments(index: number, document: IDocument): ID {
        return document.id;
    }

    public getPreviewWidth(): string {
        return this.rowWidth - 50 + 'px';
    }

    public changeOrder(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.documents, event.previousIndex, event.currentIndex);
        this.updateDocumentsOrderOnServer(SortType.manual);
    }

    private updateDocumentsOrderOnServer(sort: SortType, order: Order = Order.ASC): void {
        const documentIds = this.documents.map((doc) => doc.id.toString());
        this.documentsService.updateSort({ documentIds, sort, order });
    }

    private showRemoveConfirmDialog(dialogContent: IConfirmDialogData): MatDialogRef<ConfirmDialogComponent> {
        return this.dialog.open(ConfirmDialogComponent, { panelClass: 'confirm-dialog', data: dialogContent });
    }
}
