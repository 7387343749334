import { IFileItem } from './file-item.type';

export enum FilePickerQueueErrors {
    validationMaxQueueLimitExceeded = '[Validation] MaxQueueLimitExceeded'
}

export enum FileValidationErrors {
    validationMaxFileSizeExceeded = '[Validation] MaxFileSizeExceeded',
    validationFileExtensionNotMatched = '[Validation] FileExtensionNotMatched',
    validationMimeClassNotMatched = '[Validation] MimeClassNotMatched',
    validationCustomFailure = '[Validation] CustomFailure',
    fileNoLongerValid = 'FileNoLongerValid'
}

export type FilePickerErrors = FilePickerQueueErrors | FileValidationErrors;

export type IErrorsEmitPayload = {
    errorType: FilePickerErrors;
    file?: IFileItem;
}
