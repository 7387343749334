import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { LandRegistryDialogState, LandRegistryDialogStore, PurchaseFinishEvent } from './land-registry-dialog.store';
import { ITitleInfo } from '../../types';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class LandRegistryDialogQuery extends Query<LandRegistryDialogState> {
    constructor(
        protected store: LandRegistryDialogStore,
    ) {
        super(store);
    }

    public getTitlesFromBasket$(): Observable<ITitleInfo[]> {
        return this.select('basketTitles');
    }

    public getTitlesFromBasket(): ITitleInfo[] {
        return this.getValue().basketTitles;
    }

    public countTitlesInBasket$(): Observable<number> {
        return this.getTitlesFromBasket$()
            .pipe(switchMap((titlesList) => of(titlesList.length)));
    }

    public totalPriceOfTitlesBasket$(): Observable<number> {
        return this.getTitlesFromBasket$()
            .pipe(
                switchMap((titlesList) =>
                    of(titlesList.reduce((acc, value) => acc + value.cost, 0)),
                ),
            );
    }

    public totalPriceOfTitlesBasket(): number {
        const titlesList = this.store.getValue().basketTitles;

        return titlesList.reduce((acc, value) => acc + value.cost, 0);
    }

    public isTitleAddedToBasket$(titleNumber: string): Observable<boolean> {
        return this.select()
            .pipe(
                switchMap((state) => {
                    const titles = state.basketTitles;
                    const isTileExist = titles.some((el) => el.titleNumber === titleNumber);

                    return of(isTileExist);
                }),
            );
    }

    public purchasingFinished$(): Observable<PurchaseFinishEvent> {
        return this.store.purchaseFinished$();
    }
}
