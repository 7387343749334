import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';
import { LoggerService } from '@services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormValidators } from '../../../core/services/form-validators.service';

type ActionCode = {
    email: string;
    code: string;
    valid: boolean;
    errorMessage: string;
}

@Component({
    selector: 'avl-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ResetPasswordComponent implements OnInit {
    public readonly passwordFieldName = 'password';
    public readonly confirmPasswordFieldName = 'confirmPassword';

    public formGroup: FormGroup;
    public code: string;
    public isCodeInvalid = false;
    public urlData: { actionCode: ActionCode };
    public isPasswordVisible = false;
    public isConfirmPasswordVisible = false;
    public succeedMessage = 'The password was successfully changed, click here to login.';
    public errorMessage?: string = null;
    public isResetSuccess = false;
    public email = '';

    constructor(
        private readonly authService: AuthService,
        private readonly route: ActivatedRoute,
        private readonly log: LoggerService,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
    ) {
        this.formGroup = this.formBuilder.group({
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]],
        }, {
            validators: FormValidators.fieldsMatchValidator(this.passwordFieldName, this.confirmPasswordFieldName)
        });
    }

    public ngOnInit(): void {
        this.urlData = this.route.snapshot.data as { actionCode: ActionCode };
        this.log.info('ngOnInit', this.urlData);

        if (this.urlData && this.urlData.actionCode) {
            this.email = this.urlData.actionCode.email;
            this.code = this.urlData.actionCode.code;

            if (!this.urlData.actionCode.valid) {
                this.errorMessage = this.handleUsingSameCodeTwice(this.urlData.actionCode.errorMessage);
                this.isCodeInvalid = true;
                this.disabledFields();
            }
        } else {
            this.errorMessage = 'Invalid reset code, please check the link that has been sent to your email again.';
            this.isCodeInvalid = true;
            this.disabledFields();
        }
    }

    public onTryAgain(): void {
        this.errorMessage = null;
        this.isResetSuccess = false;
        this.router.navigate(['/reset-password-req']);
    }

    public submitReset(): void {
        this.errorMessage = null;
        this.isResetSuccess = false;
        const password = this.formGroup.get(this.passwordFieldName).value;

        this.authService.confirmPasswordReset(this.code, password)
            .then((value) => {
                this.log.info('Password has been reset:', value);
                this.authService.logout()
                    .then(() => this.isResetSuccess = true);
            })
            .catch((err) => {
                this.log.info('Something went wrong:', err.message);
                this.errorMessage = err.message;
            });
    }

    private handleUsingSameCodeTwice(errorMessage: string): string {
        const invalidActionCode = 'auth/invalid-action-code';
        const expiredActionCode = 'auth/expired-action-code';

        if (errorMessage.includes(expiredActionCode)) {
            return 'The action code has expired. Send a new password reset request.';
        }

        if (errorMessage.includes(invalidActionCode)) {
            return 'The action code is invalid. This can happen '
                + 'if the code is malformed, expired, or has already been used. '
                + 'Try to send a new password reset request.';
        }

        return errorMessage;
    }

    private disabledFields(): void {
        this.formGroup.disable();
    }
}
