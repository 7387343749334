<mat-list class="sidenav__content">
    <img
        class="sidenav__head-bg"
        src="/assets/images/avail-sidenav-bg.png"
        alt="side navigation background"
    >
    <div class="sidenav__list">
        <button
            class="sidenav__list-item sidenav__list-btn"
            mat-list-item
            (click)="createNewProject()"
        >
            <mat-icon svgIcon="plus-box"></mat-icon>
            <span>New Project</span>
        </button>
        <a
            class="sidenav__list-item"
            mat-list-item
            routerLink="reports"
            [queryParams]="{fid: getFolderId()}"
            (click)="close()"
        >
            <mat-icon svgIcon="file-chart"></mat-icon>
            <span>Previous Projects</span>
            <div
                *ngIf="counters.bookmarks"
                class="sidenav__notification-bubble"
            >
                {{ counters.bookmarks?.active || 0 }}
            </div>
        </a>
        <a
            class="sidenav__list-item"
            mat-list-item
            routerLink="purchased"
            [queryParams]="{fid: getFolderId()}"
            (click)="close()"
        >
            <mat-icon svgIcon="archive"></mat-icon>
            <span>Purchased Files</span>
            <div
                *ngIf="counters.purchased"
                class="sidenav__notification-bubble"
            >
                {{ counters.purchased?.active || 0 }}
            </div>
        </a>
    </div>

    <avl-other-tools
        class="sidenav__apps"
        [excludeTool]="tools.title"
    ></avl-other-tools>
</mat-list>
