import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

import { fadeRowAnimation } from '../../../../core/animations/fade.animation';
import {
    clearState,
    FolderQuery,
    loadTitleFeatureLocationAndSearchByTitleNumber,
    searchByAddress,
    searchByTitleNumber,
    SearchResultsQuery,
    updateMapFilter,
    updateMapZoom,
} from '../../../store';
import { FolderService, LandRegistryService, ReportGenerationHandlerService } from '../../../services';
import { ITitleInfo, LandRegistryDialogOptions } from '../../../types';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FileService, LoggerService, ProfileService } from '@services';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    landRegistriesSearchHints,
    PURCHASE_A_LOT_OF_TITLES,
    searchRegistries,
    SOMETHING_GONE_WRONG,
    TOO_MANY_FILES_TO_PURCHASE,
    TOO_MANY_TITLE_NUMBERS,
} from '@constants';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { Column, titlesQuantityThresholdForPrevention, titlesQuantityThresholdForWarning } from '../../../constants';
import { ProjectDetailsDialogComponent } from '../project-details-dialog/project-details-dialog.component';
import { OnboardingManageService } from '../../../../onboarding/services';
import { Actions } from '@datorama/akita-ng-effects';
import { LandRegistryDialogQuery, LandRegistryDialogService } from '../../../store/land-registry-dialog';
import { SearchResult } from '../../land-registry-search/types/search-result.type';
import { SearchRegistry } from '../../../enums/search-register.enum';
import { MapSearchType, RegistrySearchType } from '@enums';
import { SearchRegistryDetails } from '@core/types';
import { LandRegistryPurchaseEntity } from '../../../enums/land-registry-purchase-entity.enum';

@Component({
    selector: 'avl-land-registry-dialog',
    templateUrl: './land-registry-dialog.component.html',
    styleUrls: ['./land-registry-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [fadeRowAnimation],
})
export class LandRegistryDialogComponent implements OnInit, OnDestroy {
    public readonly landRegistriesSearchHints = landRegistriesSearchHints;
    public readonly searchRegistries = searchRegistries;

    public searchType: RegistrySearchType;
    public previousSearchType: RegistrySearchType;
    public resultTitles$: Observable<ITitleInfo[]>;
    public errorTitles$: Observable<ITitleInfo[]>;
    public titlesFromBasket$: Observable<ITitleInfo[]>;
    public titlesCount$: Observable<number>;
    public totalPrice$: Observable<number>;
    public isResultsTableHidden = true;
    public purchaseLoading$: Observable<boolean>;
    public isLoading$: Observable<boolean>;
    public isRosEnabled$: Observable<boolean>;
    public searchResultsUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public searchRegistry$: Observable<SearchRegistryDetails>;
    public resultTableColumns$: Observable<Column[]>;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private readonly dialogRef: MatDialogRef<LandRegistryDialogComponent>,
        private readonly dialog: MatDialog,
        private readonly folderQuery: FolderQuery,
        private readonly folderService: FolderService,
        private readonly resultsQuery: SearchResultsQuery,
        private readonly landRegistryDialogService: LandRegistryDialogService,
        private readonly registryService: LandRegistryService,
        private readonly fileService: FileService,
        private readonly snackBar: MatSnackBar,
        private readonly log: LoggerService,
        private readonly onboarding: OnboardingManageService,
        private readonly handlerService: ReportGenerationHandlerService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: LandRegistryDialogOptions,
        private readonly actions: Actions,
        private readonly landRegistryDialogQuery: LandRegistryDialogQuery,
        private readonly route: ActivatedRoute,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly profileService: ProfileService,
    ) {
    }

    public ngOnInit(): void {
        this.landRegistryDialogService.checkRegistryAvailability();
        this.defineSearchType();
        this.initMapSearch();

        this.resultTableColumns$ = this.resultsQuery.select('columns');
        this.searchRegistry$ = this.landRegistryDialogQuery.select('registry')
            .pipe(map((registryId) => searchRegistries.find((item) => item.id === registryId)));
        this.resultTitles$ = this.resultsQuery.getTitleInfos();
        this.errorTitles$ = this.resultsQuery.getErrorTitleInfos();
        this.isLoading$ = this.resultsQuery.getLoading();
        this.isRosEnabled$ = this.profileService.isRosEnabled$.asObservable();
        this.titlesFromBasket$ = this.landRegistryDialogQuery.getTitlesFromBasket$();
        this.titlesCount$ = this.landRegistryDialogQuery.countTitlesInBasket$();
        this.totalPrice$ = this.landRegistryDialogQuery.totalPriceOfTitlesBasket$().pipe(
            tap(() => setTimeout(() => this.changeDetectorRef.detectChanges())),
        );
        this.purchaseLoading$ = this.landRegistryDialogQuery.selectLoading();
        this.landRegistryDialogQuery.purchasingFinished$()
            .subscribe((event) => this.dialogRef.close({ result: event.status, message: event.message }));

        if (this.data.withRefresh) {
            this.refreshTitles();
        }
    }

    public ngOnDestroy(): void {
        const registry = this.landRegistryDialogQuery.getValue().registry;

        this.destroy$.next();
        this.destroy$.complete();
        this.registryService.clearResults();
        this.landRegistryDialogService.resetState();
        this.actions.dispatch(clearState());

        this.landRegistryDialogService.changeSearchRegistry(registry);
    }

    public getPurchaseEntity(): LandRegistryPurchaseEntity {
        return this.registryService.getPurchaseEntity();
    }

    public isMapSearch(kind: string): boolean {
        return kind === RegistrySearchType.map;
    }

    public onSearchChanged({ kind, query, location, locationType, placeId }: SearchResult): void {
        if (this.isMapSearch(kind)) {
            return locationType === MapSearchType.titleNumber
                ? this.actions.dispatch(searchByTitleNumber({ location, titleNumber: query }))
                : this.actions.dispatch(searchByAddress({ placeId }));
        }

        const folderId = this.folderQuery.getValue().id;
        this.registryService.searchTitleRegisters(folderId, kind, query, this.searchResultsUrl$)
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: () => this.isResultsTableHidden = false,
                error: (error) => {
                    this.registryService.clearResults();
                    this.isResultsTableHidden = false;

                    if (error.status === 429) {
                        this.dialog.open(AlertOkDialogComponent, {
                            width: '400px',
                            data: TOO_MANY_TITLE_NUMBERS,
                        });
                    }
                },
            });
    }

    public onSearchTypeChanged(type: RegistrySearchType): void {
        this.previousSearchType = this.searchType;
        this.searchType = type;
        this.landRegistryDialogService.changeSearchType(type);
        this.landRegistryDialogService.clearBasketList();
    }

    public onLandRegistryChanged(registry: SearchRegistry): void {
        this.landRegistryDialogService.changeSearchRegistry(registry);
        this.defineSearchType();
        this.registryService.clearResults();
        this.landRegistryDialogService.clearBasketList();
        this.isResultsTableHidden = true;
    }

    public downloadSearchResults(): void {
        if (this.searchResultsUrl$.getValue()) {
            this.log.info('downloadSearchResults');
            this.registryService.downloadSearchResults(this.searchResultsUrl$.getValue())
                .subscribe({
                    next: (response) => this.fileService.download(response),
                    error: () => {
                        this.snackBar.open('Sorry, unable to download the search results', 'Dismiss', { duration: 5000, verticalPosition: 'top' });
                    },
                });
        }
    }

    public onSelectedTitles(selectedTitles: ITitleInfo[]): void {
        this.landRegistryDialogService.updateBasketList(selectedTitles);
    }

    public onTitleErrorRemove(titleNumber: string): void {
        this.registryService.removeTitleRegister(titleNumber);
    }

    public onTitlesPurchased(): void {
        this.onboarding.destroyOnboarding();

        const folderId = this.folderQuery.getFolderId();
        const titlesForPurchase = this.landRegistryDialogQuery.getTitlesFromBasket();
        const titlesAmount = titlesForPurchase.length;
        const isSizeRestrictionMet = titlesAmount <= titlesQuantityThresholdForPrevention;
        const isSizeRestrictionWarning = titlesAmount > titlesQuantityThresholdForWarning;

        if (!isSizeRestrictionMet) {
            this.dialog.open(AlertOkDialogComponent, TOO_MANY_FILES_TO_PURCHASE).afterClosed();
            return;
        }

        if (isSizeRestrictionWarning) {
            const dialogData = Object.assign({}, PURCHASE_A_LOT_OF_TITLES);
            const totalPrice = this.landRegistryDialogQuery.totalPriceOfTitlesBasket();

            dialogData.subtitle = `You will incur a cost of £${totalPrice} on your client/matter file.`;

            const dialogRefHm = this.dialog.open(ConfirmDialogComponent, {
                panelClass: 'confirm-dialog', data: dialogData,
            });

            dialogRefHm.afterClosed().subscribe((isConfirm) => {
                if (!isConfirm) {
                    return;
                }
                this.folderService.projectAlreadyCreated(folderId)
                    .subscribe({
                        next: (isCreated) => {
                            isCreated ? this.closeHmLandRegistryDialog() : this.showSetProjectDetailsAndPurchase();
                        },
                    });
            });
        } else {
            this.folderService.projectAlreadyCreated(folderId)
                .subscribe({
                    next: (isCreated) => {
                        isCreated ? this.closeHmLandRegistryDialog() : this.showSetProjectDetailsAndPurchase();
                    },
                });
        }
    }

    private defineSearchType(): void {
        const activeRegistry = this.landRegistryDialogQuery.getValue().registry;
        const enabledSearchTypes = this.registryService.getLandRegistrySearchTypes(activeRegistry);
        const type = this.previousSearchType || this.data.searchType;
        const isTypeEnabled = enabledSearchTypes.includes(type as RegistrySearchType);
        const initialSearchType = isTypeEnabled ? type : RegistrySearchType.titleNumber;

        this.onSearchTypeChanged(initialSearchType);
        this.changeDetectorRef.markForCheck();

        if (type === RegistrySearchType.map) {
            this.initMapSearch();
        }
    }

    private initMapSearch(): void {
        const location = this.data.location;
        const titleNumber = this.data.titleNumber || '';
        const zoom = this.data.zoom;
        const filters = this.data.filters;

        let markerPosition;
        const markerLat = +this.route.snapshot.queryParams.markerLat;
        const markerLng = +this.route.snapshot.queryParams.markerLng;

        if (markerLat && markerLng) {
            markerPosition = {
                lat: markerLat,
                lng: markerLng,
            };
        }

        if (location) {
            this.actions.dispatch(loadTitleFeatureLocationAndSearchByTitleNumber({ titleNumber, markerPosition }));
        }

        if (zoom) {
            this.actions.dispatch(updateMapZoom({ zoom }));
        }

        if (filters) {
            this.actions.dispatch(updateMapFilter(filters));
        }
    }

    private refreshTitles(): void {
        const folderId = this.folderQuery.getFolderId();
        this.registryService.refreshTitles(folderId, this.searchResultsUrl$)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.isResultsTableHidden = false,
                error: () => this.isResultsTableHidden = false,
            });
    }

    private closeHmLandRegistryDialog(): void {
        // check if it is land registry(1) or imanage(2)
        this.doPurchaseTitles();
    }

    private showSetProjectDetailsAndPurchase(): void {
        const dialogRef = this.dialog.open<ProjectDetailsDialogComponent, null, 'success' | 'error'>(
            ProjectDetailsDialogComponent,
            {
                panelClass: 'project-details-dialog',
                disableClose: this.onboarding.isActive,
                autoFocus: false,
            });

        dialogRef.afterClosed()
            .subscribe((result) => {
                if (result === 'success') {
                    // check if it is land registry(1) or imanage(2)
                    this.doPurchaseTitles();
                } else if (result === 'error') {
                    this.handlerService.openAlertDialog(false, SOMETHING_GONE_WRONG);
                }
            });
    }

    private doPurchaseTitles(): void {
        const folderId = this.folderQuery.getFolderId();
        this.landRegistryDialogService.doPurchaseTitles(folderId);
    }
}
