import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FolderDetails } from '@core/types';
import { ProfileService } from '@services';

export enum EventType {
    cancel = 'cancel',
    confirm = 'confirm',
}

export type FolderDetailsData = {
    event: EventType;
    data?: FolderDetails;
};

@Component({
    selector: 'avl-folder-details-dialog',
    templateUrl: './folder-details-dialog.component.html',
    styleUrls: ['./folder-details-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FolderDetailsDialogComponent implements OnInit {
    public folderDetailsForm: FormGroup;
    public errorMessage = {
        projectName: 'Project Name is required',
        matterNumber: 'Matter Number is required',
    };

    constructor(
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        private readonly data: FolderDetails = { projectName: '', matterNumber: '' },
        private readonly dialogRef: MatDialogRef<FolderDetailsDialogComponent, FolderDetailsData>,
        private readonly formBuilder: FormBuilder,
        private readonly profileService: ProfileService,
    ) {
    }

    public ngOnInit(): void {
        this.setupForm();
        this.handleEscapePress();
    }

    public cancel(): void {
        this.dialogRef.close({ event: EventType.cancel });
    }

    public confirm(): void {
        const folderDetails: FolderDetails = this.folderDetailsForm.value;

        this.dialogRef.close({ event: EventType.confirm, data: folderDetails });
    }

    public isProjectNameDirtyAndInvalid(): boolean {
        return this.isDirtyAndInvalid('projectName');
    }

    public isMatterNumberDirtyAndInvalid(): boolean {
        return this.isDirtyAndInvalid('matterNumber');
    }

    public getErrorMessage(): string {
        let errorMessage = '';

        if (this.isProjectNameDirtyAndInvalid()) {
            errorMessage = this.errorMessage.projectName;
        } else if (this.isMatterNumberDirtyAndInvalid()) {
            errorMessage = this.errorMessage.matterNumber;
        }

        return errorMessage;
    }

    private isDirtyAndInvalid(fieldName: string): boolean {
        const control = this.folderDetailsForm.get(fieldName);

        return control.dirty && control.invalid;
    }

    private setupForm(): void {
        const planValue = this.profileService.plan$.getValue();
        const folderNamePattern = planValue.projectName.pattern;
        const folderMatterNumberPattern = planValue.matterNumber.pattern;

        const nameValidator = [Validators.required];
        const matterNumberValidator = [Validators.required];

        if (folderNamePattern) {
            nameValidator.push(Validators.pattern(folderNamePattern));
            this.errorMessage.projectName = planValue.projectName.errorMessage;
        }

        if (folderMatterNumberPattern) {
            matterNumberValidator.push(Validators.pattern(folderMatterNumberPattern));
            this.errorMessage.matterNumber = planValue.matterNumber.errorMessage;
        }

        this.folderDetailsForm = this.formBuilder.group({
            projectName: [this.data.projectName, nameValidator],
            matterNumber: [this.data.matterNumber, matterNumberValidator],
        });
    }

    private handleEscapePress(): void {
        this.dialogRef.keydownEvents()
            .subscribe((event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    this.cancel();
                }
            });
    }
}
