<div class="collection__table-container folders-table">
    <div class="collection__table-wrap">
        <table
            class="collection__table"
            mat-table
            matSort
            matSortDisableClear
            [trackBy]="trackReports"
            [dataSource]="source"
        >
            <!-- Project Name Column -->
            <ng-container matColumnDef="project-name">
                <th
                    mat-header-cell
                    mat-sort-header="projectName"
                    *matHeaderCellDef
                    class="folders-table__header-th"
                >
                    Project Name
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="folders-table__row"
                >
                    {{ element.projectName || '-' }}
                </td>
            </ng-container>

            <!-- Client/Matter Column -->
            <ng-container matColumnDef="matter-number">
                <th
                    mat-header-cell
                    mat-sort-header="matterNumber"
                    *matHeaderCellDef
                    class="folders-table__header-th"
                >
                    Client&nbsp;/ Matter
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    class="folders-table__row"
                >
                    {{ element.matterNumber || '-' }}
                </td>
            </ng-container>

            <!-- Created Column -->
            <ng-container matColumnDef="created-at">
                <th
                    mat-header-cell
                    mat-sort-header="createdAt"
                    *matHeaderCellDef
                    class="folders-table__header-th"
                    [class.sortable]="sort.active === 'createdAt'"
                >
                    Created
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class.sortable]="sort.active === 'createdAt'"
                    class="folders-table__row"
                >
                    {{ (element.createdAt | date:'d MMMM y') || '-' }}
                </td>
            </ng-container>

            <!-- Last Updated Column -->
            <ng-container matColumnDef="last-opened-at">
                <th
                    mat-header-cell
                    mat-sort-header="lastOpenedAt"
                    *matHeaderCellDef
                    class="folders-table__header-th"
                    [class.sortable]="sort.active === 'lastOpenedAt'"
                >
                    Last Updated
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class.sortable]="sort.active === 'lastOpenedAt'"
                    class="folders-table__row"
                >
                    {{ (element.lastOpenedAt | date:'d MMMM y') || '-' }}
                </td>
            </ng-container>

            <!-- Open Column -->
            <ng-container matColumnDef="open">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="folders-table__header-th"
                ></th>
                <td
                    mat-cell
                    *matCellDef="let element"
                >
                    <button
                        class="folders-table__open-folder-btn avl-btn avl-btn--notice-yellow"
                        (click)="openFolder(element)"
                    >
                        Open
                    </button>
                </td>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
                mat-row
                [hidden]="loading"
                *matRowDef="let row; columns: displayedColumns;"
            ></tr>
        </table>
        <avl-table-loading-placeholder
            *ngIf="loading"
            [columns]="displayedColumns"
            [size]="pageSize"
        ></avl-table-loading-placeholder>
    </div>

    <avl-table-no-data-disclaimer
        *ngIf="!loading && !bookmarks.length"
        [iconName]="'file-chart-rounded'"
        [message]="'No previous projects found'"
    ></avl-table-no-data-disclaimer>
    <mat-paginator
        [length]="pagination.totalCount"
        [class.hidden]="!bookmarks.length"
        [pageSize]="pageSize"
        [hidePageSize]="true"
    ></mat-paginator>
</div>
