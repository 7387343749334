import { Injectable } from '@angular/core';
import { NotificationsComponent } from '../dialogs/notifications/notifications.component';
import { MatDialog } from '@angular/material/dialog';
import { FolderApi } from '../../../api';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private readonly isBellHighlighted$ = new BehaviorSubject<boolean>(false);
    private readonly isDialogOpened$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly dialog: MatDialog,
        private readonly folderApi: FolderApi,
    ) {
    }

    public showDialog(folderId: string): void {
        if (folderId) {
            this.folderApi.getErrors(folderId)
                .subscribe((errorItems) => {
                    const messages = errorItems.map((error) => error.message);
                    this.openNotificationDialog(messages);
                });
        } else {
            this.openNotificationDialog([]);
        }
    }

    public isBellHighlighted(): Observable<boolean> {
        return merge(this.isBellHighlighted$.asObservable(), this.isDialogOpened$.asObservable())
            .pipe(
                map(() => this.isBellHighlighted$.getValue() && !this.isDialogOpened$.getValue()),
            );
    }

    public highlightBell(): void {
        this.isBellHighlighted$.next(true);
    }

    public reset(): void {
        this.isBellHighlighted$.next(false);
    }

    private openNotificationDialog(errors: string[]): void {
        const dialog = this.dialog.open(NotificationsComponent, {
            panelClass: 'notification-center',
            maxWidth: '100%',
            width: '100%',
            height: '347px',
            position: {
                top: '64px',
            },
            data: {
                errors,
                notificationBell: document.getElementsByClassName('notifications-bell').item(0),
            },
        });

        this.isDialogOpened$.next(true);
        dialog.afterClosed().subscribe(() => this.isDialogOpened$.next(false));
    }
}
