import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NEVER, Observable, Subject, throwError } from 'rxjs';
import { catchError, first, takeUntil, tap } from 'rxjs/operators';
import { FolderQuery, SchedulesQuery } from '../../store';
import { DataService, FolderService, ReportGenerationHandlerService, SchedulesService } from '../../services';
import { ISchedule, IScheduleAction } from '../../types';
import { DownloadSchedulePreviewDialogComponent } from '../dialogs/download-schedule-preview-dialog/download-schedule-preview-dialog.component';
import { OnboardingManageService } from '../../../onboarding/services';
import { FiledCopiesDialogComponent } from '../dialogs/filed-copies-dialog/filed-copies-dialog.component';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { FILED_COPIES_ANALYSIS_ENGAGEMENT, FILED_COPIES_ANALYSIS_LIMIT_IS_OVER } from '@constants';
import { Intercom } from 'ng-intercom';
import { SnackbarInfoMessageComponent } from '@shared/components/snackbar-info-message/snackbar-info-message.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'avl-titles-schedules',
    templateUrl: './titles-schedules.component.html',
    styleUrls: ['./titles-schedules.component.scss'],
})
export class TitlesSchedulesTableComponent implements OnInit, OnDestroy {
    public schedulesLoading$: Observable<boolean>;
    public finishedPurchases: { [key: string]: boolean } = {};
    public folderId: string;

    private readonly destroy$ = new Subject<void>();
    private allSchedules: ISchedule[] = [];
    private selectedItems = [];
    private searchQuery = '';
    private isDisabledBtn = 0;
    private infoSnackbar?: MatSnackBarRef<SnackbarInfoMessageComponent> = null;

    constructor(
        private readonly schedulesQuery: SchedulesQuery,
        private readonly folderQuery: FolderQuery,
        private readonly folderService: FolderService,
        private readonly dataService: DataService,
        private readonly schedulesService: SchedulesService,
        private readonly dialog: MatDialog,
        private readonly handlerService: ReportGenerationHandlerService,
        private readonly onboardingService: OnboardingManageService,
        private readonly snackBar: MatSnackBar,
        private readonly intercom: Intercom,
    ) {
    }

    public get currentSchedules(): ISchedule[] {
        return this.allSchedules.filter((item: ISchedule) => {
            const query = this.searchQuery.toLocaleLowerCase();
            const isItemTypeIncludesQuery = (item?.type || '').toLocaleLowerCase().includes(query);
            const isItemDescriptionIncludesQuery = (item?.description || '').toLocaleLowerCase().includes(query);

            return isItemTypeIncludesQuery || isItemDescriptionIncludesQuery;
        });
    }

    public ngOnInit(): void {
        this.onboardingService.showAddScheduleStep().subscribe(() => {
            if (this.onboardingService.isActive) {
                this.onboardingService.startAnalysing(true);
            }
        });
        this.schedulesLoading$ = this.schedulesQuery.selectLoading();
        this.schedulesQuery.selectAll()
            .pipe(takeUntil(this.destroy$))
            .subscribe((schedules: ISchedule[]) => {
                this.allSchedules = schedules;
                // we need to change the data
                this.setLinkedDocPrice();
                // this.changeScheduleData();
            });

        const folderId = this.folderQuery.getFolderId();
        if (folderId) {
            this.folderId = folderId;
            this.folderService.updateProjectStatus(folderId).subscribe();
        }
    }

    public ngOnDestroy(): void {
        this.folderService.resetFolderStore();
        this.destroy$.next();
        this.destroy$.complete();
        this.dismissInfoSnackbar();
    }

    public onSearch(searchQuery: string): void {
        this.searchQuery = searchQuery;
    }

    public onSchedulesAction({ schedule, action }: { schedule: ISchedule; action: IScheduleAction }): void {
        switch (action) {
            case 'showPreview':
                if (this.onboardingService.isActive) {
                    return;
                }
                this.dialog.open(DownloadSchedulePreviewDialogComponent, {
                    panelClass: ['schedule-preview-dialog'],
                    data: { key: schedule.key, title: schedule.type },
                });
                break;

            case 'startPurchase':
                this.isDisabledBtn++;
                const schedulePurchase = schedule;

                if (schedule.key === 'linked-documents') {
                    // schedule.items = this.selectedItems;
                    if (this.selectedItems.length > 0) {
                        // schedulePurchase.items = this.selectedItems;
                    }
                }

                const timeoutInstance = setTimeout(() => {
                    if (schedule.id === 'linked-documents-analysis') {
                        this.showInfoAboutLongTimeFiledCopyAnalysis();
                    }
                }, 1000);

                this.schedulesService
                    // .startSchedulePurchase(schedule, this.folderId)
                    .startSchedulePurchase(schedulePurchase, this.folderId, schedule, this.selectedItems)
                    .pipe(
                        catchError((error) => {
                            clearTimeout(timeoutInstance);
                            this.isDisabledBtn--;
                            this.dismissLinkedDocsAnalysisSnackbar(schedule);

                            if (error && error.status === 416) {
                                this.openFiledCopiesAnalysisLimitIsOverWarningDialog(schedule, error.error.message);
                            }

                            return throwError(error);
                        }),
                    )
                    .subscribe();
                break;

            case 'stopPurchase':
                if (this.onboardingService.isActive) {
                    this.onboardingService.closeActiveOverlay();
                }

                this.isDisabledBtn--;
                this.finishedPurchases[schedule.key] = true;
                this.schedulesService.getSingleScheduleList(this.folderId, 'linked-documents-analysis').subscribe();

                this.tryToOpenFiledCopiesAnalysisDialog(schedule);
                this.dismissLinkedDocsAnalysisSnackbar(schedule);
                break;
            case 'getLinkedDocItems':
                this.onGetLinkedDocItems();
                break;
            case 'showSelectLinkedDocument':
                if (this.onboardingService.isActive) {
                    return;
                }
                const dialogRef = this.dialog.open(FiledCopiesDialogComponent, {
                    panelClass: ['filed-copies-dialog'],
                });
                dialogRef.afterClosed()
                    .subscribe(() => {
                        // change the data of linked-documents
                        return this.changeScheduleData();
                    });
                break;
        }
    }

    public onOpenGenerateReportDialog(): void {
        this.handlerService.openGenerateReportDialog();
    }

    public isNextButtonDisabled(): boolean {
        return this.isDisabledBtn > 0;
    }

    // get linked document
    private onGetLinkedDocItems(): void {
        this.isDisabledBtn++;
        this.schedulesService.getListOfLinkedDocumentItems(this.folderId)
            .pipe(
                catchError((error) => {
                    this.isDisabledBtn--;
                    return throwError(error);
                }),
            )
            .subscribe((rsp) => {
                if (rsp.status === 200) {
                    this.isDisabledBtn--;

                    const regex = /^(\D*)(.*)/;
                    const linkedDoc = rsp.body;
                    const selectedItems = linkedDoc.items;
                    // const selectedItemss = selectedScheduleItems;
                    const availableToPurchaseItems = selectedItems.filter((item) => item.isAvailable && item.offerPurchase);
                    let allTotalCost = parseFloat(regex.exec(linkedDoc.totalCost)[2]);
                    const costCurrency = regex.exec(linkedDoc.totalCost)[1];
                    const unitCosts = parseFloat(regex.exec(linkedDoc.unitCost)[2]);
                    const hasItems = selectedItems.length > 0;

                    if (hasItems) {
                        allTotalCost = unitCosts * availableToPurchaseItems.length;
                        /*
                        selectedItemss.forEach((sitm, i) => {
                          if (sitm.isAvailable === true && sitm.offerPurchase === true) {
                            // the cost should come from the server
                            const allItemTotalCost =  100+i; // parseFloat(regexx.exec(sitm.cost)[2]);
                            sitemTotalConst += allItemTotalCost;
                          }
                        });
                        allTotalCost = sitemTotalConst;
                        */
                        // ***** End of calculation
                    }

                    const costAmount = allTotalCost.toFixed(2);

                    this.dataService.sendLinkedDocData({
                        selectedItems: selectedItems,
                        costCurrency: costCurrency,
                        costAmount: costAmount,
                        isEmpty: !hasItems,
                        unitCost: unitCosts,
                    });
                    this.dataService.sendSelectedLinkedDocData({
                        selectedItems: selectedItems,
                        costCurrency: costCurrency,
                        costAmount: costAmount,
                        isEmpty: !hasItems,
                        unitCost: unitCosts,
                    });
                    this.changeScheduleData();
                }
            });
    }

    // Set the price of linked documents based on the available items
    private setLinkedDocPrice(): void {
        this.allSchedules = JSON.parse(JSON.stringify(this.allSchedules));

        if (this.allSchedules.length > 0) {
            this.allSchedules.forEach((item, i) => {
                // check if the it was linked-documents title
                if (item?.key === 'linked-documents') {
                    const linkedDocItems = this.allSchedules[i].items;
                    let lnkIsEmpty = true;
                    if (linkedDocItems.length > 0) {
                        lnkIsEmpty = false;
                        const availableItem = linkedDocItems.filter((litem: any) => litem.isAvailable === true && litem.offerPurchase === true);
                        // calculate
                        const regex = /^(\D*)(.*)/;
                        const munitCost = parseFloat(regex.exec(this.allSchedules[i].unitCost)[2]);
                        const costCurrencys = regex.exec(this.allSchedules[i].unitCost)[1];
                        let totalAvlPrice: any = 0;
                        if (availableItem.length > 0) {
                            totalAvlPrice = munitCost * availableItem.length;
                            /*
                              const totalAvlPrice = availableItem.reduce((accumulator, obj) => {
                                // price field should come from the api
                                return accumulator + 100;
                                //return accumulator + obj.price;
                              }, 0);
                              */
                            this.allSchedules[i].totalCost = totalAvlPrice;
                        }
                        this.allSchedules[i].totalCost = costCurrencys + parseFloat(totalAvlPrice).toFixed(2);
                        // Send data to the services
                        this.dataService.sendLinkedDocData({
                            selectedItems: linkedDocItems,
                            costCurrency: costCurrencys,
                            costAmount: totalAvlPrice,
                            isEmpty: lnkIsEmpty,
                            unitCost: munitCost,
                        });
                        this.dataService.sendSelectedLinkedDocData({
                            selectedItems: availableItem,
                            costCurrency: costCurrencys,
                            costAmount: totalAvlPrice,
                            isEmpty: lnkIsEmpty,
                            unitCost: munitCost,
                        });
                    }
                }
            });
        }
    }

    // --Change data
    private changeScheduleData(): void {
        this.allSchedules = JSON.parse(JSON.stringify(this.allSchedules));

        if (!this.allSchedules.length) {
            return;
        }

        this.allSchedules.forEach((item, i) => {
            if (item?.key === 'linked-documents') {
                this.allSchedules[i].items = [];
                this.allSchedules[i].totalCost = '£0.00';

                this.dataService.linkedDoc
                    .pipe(first())
                    .subscribe((lnks) => {
                        if (lnks.selectedItems.length) {
                            this.allSchedules[i].items = lnks.selectedItems;
                            this.allSchedules[i].isEmpty = lnks.isEmpty;
                            let tCost = lnks.costAmount;

                            // get selected items
                            this.dataService.linkedDocSelected
                                .pipe(first())
                                .subscribe((sitm) => {
                                    this.selectedItems = sitm.selectedItems;
                                    tCost = tCost === '0'
                                        ? '£0.00'
                                        : '£' + parseFloat(sitm.costAmount).toFixed(2);

                                    this.allSchedules[i].totalCost = tCost;
                                });
                        }
                    });
                // return this.currentSchedules;
            }
        });
    }

    private tryToOpenFiledCopiesAnalysisDialog(schedule: ISchedule): void {
        const filedCopiesAnalysis = this.allSchedules.find((el) => el.id === 'linked-documents-analysis');
        const isFiledCopiesAnalysisExist = !!filedCopiesAnalysis;
        const isLinkedDocuments = schedule.id === 'linked-documents';

        if (isLinkedDocuments && isFiledCopiesAnalysisExist) {
            const dialogData = FILED_COPIES_ANALYSIS_ENGAGEMENT;
            const isAnalysisFree = filedCopiesAnalysis.unitCost?.includes('£0.00');

            if (!isAnalysisFree) {
                dialogData.subtitle = `It will cost ${filedCopiesAnalysis.unitCost} per document`;
            }

            this.dialog.open(ConfirmDialogComponent, {
                panelClass: 'confirm-dialog',
                data: dialogData,
            })
                .afterClosed()
                .pipe(
                    tap((isPositiveButtonPressed) => isPositiveButtonPressed ? this.schedulesService.startLinkedDocumentsAnalysis() : NEVER),
                )
                .subscribe();
        }
    }

    private openFiledCopiesAnalysisLimitIsOverWarningDialog(schedule: ISchedule, message: string | undefined = ''): void {
        if (schedule.id === 'linked-documents-analysis') {
            const dialogData = message
                ? { ...FILED_COPIES_ANALYSIS_LIMIT_IS_OVER, title: message }
                : FILED_COPIES_ANALYSIS_LIMIT_IS_OVER;

            this.dialog.open(ConfirmDialogComponent, {
                panelClass: 'confirm-dialog',
                data: dialogData,
            })
                .afterClosed()
                .pipe(
                    tap((isPositiveButtonPressed) => isPositiveButtonPressed ? this.intercom.show() : NEVER),
                )
                .subscribe();
        }
    }

    private showInfoAboutLongTimeFiledCopyAnalysis(): void {
        this.dismissInfoSnackbar();

        const message = 'Filed copy summaries take quite a long time as they really put our AI through its '
            + 'paces! It\'ll take approximately 90 seconds per document, so please don\'t close this window';

        this.infoSnackbar = this.snackBar.openFromComponent(SnackbarInfoMessageComponent, {
            data: message,
            panelClass: 'info-snackbar',
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
        });
    }

    private dismissInfoSnackbar(): void {
        if (this.infoSnackbar) {
            this.infoSnackbar.dismiss();
        }
    }

    private dismissLinkedDocsAnalysisSnackbar(schedule: ISchedule): void {
        if (schedule.id === 'linked-documents-analysis') {
            this.dismissInfoSnackbar();
        }
    }
}
