import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fadeRowAnimation } from '../../../core/animations/fade.animation';
import { BehaviorSubject, EMPTY, iif, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { FolderQuery, NotifyCountersQuery, NotifyCountersState } from '../../store';
import { resetStores } from '@datorama/akita';
import { DocumentsService, NotifyCountersService } from '../../services';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { CREATE_NEW_FOLDER_CONFIRM_DATA, SOMETHING_GONE_WRONG, TUTORIAL_VIEW_CONFIRM_DATA } from '@constants';
import { OnboardingManageService } from '../../../onboarding/services';
import { ProjectDetailsDialogComponent } from '../dialogs/project-details-dialog/project-details-dialog.component';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { FolderDetails, Logo } from '@core/types';
import { ProfileService } from '@services';

@Component({
    selector: 'avl-title-registers',
    templateUrl: './title-registers.component.html',
    styleUrls: ['./title-registers.component.scss'],
    animations: [fadeRowAnimation],
})
export class TitleRegistersComponent implements OnInit, OnDestroy {
    public readonly titleLogo: Logo = {
        defaultLogo: true,
        icon: 'assets/images/avail-titles-logo.svg',
        iconWidth: 121,
        iconHeight: 20,
    };

    public folderIsNotCreated$: Observable<boolean>;
    public projectDetails$: Observable<FolderDetails>;
    public counters$: Observable<NotifyCountersState>;
    public isOnboardingActive$: Observable<boolean>;

    constructor(
        private readonly folderQuery: FolderQuery,
        private readonly countersQuery: NotifyCountersQuery,
        private readonly documentsService: DocumentsService,
        private readonly notifyCountersService: NotifyCountersService,
        private readonly profileService: ProfileService,
        private readonly onboarding: OnboardingManageService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.projectDetails$ = this.folderQuery.selectProjectDetails();
        this.folderIsNotCreated$ = this.folderQuery.select((state) => !state.id);
        this.counters$ = this.countersQuery.select();
        this.isOnboardingActive$ = this.onboarding.isOnboardingActive();
        this.countersUpdate();
        this.profileService.loadConfig();
    }

    public ngOnDestroy(): void {
        resetStores();
    }

    public isHmlrEnabled$(): BehaviorSubject<boolean> {
        return this.profileService.isHmlrEnabled$;
    }

    public onNewProjectCreated(): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog',
            data: CREATE_NEW_FOLDER_CONFIRM_DATA,
        });

        dialogRef.afterClosed().subscribe((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            this.resetViewState();
        });
    }

    public onOnboardingShowed(): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog',
            data: TUTORIAL_VIEW_CONFIRM_DATA,
        });

        dialogRef.afterClosed()
            .pipe(
                tap((confirmationResult) => confirmationResult && this.resetViewState()),
                mergeMap((confirmationResult: boolean) => {
                    return iif(() => confirmationResult, this.onboarding.makeOnboardingVisible(), EMPTY);
                }),
            )
            .subscribe(() => this.onboarding.isShowReport = true);
    }

    public onOpenedStart(): void {
        this.countersUpdate();
        this.onboarding.showMainMenuStep().subscribe();
    }

    public onProjectDetailsOpen(): void {
        this.showProjectDetailsDialog();
    }

    private showProjectDetailsDialog(): void {
        const dialogRef = this.dialog.open<ProjectDetailsDialogComponent, FolderDetails, 'success' | 'error'>(
            ProjectDetailsDialogComponent,
            {
                panelClass: 'project-details-dialog',
                autoFocus: false,
                disableClose: this.onboarding.isActive,
                data: this.folderQuery.getProjectDetails(),
            });

        dialogRef.afterClosed()
            .subscribe((result) => {
                if (result === 'error') {
                    this.openAlertDialog(SOMETHING_GONE_WRONG);
                }
            });
    }

    private resetViewState(): void {
        resetStores({ exclude: ['notify-counters'] });
        this.documentsService.resetLoading();
        this.router.navigate(['title']);
    }

    private openAlertDialog(errorData?: { title: string; message: string }): void {
        this.dialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: errorData,
        });
    }

    private countersUpdate(): void {
        this.notifyCountersService.getCounters().subscribe();
    }
}
