import { AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LoggerService } from '@services';
import { NoticeService } from 'app/notices/services/notice.service';

import { forkJoin, interval, of, throwError, timer } from 'rxjs';
import { catchError, mergeMap, take, takeWhile } from 'rxjs/operators';

const GENERATION_STEPS_COUNT = 5;
const GENERATION_ONE_STEP_DURATION = 500;

const REPORT_STEPS = [
    'Engaging Artificial Intelligence',
    'Analysing Title Registers',
    'Cross-checking Companies House',
    'Identifying Break Clauses',
    'Drafting Notices',
];

@Component({
    selector: 'avl-notice-generation-dialog',
    templateUrl: './notice-generation-dialog.component.html',
    styleUrls: ['./notice-generation-dialog.component.scss'],
})
export class NoticeGenerationDialogComponent implements OnInit, AfterViewInit {

    @ViewChildren('step')
    public steps: QueryList<ElementRef>;

    @ViewChild('reportLink', { static: true })
    public reportLink: ElementRef;

    public reportSteps = REPORT_STEPS;

    constructor(
        private readonly dialogRef: MatDialogRef<NoticeGenerationDialogComponent>,
        private readonly noticeService: NoticeService,
        private readonly log: LoggerService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    public ngOnInit(): void {
        this.generateReportAndDisplayAnimation();
    }

    public ngAfterViewInit(): void {
        const animationSteps = this.steps.toArray();
        interval(GENERATION_ONE_STEP_DURATION).pipe(take(GENERATION_STEPS_COUNT))
            .subscribe(
                (step) => animationSteps[step].nativeElement.classList.add('animated'),
            );
    }

    private generateReportAndDisplayAnimation(): void {
        forkJoin([
            this.noticeService.getGenerationStatus(this.data.url)
                .pipe(
                    mergeMap((response) => {
                        if (response.status >= 400) {
                            return throwError(response);
                        }
                        return of(response);
                    }),
                    takeWhile((response) => response.status === 202 || response.status === 102, true),
                ),
            timer(this.getGenerationStepsDuration())],
        ).pipe(
            catchError((error) => {
                this.log.info('an error was raised', error);
                return of(error);
            }),
        )
            .subscribe(([response]) => {
                const url = response.headers.get('Content-Location');
                this.simulateReportDownload(url);
                if (response && response.error) {
                    this.dialogRef.close('error');
                } else {
                    this.dialogRef.close('success');
                }
            });
    }

    private getGenerationStepsDuration(): number {
        return GENERATION_ONE_STEP_DURATION * GENERATION_STEPS_COUNT + 1000;
    }

    private simulateReportDownload(url: string): void {
        this.reportLink.nativeElement.href = url;
        this.reportLink.nativeElement.click();
    }
}
