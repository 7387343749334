<mat-sidenav-container
    class="title-registers__sidenav"
    [class.active-onboarding]="isOnboardingActive$ | async"
>
    <mat-sidenav
        mode="over"
        #sidenav
        (openedStart)="onOpenedStart()"
    >
        <avl-sidenav
            [counters]="counters$ | async"
            (newProjectCreated)="onNewProjectCreated()"
            (closed)="sidenav.close()"
        ></avl-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="title-registers__content">
            <avl-header
                [logo]="titleLogo"
                [homePagePath]="'titles'"
                [folderIsNotCreated]="folderIsNotCreated$ | async"
                [showHamburgerMenu]="isHmlrEnabled$() | async"
                [folderDetails]="projectDetails$ | async"
                (newFolderCreated)="onNewProjectCreated()"
                (onboardingShowed)="onOnboardingShowed()"
                (sideNavToggled)="sidenav.toggle()"
                (projectDetailsOpened)="onProjectDetailsOpen()"
            ></avl-header>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
