<div
    *ngIf="!isCodeInvalid && !errorMessage && !isResetSuccess"
    class="auth-page__form-title"
>
    <h2>Choose a New Password</h2>
</div>
<form
    *ngIf="!isCodeInvalid && !errorMessage && !isResetSuccess"
    class="auth-page__form"
    [formGroup]="formGroup"
    (ngSubmit)="submitReset()"
>
    <input
        hidden
        autocomplete="username"
        [value]="email"
        [disabled]="true"
    />
    <div class="form-field">
        <label>New Password</label>
        <input
            placeholder="New Password"
            autocomplete="new-password"
            autofocus
            tabindex="1"
            [name]="passwordFieldName"
            [formControlName]="passwordFieldName"
            [ngClass]="{
                'error': formGroup.get(passwordFieldName).touched && formGroup.get(passwordFieldName).invalid
            }"
            [type]="isPasswordVisible ? 'text' : 'password'"
        />
        <mat-icon
            class="button"
            [svgIcon]="isPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isPasswordVisible = !isPasswordVisible"
        ></mat-icon>
        <mat-error *ngIf="formGroup.get(passwordFieldName).touched && formGroup.get(passwordFieldName).invalid">
            Password is required
        </mat-error>
    </div>
    <div class="form-field">
        <label>Confirm Password</label>
        <input
            tabindex="2"
            [name]="confirmPasswordFieldName"
            autocomplete="new-password"
            placeholder="Confirm Password"
            [formControlName]="confirmPasswordFieldName"
            [ngClass]="{
                'error': formGroup.get(confirmPasswordFieldName).touched
                            && (formGroup.get(confirmPasswordFieldName).invalid
                            || !!formGroup.errors)
            }"
            [type]="isConfirmPasswordVisible ? 'text' : 'password'"
        />
        <mat-icon
            class="button"
            [svgIcon]="isConfirmPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible"
        ></mat-icon>
        <mat-error
            *ngIf="formGroup.get(confirmPasswordFieldName).touched
                        && (formGroup.get(confirmPasswordFieldName).invalid
                        || !!formGroup.errors)"
        >
            {{ formGroup.errors?.['fieldsMismatch'] ? 'Passwords do not match' : 'Confirm password is required' }}
        </mat-error>
    </div>
    <button
        class="avl-btn avl-btn--blue avl-btn--wide"
        tabindex="3"
        [disabled]="isCodeInvalid || formGroup.invalid"
    >
        Reset password
    </button>
</form>
<avl-error-form
    *ngIf="!!errorMessage"
    [message]="errorMessage"
    [isTryAgainButtonVisible]="true"
    (tryAgain)="onTryAgain()"
></avl-error-form>
<avl-succeed-form
    *ngIf="isResetSuccess"
    [message]="succeedMessage"
    [isResendButton]="false"
    (resend)="onTryAgain()"
></avl-succeed-form>
