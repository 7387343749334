<div class="map-container">
    <avl-map
        [zoom]="zoom"
        [center]="center"
        [isLoading]="isMapLoading$ | async"
        (featuresSelected)="onFeaturesSelected($event)"
        (mapClicked)="onMapClick($event)"
    >
    </avl-map>
    <avl-content-sidebar></avl-content-sidebar>
    <avl-map-controls (controlsChanged)="onControlsChanged($event)"></avl-map-controls>
</div>
