import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { IReportOption } from '../types';

@Injectable()
export class ReportApi {

    constructor(private readonly http: HttpClient) {
    }

    public generateReport(folderId: string, sync: boolean): Observable<IReportOption[]> {
        const parameters = sync ? { wait: 't' } : {};

        return this.http.put<IReportOption[]>(`/api/titles/reports/${folderId}`, {}, { params: parameters });
    }

    public getReportStatus(folderId: string): Observable<HttpResponse<IReportOption[]>> {
        return this.http.get<IReportOption[]>(`/api/titles/reports/${folderId}`, { observe: 'response' });
    }

    public getReportFile(folderId: string, reportName: string): Observable<any> {
        return this.http.get(`/api/titles/report/${folderId}/${reportName}`);
    }

    public loadReport(folderId: string, key: string, format: string): Observable<HttpResponse<any>> {
        return this.http.get(
            `/api/titles/report/${folderId}/${key}.${format}`,
            { responseType: 'arraybuffer', observe: 'response' },
        );
    }

    public getReportFileLink(folderId: string, key: string, format: string): string {
        return `/api/titles/report/${folderId}/${key}.${format}`;
    }
}
