import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { FileUploader } from '../classes/file-uploader.class';

/**
 * @example
 * <input
 *              type="file"
 *              avlFilePicker
 *              [uploader]="fileUploader"
 * >
 */
@Directive({
    selector: '[avlFilePicker]',
})
export class FilePickerDirective implements OnInit {
    /**
     * FileUploader instance
     */
    @Input()
    public uploader: FileUploader;

    /**
     * @ignore
     */
    constructor(
        private readonly element: ElementRef,
        private readonly renderer: Renderer2,
    ) {
    }

    /**
     * @ignore
     */
    public ngOnInit(): void {
        this.validateTargetElement();
        this.setMultipleAttr();
    }

    /**
     * handler of the input element 'change' event
     */
    @HostListener('change')
    public onValueChange(): void {
        const files = this.element.nativeElement.files;

        this.uploader.addFiles(files);
        this.element.nativeElement.value = '';
    }

    /**
     * @ignore
     */
    private setMultipleAttr(): void {
        if (this.uploader.isMultipleFilesAllowed()) {
            this.renderer.setProperty(this.element.nativeElement, 'multiple', 'multiple');
        }
    }

    /**
     * @ignore
     */
    private validateTargetElement(): void {
        const elementAttrType = this.element.nativeElement.getAttribute('type');
        const isInputElement = this.element.nativeElement.tagName.toLowerCase() === 'input';
        const isInputTypeFile = elementAttrType ? elementAttrType.toLowerCase() === 'file' : false;

        if (!isInputElement || !isInputTypeFile) {
            throw new Error('FilePickerDirective should be attached to <input type="file">');
        }
    }
}
