import { Injectable } from '@angular/core';

import { HttpQueryParamsService } from '@services';
import { BookmarksApi } from '../api';

import { IGeneratedReport } from '../types';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GeneratedReportsStore } from '../store';
import { HttpResponse } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { ICollectionTableUI } from '@core/types';

@Injectable()
export class GeneratedReportsService {

    constructor(
        private readonly bookmarksApi: BookmarksApi,
        private readonly queryParamsService: HttpQueryParamsService,
        private readonly generatedReportsStore: GeneratedReportsStore,
    ) {
    }

    public getReportsList(uiState: ICollectionTableUI): Observable<IGeneratedReport[]> {
        this.generatedReportsStore.setLoading(true);
        const params = this.queryParamsService.buildCollectionParams(uiState);

        return this.bookmarksApi.getReports(params).pipe(
            map((response) => this.getCollectionFrom(response)),
            catchError((error) => {
                this.generatedReportsStore.setLoading(false);
                return throwError(error);
            }),
        );
    }

    public updateSearchUI(search: string): void {
        this.generatedReportsStore.update((state) => ({ ui: { ...state.ui, search } }));
    }

    public updatePaginationUI(page: PageEvent): void {
        this.generatedReportsStore.update((state) => ({ ui: { ...state.ui, page } }));
    }

    public resetState(): void {
        this.generatedReportsStore.reset();
    }

    private getCollectionFrom(response: HttpResponse<IGeneratedReport[]>): IGeneratedReport[] {
        const totalCount = +response.headers.get('X-Total-Count');
        this.generatedReportsStore.updateReports(response.body, { totalCount });

        return response.body;
    }
}
