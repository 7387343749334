<button
    class="avl-btn avl-btn--dark-purple avl-btn--wide button"
    [disabled]="disabled || isBusy"
    (click)="onClicked()"
>
    <div class="button__label">
        <ng-content></ng-content>
        <span
            *ngIf="badgeValue > 0"
            class="button__badge"
        >
            {{ badgeValue }}
        </span>
    </div>
</button>
