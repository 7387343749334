<h4 mat-dialog-title>{{ title }}</h4>
<a
    [href]="path"
    [download]="title"
>
    <button
        type="button"
        class="dialog__left-top-btn"
        mat-dialog-close
    >
        <mat-icon
            class="close-icon"
            svgIcon="cloud-download"
            (click)="$event.stopPropagation()"
        ></mat-icon>
    </button>
</a>
<button
    type="button"
    class="dialog__close"
    mat-dialog-close
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>
<mat-dialog-content class="schedule-preview__content">
    <img
        [src]="path"
        [alt]="title"
    >
</mat-dialog-content>
