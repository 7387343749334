import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FolderKeyData } from '../types/folder-key-data.type';
import { FolderDetails, IResponseStatus } from '@core/types';
import { map } from 'rxjs/operators';
import { ItemError } from '../types/item-error.type';
import { MinimalDocument } from '../types/minimal-document.type';
import { FileMetadata } from '../types/file-metadata.type';

type ResponseFolderDetails = {
  found: boolean;
  projectName?: string;
  matterNumber?: string;
}

@Injectable()
export class FolderApi {

    constructor(private readonly http: HttpClient) {
    }

    public create(): Observable<string> {
        return this.http.post('/api/filed-documents/folder', null, { responseType: 'text' });
    }

    public getFileStatus(folderId: string): Observable<IResponseStatus<FileMetadata>> {
        return this.http.get<IResponseStatus<FileMetadata>>(`/api/filed-documents/folder/${folderId}/file-status`);
    }

    public getErrors(folderId: string): Observable<ItemError[]> {
        return this.http.get<ItemError[]>(`/api/filed-documents/${folderId}/messages`);
    }

    public getStatus(folderId: string): Observable<IResponseStatus<MinimalDocument>> {
        return this.http.get<IResponseStatus<MinimalDocument>>(`/api/filed-documents/folder/${folderId}/status`);
    }

    public getKeyData(folderId: string): Observable<FolderKeyData> {
        return this.http.get<FolderKeyData>(`/api/filed-documents/${folderId}/key-data`);
    }

    public getDetails(folderId: string): Observable<FolderDetails> {
        return this.http.get<ResponseFolderDetails>(`/api/filed-documents/project/${folderId}`)
            .pipe(
                map((res) => ({ projectName: res.projectName || '', matterNumber: res.matterNumber || '' })),
            );
    }

    public updateDetails(folderId: string, folderDetails: FolderDetails): Observable<void> {
        return this.http.put<void>(`/api/filed-documents/project/${folderId}`, {
            projectName: folderDetails.projectName,
            matterNumber: folderDetails.matterNumber
        });
    }
}
