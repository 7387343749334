import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { getEmptyDocument, MinimalDocument } from '../../types/minimal-document.type';

export type DocumentState = EntityState<MinimalDocument>;

@Injectable()
@StoreConfig({ name: '[leases] document', resettable: true })
export class DocumentStore extends EntityStore<DocumentState> {

    constructor() {
        super({ loading: false });
    }

    public addLoadingDocument(tempId: string, fileName?: string): void {
        const document = getEmptyDocument(tempId, fileName);

        this.add(document, { loading: true });
    }
}

