import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NoticeFolderQuery } from './store/notice-folder';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { resetStores } from '@datorama/akita';
import { CREATE_NEW_NOTICE_CONFIRM_DATA, routingNotice } from 'app/core/constants';
import { Router } from '@angular/router';
import { FolderDetails, Logo } from '@core/types';
import { ProfileService } from '@services';
import { filter } from 'rxjs/operators';
import { EventType, FolderDetailsData } from '@shared/components/dialogs/folder-details-dialog/folder-details-dialog.component';
import { NoticeService } from './services/notice.service';
import { SideNavCounters } from './types/side-nav-counters.type';
import { SideNavCountersService } from './store/side-nav-counters/side-nav-counters.service';
import { SideNavCountersQuery } from './store/side-nav-counters/side-nav-counters.query';

const litig8Logo: Logo = {
    defaultLogo: true,
    icon: 'assets/images/avail-notice-logo.svg',
    iconWidth: 141,
    iconHeight: 20,
};

@Component({
    selector: 'avl-notices',
    templateUrl: './notices.component.html',
    styleUrls: ['./notices.component.scss'],
})

export class NoticesComponent implements OnInit {
    public readonly routingNotice = routingNotice;
    public folderDetails$: Observable<FolderDetails>;
    public folderIsNotCreated$: Observable<boolean>;
    public logo = litig8Logo;
    public counters$: Observable<SideNavCounters>;

    constructor(
        private readonly folderQuery: NoticeFolderQuery,
        private readonly folderService: NoticeService,
        private readonly profileService: ProfileService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly countersService: SideNavCountersService,
        private readonly countersQuery: SideNavCountersQuery,
    ) {
    }

    public ngOnInit(): void {
        this.counters$ = this.countersQuery.select();
        this.folderDetails$ = this.folderQuery.getDetails$();
        this.folderIsNotCreated$ = this.folderQuery.select((state) => !state.id);
        this.profileService.loadConfig();
        this.localSidenavCounters();
    }

    public createNewProject(): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent,
            { panelClass: 'confirm-dialog', data: CREATE_NEW_NOTICE_CONFIRM_DATA });

        dialogRef.afterClosed().subscribe((isConfirm) => {
            if (!isConfirm) {
                return;
            }
            this.resetViewState();
        });
    }

    public onFolderDetailsOpen(): void {
        const folderId = this.folderQuery.getValue().id;
        const folderDetails = this.folderQuery.getDetails();

        this.folderService.openFolderDetailsDialog(folderDetails)
            .pipe(
                filter((result) => !!result),
            )
            .subscribe((result: FolderDetailsData) => {
                if (result.event === EventType.confirm) {
                    this.folderService.updateFolderDetails(folderId, result.data);
                }
            });
    }

    public localSidenavCounters(): void {
        this.countersService.load();
    }

    private resetViewState(): void {
        resetStores();
        this.router.navigate([routingNotice]);
    }
}
