import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FileUploader,
    FileUploaderEventTypes,
    FileUploaderService,
    FileValidationErrors,
    IErrorsEmitPayload,
    IFileItem,
    IFileUploaderEvents,
    IFileUploaderOptions,
} from '../../../blocks/file-uploader';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
    selector: 'avl-upload-drop-area',
    templateUrl: './upload-drop-area.component.html',
    styleUrls: ['./upload-drop-area.component.scss'],
})
export class UploadDropAreaComponent implements OnInit {
    @Input()
    public isLoading = false;

    @Input()
    public options: IFileUploaderOptions;

    @Output()
    public beforeUpload = new EventEmitter<number>();

    @Output()
    public fileUploadingStarted = new EventEmitter<IFileUploaderEvents>();

    @Output()
    public fileUploadingFailed = new EventEmitter<IFileUploaderEvents>();

    @Output()
    public fileUploadingCanceled = new EventEmitter<IFileUploaderEvents>();

    @Output()
    public fileUploadingSucceeded = new EventEmitter<IFileUploaderEvents>();

    @Output()
    public unsupportedFileAdded = new EventEmitter<IErrorsEmitPayload>();

    public fileUploader: FileUploader;
    public isFileOverArea = false;

    constructor(private readonly fileUploaderService: FileUploaderService) {
    }

    public ngOnInit(): void {
        this.initFileUploader();
    }

    public getFilesAmount$(): Observable<number> {
        return new Observable((sub) =>
            this.fileUploader.filesList$.subscribe((files) => sub.next(files.length)),
        );
    }

    public uploadAll(url: string): void {
        if (this.fileUploader) {
            this.fileUploader.updateUploaderUrl(url);
            this.fileUploader.uploadAll();
        }
    }

    public clearLoading(): void {
        if (this.fileUploader) {
            this.fileUploader.removeAll();
        }
    }

    public cancelUpload(fileId: string): void {
        if (this.fileUploader) {
            this.fileUploader.cancelUpload({ uuid: fileId } as IFileItem);
        }
    }

    private initFileUploader(): void {
        this.fileUploader = this.fileUploaderService.init(this.options);
        this.fileUploader.onBeforeUpload().subscribe((filesAmount) => this.beforeUpload.emit(filesAmount));
        this.fileUploader.errors$
            .subscribe((error) => {
                const isTypeOfUploadFileIncorrect = error.errorType === FileValidationErrors.validationMimeClassNotMatched
                    || error.errorType === FileValidationErrors.validationFileExtensionNotMatched;

                if (isTypeOfUploadFileIncorrect) {
                    this.unsupportedFileAdded.emit(error);
                }
            });
        this.fileUploader.events$.pipe(
            tap((event) => {
                switch (event.type) {
                    case FileUploaderEventTypes.fileUploadingStarted:
                        this.fileUploadingStarted.emit(event);
                        break;
                    case FileUploaderEventTypes.fileUploadingCanceled:
                        this.fileUploadingCanceled.emit(event);
                        break;
                    case FileUploaderEventTypes.fileUploadingSucceeded:
                        this.fileUploadingSucceeded.emit(event);
                        break;
                    case FileUploaderEventTypes.fileUploadingFailed:
                        this.fileUploadingFailed.emit(event);
                        break;
                }
            }),
            catchError(() => {
                this.fileUploadingFailed.emit();

                return EMPTY;
            }),
        )
            .subscribe();
    }
}
