/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { asyncScheduler, of, Subject, Subscription, switchMap, timer } from 'rxjs';
import { IMapBounds } from '../../../types';
import { Actions } from '@datorama/akita-ng-effects';
import {
    clearHighlightedFeatures,
    fetchMapGeoJson,
    FolderQuery,
    MapSearchQuery,
    setMapComponentInitialized,
    updateMapCenter,
    updateMapMarkerPosition,
    updateMapZoom,
} from '../../../store';
import { FeatureUtilsService } from '../../../services';
import { debounce, debounceTime, distinctUntilChanged, filter, throttleTime } from 'rxjs/operators';
import { mapSearchMaxZoom, mapSearchMaxZoomFeatureVisibility, mapSearchMinZoom, mapSearchZoomAfterSearch } from '@constants';
import {
    FilterSpecification,
    GeoJSONFeature,
    GeoJSONSource,
    LngLat,
    LngLatBounds,
    Map,
    MapGeoJSONFeature,
    Marker,
    Point2D,
    Popup,
    PopupOptions,
} from 'maplibre-gl';
import { StringUtilsService } from '@services';
import { MapSearchApi } from '../../../api';
import { environment } from '@env/environment';
import { MultiPolygon } from 'geojson';

export type PopupData = {
    freehold: MapGeoJSONFeature[];
    leasehold: MapGeoJSONFeature[];
}

export type EventWithLocation = {
    lngLat: LngLat;
    point: Point2D;
}

export type FeatureSelectionEvent = {
    features: GeoJSONFeature[];
    point: LngLat;
}

export enum Layers {
    freeholdFill = 'FreeholdFillLayer',
    freeholdLine = 'FreeholdLineLayer',
    leaseholdFill = 'LeaseholdFillLayer',
    leaseholdLine = 'LeaseholdLineLayer',
    highlightedLine = 'HighlightedLineLayer',
}

@Component({
    selector: 'avl-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
    public readonly defaultFeatureStyles = {
        fillColor: 'transparent',
        strokeColor: '#BE2535',
        strokeWidth: 1,
    };

    public readonly highlightedFeatureStyles = {
        fillColor: 'rgba(12,106,217,0.1)',
        strokeColor: '#0C6AD9',
    };

    public readonly mapSearchMaxZoom = mapSearchMaxZoom;
    public readonly mapSearchMinZoom = mapSearchMinZoom;
    public readonly styleLink = MapComponent.styleLink;

    @Input()
    public isLoading = false;

    @Input()
    public zoom: number;

    @Input()
    public center: LngLat;

    @Output()
    public featuresSelected = new EventEmitter<FeatureSelectionEvent>();

    @Output()
    public mapClicked = new EventEmitter<FeatureSelectionEvent>();

    public popupData: PopupData;
    public isProxyAccessKeyLoaded = false;

    private readonly sourceId = 'SourceId';
    private readonly mapMoved = new Subject<boolean>();
    private readonly popupShowing$ = new Subject<EventWithLocation>();
    private readonly popupHidingTimer$ = new Subject<void>();
    private readonly popupHiding$ = new Subject<void>();
    private readonly underPinSelection$ = new Subject<void>();
    private readonly updateProxyServerAccessKey$ = new Subject<void>();
    private readonly sub = new Subscription();
    private readonly msBeforeTooltipAppear = 200;
    private readonly msBeforeTooltipDisappear = 200;
    private readonly msTooltipAutoClose = 4000;
    private readonly msMovingAnimationDurationAfterClick = 500;
    private readonly popupOptions: PopupOptions = {
        closeButton: false,
        closeOnClick: true,
        closeOnMove: true,
        anchor: 'right',
        offset: [-10, 0],
    };

    private static readonly styleLink = environment.mapSearch.styleUrl;
    private static stylesApiKey = '';

    private map: Map;
    private popup?: Popup;
    private marker?: Marker;
    private isMouseOverPopup = false;
    private highlightedFeatureId?: number | string;
    private selectedTitleNumbers: string[] = [];

    constructor(
        private readonly ngZone: NgZone,
        private readonly ref: ChangeDetectorRef,
        private readonly actions: Actions,
        private readonly featureUtils: FeatureUtilsService,
        private readonly mapSearchQuery: MapSearchQuery,
        private readonly stringUtils: StringUtilsService,
        private readonly mapSearchApi: MapSearchApi,
        private readonly folderQuery: FolderQuery,
    ) {
    }

    public ngOnInit(): void {
        this.listenUpdateProxyServerToken();
        this.listenMapMoved();

        this.updateProxyServerAccessKey$.next();
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public onMapInit(map: Map): void {
        this.map = map;
        this.map.resize();
        this.map.addSource(this.sourceId, {
            type: 'geojson',
            data: {
                'type': 'FeatureCollection',
                'features': [],
            },
        });
        this.map.on('sourcedata', (event) => {
            if (event?.sourceDataType === 'content') {
                this.underPinSelection$.next();
            }
        });

        this.createLayers();
        this.initPopup();
        this.initMarker();

        this.actions.dispatch(setMapComponentInitialized());
        this.loadFeatures();
    }

    public initPopup(): void {
        this.popup = new Popup(this.popupOptions);

        this.map.on('click', (e) => this.onMapClick(e.point, e.lngLat));
        this.map.on('mousemove', Layers.freeholdFill, (e) => this.popupShowing$.next(e));
        this.map.on('mousemove', Layers.leaseholdFill, (e) => this.popupShowing$.next(e));

        this.sub.add(
            this.popupShowing$
                .pipe(
                    distinctUntilChanged(),
                    debounce(() => timer(this.msBeforeTooltipAppear, asyncScheduler)),
                    filter(() => !this.isMouseOverPopup && this.mapSearchQuery.isZoomApplicable()),
                )
                .subscribe((event) => {
                    this.openHoverPopup(event.lngLat, event.point);

                    const popupElement = document.getElementsByClassName('maplibregl-popup')[0];
                    if (popupElement) {
                        popupElement.addEventListener('mouseover', () => this.isMouseOverPopup = true);
                        popupElement.addEventListener('mouseout', () => {
                            this.isMouseOverPopup = false;
                            this.popupHidingTimer$.next();
                        });
                    }

                    this.popupHidingTimer$.next();
                }),
        );
        this.sub.add(
            this.popupHidingTimer$
                .pipe(
                    debounce(() => timer(this.msTooltipAutoClose, asyncScheduler)),
                )
                .subscribe(() => this.popupHiding$.next()),
        );
        this.sub.add(
            this.popupHiding$
                .pipe(
                    debounce(() => timer(this.msBeforeTooltipDisappear, asyncScheduler)),
                    filter(() => !this.isMouseOverPopup),
                )
                .subscribe(() => {
                    this.popup?.remove();
                    this.isMouseOverPopup = false;
                }),
        );
    }

    public renderFeatures(): void {
        const loadedFeatures = this.mapSearchQuery.getValue().featuresMap;
        const existingFeatures = Object.keys(loadedFeatures).length
            ? this.featureUtils.convertToArray(loadedFeatures)
            : this.mapSearchQuery.getValue().selectedFeatures;
        const featuresWithId = existingFeatures.map<GeoJSONFeature>((feature) =>
            Object.assign({
                id: this.stringUtils.hashCode(feature.properties.title_number),
            }, feature),
        );
        const geoJsonForRender: GeoJSON.GeoJSON = {
            type: 'FeatureCollection',
            features: featuresWithId,
        };

        const source = this.map.getSource(this.sourceId);
        if (source) {
            (source as GeoJSONSource).setData(geoJsonForRender);
        }
    }

    public pointOn(point?: LngLat): void {
        if (!point) {
            this.marker?.remove();
            this.marker = null;
            return;
        }

        if (!this.marker) {
            this.marker = new Marker()
                .setLngLat(point);

            if (this.map) {
                this.marker.addTo(this.map);
            }
        } else {
            this.marker.setLngLat(point);
        }

        this.selectPolygonsUnderThePin();
    }

    public highlightFeature(titleNumber?: string): void {
        if (this.highlightedFeatureId) {
            this.map?.setFeatureState({ source: this.sourceId, id: this.highlightedFeatureId }, { 'isHighlighted': false });
        }

        if (titleNumber) {
            const featureId = this.stringUtils.hashCode(titleNumber);
            this.map?.setFeatureState({ source: this.sourceId, id: featureId }, { 'isHighlighted': true });
            this.highlightedFeatureId = featureId;
        }

        this.updateHighlightedFeatureLineLayer(titleNumber);
    }

    public jumpTo(location: LngLat, zoom?: number): void {
        this.map?.jumpTo({
            center: location,
            zoom: zoom || mapSearchZoomAfterSearch,
        });
    }

    public onMapClick(offsetPoint: Point2D, point: LngLat): void {
        const oldPoint = this.marker?.getLngLat();
        let selectedFeatures: GeoJSONFeature[] = [];

        if (!oldPoint || oldPoint && point.distanceTo(oldPoint)) {
            selectedFeatures = this.featuresSelectionHandler(offsetPoint, point);
        }

        this.actions.dispatch(updateMapMarkerPosition({ point }));
        this.actions.dispatch(clearHighlightedFeatures());
        this.map.panTo(point, { duration: this.msMovingAnimationDurationAfterClick });

        this.mapClicked.emit({ features: selectedFeatures, point });
    }

    public onZoomChanged(zoom: number): void {
        this.actions.dispatch(updateMapZoom({ zoom: Math.round(zoom) }));
        this.popupHiding$.next();
        this.onMapChanged();
    }

    public onCenterChanged(center: LngLat): void {
        this.actions.dispatch(updateMapCenter({ center }));
        this.onMapChanged();
    }

    public onMapChanged(): void {
        this.mapMoved.next(true);
    }

    public onMapResize(): void {
        of()
            .pipe(
                throttleTime(1000),
            )
            .subscribe(() => {
                this.ngZone.run(() => this.loadFeatures());
            });
    }

    public updateSelectedFeaturesList(titleNumbers: string[]): void {
        this.selectedTitleNumbers.forEach((titleNumber) => {
            const identifier = { id: this.stringUtils.hashCode(titleNumber), source: this.sourceId };
            this.map?.setFeatureState(identifier, { 'isSelected': false });
        });
        titleNumbers.forEach((titleNumber) => {
            const identifier = { id: this.stringUtils.hashCode(titleNumber), source: this.sourceId };
            this.map?.setFeatureState(identifier, { 'isSelected': true });
        });
        this.selectedTitleNumbers = titleNumbers;
    }

    public updateFeaturesFilter(): void {
        const isFreeholdsOn = this.mapSearchQuery.getValue().isFreeholdsOn;
        const isLeaseholdsOn = this.mapSearchQuery.getValue().isLeaseholdsOn;
        const zoom = this.mapSearchQuery.getValue().zoom;
        const isAvailableZoom = zoom >= mapSearchMaxZoomFeatureVisibility;

        this.updateFeatureLayerOpacity(Layers.freeholdFill, Layers.freeholdLine, isFreeholdsOn, isAvailableZoom);
        this.updateFeatureLayerOpacity(Layers.leaseholdFill, Layers.leaseholdLine, isLeaseholdsOn, isAvailableZoom);
    }

    public jumpToFeature(features: GeoJSONFeature[]): void {
        const coordinatesArrays = features.map((feature) => (feature.geometry as MultiPolygon).coordinates);
        const coordinates = coordinatesArrays.reduce<number[][]>((acc: number[][], value) => {
            const featureCoordinates = this.featureUtils.excludeCoordinates(value);
            acc.push(...featureCoordinates);

            return acc;
        }, []);

        const bounds = coordinates.reduce(
            (bounds, coordinates) => bounds.extend({ lng: coordinates[0], lat: coordinates[1] }),
            new LngLatBounds(),
        );

        this.map?.fitBounds(bounds, { padding: 100, duration: 0 });
    }

    public transformRequest(urlString: string): { url: string } {
        if (urlString === MapComponent.styleLink) {
            return { url: urlString };
        }

        const url = new URL(urlString);
        const hrefWithoutParams = url.href.split('?')[0];
        const newOrigin = environment.mapSearch.stylesUrlOrigin ?? location.origin;
        const hrefWithoutParamsWithNewOrigin = hrefWithoutParams.replace(url.origin, newOrigin);
        const params = url.searchParams;

        params.set('key', MapComponent.stylesApiKey);
        params.set('srs', '3857'); // Set WEB projection: https://epsg.io/3857

        const updatedUrl = `${hrefWithoutParamsWithNewOrigin}?${params.toString()}`;

        return { url: updatedUrl };
    }

    public mapError(errorEvent: ErrorEvent): void {
        const isUnauthorized = errorEvent.error.status === 401;
        if (isUnauthorized) {
            this.updateProxyServerAccessKey$.next();
        }
    }

    private createLayers(): void {
        const freeholdTenure = 'Freehold';
        const leaseholdTenure = 'Leasehold';

        if (!this.map?.getLayer(Layers.freeholdFill)) {
            this.createFeatureLayer(Layers.freeholdFill, Layers.freeholdLine, ['==', ['get', 'tenure'], freeholdTenure]);
            this.createFeatureLayer(Layers.leaseholdFill, Layers.leaseholdLine, ['==', ['get', 'tenure'], leaseholdTenure]);
        }
    }

    private createFeatureLayer(fillLayerId: Layers, lineLayerId: Layers, filter: FilterSpecification): void {
        const highlightingFillCondition: any = [
            'case',
            ['boolean', ['feature-state', 'isHighlighted'], false],
            this.highlightedFeatureStyles.fillColor,
            this.defaultFeatureStyles.fillColor,
        ];

        // Polygon layer
        this.map.addLayer({
            id: fillLayerId,
            type: 'fill',
            filter: filter,
            source: this.sourceId,
            paint: {
                'fill-color': highlightingFillCondition,
            },
        });
        // Line of polygon layer
        this.map.addLayer({
            id: lineLayerId,
            type: 'line',
            filter: filter,
            source: this.sourceId,
            paint: {
                'line-color': this.defaultFeatureStyles.strokeColor,
                'line-width': this.defaultFeatureStyles.strokeWidth,
                // 'line-dasharray': [10, 6],
            },
        });
    }

    private updateHighlightedFeatureLineLayer(titleNumber: string): void {
        if (this.map?.getLayer(Layers.highlightedLine)) {
            this.map.removeLayer(Layers.highlightedLine);
        }

        this.map?.addLayer({
            id: Layers.highlightedLine,
            type: 'line',
            filter: ['==', ['get', 'title_number'], titleNumber],
            source: this.sourceId,
            paint: {
                'line-color': this.highlightedFeatureStyles.strokeColor,
                'line-width': this.defaultFeatureStyles.strokeWidth,
            },
            layout: {
                'line-sort-key': 1000,
            },
        });
    }

    private loadFeatures(): void {
        const bounds = this.map?.getBounds();
        if (bounds) {
            const formattedBounds: IMapBounds = {
                'longitude_sw': bounds.getSouthWest().lng,
                'latitude_sw': bounds.getSouthWest().lat,
                'longitude_ne': bounds.getNorthEast().lng,
                'latitude_ne': bounds.getNorthEast().lat,
            };

            this.actions.dispatch(fetchMapGeoJson({ bounds: formattedBounds }));
        }
    }

    private listenMapMoved(): void {
        this.sub.add(
            this.mapMoved
                .pipe(
                    debounce(() => timer(1000, asyncScheduler)),
                )
                .subscribe(() => this.loadFeatures()),
        );
    }

    private featuresSelectionHandler(offset: Point2D, point: LngLat): GeoJSONFeature[] {
        const features = this.extractFeaturesFromPoint(offset);
        this.featuresSelected.emit({ features, point });

        return features;
    }

    private openHoverPopup(position: LngLat, offset: Point2D): void {
        const features = this.extractFeaturesFromPoint(offset);
        const data = this.extractPopupData(features);
        const isTitleNumbersExist = data.freehold?.length || data.leasehold?.length;

        if (isTitleNumbersExist) {
            this.popupData = data;
            this.ref.detectChanges();
            this.popup
                .setLngLat(position)
                .setHTML(document.getElementById('popup-template').innerHTML)
                .addTo(this.map);
        }
    }

    private selectPolygonsUnderThePin(): void {
        const isSelectionBlocked = this.mapSearchQuery.getValue().isSelectionUnderPinBlocked;

        if (this.marker && !isSelectionBlocked) {
            const coordinates = this.marker.getLngLat();
            const pointOnMap = this.map.project(coordinates);
            this.featuresSelectionHandler(pointOnMap, coordinates);
        }
    }

    private extractFeaturesFromPoint(offset: Point2D): MapGeoJSONFeature[] {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.map.queryRenderedFeatures(offset, {
            layers: [Layers.freeholdFill, Layers.leaseholdFill],
        });
    }

    private extractPopupData(features: MapGeoJSONFeature[]): PopupData {
        return {
            freehold: features.filter((feature) => feature.properties.tenure === 'Freehold'),
            leasehold: features.filter((feature) => feature.properties.tenure === 'Leasehold'),
        };
    }

    private updateFeatureLayerOpacity(fillLayerId: Layers, lineLayerId: Layers, isFilterOn: boolean, isAvailableZoom: boolean): void {
        const opacitySwitchCase = [
            'case', [
                'any',
                ['boolean', ['feature-state', 'isSelected'], false], // Check property 'isSelected', return a false if null (not set)
                ['all', isAvailableZoom, isFilterOn], // Check filter and zoom states
            ],
            1, // Value of first case
            0, // Default value
        ];

        this.map?.setPaintProperty(fillLayerId, 'fill-opacity', opacitySwitchCase);
        this.map?.setPaintProperty(lineLayerId, 'line-opacity', opacitySwitchCase);
    }

    private initMarker(): void {
        this.marker?.addTo(this.map);
        this.underPinSelection$
            .pipe(
                debounceTime(300),
            )
            .subscribe(() => this.selectPolygonsUnderThePin());
    }

    private listenUpdateProxyServerToken(): void {
        this.updateProxyServerAccessKey$
            .pipe(
                throttleTime(9000),
                switchMap(() => {
                    const folderId = this.folderQuery.getFolderId();

                    return this.mapSearchApi.getMappingProxyAccessKey(folderId);
                }),
            )
            .subscribe((keyObject) => {
                MapComponent.stylesApiKey = keyObject.key;
                this.isProxyAccessKeyLoaded = true;
                this.ref.detectChanges();
            });
    }
}
