import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { AkitaNgEffectsModule } from '@datorama/akita-ng-effects';
import { ToggleModule } from '@shared/components/toggle-control/toggle.module';
import { ContentSidebarModule } from './map-content-sidebar/content-sidebar.module';
import { LandRegistryMapSearchComponent } from './map-search.component';
import { MapControlsModule } from './map-controls/map-controls.module';
import { MapModule } from './map/map.module';
import { MapSearchApi } from '../../api';
import { MapSearchEffects, MapSearchQuery, MapSearchStore } from 'app/titles/store';

@NgModule({
    imports: [
        MapControlsModule,
        ContentSidebarModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ToggleModule,
        MapModule,
        AkitaNgEffectsModule.forFeature([
            MapSearchEffects,
        ]),
    ],
    declarations: [
        LandRegistryMapSearchComponent,
    ],
    exports: [
        LandRegistryMapSearchComponent,
    ],
    providers: [
        MapSearchApi,
        MapSearchStore,
        MapSearchQuery,
    ],
})
export class LandRegistryMapSearchModule {
}
