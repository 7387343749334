import { IFileItem } from './file-item.type';

export type IFileUploaderEvents = {
    type: FileUploaderEventTypes;
    file?: IFileItem;
    error?: any;
    response?: any;
}

export enum FileUploaderEventTypes {
    fileAdded = 'FileAdded',
    fileRemoved = 'FileRemoved',
    fileUploadingStarted = 'FileUploadingStarted',
    fileUploadingSucceeded = 'FileUploadingSucceeded',
    fileUploadingFailed = 'FileUploadingFailed',
    fileUploadingCanceled = 'FileUploadingCanceled',
    allFilesRemoved = 'AllFilesRemoved',
}
