import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoticesComponent } from './notices.component';

import { SharedModule } from '@shared/shared.module';
import { NoticesRoutingModule } from './notices-routing.module';
import { NoticeAreaComponent } from './components/notice-area/notice-area.component';
import { NoticeProgressComponent } from './components/notice-progress/notice-progress.component';
import { NoticeDocumentsComponent } from './components/notice-documents/notice-documents.component';
import { NoticeGenerationDialogComponent } from './components/notice-generation-dialog/notice-generation-dialog.component';

import { NoticeFolderQuery, NoticeFolderStore } from './store/notice-folder';
import { NoticeApi } from './api/notice.api';
import { NoticeService } from './services/notice.service';
import { SelectClientDialogComponent } from './components/select-client-dialog/select-client-dialog.component';
import { NoticeDocumentsQuery, NoticeDocumentsStore } from './store/notice-documents';
import { NoticesResolver } from './resolvers/notices.resolver';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { PreviousFoldersComponent } from './components/previous-folders/previous-folders.component';
import { FoldersTableComponent } from './components/folders-table/folders-table.component';
import { BookmarksQuery } from './store/bookmarks/bookmarks.query';
import { BookmarksService } from './store/bookmarks/bookmarks.service';
import { BookmarksStore } from './store/bookmarks/bookmarks.store';
import { BookmarksApi } from './api/bookmarks.api';
import { ReportsApi } from './api/reports.api';
import { SideNavCountersQuery } from './store/side-nav-counters/side-nav-counters.query';
import { SideNavCountersStore } from './store/side-nav-counters/side-nav-counters.store';
import { SideNavCountersService } from './store/side-nav-counters/side-nav-counters.service';
import { WarningBannerComponent } from './components/warning-banner/warning-banner.component';


@NgModule({
    declarations: [
        NoticesComponent,
        NoticeAreaComponent,
        NoticeProgressComponent,
        NoticeDocumentsComponent,
        NoticeGenerationDialogComponent,
        SelectClientDialogComponent,
        SidenavComponent,
        PreviousFoldersComponent,
        FoldersTableComponent,
        WarningBannerComponent,
    ],
    imports: [
        CommonModule,
        NoticesRoutingModule,
        SharedModule
    ],
    providers: [
        NoticeApi,
        BookmarksApi,
        NoticeService,
        NoticesResolver,
        NoticeFolderQuery,
        NoticeFolderStore,
        NoticeDocumentsQuery,
        NoticeDocumentsStore,
        BookmarksQuery,
        BookmarksService,
        BookmarksStore,
        ReportsApi,
        SideNavCountersQuery,
        SideNavCountersStore,
        SideNavCountersService,
    ]
})
export class NoticesModule { }
