import { Component, Input } from '@angular/core';

@Component({
    selector: 'avl-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
    @Input()
    public isBackgroundCircleVisible = false;

    @Input()
    public diameter = 56;

    @Input()
    public strokeWidth = 2;

    @Input()
    public primaryColor = '#6f90d4';

    @Input()
    public secondaryColor = '#cdcdcd';
}
