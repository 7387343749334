import { Component, Input } from '@angular/core';

@Component({
    selector: 'avl-table-no-data-disclaimer',
    templateUrl: './table-no-data-disclaimer.component.html',
    styleUrls: ['./table-no-data-disclaimer.component.scss'],
})
export class TableNoDataDisclaimerComponent {
    @Input()
    public message: string;

    @Input()
    public iconName: string;
}
