import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MinimalBookmark } from '../../types/minimal-bookmark.type';

export type BookmarkState = EntityState<MinimalBookmark> & {
    totalCount: number;
};

@Injectable()
@StoreConfig({ name: '[leases] bookmark', resettable: true })
export class BookmarkStore extends EntityStore<BookmarkState> {
    constructor() {
        super({ totalCount: 0 });
    }
}

