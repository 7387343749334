import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { resetStores } from '@datorama/akita';
import { FolderQuery } from '../../store';

import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subscription, timer } from 'rxjs';

import { FileUploader, FileUploaderEventTypes, FileUploaderService, FileValidationErrors, IFileUploaderOptions } from '../../../blocks/file-uploader';

import { LONG_PROCESSING_REFRESH_CONFIG, SOMETHING_GONE_WRONG, UNABLE_COMMUNICATE_WITH_SERVER, UNSUPPORTED_FILE_TYPE } from '@constants';

import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { ProjectDetailsDialogComponent } from '../dialogs/project-details-dialog/project-details-dialog.component';

import { LoggerService, ProfileService } from '@services';
import { OnboardingManageService } from '../../../onboarding/services';
import { PulseButtonDirective } from '@shared/directives/pulse-button.directive';
import { DocumentsService, FolderService, ReportService } from '../../services';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'avl-drop-area',
    templateUrl: './drop-area.component.html',
    styleUrls: ['./drop-area.component.scss'],
})
export class DropAreaComponent implements OnInit, OnDestroy {

    @ViewChild(PulseButtonDirective, { static: true })
    public pulsedButton: PulseButtonDirective;

    @Input()
    public loading$: Observable<boolean>;

    @Input()
    public landRegistryDisabled: boolean;

    @Input()
    public imanageDisabled: boolean;

    @Output()
    public landRegistryOpened = new EventEmitter<void>();

    @Output()
    public newFolderCreated = new EventEmitter<string>();

    @Output()
    public imanageOpened = new EventEmitter<void>();

    public fileUploader: FileUploader;
    public fileUploaderOptions: IFileUploaderOptions;
    public isFileOverArea = false;
    public filesAmount = 0;
    public isUploading = false;
    public onboardingActive$: Observable<boolean>;
    public isOldTheme = false;
    private readonly subscriptions: Subscription = new Subscription();

    constructor(
        public profileService: ProfileService,
        private readonly documentsService: DocumentsService,
        private readonly folderService: FolderService,
        private readonly folderQuery: FolderQuery,
        private readonly fileUploaderService: FileUploaderService,
        private readonly reportService: ReportService,
        private readonly dialog: MatDialog,
        private readonly log: LoggerService,
        private readonly onboarding: OnboardingManageService,
        private readonly activatedRoute: ActivatedRoute,
    ) {
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnInit(): void {
        this.isOldTheme = this.activatedRoute.snapshot.data['isOldTheme'];
        this.onboardingActive$ = this.onboarding.isOnboardingActive();
        this.fileUploaderOptions = this.setUploaderConfig();
        this.fileUploader = this.fileUploaderService.init(this.fileUploaderOptions);

        this.addListeners();
    }

    public onLandRegistryClick(): void {
        this.landRegistryOpened.emit();
        this.onboarding.closeActiveOverlay();
        this.pulsedButton.completePulsing();
    }

    public onImanageClick(): void {
        this.imanageOpened.emit();
    }

    private setUploaderConfig(): IFileUploaderOptions {
        return {
            uploader: {
                fileAlias: 'file',
                autoUpload: false,
                simultaneousUpload: true,
                simultaneousUploadQuantityLimit: 20,
            },
            filePicker: {
                allowedMultipleFiles: true,
                allowedMimeClass: ['pdf'],
                allowedFileExtension: ['pdf'],
            },
            queue: {
                removeAfterUploadComplete: false,
            },
        };
    }

    private addListeners(): void {
        const beforeUploadSub = this.fileUploader.onBeforeUpload()
            .subscribe((amount) => {
                if (!amount) {
                    return;
                }

                const folderId = this.folderQuery.getFolderId();
                folderId ? this.proceedProjectDetails(folderId, amount) : this.createNewFolder(amount);
                this.onboarding.closeActiveOverlay();
            });
        const fileUploaderErrorsSub = this.fileUploader.errors$
            .subscribe((error) => {
                this.log.warn(error);
            });

        this.setUploadEvents();

        const folderStatusPollingSub = this.documentsService.folderStatusPolling()
            .subscribe({
                error: (err) => {
                    this.log.info('folder status polling failure', err);
                },
            });

        this.resetLoadingCounter();

        const resetLoadingSub = this.documentsService.resetLoading$
            .subscribe(() => {
                this.log.info('--> documentsService.resetLoading$');
                this.fileUploader.removeAll();
            });
        this.addSubscriptions([
            beforeUploadSub,
            fileUploaderErrorsSub,
            folderStatusPollingSub,
            resetLoadingSub,
        ]);
    }

    private startFilesProcessing(amount: number): void {
        this.documentsService.fillWithEmptyFiles(amount - this.filesAmount);
        this.filesAmount = amount;
        this.isUploading = true;
        this.documentsService.setLoadingState(true);
        this.reportService.resetReport();

        if (amount < 10) {
            this.setRefreshTimer(2);
        } else if (amount >= 10) {
            this.setRefreshTimer(15);
        }
    }

    private setRefreshTimer(time: number): void {
        timer(time * 60 * 1000)
            .pipe(
                takeUntil(this.documentsService.stopPollingSignal$),
                switchMap(() => this.openProcessingRefreshDialog()),
            )
            .subscribe();
    }

    private openProcessingRefreshDialog(): Observable<void> {
        return this.dialog.open(AlertOkDialogComponent, LONG_PROCESSING_REFRESH_CONFIG)
            .afterClosed()
            .pipe(
                tap(() => window.location.reload()),
            );
    }

    private proceedProjectDetails(folderId: string, amount: number): void {
        this.folderService.projectAlreadyCreated(folderId)
            .subscribe((isCreated) => {
                isCreated ? this.uploadAllFiles(folderId, amount) : this.showProjectDetailsDialog(folderId, amount);
            });
    }

    private showProjectDetailsDialog(folderId: string, amount: number): void {
        const dialogRef = this.dialog.open<ProjectDetailsDialogComponent, null, 'success' | 'error' | 'close' | undefined>(
            ProjectDetailsDialogComponent, {
                panelClass: 'project-details-dialog',
                autoFocus: false,
                disableClose: this.onboarding.isActive,
            });

        dialogRef.afterClosed()
            .subscribe((result) => {
                switch (result) {
                    case 'success':
                        this.uploadAllFiles(folderId, amount);
                        break;
                    case 'error':
                        this.openAlertDialog(SOMETHING_GONE_WRONG);
                        this.fileUploader.removeAll();
                        break;
                    case undefined:
                    case 'close':
                        this.fileUploader.removeAll();
                        break;
                    default:
                        break;
                }
            });
    }

    private setUploadEvents(): void {
        const subscriptionOnErrors = this.fileUploader.errors$.subscribe((error) => {
            const isTypeOfUploadFileIncorrect = error.errorType === FileValidationErrors.validationMimeClassNotMatched
                || error.errorType === FileValidationErrors.validationFileExtensionNotMatched;

            if (isTypeOfUploadFileIncorrect) {
                const documentName = error.file.fileAsObject.name;
                const fileType = 'titles';
                this.handleUnsupportedType(documentName, fileType);
            }
        });

        const subscription = this.fileUploader.events$
            .pipe(
                tap((event) => {
                    if (event.type === FileUploaderEventTypes.fileUploadingFailed) {
                        this.documentsService.anUploadFailed(event.file, event.error);
                        this.documentsService.numOfFailedFiles.next(this.documentsService.numOfFailedFiles.getValue() + 1);
                    }
                    if (event.type === FileUploaderEventTypes.fileUploadingCanceled) {
                        this.documentsService.numOfCanceledFiles.next(this.documentsService.numOfCanceledFiles.getValue() + 1);
                    }
                    this.log.info('(1) upload events', event);
                    this.log.info('(1.1) fileUploader.successfulAddedCounter', this.fileUploader.successfulAddedCounter);
                    this.fileUploader.filesList$.pipe(take(1)).subscribe((v) => this.documentsService.totalNumOfFilesInUploadQueue.next(v.length));
                }),
                filter((event) => {
                    return event.type === FileUploaderEventTypes.fileUploadingSucceeded || event.type === FileUploaderEventTypes.fileUploadingFailed;
                }),
                tap((value) => {
                    this.log.info('(2) upload events', value);
                    this.fileUploader.getUploadingFiles().pipe(take(1))
                        .subscribe((v) => this.log.info(
                            '(2.4) fileUploader.getUploadingFiles()', v.length,
                        ));
                    this.log.info('(2.1) fileUploader.successfulAddedCounter', this.fileUploader.successfulAddedCounter);
                }),
                tap(() => {
                    this.documentsService.startPolling();
                }),
            )
            .subscribe({
                next: (value) => {
                    this.log.info('subscribe.events$ value', value);
                    this.fileUploader.getUploadingFiles()
                        .pipe(take(1))
                        .subscribe((v) => this.log.info(
                            '(3.4) fileUploader.getUploadingFiles()', v.length,
                        ));
                    this.log.info('(3.1) fileUploader.successfulAddedCounter', this.fileUploader.successfulAddedCounter);
                    this.fileUploader.getUploadedFiles()
                        .pipe(take(1))
                        .subscribe((v) => this.log.info('(3.5) fileUploader.getUploadedFiles()', v.length));
                    this.fileUploader.getUploadFailedFiles()
                        .pipe(take(1))
                        .subscribe((v) => this.log.info('(3.6) fileUploader.getUploadFailedFiles()', v.length));
                    this.fileUploader.filesList$
                        .pipe(take(1))
                        .subscribe((v) => this.log.info('(3.7) fileUploader.filesList$', v.length));
                },
                error: (err) => {
                    this.log.info('subscribe.events$ error', err);
                    this.documentsService.pollingDocumentsHasFailed();
                },
            });

        this.subscriptions.add(subscription);
        this.subscriptions.add(subscriptionOnErrors);
    }

    private createNewFolder(filesAmount: number): void {
        this.subscriptions.add(
            this.folderService.createFolder()
                .subscribe({
                    next: (folderId: string) => {
                        this.newFolderCreated.emit(folderId);
                        this.proceedProjectDetails(folderId, filesAmount);
                    },
                    error: (err) => {
                        this.log.info('createNewFolder error', err);
                        this.documentsService.resetLoading();
                        resetStores();
                        setTimeout(() => {
                            const isLoginPage = location.pathname.includes('login');
                            if (!isLoginPage) {
                                this.openAlertDialog(UNABLE_COMMUNICATE_WITH_SERVER);
                            }
                        }, 300);
                    },
                }),
        );
    }

    private handleUnsupportedType(documentName: string, fileType: string): void {
        const error = { ...UNSUPPORTED_FILE_TYPE };
        error.title = error.title.replace('{document_name}', documentName);
        error.message = error.message.replace('{file_type}', fileType);

        this.openAlertDialog(error);
    }

    private openAlertDialog(errorData?: { title: string; message: string }): void {
        this.dialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: errorData,
        });
    }

    private uploadAllFiles(folderId: string, filesAmount: number): void {
        this.startFilesProcessing(filesAmount);
        this.fileUploader.updateUploaderUrl(`/api/titles/document/${folderId}`);
        this.fileUploader.uploadAll();
    }

    private resetLoadingCounter(): void {
        this.subscriptions.add(
            this.loading$.subscribe((loading) => {
                if (!loading) {
                    this.filesAmount = 0;
                    this.fileUploader.removeAll();
                }
            }),
        );
    }

    private addSubscriptions(subs: Subscription[]): void {
        subs.forEach((sub) => this.subscriptions.add(sub));
    }
}
