import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type NotificationsDialogParams = {
    errors: string[];
    notificationBell?: Element;
};

@Component({
    selector: 'avl-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {

    public errors: string[];
    public notificationBell: Element;

    constructor(
        private readonly dialogRef: MatDialogRef<NotificationsComponent>,
        @Inject(MAT_DIALOG_DATA)
        private readonly data: NotificationsDialogParams,
    ) {
        this.errors = data.errors || [];
        this.notificationBell = data.notificationBell;
    }

    public close(): void {
        this.dialogRef.close();
    }
}
