import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IFailedPurchase } from '@core/types';

export type FailedPurchasesState = EntityState<IFailedPurchase>;

@Injectable()
@StoreConfig({ name: 'failed-purchases', resettable: true })
export class FailedPurchasesStore extends EntityStore<FailedPurchasesState> {

    constructor() {
        super();
    }
}
