import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceCheckerService } from '@services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'avl-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss'],
})
export class DocumentViewerComponent implements OnInit {
    public isLoading = true;
    public documentUrl: SafeUrl;

    private folderId?: string;
    private documentId?: string;
    private resourceUrlWithPlaceholders?: string;
    private resourceNotFoundPage?: string;
    private folderPageUrl?: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly resourceCheckerService: ResourceCheckerService,
        private readonly sanitizer: DomSanitizer,
    ) {
    }

    public ngOnInit(): void {
        this.folderId = this.route.snapshot.queryParamMap.get('fid');
        this.documentId = this.route.snapshot.paramMap.get('documentId');
        this.resourceUrlWithPlaceholders = this.route.snapshot.data['resourceUrlWithPlaceholders'];
        this.resourceNotFoundPage = this.route.snapshot.data['resourceNotFoundPage'];
        this.folderPageUrl = this.route.snapshot.data['folderPageUrl'];
        const resourceUrl = this.replacePlaceholdersWithValues(this.resourceUrlWithPlaceholders);
        this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(resourceUrl);
        this.checkResource(resourceUrl);
    }

    private replacePlaceholdersWithValues(basePattern: string): string {
        return basePattern
            .replace(':folderId', this.folderId)
            .replace(':documentId', this.documentId);
    }

    private checkResource(resourceUrl: string): void {
        this.resourceCheckerService.checkDocumentExistence(resourceUrl)
            .subscribe((isExist) => {
                if (isExist) {
                    this.isLoading = false;
                } else {
                    this.redirectToResourceNotFoundPage();
                }
            });
    }

    private redirectToResourceNotFoundPage(): void {
        const redirectTo = this.resourceNotFoundPage || '/title-document-not-found';
        const metadata = {
            previousPageUrl: this.replacePlaceholdersWithValues(this.folderPageUrl),
        };

        void this.router.navigate([redirectTo], { state: metadata });
    }
}
