/**
 * maps FileList to File[]
 *
 * @param {any[]} filesList  original type 'FileList', used as 'any[]', to avoid types linting errors
 * @returns {File[]}
 */
export function getFileListCopyAsArray(
    filesList: File[],
): File[] {
    // map FileList to File[]
    const list: File[] = [];

    for (const file of filesList) {
        list.push(file);
    }

    return list;
}
