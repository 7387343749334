import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SideNavCountersStore } from './side-nav-counters.store';
import { SideNavCounters } from '../../types/side-nav-counters.type';

@Injectable()
export class SideNavCountersQuery extends Query<SideNavCounters> {

    constructor(protected store: SideNavCountersStore) {
        super(store);
    }

}
