import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { FolderDetails } from '@core/types';

export type FolderState = FolderDetails & {
    id: string;
};

function createInitialState(): FolderState {
    return {
        id: '',
        projectName: '',
        matterNumber: '',
    };
}

@Injectable()
@StoreConfig({ name: '[leases] folder', resettable: true })
export class FolderStore extends Store<FolderState> {
    constructor() {
        super(createInitialState());
    }
}

