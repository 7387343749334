import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { MapComponent } from './map.component';
import { MaplibreModule } from '../../../../maplibre/maplibre.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MaplibreModule,
    ],
    exports: [
        MapComponent,
        MapComponent,
    ],
    declarations: [
        MapComponent,
        MapComponent,
    ],
})
export class MapModule {
}
