import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentsService, FolderService, ImanageService, ReportService } from '../../services';
import { FolderQuery } from '../../store';
import { LoggerService } from '@services';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import * as JSZip from 'jszip';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ShowImanageDialogComponent } from '../dialogs/show-imanage-dialog/show-imanage-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ImanageParams } from '../../types';
import { FileUploader, FileUploaderEventTypes, FileUploaderService, IFileUploaderOptions } from '../../../blocks/file-uploader';

declare const imanage: any;

@Component({
    selector: 'avl-imanage-auth',
    templateUrl: './imanage-auth.component.html',
    styleUrls: ['./imanage-auth.component.scss'],
})
export class ImanageAuthComponent implements OnInit, OnDestroy {
    public iframeLink: SafeResourceUrl;
    public destinationLibraryName = '';
    public destinationFolderId = '';
    public listOfOrder = [];
    public dialogToken = '';
    public customerId = '';
    public hideIframe = false;
    public contentLocation = '';
    public intervalInstance: NodeJS.Timeout | null;

    public fileUploader: FileUploader;
    public fileUploaderOptions: IFileUploaderOptions;
    public filesAmount = 0;
    public isUploading = false;
    public imanageAuthParamsData: ImanageParams;
    public nArray = [];
    public dialogRef;
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);

    @Input()
    public aesEncdecKey: string;

    @Input()
    public paramsEncryptionDecryption: any;

    @Input()
    public folderId: string;

    @Output()
    public closeImanage = new EventEmitter<void>();

    @Input()
    public loading$: Observable<boolean>;

    private readonly subscriptions: Subscription = new Subscription();
    private readonly imanageShowSubsc: Subscription = new Subscription();
    private readonly unsubscribe$ = new Subject<void>();
    private unsubImanageCallback: () => void;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly folderService: FolderService,
        private readonly documentsService: DocumentsService,
        private readonly folderQuery: FolderQuery,
        private readonly log: LoggerService,
        private readonly router: Router,
        private readonly cookieService: CookieService,
        private readonly fileUploaderService: FileUploaderService,
        private readonly dialog: MatDialog,
        private readonly reportService: ReportService,
        private readonly imanageService: ImanageService,
    ) {
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.imanageShowSubsc.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public ngOnInit(): void {
        const imanageCallback = (msg: any): void => this.handleDialogResponse(msg);
        this.unsubImanageCallback = imanage.dialogs.handler(imanageCallback);
        this.fileUploaderOptions = this.setUploaderConfig();
        this.fileUploader = this.fileUploaderService.init(this.fileUploaderOptions);

        this.log.info('imanageAuthComponent.onInit');
        this.listenIframeRequests();
        this.folderId = this.folderQuery.getFolderId();
        this.addListeners();
    }

    public hideIframeHandle(): void {
        this.hideIframe = true;
        this.dialogRef.close(true);
        this.dialogRef.afterClosed().subscribe((data) => {
            if (data === true) {
                this.router.navigate(['/title/upload'], {
                    queryParams: { fid: this.folderId },
                    queryParamsHandling: '',
                });
            } else if (data === 'error') {
                this.log.error('error occured');
            }
        });
    }

    public select(data: any): void {
        this.listOfOrder = [];
        const selectedFiles = data.output_data.selected;
        this.listOfOrder.push(...selectedFiles);
        this.log.info('Selected Files List', this.listOfOrder);
    }

    public defineFolderLocation(data: any): any {
        this.destinationLibraryName = data.database;
        this.destinationFolderId = data.id;

        return data;
    }

    // Imanage Event Listener
    public handleDialogResponse(msg: any): void {
        switch (msg.type) {
            case 'unauthorized':
            // Token has expired; restart the authentication flow
                break;
            case 'select':
            // User clicked Select button
                if (msg.data.output_data.selected[msg.data.output_data.selected.length - 1].wstype === 'folder') {
                    this.log.info('Folder selected');
                    this.defineFolderLocation(msg.data.output_data.selected[msg.data.output_data.selected.length - 1]);
                    this.hideIframeHandle();
                } else {
                    this.select(msg.data);

                    if (msg.data.action === '1') {
                        this.showLoadingSpinner$.next(true);
                        this.sendImanageDataToAgent();

                    }

                    if (msg.data.action === '3') {
                    // this.sendImanageDataToAvail();
                    }
                }
                break;
            case 'Upload to Avail':
            case 'Upload to Avail and Close':
            case 'cancel':
            // User clicked Cancel button
                this.hideIframeHandle();
                break;
            default:
            // console.log('Unknown message received from dialog', msg);
        }
    }

    // Send requested file list to agent to download the files
    private sendImanageDataToAgent(): void {
        const data = JSON.stringify({
            listOfOrder: this.listOfOrder,
        });

        if (this.imanageAuthParamsData !== undefined) {
            this.folderService.downloadFiles(this.folderId, this.imanageAuthParamsData, data)
                .subscribe((response: HttpResponse<ArrayBuffer>) => {
                    this.contentLocation = response.headers.get('Content-Location');
                    const taskId = this.contentLocation.split('/')[6];

                    if (response.status === 202) {
                        this.intervalInstance = setInterval(() => this.isDownloadFinished(taskId), 1000);
                    }
                });
        } else {
            this.router.navigate(['/title/upload/imng'], { queryParams: { fid: this.folderId }, queryParamsHandling: '' });
        }
    }

    private isDownloadFinished(taskId: string): void {
        if (taskId) {
            this.folderService.isDownloadFinished(this.imanageAuthParamsData, taskId)
                .subscribe((res: any) => {
                    if (res.status === 200) {
                        this.downloadFilesToUi();
                        clearInterval(this.intervalInstance);
                    }
                });
        }
    }

    private extractData(data: any): void {
        this.nArray.push(data);
    }

    private async processBlobFile(zip: JSZip): Promise<File[]> {
        const ar: File[] = [];

        for (const fname of Object.keys(zip.files)) {
            const bFile = await zip.file(fname).async('blob')
                .then((blob) => {
                    const blobFile = new File([blob], fname, { type: 'application/pdf' });
                    this.extractData(blobFile);
                    return blobFile;
                });
            ar.push(bFile);
        }

        return ar;
    }

    private downloadFilesToUi(): void {
        this.folderService.downloadFilesToUi(this.imanageAuthParamsData, this.contentLocation).subscribe(async (result: any) => {
            const zzip: JSZip = new JSZip();
            // unzip files and append to form data
            await zzip.loadAsync(result)
                .then((zip) => {
                    // create file
                    const dt = new DataTransfer();
                    dt.dropEffect = 'move';
                    dt.effectAllowed = 'all';

                    this.nArray = [];
                    const unzipedFiles = this.processBlobFile(zip);

                    unzipedFiles.then((filesArr) => {
                        if (filesArr.length > 0) {
                            filesArr.forEach((item) => {
                                dt.items.add(item);
                            });
                            this.sendFilesToAvail(dt.files);
                        }
                    });

                    return zip;
                });
        });
    }

    // send file data to the avail app
    private sendFilesToAvail(fdata: any): void {
        this.fileUploader.addFiles(fdata);
    }

    // ******************** Configure **************************/

    // Check the iframe request form imanage service.
    private listenIframeRequests(): void {
        this.imanageService.showDirectIframe$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((resp) => {
                // Unsubscribe from the polling, !! this is not the best practice but this fixed duplicated iframe.
                this.unsubscribe$.next();
                const agentAuth = this.cookieService.get('agentAuthorization');
                this.log.info(
                    'listenIframeRequests: Imanage Agent Authorization: ',
                    agentAuth,
                    'isAgentAuthExpired',
                    this.imanageService.isAgentSessionExpired,
                );

                if (resp.showIframe === true && agentAuth !== null) {
                    this.showLoadingSpinner$.next(false);
                    this.imanageAuthParamsData = {
                        authorization: agentAuth,
                        agentUrl: resp.data.agentUrl,
                        agentSecret: resp.data.agentSecret,
                    };
                    this.showIframeDialog(resp.data.imanageUrl, resp.data.customerId, resp.data.dialogToken);
                }
            });
    }

    private showIframeDialog(imanageUrl: string, customerId: string, dialogToken: string): void {
        // url without selection option
        const ifrUrl = `${imanageUrl}/work/partner-apps/${customerId}/dialogs/file-picker/?protocol=postmessage&mode=open&filename=true`
            + `&multiselect=true&multiselectVersion=true&dialogToken=${dialogToken}&types=document,folder,folder_shortcut`
            + `&actions=Upload to Avail@1,&customerId=${customerId}`;

        this.iframeLink = this.sanitizer.bypassSecurityTrustResourceUrl(ifrUrl);
        const dataPass = {
            iframeLink: this.iframeLink,
            message: 'Imanage Iframe',
            showLoadingSpinner: this.showLoadingSpinner$,
            folderId: this.folderId,
        };
        this.dialogRef = this.dialog.open(ShowImanageDialogComponent, {
            panelClass: 'imanage-upload-dialog',
            data: dataPass,
        });
        this.dialogRef.afterClosed().subscribe(() => this.unsubImanageCallback());
    }

    private setUploaderConfig(): IFileUploaderOptions {
        return {
            uploader: {
                fileAlias: 'file',
                autoUpload: false,
                simultaneousUpload: true,
                simultaneousUploadQuantityLimit: 20,
            },
            filePicker: {
                allowedMultipleFiles: true,
                allowedMimeClass: ['pdf'],
                allowedFileExtension: ['pdf'],
            },
            queue: {
                removeAfterUploadComplete: false,
            },
        };
    }

    private setUploadEvents(): void {
        const subscription = this.fileUploader.events$
            .pipe(
                tap((event) => {
                    if (event.type === FileUploaderEventTypes.fileUploadingFailed) {
                        this.documentsService.anUploadFailed(event.file, event.error);
                        this.documentsService.numOfFailedFiles.next(this.documentsService.numOfFailedFiles.getValue() + 1);
                    }

                    if (event.type === FileUploaderEventTypes.fileUploadingCanceled) {
                        this.documentsService.numOfCanceledFiles.next(this.documentsService.numOfCanceledFiles.getValue() + 1);
                    }

                    this.log.info('(1) upload events', event);
                    this.log.info('(1.1) fileUploader.successfulAddedCounter', this.fileUploader.successfulAddedCounter);
                    this.fileUploader.filesList$.pipe(take(1)).subscribe((v) => this.documentsService.totalNumOfFilesInUploadQueue.next(v.length));
                }),
                filter((event) => {
                    return event.type === FileUploaderEventTypes.fileUploadingSucceeded || event.type === FileUploaderEventTypes.fileUploadingFailed;
                }),
                tap((value) => {
                    this.log.info('(2) upload events', value);
                    this.fileUploader.getUploadingFiles().pipe(take(1))
                        .subscribe((v) => this.log.info(
                            '(2.4) fileUploader.getUploadingFiles()', v.length,
                        ));
                    this.log.info('(2.1) fileUploader.successfulAddedCounter', this.fileUploader.successfulAddedCounter);
                }),
                tap(() => {
                    this.documentsService.startPolling();
                }),
            )
            .subscribe({
                next: (value) => {
                    this.log.info('subscribe.events$ value', value);
                    this.fileUploader.getUploadingFiles().pipe(take(1))
                        .subscribe((v) => this.log.info(
                            '(3.4) fileUploader.getUploadingFiles()', v.length,
                        ));
                    this.log.info('(3.1) fileUploader.successfulAddedCounter', this.fileUploader.successfulAddedCounter);
                    this.fileUploader.getUploadedFiles().pipe(take(1))
                        .subscribe((v) => this.log.info(
                            '(3.5) fileUploader.getUploadedFiles()', v.length,
                        ));
                    this.fileUploader.getUploadFailedFiles().pipe(take(1))
                        .subscribe((v) => this.log.info(
                            '(3.6) fileUploader.getUploadFailedFiles()', v.length,
                        ));
                    this.fileUploader.filesList$.pipe(take(1)).subscribe((v) => this.log.info(
                        '(3.7) fileUploader.filesList$', v.length,
                    ));
                    // check if the polling completed
                    this.documentsService.stopPollingSignal$.subscribe((res) => {
                        this.log.info('polling complted and close the dialog');
                        if (res === true) {
                            this.hideIframeHandle();
                        }
                    });
                },
                error: (err) => {
                    this.log.info('subscribe.events$ error', err);
                    this.documentsService.pollingDocumentsHasFailed();
                },
            });

        this.subscriptions.add(subscription);
    }

    /************************** Upload  *****************/
    private uploadAllFiles(folderId: string, filesAmount: number): void {
        this.startFilesProcessing(filesAmount);
        this.fileUploader.updateUploaderUrl(`/api/titles/document/${folderId}`);
        this.fileUploader.uploadAll();
    }

    private startFilesProcessing(amount: number): void {
        this.documentsService.fillWithEmptyFiles(amount - this.filesAmount);
        this.filesAmount = amount;
        this.isUploading = true;
        this.documentsService.setLoadingState(true);
        this.reportService.resetReport();

        if (amount < 10) {
            this.setRefreshTimer(2);
        } else if (amount >= 10) {
            this.setRefreshTimer(15);
        }
    }

    private setRefreshTimer(time: number): void {
        timer(time * 60 * 1000)
            .pipe(
                takeUntil(this.documentsService.stopPollingSignal$),
                switchMap(() => this.openProcessingRefreshDialog()),
            )
            .subscribe();
    }

    private openProcessingRefreshDialog(): Observable<void> {
        return of();
    }

    private addListeners(): void {
        this.fileUploader.onBeforeUpload()
            .subscribe((amount) => {
                if (!amount) {
                    return;
                }
                this.proceedProjectDetails(this.folderId, amount);
            });

        this.subscriptions.add(
            this.fileUploader.errors$.subscribe((error) => {
                this.log.warn(error);
            }),
        );

        this.setUploadEvents();

        this.subscriptions.add(
            this.documentsService.folderStatusPolling()
                .subscribe({
                    error: (err) => {
                        this.log.info('folder status polling failure', err);
                    },
                }),
        );
        this.resetLoadingCounter();

        this.subscriptions.add(
            this.documentsService.resetLoading$.subscribe(() => {
                this.log.info('--> documentsService.resetLoading$');
                this.fileUploader.removeAll();
            }),
        );
    }

    private proceedProjectDetails(folderId: string, amount: number): void {
        this.uploadAllFiles(folderId, amount);
    }

    private resetLoadingCounter(): void {
        this.subscriptions.add(
            this.loading$.subscribe((loading) => {
                if (!loading) {
                    this.filesAmount = 0;
                    this.fileUploader.removeAll();
                }
            }),
        );
    }
}
