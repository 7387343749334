import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { mapProfileConfig, ProfileConfig, ProfileConfigToMap } from '@core/types';
import { CacheService } from '@services';
import packageJson from '../../../../package.json';

@Injectable({ providedIn: 'root' })
export class ProfileApi {

    constructor(
        private readonly http: HttpClient,
        private readonly profileConfigCacheService: CacheService<ProfileConfig>,
    ) {
    }

    public getConfig(isCacheEnabled: boolean | undefined = true): Observable<ProfileConfig> {
        const url = `/api/profile/configs?version=${packageJson.version}`;
        const cacheKey = url;

        const observable = this.http.get<ProfileConfigToMap>(url)
            .pipe(
                map((config) => mapProfileConfig(config)),
            );

        return isCacheEnabled
            ? this.profileConfigCacheService.findOrSet(cacheKey, observable.pipe(shareReplay(1)))
            : observable;
    }

    public ensureProfile(): Observable<any> {
        return this.http.put('/api/profile/create', null, { responseType: 'text' });
    }

    public shouldShowOnboarding(): Observable<any> {
        return this.http.get('/api/profile/onboarding', { responseType: 'text' });
    }

    public onboardingShown(): Observable<any> {
        return this.http.put('/api/profile/onboarding', null, { responseType: 'text' });
    }
}
