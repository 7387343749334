<div class="progress-bar__container">
    <span class="progress-bar__file-name">{{ fileName }}</span>
    <span class="progress-bar__value">
        Uploading Lease... <span class="progress-bar__value--purple">({{ value | number:'1.0-0' }}%)</span>
    </span>
    <mat-progress-bar
        class="progress-bar__progress-bar progress-bar__progress-bar--purple"
        [value]="value"
    ></mat-progress-bar>
</div>
