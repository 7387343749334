import { NEVER, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { NoticeService } from '../services/notice.service';
import { INoticeDocument } from '../types';
import { routingNotice } from '@constants';
import { LoggerService } from '@services';

@Injectable()
export class NoticesResolver implements Resolve<INoticeDocument[]> {

    constructor(
        private readonly noticeService: NoticeService,
        private readonly router: Router,
        private readonly log: LoggerService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<INoticeDocument[]> {
        const id = route.queryParams.fid;

        if (!id) {
            return;
        }

        return this.noticeService.loadFolderStatusAndDetails(id)
            .pipe(
                catchError((error) => {
                    this.log.warn(error);
                    this.router.navigate([routingNotice]);

                    return NEVER;
                }),
            );
    }
}
