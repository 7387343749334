import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, OperatorFunction, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HttpClientUtilsService {
    public repeatIfServerError(retryMaxAttempts: number, retryDelayMs: number): OperatorFunction<unknown, any> {
        return retryWhen((errors: Observable<HttpErrorResponse>) =>
            errors.pipe(
                mergeMap((error, count) => {
                    const isServerSideError = error.status >= 500;

                    if (isServerSideError && count < retryMaxAttempts) {
                        return of(error).pipe(mergeMap(() => timer(retryDelayMs)));
                    }

                    return throwError(error);
                }),
            ),
        );
    }
}
