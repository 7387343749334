import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SchedulesState, SchedulesStore } from './schedules.store';

@Injectable()
export class SchedulesQuery extends QueryEntity<SchedulesState> {
    constructor(protected store: SchedulesStore) {
        super(store);
    }
}
