<div *ngIf="failedPurchases as purchases">
    <div
        *ngIf="purchases.length === 1"
        class="error-alert error-alert--single"
        [@fadeRowAnimation]=""
    >
        <div class="error-alert__icon">
            <mat-icon svgIcon="alert"></mat-icon>
            <!--
                  <a [href]="getPurchaseLink(purchases[0].id)" target="_blank">
                    <mat-icon class="download-icon" svgIcon="download-white"></mat-icon>
                  </a>
            -->
        </div>
        <p class="error-alert__text"><b>{{purchases[0].reference}}: </b>{{ purchases[0].message }}</p>
        <button
            class="error-alert__close-btn"
            type="button"
            (click)="removePurchase(purchases[0].id)"
        >
            <mat-icon
                class="remove-icon"
                svgIcon="close-white"
            ></mat-icon>
        </button>
    </div>
    <div
        *ngIf="purchases.length > 1"
        class="error-alert__multiple"
    >
        <mat-expansion-panel class="error-alert__multiple-panel">
            <mat-expansion-panel-header
                [collapsedHeight]="'45px'"
                [expandedHeight]="'45px'"
            >
                <mat-icon
                    class="error-alert__icon"
                    svgIcon="alert"
                ></mat-icon>
                <p class="error-alert__text">Oops!
                    <b [ngPlural]="purchases.length">
                        <ng-template ngPluralCase="=1">1 purchase request</ng-template>
                        <ng-template ngPluralCase="other">{{ purchases.length }} purchase requests</ng-template>
                    </b>
                    &nbsp;were unsuccessful
                </p>
            </mat-expansion-panel-header>
            <mat-panel-description *ngFor="let purchase of purchases; trackBy: trackByDocuments">
                <div class="error-alert__icon">
                    <mat-icon svgIcon="alert"></mat-icon>
                    <!--
                              <a [href]="getPurchaseLink(purchase.id)" target="_blank">
                                <mat-icon class="download-icon" svgIcon="download-white"></mat-icon>
                              </a>
                    -->
                </div>

                <p class="error-alert__text"><b>{{purchase.reference}}: </b>{{  purchase.message }}</p>
                <div
                    (click)="removePurchase(purchase.id)"
                    class="error-alert__remove-icon"
                >
                    <mat-icon
                        class="remove-icon"
                        svgIcon="close-white"
                    ></mat-icon>
                </div>
            </mat-panel-description>
        </mat-expansion-panel>
    </div>
</div>
