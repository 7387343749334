import { Component, EventEmitter, Input, Output } from '@angular/core';
import { routingTitles } from '@constants';

import { fadeRowAnimation } from 'app/core/animations/fade.animation';

import { IFailedPurchase } from '@core/types';
import { ID } from '@datorama/akita';


@Component({
    selector: 'avl-purchase-error-alert',
    templateUrl: './purchase-error-alert.component.html',
    styleUrls: ['./purchase-error-alert.component.scss'],
    animations: [fadeRowAnimation],
})
export class PurchaseErrorAlertComponent {
    @Input()
    public failedPurchases: IFailedPurchase[];

    @Input()
    public folderId: string;

    @Input()
    public transactionId: string;

    @Output()
    public purchaseRemoved = new EventEmitter<string>();

    public removePurchase(id: string): void {
        this.purchaseRemoved.emit(id);
    }

    public getPurchaseLink(documentId: string): string {
        return `/api/${routingTitles}/document/${this.folderId}/${documentId}`;
    }

    public trackByDocuments(index: string, purchase: IFailedPurchase): ID {
        return purchase.id;
    }
}
