export type MinimalDocument = {
    id: string;
    signature: string;
    title: string;
    fileName: string;
    uploadedAt: string;
    type: string;
    source: string;
    isProcessed: boolean;
    isAccepted: boolean;
    includedInReports: boolean;

    messages: [];

    dataPoints: object;
    metadata: {
        dateUploaded?: string;
        fileName?: string;
        pages?: number;
        size?: string;
    };
    isUploadFinished: boolean;
    loadingPercentage: number;
}

export function getEmptyDocument(id: string, fileName: string | undefined = ''): MinimalDocument {
    return {
        id,
        signature: '',
        title: 'Empty document',
        fileName,
        uploadedAt: '',
        type: '',
        source: '',
        isProcessed: false,
        isAccepted: false,
        includedInReports: false,
        isUploadFinished: false,
        loadingPercentage: 0,
        dataPoints: {},
        metadata: {},
        messages: [],
    };
}
