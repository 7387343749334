<div
    class="overlay-content"
    [@slideContent]="animationState"
    (@slideContent.start)="onAnimationStart($event)"
    (@slideContent.done)="onAnimationDone($event)"
>
    <ng-container [ngSwitch]="content.type">
        <avl-splash-screen
            *ngSwitchCase="'splash'"
            (getStarted)="close()"
        ></avl-splash-screen>
        <avl-character-view
            *ngSwitchCase="'character'"
            [options]="content"
        ></avl-character-view>
        <avl-medal
            *ngSwitchCase="'medal'"
            (medalClose)="close()"
            [options]="content"
        ></avl-medal>
        <div *ngSwitchDefault>No Onboarding preview</div>
    </ng-container>
</div>
