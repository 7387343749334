import { ComponentRef, Injectable, Injector } from '@angular/core';
import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { OnboardingOverlayComponent } from '../components/onboarding-overlay/onboarding-overlay.component';

import { IOverlayConfig, OnboardingContent, OnboardingOverlayRef } from '../types/overlay-config.type';
import { onboardingDialogData } from '../tokens/onboarding-overlay.token';
import { take, tap } from 'rxjs/operators';

const defaultConfig: IOverlayConfig = {
    hasBackdrop: true,
    content: {
        type: null,
    },
};

@Injectable({ providedIn: 'root' })
export class OnboardingOverlayService {

    constructor(
        private readonly overlay: Overlay,
        private readonly injector: Injector,
    ) {
    }

    public open(config: IOverlayConfig = {}): OnboardingOverlayRef {
        const overlayConfig = { ...defaultConfig, ...config };
        const overlayRef = this.createOverlay(overlayConfig);

        const onboardingRef = new OnboardingOverlayRef(overlayRef);

        const overlayContainer = this.attachDialogContainer(overlayRef, overlayConfig, onboardingRef);
        if (!overlayConfig.content.backdropClickSkip) {
            overlayRef.backdropClick()
                .pipe(
                    take(1),
                    tap(() => onboardingRef.close()),
                )
                .subscribe();
        }

        onboardingRef.componentInstance = overlayContainer;

        return onboardingRef;
    }

    private createOverlay(config: IOverlayConfig): OverlayRef {
        const overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    }

    private attachDialogContainer(overlayRef: OverlayRef, config: IOverlayConfig, onboardingRef: OnboardingOverlayRef): OnboardingOverlayComponent {
        const injector = this.createInjector(config, onboardingRef);

        const containerPortal = new ComponentPortal(OnboardingOverlayComponent, null, injector);
        const containerRef: ComponentRef<OnboardingOverlayComponent> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }

    private createInjector(config: IOverlayConfig, onboardingRef: OnboardingOverlayRef): PortalInjector {
        const injectionTokens = new WeakMap();

        injectionTokens.set(OnboardingOverlayRef, onboardingRef);
        injectionTokens.set(onboardingDialogData, config.content);

        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(config: IOverlayConfig): OverlayConfig {
        const positionStrategy = this.getOverlayPosition(config.content);

        return new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy,
        });
    }

    private getOverlayPosition(positions: OnboardingContent): GlobalPositionStrategy {
        const positionStrategy = this.overlay.position().global();
        switch (positions.positionX) {
            case 'center':
                positionStrategy.centerHorizontally();
                break;
            case 'left':
                positionStrategy.left();
                break;
            case 'right':
                positionStrategy.right();
                break;
        }

        switch (positions.positionY) {
            case 'center':
                positionStrategy.centerVertically();
                break;
            case 'top':
                positionStrategy.top();
                break;
            case 'bottom':
                positionStrategy.bottom();
                break;
        }

        return positionStrategy;
    }
}
