import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'avl-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input()
    public disabled = true;

    @Input()
    public badgeValue = 0;

    @Input()
    public isBusy = false;

    @Output()
    public clicked = new EventEmitter<void>();

    public onClicked(): void {
        if (!this.isBusy) {
            this.clicked.emit();
        }
    }
}
