import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ThemesService } from '../services';
import { ProfileApi } from '@api';

@Injectable({
    providedIn: 'root',
})
export class ThemesResolve implements Resolve<boolean> {

    constructor(
        private readonly profileApi: ProfileApi,
        private readonly themesService: ThemesService,
    ) {
    }

    public resolve(): Observable<boolean> {
        return this.profileApi.getConfig()
            .pipe(
                map((config) => !config.isNewHomePage),
                tap((isOldTheme) => this.themesService.setIsOldTheme(isOldTheme)),
            );
    }
}
