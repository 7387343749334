import { ReportAction } from '@core/types';

export const multipleActions: Pick<ReportAction, 'key' | 'format'>[] = [
    {
        key: 'all-in-one',
        format: 'zip',
    },
    {
        key: 'save-to-imanage',
        format: 'zip',
    },
];
