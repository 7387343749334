import { Injectable } from '@angular/core';
import { FailedDocumentsStore } from './failed-documents.store';
import { IDocument } from '../../types';
import { IFailedDocument } from '@core/types';

@Injectable()
export class FailedDocumentsService {

    constructor(
        private readonly store: FailedDocumentsStore,
    ) {
    }

    public add(document: IFailedDocument): void {
        this.store.add(document);
    }

    public remove(documentId: string): void {
        this.store.remove(documentId);
    }

    public setDocuments(documents: IDocument[]): void {
        const normalizeDocuments = documents.map((document) => this.normalizeFailedDocument(document));
        this.store.set(normalizeDocuments);
    }

    public addDocument(document: IDocument): void {
        const normalisedDocument = this.normalizeFailedDocument(document);
        this.store.add(normalisedDocument);
    }

    private normalizeFailedDocument(document: IDocument): IFailedDocument {
        return {
            id: document.id,
            fileName: document.fileName,
            message: document.messages[0].message,
        };
    }
}
