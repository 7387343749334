import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, take, takeUntil, tap } from 'rxjs/operators';

import { DocumentsQuery, FailedDocumentsQuery, FailedPurchasesQuery, FolderQuery } from '../../store';
import { DocumentsService, FolderService, ImanageService, RefreshService, ReportGenerationHandlerService } from '../../services';

import {
    MISMATCH_TITLES_AND_PLANS,
    NO_TITLES_ONLY_PLANS,
    NO_VALID_DOCUMENT_TO_PROCEED,
    REFRESH_CONFIRM_DATA,
    SOMETHING_GONE_WRONG,
    UNABLE_COMMUNICATE_WITH_SERVER,
} from '@constants';

import { IFailedDocument, IFailedPurchase } from '@core/types';
import { IDocument } from '../../types';

import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { LandRegistryDialogComponent } from '../dialogs/land-registry-dialog/land-registry-dialog.component';
import { ProjectDetailsDialogComponent } from '../dialogs/project-details-dialog/project-details-dialog.component';
import { LoggerService, PlanService } from '@services';
import { environment } from '@env/environment';
import { OnboardingManageService } from '../../../onboarding/services';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { LandRegistryDialogOptions } from '../../types';
import { LngLat } from 'maplibre-gl';
import { SortType } from '../../types';
import { OrderType, RegistrySearchType } from '@enums';
import { SearchRegistry } from '../../enums/search-register.enum';
import { LandRegistryDialogQuery, LandRegistryDialogService } from '../../store/land-registry-dialog';

@Component({
    selector: 'avl-titles-upload',
    templateUrl: './titles-upload.component.html',
    styleUrls: ['./titles-upload.component.scss'],
})
export class TitlesUploadComponent implements OnInit, OnDestroy {
    public readonly isDragDropEnabled = environment.isTitleDocumentsReorderingEnabled;
    public searchRegistry$: Observable<SearchRegistry>;
    public documentsLoading$: Observable<boolean>;
    public failedDocuments$: Observable<IFailedDocument[]>;
    public failedPurchases$: Observable<IFailedPurchase[]>;
    public landRegistryButtonDisabled = false;
    public destroy$ = new Subject<void>();

    public isDisabledBtn = false;
    public documentsLoading = true;
    public folderId: string;
    public succeedDocuments: IDocument[] = [];
    public sortType: SortType;
    public orderType: OrderType;
    public hasTitles = new BehaviorSubject<boolean>(false);
    public imanageButtonDisabled = false;
    public showImanageIframe = false;
    public isOldTheme = true;

    private readonly unsubscribe$ = new Subject<void>();
    private succeedDocuments$: Observable<IDocument[]>;

    constructor(
        private readonly documentsQuery: DocumentsQuery,
        private readonly refreshService: RefreshService,
        private readonly folderQuery: FolderQuery,
        private readonly folderService: FolderService,
        private readonly failedDocumentsQuery: FailedDocumentsQuery,
        private readonly failedPurchasesQuery: FailedPurchasesQuery,
        private readonly documentsService: DocumentsService,
        private readonly planService: PlanService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly log: LoggerService,
        private readonly handlerService: ReportGenerationHandlerService,
        private readonly onboarding: OnboardingManageService,
        private readonly imanageService: ImanageService,
        private readonly landRegistryQuery: LandRegistryDialogQuery,
        private readonly landRegistryService: LandRegistryDialogService,
    ) {
    }

    public ngOnInit(): void {
        this.searchRegistry$ = this.landRegistryQuery.select('registry');
        this.isOldTheme = this.activatedRoute.snapshot.data['isOldTheme'];
        this.documentsService.orderType
            .pipe(first())
            .subscribe((type) => this.orderType = type);
        this.documentsService.sortType
            .pipe(first())
            .subscribe((type) => this.sortType = type);
        this.folderQuery.folderId$
            .pipe(takeUntil(this.destroy$))
            .subscribe((id: string) => this.folderId = id);
        this.documentsLoading$ = this.documentsQuery.selectLoading()
            .pipe(tap((loading: boolean) => this.documentsLoading = loading));
        this.succeedDocuments$ = this.documentsQuery.selectAll();
        this.failedDocuments$ = this.failedDocumentsQuery.selectAll();
        this.failedPurchases$ = this.failedPurchasesQuery.selectAll();
        this.setupListeners();
        this.setOnboarding();
        this.setOnImanageLoad();
        this.checkUrlParams();
    }

    public ngOnDestroy(): void {
        this.folderService.resetFolderStore();
        this.destroy$.next();
        this.destroy$.complete();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public setupListeners(): void {
        this.succeedDocuments$
            .pipe(takeUntil(this.destroy$))
            .subscribe((docs: IDocument[]) => {
                this.succeedDocuments = docs;
                const documentTypes = Array.from(new Set(docs.map((item: any) => item.type)));
                this.handlerService.setUniqueDocTypes(documentTypes);
                const validDocumentTypes = documentTypes.filter((d) => d.type !== 'unknown');
                this.hasTitles.next(validDocumentTypes.length > 0);
            });

        this.activatedRoute.queryParams
            .pipe(take(1))
            .subscribe((params) => {
                if ('download' in params) {
                    this.handlerService.openGenerateReportDialog();
                }
            });

        if (this.isDocumentsPresentInFolder()) {
            this.refreshService.onRefresh()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (refreshed) => refreshed && this.showConfirmAdvancedRefreshDialog(),
                    complete: () => this.refreshService.resetRefresh(),
                });
        }

        const folderId = this.folderQuery.getFolderId();
        if (folderId) {
            this.folderService.updateProjectStatus(folderId).subscribe();
        }
    }

    public showConfirmAdvancedRefreshDialog(): void {
        this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'confirm-dialog',
            data: REFRESH_CONFIRM_DATA,
        })
            .afterClosed()
            .subscribe((isConfirm) => {
                if (isConfirm) {
                    this.openLandRegistryDialog({ withRefresh: true });
                }
            });

    }

    public onLandRegistryOpened(): void {
        this.landRegistryButtonDisabled = true;
        this.folderQuery.folderCreated()
            ? this.proceedProjectDetailsOrLandRegistryDialog(false)
            : this.startNewProject({});
    }

    public onFailedDocumentRemove(id: string): void {
        this.documentsService.removeFailedDocument(id)
            .pipe(take(1))
            .subscribe({
                error: (err: HttpErrorResponse) => {
                    if (err.status === 404) {
                        this.documentsService.removeFailedDocumentFromStore(id);
                    }
                },
            });
    }

    public onFailedPurchaseRemove(id: string): void {
        this.log.info('remove the failed purchase', id);
        this.documentsService.removeFailedPurchaseAlert(id);
    }

    public navigateToNewFolder(folderId: string): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { fid: folderId },
            queryParamsHandling: 'merge',
        });
    }

    public onNextButtonClick(): void {
        this.checkRelatedDocumentsAndProceed();
    }

    public proceedToNextStep(): void {
        this.planService.isSchedulesEnabled(this.folderQuery.getFolderId())
            .pipe(take(1))
            .subscribe((enabled) => {
                if (enabled) {
                    this.onboarding.closeActiveOverlay();
                    this.router.navigate(
                        ['/titles/schedules'],
                        { queryParams: { fid: this.folderId } },
                    );
                } else {
                    this.handlerService.openGenerateReportDialog();
                }
            });
    }


    // ************* Imanage button event ****************************
    public onImanageOpened(): void {
        this.imanageButtonDisabled = true;
        this.folderQuery.folderCreated()
            ? this.checkImanageLogin()
            : this.startNewProject({ isImanageTrigger: true });
    }

    // Function to handle the login process of Imanage
    public showImanageDialog(): void {
        this.log.info('titles.agentAuthorization');
        this.showImanageIframe = false;
        this.imanageService.showImanageDialog();
        this.imanageService.showDirectIframe$
            .pipe(takeUntil(this.unsubscribe$))
            .pipe(filter((d) => d.showIframe === true))
            .pipe(take(1))
            .subscribe((resp) => {
                // this.unsubscribe$.unsubscribe();
                if (resp.showIframe === true) {
                    this.showImanageIframe = true;
                }
            });
    }

    // Check display of Imanage iframe
    public setOnImanageLoad(): void {
        this.activatedRoute.queryParamMap
            .subscribe((params) => {
                const codeParam = params.get('code');
                if (codeParam !== null) {
                    this.showImanageIframe = true;
                }
            });
    }

    public showRegistrySearchDialog(type: RegistrySearchType): void {
        this.landRegistryButtonDisabled = true;
        const landRegistryOptions: LandRegistryDialogOptions = {
            searchType: type,
        };

        this.folderQuery.folderCreated()
            ? this.proceedProjectDetailsOrLandRegistryDialog(false, landRegistryOptions)
            : this.startNewProject({ landRegistryOptions });
    }

    public changeSearchRegistry(registry: SearchRegistry): void {
        this.landRegistryService.changeSearchRegistry(registry);
    }

    private isDocumentsPresentInFolder(): boolean {
        return this.folderQuery.folderCreated() && !this.documentsQuery.isEmpty();
    }

    private proceedProjectDetailsOrLandRegistryDialog(
        isImanageTrigger: boolean | undefined = false,
        options?: LandRegistryDialogOptions,
    ): void {
        if (isImanageTrigger) {
            this.showProjectDetailsDialog();
        } else {
            this.openLandRegistryDialog(options);
        }
    }

    private showProjectDetailsDialog(): void {
        const dialogRef = this.dialog.open<ProjectDetailsDialogComponent, null, 'success' | 'error'>(
            ProjectDetailsDialogComponent,
            {
                panelClass: 'project-details-dialog',
                disableClose: this.onboarding.isActive,
                autoFocus: false,
            });

        dialogRef.afterClosed()
            .subscribe((result) => {
                this.log.info('showProjectDetailsDialog afterClosed', result);
                if (result === 'success') {
                    // check if it is land registry(1) or imanage(2)
                    this.checkImanageLogin();
                } else if (result === 'error') {
                    this.handlerService.openAlertDialog(false, SOMETHING_GONE_WRONG);
                }
                this.landRegistryButtonDisabled = false;
                this.imanageButtonDisabled = false;
            });
    }

    private startNewProject({
        isImanageTrigger = false,
        landRegistryOptions,
    }: {
        isImanageTrigger?: boolean;
        landRegistryOptions?: LandRegistryDialogOptions;
    }): void {
        this.folderService.createFolder()
            .subscribe({
                next: (folderId: string) => {
                    this.navigateToNewFolder(folderId);
                    this.proceedProjectDetailsOrLandRegistryDialog(isImanageTrigger, landRegistryOptions);
                },
                error: () => {
                    this.handlerService.openAlertDialog(false, UNABLE_COMMUNICATE_WITH_SERVER);
                    if (isImanageTrigger) {
                        this.imanageButtonDisabled = false;
                    } else {
                        this.landRegistryButtonDisabled = false;
                    }
                },
            });
    }

    private checkUrlParams(): void {
        const paramsMap = this.activatedRoute.snapshot.queryParamMap;
        const landRegistryType = paramsMap.get('searchRegistry');
        const landRegistrySearchType = paramsMap.get('searchType');
        const titleNumber = paramsMap.get('titleNumber');
        const mapIsFreeholdsOn = paramsMap.get('isFreeholdsOn');
        const mapIsLeaseholdsOn = paramsMap.get('isLeaseholdsOn');
        const filters = {
            isFreeholdsOn: mapIsFreeholdsOn === 'true',
            isLeaseholdsOn: mapIsLeaseholdsOn === 'true',
        };
        const mapZoom = paramsMap.get('zoom');
        const mapLat = paramsMap.get('lat');
        const mapLng = paramsMap.get('lng');
        const location = new LngLat(Number(mapLng), Number(mapLat));
        const isFiltersConfigured = mapIsFreeholdsOn !== null || mapIsLeaseholdsOn !== null;
        const isLocationConfigured = mapLat !== null || mapLng !== null;

        if (landRegistrySearchType) {
            const landRegistryOptions: LandRegistryDialogOptions = {
                zoom: mapZoom && Number(mapZoom),
                location: isLocationConfigured ? location : null,
                filters: isFiltersConfigured ? filters : null,
                titleNumber,
                searchType: landRegistrySearchType as RegistrySearchType,
                withRefresh: false,
                searchRegistry: landRegistryType as SearchRegistry,
            };

            this.openLandRegistryDialog(landRegistryOptions);
        }
    }

    private openLandRegistryDialog(landRegistryOptions?: LandRegistryDialogOptions): void {
        const dialogRef = this.dialog.open(LandRegistryDialogComponent, {
            panelClass: 'land-registry-dialog',
            data: landRegistryOptions || { withRefresh: false },
        });

        this.afterClosedLandRegistry(dialogRef);
    }

    private afterClosedLandRegistry(dialogRef: MatDialogRef<LandRegistryDialogComponent>): void {
        dialogRef
            .afterClosed()
            .pipe(
                tap((result) => {
                    if (result?.result === 'success') {
                        this.handlerService.openDoneDialog(result.message);
                    } else if (result?.result === 'error') {
                        const errorData = {
                            title: 'Oops!',
                            message: result.message,
                        };
                        this.handlerService.openAlertDialog(false, errorData);
                    }

                    this.landRegistryButtonDisabled = false;
                    this.imanageButtonDisabled = false;
                    this.onboarding.destroyOnboarding();
                }),
            )
            .subscribe();
    }

    private setOnboarding(): void {
        this.onboarding.isSchedulesNavigate = environment.enableAddSchedules;

        if (this.onboarding.isActive) {
            this.onboarding.isShowReport = true;
            this.onboarding.isDisabledNextButton$.next(true);
            this.addOnboardingHandlers();
        }
    }

    private addOnboardingHandlers(): void {
        const unsubscriber$ = new Subject<void>();
        this.onboarding.isDisabledNextButton$
            .pipe(
                takeUntil(unsubscriber$),
                tap((isDisabled) => {
                    if (!this.onboarding.isActive) {
                        this.isDisabledBtn = false;
                        unsubscriber$.next();
                    } else {
                        this.isDisabledBtn = isDisabled;
                    }
                }),
            )
            .subscribe();
    }

    private checkRelatedDocuments(): string {
        const titleRegisters = this.succeedDocuments.filter((d) => d.type === 'title-register').map((d) => d.titleNumber);
        const titlePlans = this.succeedDocuments.filter((d) => d.type === 'title-plan').map((d) => d.titleNumber);
        const scottishTitles = this.succeedDocuments.filter((d) => d.type === 'scottish-title').map((d) => d.titleNumber);
        if (titlePlans.length === 0) {
            if (titleRegisters.length === 0 && scottishTitles.length === 0) {
                return 'has-no-title-nor-plans';
            }
            return 'Okay';
        }
        if (titleRegisters.length === 0) {
            if (scottishTitles.length === 0) {
                return 'has-no-title-only-plans';
            }
            return 'has-title-mismatched-plans';
        }
        for (const titleNumber of titlePlans) {
            if (!titleRegisters.includes(titleNumber)) {
                return 'has-title-mismatched-plans';
            }
        }
        return 'Okay';
    }

    private checkRelatedDocumentsAndProceed(): void {
        const state = this.checkRelatedDocuments();
        if (state === 'has-title-mismatched-plans') {
            const dialogRef = this.dialog.open(
                ConfirmDialogComponent,
                {
                    panelClass: 'confirm-dialog',
                    data: MISMATCH_TITLES_AND_PLANS,
                    maxWidth: '400px',
                },
            );

            dialogRef.afterClosed().subscribe((isConfirm) => {
                if (isConfirm) {
                    this.proceedToNextStep();
                }
            });
        } else if (state === 'has-no-title-only-plans') {
            this.dialog.open(AlertOkDialogComponent, NO_TITLES_ONLY_PLANS).afterClosed();
        } else if (state === 'has-no-title-nor-plans') {
            this.dialog.open(AlertOkDialogComponent, NO_VALID_DOCUMENT_TO_PROCEED).afterClosed();
        } else {
            this.proceedToNextStep();
        }
    }

    // Check if project is selected
    private checkImanageLogin(): void {
        const folderId = this.folderQuery.getFolderId();
        this.folderService.projectAlreadyCreated(folderId)
            .subscribe({
                next: (isCreated) => {
                    isCreated ? this.showImanageDialog() : this.showProjectDetailsDialog();
                },
                complete: () => this.imanageButtonDisabled = false,
            });
    }
}
