import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../notification-center/services/notification.service';
import { FolderQuery } from '../../../../states';

@Component({
    selector: 'avl-report-generation-failed',
    templateUrl: './report-generation-failed.component.html',
    styleUrls: ['./report-generation-failed.component.scss'],
})
export class ReportGenerationFailedComponent implements OnInit {
    public title: string;
    public message: string;

    public notificationBell: Element;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private readonly data: { title: string; message: string },
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<ReportGenerationFailedComponent>,
        private readonly folderQuery: FolderQuery,
    ) {
        this.title = data.title;
        this.message = data.message;
    }

    public ngOnInit(): void {
        this.notificationBell = document.getElementsByClassName('notifications-bell').item(0);
    }

    public openNotificationDialog(): void {
        const folderId = this.folderQuery.getId();

        this.dialogRef.close();
        this.notificationService.showDialog(folderId);
    }
}
