import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from '@services';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class PlanService {

    constructor(
        private readonly http: HttpClient,
        private readonly log: LoggerService,
    ) {
    }

    public isSchedulesEnabled(folderId: string): Observable<boolean> {
        if (!environment.enableAddSchedules) {
            return of(false);
        }

        return this.http.get(`/api/titles/schedules-enabled/${folderId}`)
            .pipe(
                tap((response) => {
                    this.log.info('isSchedulesEnabled.http', response);
                }),
                map((response) => response),
                tap((enabled: boolean) => {
                    this.log.info('isSchedulesEnabled.enabled', enabled);
                }),
                catchError(() => of(false)),
            );
    }
}
