import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class CachControlInterceptor implements HttpInterceptor {
    public intercept(
        request: HttpRequest<never>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

        if (isIE && request.method === 'GET') {
            const noCachedRequest = request.clone({
                headers: request.headers
                    .set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache'),
            });

            return next.handle(noCachedRequest);
        }

        return next.handle(request);
    }
}
