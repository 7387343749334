import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AvailToolKey } from '@enums';
import { ProfileService } from '@services';

type AvailTool = {
    name: AvailToolKey;
    logoSrc: string;
    logoAlt: string;
}

const availTools: AvailTool[] = [
    {
        name: AvailToolKey.title,
        logoSrc: '/assets/images/avail-titles-logo.svg',
        logoAlt: 'Avail title logo',
    },
    {
        name: AvailToolKey.lease,
        logoSrc: '/assets/images/avail-lease-logo.svg',
        logoAlt: 'Avail lease logo',
    },
    {
        name: AvailToolKey.notice,
        logoSrc: '/assets/images/avail-notice-logo.svg',
        logoAlt: 'Avail notice logo',
    },
];

@Component({
    selector: 'avl-other-tools',
    templateUrl: './other-tools.component.html',
    styleUrls: ['./other-tools.component.scss'],
})
export class OtherToolsComponent implements OnInit {
    @Input()
    public excludeTool: AvailToolKey;

    public availableTools: AvailTool[];
    public availableToolNames: string[];

    constructor(
        private readonly router: Router,
        private readonly profileService: ProfileService,
    ) {
    }

    public ngOnInit(): void {
        this.profileService.apps$.subscribe((apps) => {
            const tools = apps.map((el) => el.key);

            this.availableTools = availTools.filter((tool) => {
                const isToolExist = tools.includes(tool.name);
                const isToolIncluded = this.excludeTool !== tool.name;

                return isToolExist && isToolIncluded;
            });

            this.availableToolNames = this.availableTools.map((tool) => tool.name);
        });
    }

    public goToTool(tool: AvailToolKey): void {
        this.router.navigate([tool]);
    }
}
