import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ITitleInfo, LandRegistry } from '../../types';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { SearchResultsStore } from '../../store';
import { PurchasedTitleDetails } from '../../types/purchased-title-details.type';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { InfoSnackbarComponent } from '@shared/components/info-snackbar/info-snackbar.component';
import { MatSnackBarConfig } from '@angular/material/snack-bar/snack-bar-config';
import { HmLandRegistryService } from './hm-land-registry.service';
import { ScotlandLandRegistryService } from './scotland-land-registry.service';
import { LandRegistrySearchSource } from '../../enums/land-registry-search-source.enum';
import { SearchRegistry } from '../../enums/search-register.enum';
import { RegistrySearchType } from '@enums';
import { searchRegistries } from '@constants';
import { LandRegistryPurchaseEntity } from '../../enums/land-registry-purchase-entity.enum';

@Injectable()
export class LandRegistryService {

    private readonly tooLongSearchSnackBarAppearingDelayMs = 4000;
    private snackBarRef?: MatSnackBarRef<InfoSnackbarComponent>;
    private tooLongSearchSnackBarSetTimeoutRef?: NodeJS.Timeout;
    private registryStrategy: LandRegistry;

    constructor(
        private readonly hmLandRegistryService: HmLandRegistryService,
        private readonly scotlandLandRegistryService: ScotlandLandRegistryService,
        private readonly searchResultStore: SearchResultsStore,
        private readonly snackBar: MatSnackBar,
    ) {
        this.setRegistry(SearchRegistry.hmlr);
    }

    public setRegistry(registry: SearchRegistry): void {
        switch (registry) {
            case SearchRegistry.hmlr:
                this.registryStrategy = this.hmLandRegistryService;
                break;
            case SearchRegistry.ros:
                this.registryStrategy = this.scotlandLandRegistryService;
                break;
        }
    }

    public getPurchaseEntity(): LandRegistryPurchaseEntity {
        return this.registryStrategy.getPurchaseEntity();
    }

    public checkRegistryAvailability(): Observable<boolean> {
        return this.registryStrategy.getIsAvailable()
            .pipe(
                switchMap((isAvailable) => {
                    return isAvailable
                        ? of(true)
                        : this.registryStrategy.registryUnavailable()
                            .pipe(map(() => false));
                }),
            );
    }

    public searchTitleRegisters(folder: string, kind: string, query: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        this.startLoading();
        statusUrl.next(null);

        return this.registryStrategy.searchTitle(folder, kind, query, LandRegistrySearchSource.default, statusUrl)
            .pipe(
                tap((titlesInfo) => this.searchResultStore.set(titlesInfo)),
                finalize(() => this.stopLoading()),
            );
    }

    public downloadSearchResults(url: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.registryStrategy.getSearchResults(url);
    }

    public refreshTitles(folder: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        this.startLoading();

        return this.registryStrategy.refreshTitles(folder, statusUrl)
            .pipe(
                tap((titlesInfo) => this.searchResultStore.set(titlesInfo)),
                finalize(() => this.stopLoading()),
            );
    }

    public clearResults(): void {
        this.searchResultStore.set([]);
    }

    public removeTitleRegister(titleNumber: string): void {
        this.searchResultStore.remove(titleNumber);
    }

    public purchaseTitles(folder: string, titles: ITitleInfo[]): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        return this.registryStrategy.purchaseTitles(folder, titles);
    }

    public getLandRegistrySearchTypes(registry: SearchRegistry): RegistrySearchType[] {
        const registryDetails = searchRegistries.find((registerDetails) => registerDetails.id === registry);

        return registryDetails?.searchTypes || [];
    }

    private startLoading(): void {
        this.searchResultStore.setLoading(true);
        this.tooLongSearchSnackBarSetTimeoutRef = setTimeout(
            () => {
                this.showTooLongSearchSnackBar();
                this.tooLongSearchSnackBarSetTimeoutRef = null;
            },
            this.tooLongSearchSnackBarAppearingDelayMs,
        );
    }

    private stopLoading(): void {
        this.searchResultStore.setLoading(false);
        this.dismissTooLongSearchSnackBar();
    }

    private showTooLongSearchSnackBar(): void {
        if (!this.snackBarRef?.instance) {
            const options: MatSnackBarConfig = {
                data: 'It’s taking a bit longer than expected, but your results are on the way.',
                panelClass: ['info-snackbar', 'min-content'],
            };

            this.snackBarRef = this.snackBar.openFromComponent(InfoSnackbarComponent, options);
            this.snackBarRef.afterDismissed().subscribe(() => this.snackBarRef = null);
        }
    }

    private dismissTooLongSearchSnackBar(): void {
        this.snackBarRef?.dismiss();

        if (this.tooLongSearchSnackBarSetTimeoutRef) {
            clearTimeout(this.tooLongSearchSnackBarSetTimeoutRef);
            this.tooLongSearchSnackBarSetTimeoutRef = null;
        }
    }
}
