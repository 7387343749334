/* eslint-disable @typescript-eslint/naming-convention */
import { App, ImanageParams, ImanageParamsToMap, Logo, LogoToMap, mapImanageParams, mapLogo, Plan } from '@core/types';

export type ProfileConfig = {
    apps: App[];
    isHmlrEnabled: boolean;
    isRosEnabled: boolean;
    imanageConfig?: ImanageParams;
    isNewHomePage: boolean;
    plan: Plan;
    theme?: Logo;
}

export type ProfileConfigToMap = {
    'apps': App[];
    'hmlr-enabled': boolean;
    'is-ros-enabled': boolean;
    'imanage-config'?: ImanageParamsToMap;
    'new-home-page': boolean;
    'plan': Plan;
    'theme'?: LogoToMap;
}

export function mapProfileConfig(config: ProfileConfigToMap): ProfileConfig {
    return {
        apps: config['apps'],
        isHmlrEnabled: config['hmlr-enabled'],
        isRosEnabled: config['is-ros-enabled'],
        imanageConfig: config['imanage-config'] && mapImanageParams(config['imanage-config']),
        isNewHomePage: config['new-home-page'],
        plan: config['plan'],
        theme: config['theme'] && mapLogo(config['theme']),
    };
}
