import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DocumentsState, DocumentsStore } from './documents.store';

@Injectable()
export class DocumentsQuery extends QueryEntity<DocumentsState> {
    constructor(protected store: DocumentsStore) {
        super(store);
    }

    public isEmpty(): boolean {
        return this.getAll().length < 1;
    }
}
