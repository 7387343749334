/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { IDocument, ImanageParams } from '../types';
import { FolderDetails, IResponseStatus } from '@core/types';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class FolderApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public createFolder(): Observable<string> {
        return this.http.post('/api/titles/folder', null, { responseType: 'text' });
    }

    public getFolder(id: string): Observable<IResponseStatus<IDocument>> {
        return this.http.get<IResponseStatus<IDocument>>(`/api/titles/status/${id}`);
    }

    public createProject(folderId: string, project: FolderDetails): Observable<void> {
        return this.http.put<void>(`/api/titles/project/${folderId}`, project);
    }

    public getIsProjectDetailsExist(folderId: string): Observable<boolean> {
        const url = `/api/titles/project/${folderId}?suppress404=true`;

        return this.http.get<FolderDetails | { found: false }>(url, { observe: 'response' })
            .pipe(
                map((response) => {
                    const isRequestSuccessful = response.status === HttpStatusCode.Ok;

                    return isRequestSuccessful
                        && response.body['projectName'] !== undefined
                        && response.body['matterNumber'] !== undefined;
                }),
                catchError((error) => error.status === HttpStatusCode.Unauthorized ? throwError(error) : of(false)),
            );
    }

    public getProjectDetails(folderId: string): Observable<FolderDetails> {
        return this.http.get<FolderDetails>(`/api/titles/project/${folderId}?suppress404=true`)
            .pipe(
                map((details) => ({
                    projectName: details.projectName || '',
                    matterNumber: details.matterNumber || '',
                })),
            );
    }


    // Imanage
    public logInToImanage(data: any, agentUrl: string | undefined = '', agentSecret: string | undefined = ''): Observable<any> {
        if (agentUrl !== '') {
            const headerDict = {
                'Content-Type': 'application/json',
                'Agent-Key': agentSecret,
            };
            const requestOptions = {
                headers: new HttpHeaders(headerDict),
            };

            return this.http.post(`${agentUrl}/api/imanage/agent/auth/oauth2/token`, data, requestOptions);
        } else {
            return this.http.post('/api/imanage/auth/oauth2/token', data);
        }
    }

    public checkIfAuthorised(): Observable<any> {
        return this.http.get('/api/imanage/authorised', { observe: 'response' });
    }

    public getDialogTokenCall(agentUrl: string | undefined = '', agentSecret: string | undefined, authorization: string): Observable<any> {
        if (agentUrl) {
            const headerDic = {
                'Content-Type': 'application/json',
                'Agent-Authorization': `Bearer ${authorization}`,
                'Agent-Key': agentSecret,
            };
            const requestOptions = {
                headers: new HttpHeaders(headerDic),
            };
            const url = `${agentUrl}/api/imanage/agent/dialog-token`;

            return this.http.get(url, requestOptions);
        } else {
            return this.http.get('/api/imanage/dialog-token');
        }
    }

    public downloadFiles(
        folderId: string,
        confData: ImanageParams,
        listOfOrder: any,
    ): Observable<any> {
        if (confData.agentUrl) {
            const headerDic = {
                'Content-Type': 'application/json',
                'Agent-Authorization': `Bearer ${confData.authorization}`,
                'Agent-Key': confData.agentSecret,
            };
            const requestOptions = {
                headers: new HttpHeaders(headerDic),
                observe: 'response' as const,
            };
            const url = `${confData.agentUrl}/api/imanage/agent/${folderId}/download-from-imanage`;

            return this.http.post(url, listOfOrder, requestOptions);
        } else {
            return this.http.post(`/api/imanage/${folderId}/add`, listOfOrder);
        }
    }

    public isDownloadFinished(confData: ImanageParams, taskId: string): Observable<any> {
        const headerDic = {
            'Content-Type': 'application/json',
            'Agent-Authorization': `Bearer ${confData.authorization}`,
            'Agent-Key': confData.agentSecret,
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDic),
            observe: 'response' as const,
        };
        const url = `${confData.agentUrl}/api/imanage/agent/is-download-finished/${taskId}`;

        return this.http.get(url, requestOptions);
    }

    public downloadFilesToUi(confData: ImanageParams, contentLocation: string): Observable<any> {
        const headerDic = {
            'Content-Type': 'application/json',
            'Agent-Authorization': `Bearer ${confData.authorization}`,
            'Agent-Key': confData.agentSecret,
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDic),
            responseType: 'blob' as const,
        };
        const url = `${confData.agentUrl}${contentLocation}`;

        return this.http.get(url, requestOptions);
    }

    public sendThemToAvail(folderId: string, formData: any): Observable<any> {
        const headerDic = {
            Accept: 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDic),
            observe: 'response' as const,
        };
        const url = `/api/titles/document/${folderId}`;

        return this.http.post(url, formData, requestOptions);
    }

    public sendImanageDataToAvail(folderId: string, data: any): Observable<any> {
        return this.http.post(`/api/imanage/${folderId}/add`, data);
    }

    public checkImanageConfiguration(): Observable<any> {
        return this.http.get('/api/imanage/config');
    }
}
