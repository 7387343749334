<button
    class="report-button"
    matRipple
    [class.centered]="isCentered"
    [disabled]="disableButton"
    [class.downloaded]="value.isDownloaded"
    (click)="reportDownload(value)"
>
    <img
        class="icon"
        [src]="'assets/images/report/' + value.icon + '.png'"
        [alt]="value.icon"
    >
    <div class="title-format">
        <span class="title display-block">{{ value.title }}</span>
        <span class="format">{{ value.format | documentFormat }}</span>
    </div>
</button>
