/* eslint-disable @typescript-eslint/naming-convention */
import { NoticeDocType } from '../enums';

export type INoticeDocument = {
    dataPoints: IDataPoints;
    fileName: string;
    id: string;
    isAccepted: boolean;
    isError: boolean;
    isProcessed: boolean;
    messages: INoticeMessage[];
    signature: string;
    title: string;
    type: NoticeDocType;
    uploadedAt: Date;
}

export type IDataPoints = {
    address: string;
    issue_date: string;
    ownership: string;
    title_number: string;
}

export type INoticeMessage = {
    level: string;
    message: string;
}

export type ClientNoticeDocument = INoticeDocument & {
    clientType?: NoticeDocType; // Needs to handle 'unknown' type if server gets broken file
}

export function createEmptyClientNoticeDocument(): ClientNoticeDocument {
    return {
        dataPoints: null,
        fileName: '',
        id: '',
        isAccepted: false,
        isError: false,
        isProcessed: false,
        messages: [],
        signature: '',
        title: '',
        type: null,
        uploadedAt: null,
    };
}
