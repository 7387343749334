import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { GeneratedReportsStore, GeneratedReportsState } from './generated-reports.store';
import { Observable } from 'rxjs';
import { IPagination } from '@core/types';

@Injectable()
export class GeneratedReportsQuery extends QueryEntity<GeneratedReportsState> {

    constructor(protected store: GeneratedReportsStore) {
        super(store);
    }

    public getLoading(): Observable<boolean> {
        return this.selectLoading();
    }

    public getPagination(): IPagination {
        const { currentPage, totalCount, totalPages } = this.getValue();
        return { currentPage, totalCount, totalPages };
    }

}
