import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'avl-redirect',
    templateUrl: './imanage-redirect.component.html',
    styleUrls: ['./imanage-redirect.component.scss'],
})
export class ImanageRedirectComponent implements OnInit {

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
    ) {
    }

    public ngOnInit(): void {
        this.getParams();
    }

    public getParams(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            const codeParam = params.code;
            const stateParam = params.state;
            if (codeParam) {
                this.router.navigate(['authentication/successful'], {
                    queryParams: {
                        code: codeParam,
                        state: stateParam,
                    },
                });
            }
        });
    }
}
