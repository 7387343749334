import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { LoggerService } from '@services';
import { ReportService } from '../../../services';

import { forkJoin, Observable, of, Subject, timer } from 'rxjs';
import { catchError, delay, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { IReportOption } from '../../../types';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';

import { LONG_PROCESSING_REFRESH_CONFIG } from '@constants';

const PROCESSING_REFRESH_TIME = 45;
const GENERATION_STEPS_COUNT = 6;
const GENERATION_ONE_STEP_DURATION = 1000;
const MIN_ONE_STEP_DURATION = 100;
const MIN_DELAY = 3;

const REPORT_STEPS = [
    'Engaging Avail AI Engine',
    'Reviewing Project Scope',
    'Analysing Title Entries',
    'Validating Proprietorship',
    'Compiling Schedules',
    'Finalising Reports',
];

@Component({
    selector: 'avl-report-generation-dialog',
    templateUrl: './report-generation-dialog.component.html',
    styleUrls: ['./report-generation-dialog.component.scss'],
})
export class ReportGenerationDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChildren('step')
    public steps: QueryList<ElementRef>;

    public reportSteps = REPORT_STEPS;

    private readonly generatingStopSignal$ = new Subject<void>();
    private readonly animationEnded$ = new Subject<void>();
    private reportReady = false;
    private reportGenerationStatusCode = 200;
    private oneStepDuration = 0;

    constructor(
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<ReportGenerationDialogComponent>,
        private readonly reportService: ReportService,
        private readonly log: LoggerService,
        @Inject(MAT_DIALOG_DATA)
        public data: any,
    ) {
    }

    public ngOnInit(): void {
        this.oneStepDuration = this.getGenerationOneStepDuration();
        this.generateReportAndDisplayAnimation();
    }

    public ngOnDestroy(): void {
        this.generatingStopSignal$.next();
        this.generatingStopSignal$.complete();
    }

    public ngAfterViewInit(): void {
        this.playAnimation(0, this.steps.toArray());
    }

    private generateReportAndDisplayAnimation(): void {
        const isWait = this.data && this.data.numOfDocuments <= 10;

        forkJoin([
            this.generateReport(isWait)
                .pipe(
                    tap(() => this.setRefreshTimer()),
                    switchMap(() => this.reportService.reportStatus()),
                    takeWhile((response) => {
                        if (!response) {
                            return false;
                        }

                        const status = response.status;
                        this.reportGenerationStatusCode = status;

                        if (status === 200) {
                            this.reportReady = true;
                        }

                        return status === 202 || status === 102;
                    }, true),
                ),
            this.animationEnded$.asObservable(),
        ])
            .pipe(
                catchError((error) => {
                    this.log.info('an error was raised', error);
                    this.reportGenerationStatusCode = error.status;

                    return of([error]);
                }),
                delay(GENERATION_ONE_STEP_DURATION + MIN_ONE_STEP_DURATION),
            )
            .subscribe(([response]) => {
                if (response && response.error || !this.reportReady) {
                    this.dialogRef.close(this.reportGenerationStatusCode === 404 ? 'not-found' : 'error');
                } else {
                    this.dialogRef.close('success');
                }
                this.generatingStopSignal$.next();
            });
    }

    private setRefreshTimer(): void {
        timer(PROCESSING_REFRESH_TIME * 60 * 1000)
            .pipe(
                takeUntil(this.generatingStopSignal$),
                switchMap(() => this.openProcessingRefreshDialog()),
            )
            .subscribe();
    }

    private openProcessingRefreshDialog(): Observable<void> {
        return this.dialog.open(AlertOkDialogComponent, LONG_PROCESSING_REFRESH_CONFIG)
            .afterClosed()
            .pipe(
                tap(() => window.location.reload()),
            );
    }

    private generateReport(wait: boolean): Observable<IReportOption[]> {
        return this.data.reportId
            ? this.reportService.generateReport(wait, this.data.reportId)
            : this.reportService.generateReport(wait);
    }

    private playAnimation(step: number, animationSteps: ElementRef[]): void {
        if (step < GENERATION_STEPS_COUNT) {
            const duration = this.reportReady && MIN_ONE_STEP_DURATION
                || step === 0 && (this.oneStepDuration / 2)
                || this.oneStepDuration;

            of(true)
                .pipe(delay(duration))
                .subscribe(() => {
                    if (this.reportReady || !this.reportReady && step < GENERATION_STEPS_COUNT - 1) {
                        animationSteps[step].nativeElement.classList.add('animated');
                        step++;
                    }
                    this.playAnimation(step, animationSteps);
                });
        } else {
            this.animationEnded$.next();
            this.animationEnded$.complete();
        }
    }

    private getGenerationOneStepDuration(): number {
        const num = this.data && this.data.numOfDocuments || 1;
        const delayLength = GENERATION_ONE_STEP_DURATION * MIN_DELAY;
        const percentage = 1 + num / 100;

        return delayLength * percentage;
    }
}
