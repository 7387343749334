import { Routes } from '@angular/router';

import { TitleRegistersComponent } from '../components/title-registers/title-registers.component';
import { TitlesResolve } from '../resolvers/titles.resolve';

import { AuthGuard } from '@auth/guards';
import { TitlesUploadComponent } from '../components/titles-upload/titles-upload.component';
import { PurchasedPageComponent } from '../components/purchased-page/purchased-page.component';
import { ReportsPageComponent } from '../components/reports-page/reports-page.component';
import { TitlesSchedulesTableComponent } from '../components/titles-schedules/titles-schedules.component';
import { SchedulesResolve } from '../resolvers/schedules.resolve';
import { ToolAccessGuard } from '../../core/guards/tool-access.guard';
import { AvailToolKey } from '@enums';
import { ThemesResolve } from '../resolvers/themes.resolve';
import { DocumentViewerComponent } from '@shared/components/document-viewer/document-viewer.component';

export const titlesRoutes: Routes = [
    {
        path: 'title',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                data: { tool: AvailToolKey.title, title: 'Avail Title' },
                canActivate: [ToolAccessGuard],
                children: [
                    {
                        path: '',
                        component: TitleRegistersComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'upload',
                                pathMatch: 'full',
                            },
                            {
                                path: 'upload',
                                component: TitlesUploadComponent,
                                resolve: { folder: TitlesResolve, isOldTheme: ThemesResolve },
                            },
                            {
                                path: 'upload/:imanage',
                                component: TitlesUploadComponent,
                                resolve: { folder: TitlesResolve, isOldTheme: ThemesResolve },
                            },
                            {
                                path: 'schedules',
                                component: TitlesSchedulesTableComponent,
                                resolve: { folder: TitlesResolve, schedules: SchedulesResolve },
                            },
                            {
                                path: 'purchased',
                                component: PurchasedPageComponent,
                            },
                            {
                                path: 'reports',
                                component: ReportsPageComponent,
                            },
                        ],
                    },
                    {
                        path: 'documents/:documentId',
                        data: {
                            resourceUrlWithPlaceholders: 'api/titles/document/:folderId/:documentId',
                            resourceNotFoundPage: 'title-document-not-found',
                            folderPageUrl: '/title/upload?fid=:folderId',
                        },
                        component: DocumentViewerComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'titles',
        redirectTo: 'title',
    },
];
