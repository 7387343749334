<button
    class="dialog__close"
    type="button"
    mat-dialog-close="close"
    [disabled]="disableClose"
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>
<h4
    class="remove-margin-bottom"
    mat-dialog-title
>
    FILED COPIES
</h4>
<mat-dialog-content>
    <div class="ckeckbox-container">
        <mat-checkbox
            class="example-margin dialog-checkbox"
            (change)="onClickShowAvilable()"
        >
            Show only available
        </mat-checkbox>
    </div>
    <div class="table-container">
        <table
            mat-table
            class="collection__table mat-elevation-z8 field-copies-dialog-table"
            data-testid="filed-copies-table"
            [dataSource]="dataSource"
        >
            <!-- Parent Title Column -->
            <ng-container matColumnDef="reference">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >
                    Parent Title
                </th>
                <td
                    *matCellDef="let element"
                    mat-cell
                >
                    {{element.reference}}
                </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >
                    Type
                </th>
                <td
                    *matCellDef="let element"
                    mat-cell
                >
                    {{element.documentType}}
                </td>
            </ng-container>

            <!-- Filed Under Column -->
            <ng-container matColumnDef="filedUnder">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >
                    Filed Under
                </th>
                <td
                    *matCellDef="let element"
                    mat-cell
                >
                    {{element.filedUnder}}
                </td>
            </ng-container>

            <!-- Location Column -->
            <ng-container matColumnDef="location">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >
                    Location
                </th>
                <td
                    *matCellDef="let element"
                    mat-cell
                >
                    <ng-container *ngIf="element.entryNumbers.length === 1">
                        {{element.entryNumbers[0]}}
                    </ng-container>
                    <ng-container *ngIf="element.entryNumbers.length > 1">
                        <span *ngFor="let nmbr of element.entryNumbers | keyvalue; let index = index">
                            <ng-container *ngIf="index + 1 < element.entryNumbers.length; else withoutComma">
                                {{nmbr.value}},
                            </ng-container>
                            <ng-template #withoutComma>
                                {{nmbr.value}}
                            </ng-template>
                        </span>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Document Date Column -->
            <ng-container matColumnDef="documentDate">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >
                    Document Date
                </th>
                <td
                    *matCellDef="let element"
                    mat-cell
                >
                    {{element.documentDate | date:'d MMMM y'}}
                </td>
            </ng-container>

            <!-- Document Date Column -->
            <ng-container matColumnDef="add">
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >
                    <label
                        *ngIf="dataSource.length"
                        class="collection__add-all-control"
                    >
                        <input
                            type="checkbox"
                            class="checkbox-input"
                            (change)="$event ? masterToggle() : null"
                            [checked]="isAllSelected()"
                        >
                        <ng-container *ngIf="!isAllSelected()">
                            <mat-icon svgIcon="add-all-checkbox"></mat-icon>
                            <span class="checkbox-text">Add All</span>
                        </ng-container>
                        <ng-container *ngIf="isAllSelected()">
                            <mat-icon
                                class="height-12px"
                                svgIcon="close-red"
                            ></mat-icon>
                            <span class="checkbox-text pink-color">Remove</span>
                        </ng-container>
                    </label>
                </th>
                <td
                    *matCellDef="let element"
                    mat-cell
                >
                    <ng-container *ngIf="element.isAvailable === false; else showEnableBtn">
                        <button
                            [disabled]="true"
                            class="unavailable-btn"
                        >
                            Unavailable
                        </button>
                    </ng-container>
                    <ng-template #showEnableBtn>
                        <ng-container
                            *ngIf="(element.isAvailable === true && element.offerPurchase === false); else showPurchaseBtn"
                        >

                            <button
                                [disabled]="true"
                                class="already-purchased-btn"
                            >
                                Purchased
                            </button>
                        </ng-container>
                        <ng-template #showPurchaseBtn>
                            <button
                                *ngIf="!element.isSelected"
                                class="add-btn"
                                (click)="addItem(element)"
                            >
                                Add
                            </button>
                            <button
                                *ngIf="element.isSelected"
                                class="already-added-btn"
                                (click)="addItem(element)"
                            >
                                <img
                                    src="../../../../../assets/icons/icon_check.svg"
                                    alt=""
                                >
                            </button>
                        </ng-template>
                    </ng-template>
                </td>
            </ng-container>

            <tr
                *matHeaderRowDef="displayedColumns"
                mat-header-row
            ></tr>
            <tr
                *matRowDef="let row; columns: displayedColumns;"
                mat-row
            ></tr>
        </table>
    </div>
</mat-dialog-content>
<div>
    <!--    <button-->
    <!--        class="avl-btn avl-btn&#45;&#45;blue avl-btn&#45;&#45;wide btn-border-n"-->
    <!--        type="button"-->
    <!--        [disabled]="!itemAdded"-->
    <!--        (click)="saveAddFileCopy()"-->
    <!--    >-->
    <!--        Add to project-->
    <!--        <span *ngIf="totalCost !== 0">-->
    <!--            for {{ costCurrency }}{{ totalCost }}-->
    <!--        </span>-->
    <!--    </button>-->
    <button
        class="avl-btn avl-btn--blue avl-btn--wide btn-border-n"
        type="button"
        [disabled]="!itemAdded"
        (click)="saveAddFileCopy()"
    >
        SELECT
    </button>
</div>
