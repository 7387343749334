import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ReportOptionsState, ReportOptionsStore } from './report-options.store';
import { Observable } from 'rxjs';
import { ReportAction } from '@core/types';
import { map } from 'rxjs/operators';
import { multipleActions } from '../../constants/report-action-types.constant';

@Injectable()
export class ReportOptionsQuery extends QueryEntity<ReportOptionsState> {
    constructor(protected store: ReportOptionsStore) {
        super(store);
    }

    public getSorted$(): Observable<ReportAction[]> {
        return this.selectAll()
            .pipe(
                map((actions) => actions.sort((a, b) => a.displayOrder - b.displayOrder)),
            );
    }

    public getSortedMultipleReportActions(): Observable<ReportAction[]> {
        return this.getSorted$()
            .pipe(
                map((actions) => actions.filter((action) => {
                    return multipleActions.some((multipleAction) =>
                        multipleAction.key === action.key
                        && multipleAction.format === action.format,
                    );
                })),
            );
    }

    public getSortedIndividualReportActions(): Observable<ReportAction[]> {
        return this.getSorted$()
            .pipe(
                map((actions) => actions.filter((action) => {
                    const isMultipleReport = multipleActions.some((multipleAction) =>
                        multipleAction.key === action.key
                        && multipleAction.format === action.format,
                    );

                    return !isMultipleReport;
                })),
            );
    }
}
