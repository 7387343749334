import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MapSearchQuery, MapSearchStore, updateMapFilter, updateMapZoom } from 'app/titles/store/map-search';
import { MapSearchControls } from './types/map-search-controls.type';
import { Actions } from '@datorama/akita-ng-effects';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'avl-map-controls',
    templateUrl: './map-controls.component.html',
    styleUrls: ['./map-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapControlsComponent implements OnInit, OnDestroy {
  @Output()
    public controlsChanged = new EventEmitter<MapSearchControls>();

  public zoom: number;
  public isFreeholdsOn$ = new BehaviorSubject<boolean>(false);
  public isLeaseholdsOn$ = new BehaviorSubject<boolean>(false);
  public isFiltersVisible: Observable<boolean>;
  public isMaxZoomAchieved: Observable<boolean>;
  public isMinZoomAchieved: Observable<boolean>;

  public sub = new Subscription();

  constructor(
    private readonly actions: Actions,
    private readonly mapSearchStore: MapSearchStore,
    private readonly mapSearchQuery: MapSearchQuery,
  ) {
  }

  public ngOnInit(): void {
      this.isFiltersVisible = this.mapSearchQuery.isFiltersVisible$();
      this.sub.add(
          this.mapSearchQuery.select(['zoom', 'isFreeholdsOn', 'isLeaseholdsOn'])
              .subscribe((state) => {
                  this.zoom = state.zoom;
                  this.isFreeholdsOn$.next(state.isFreeholdsOn);
                  this.isLeaseholdsOn$.next(state.isLeaseholdsOn);
              }),
      );
      this.isMaxZoomAchieved = this.mapSearchQuery.isMaxZoomAchieved();
      this.isMinZoomAchieved = this.mapSearchQuery.isMinZoomAchieved();
  }

  public ngOnDestroy(): void {
      this.sub.unsubscribe();
  }

  public zoomIn(): void {
      this.actions.dispatch(updateMapZoom({ zoom: this.zoom + 1 }));
  }

  public zoomOut(): void {
      this.actions.dispatch(updateMapZoom({ zoom: this.zoom - 1 }));
  }

  public onLeaseholdsFilterChanged(isLeaseholdsOn: boolean): void {
      this.actions.dispatch(
          updateMapFilter({
              isLeaseholdsOn: isLeaseholdsOn,
              isFreeholdsOn: this.isFreeholdsOn$.getValue(),
          })
      );
  }

  public onFreeholdsFilterChanged(isFreeholdsOn: boolean): void {
      this.actions.dispatch(
          updateMapFilter({
              isLeaseholdsOn: this.isLeaseholdsOn$.getValue(),
              isFreeholdsOn: isFreeholdsOn,
          })
      );
  }
}
