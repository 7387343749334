import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
    providedIn: 'root',
})
export class PaginatorIntlService extends MatPaginatorIntl {
    public nextPageLabel = '';
    public previousPageLabel = '';

    constructor() {
        super();
    }
}
