<div class="wrapper">
    <div
        *ngIf="showSpinner"
        class="overlay"
    >
        <div class="spinner-wrapper">
            <avl-spinner
                class="margin-auto"
                [isBackgroundCircleVisible]="true"
            ></avl-spinner>
        </div>
    </div>
    <iframe
        *ngIf="displayIframe"
        class="iframe"
        [src]="iframeLink"
    ></iframe>
</div>
