import { Component, EventEmitter, Input, Output } from '@angular/core';
import { routingTitles } from '@constants';

import { fadeRowAnimation } from 'app/core/animations/fade.animation';

import { IFailedDocument } from '@core/types';
import { ID } from '@datorama/akita';


@Component({
    selector: 'avl-error-alert',
    templateUrl: './error-alert.component.html',
    styleUrls: ['./error-alert.component.scss'],
    animations: [fadeRowAnimation],
})
export class ErrorAlertComponent {
    @Input()
    public failedDocuments: IFailedDocument[];

    @Input()
    public folderId: string;

    @Output()
    public documentRemoved = new EventEmitter<string>();

    public removeDocument(id: string): void {
        this.documentRemoved.emit(id);
    }

    public getDocumentLink(documentId: string): string {
        return `/api/${routingTitles}/document/${this.folderId}/${documentId}`;
    }

    public trackByDocuments(index: string, document: IFailedDocument): ID {
        return document.id;
    }
}
