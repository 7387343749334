import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PurchasedTitleDetails } from '../types/purchased-title-details.type';
import { IFilesForDownload, INotificationCounter, IPurchasedFile, ITileNumberForPurchase, LandRegistryApi, TitleInfoToMap } from '../types';

@Injectable()
export class RosApi implements LandRegistryApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public getIsRosAvailable(): Observable<boolean> {
        return this.http.get<string>('/api/ros/available', { observe: 'response' })
            .pipe(
                // status 200 - available, status 503 - unavailable
                map((response) => response.ok),
            );
    }

    public getContentLocation(folderId: string, kind: string, query: string): Observable<string> {
        const url = `/api/ros/search/${folderId}`;

        return this.http.post(url, { kind, query }, { observe: 'response' })
            .pipe(
                map((response) => response.headers.get('Content-Location') || ''),
            );
    }

    public getContentByLocation(url: string): Observable<HttpResponse<TitleInfoToMap[]>> {
        return this.http.get<TitleInfoToMap[]>(url, { observe: 'response' });
    }

    public getSearchResultsExcel(url: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
    }

    public refresh(folderId: string): Observable<string> {
        return this.http.post(`/api/ros/refresh/${folderId}`, null, { observe: 'response' })
            .pipe(
                map((response) => response.headers.get('Content-Location')),
            );
    }

    public purchase(folderId: string, titleNumbers: ITileNumberForPurchase[]): Observable<string> {
        return this.http.post(`/api/ros/purchase/${folderId}`, titleNumbers, { observe: 'response' })
            .pipe(
                map((response) => response.headers.get('Content-Location') || ''),
            );
    }

    public getPurchaseStatus(url: string): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        return this.http.get<PurchasedTitleDetails[] | ''>(url, { observe: 'response' })
            .pipe(
                map((response) =>
                    new HttpResponse({
                        ...response,
                        body: response.body || [],
                    }),
                ),
            );
    }

    public getPurchasedFilesList(params: HttpParams): Observable<HttpResponse<IPurchasedFile[]>> {
        return this.http.get<IPurchasedFile[]>('/api/ros/purchased', { params, observe: 'response' });
    }

    public getPurchasedFiles(files: IFilesForDownload[]): Observable<HttpResponse<ArrayBuffer>> {
        return this.http.post('/api/ros/purchased', files, { responseType: 'arraybuffer', observe: 'response' });
    }

    public getActivePurchases(): Observable<INotificationCounter> {
        return this.http.get<INotificationCounter>('/api/ros/purchased/active');
    }
}
