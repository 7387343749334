import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FailedPurchasesState, FailedPurchasesStore } from './failed-purchases.store';

@Injectable()
export class FailedPurchasesQuery extends QueryEntity<FailedPurchasesState> {
    constructor(protected store: FailedPurchasesStore) {
        super(store);
    }
}
