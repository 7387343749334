import { Component, OnInit } from '@angular/core';
import { IntercomService } from '../../../core/services/intercom.service';

@Component({
    selector: 'avl-no-subscriptions',
    templateUrl: './no-subscriptions.component.html',
    styleUrls: ['./no-subscriptions.component.scss'],
})
export class NoSubscriptionsComponent implements OnInit {

    constructor(
        private readonly intercom: IntercomService,
    ) {
    }

    public ngOnInit(): void {
        this.intercom.load();
    }

    public showIntercom(): void {
        this.intercom.show();
    }
}
