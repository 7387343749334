import { Injectable } from '@angular/core';
import { BaseLandRegistryService } from './base-land-registry.service';
import { LandRegistrySearchSource } from '../../enums/land-registry-search-source.enum';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { ITileNumberForPurchase, ITitleInfo, LandRegistry } from '../../types';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { PurchasedTitleDetails } from '../../types/purchased-title-details.type';
import { catchError, switchMap } from 'rxjs/operators';
import { ROS_IS_UNAVAILABLE, ROS_REPEAT_PURCHASING } from '@constants';
import { MatDialog } from '@angular/material/dialog';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { RosApi } from '../../api/ros.api';
import { LoggerService } from '@services';
import { LandRegistryPurchaseEntity } from '../../enums/land-registry-purchase-entity.enum';

@Injectable()
export class ScotlandLandRegistryService extends BaseLandRegistryService implements LandRegistry {

    constructor(
        private readonly rosApi: RosApi,
        private readonly dialog: MatDialog,
        private readonly logger: LoggerService,
    ) {
        super(rosApi);
    }

    public getPurchaseEntity(): LandRegistryPurchaseEntity {
        return LandRegistryPurchaseEntity.ros;
    }

    public getIsAvailable(): Observable<boolean> {
        return this.rosApi.getIsRosAvailable();
    }

    public searchTitle(folder: string, kind: string, query: string, source: LandRegistrySearchSource, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        return this.search(folder, kind, query, source, statusUrl);
    }

    public refreshTitles(folder: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        return this.refresh(folder, statusUrl);
    }

    public purchaseTitles(folder: string, titles: ITitleInfo[]): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        const titleNumbers: ITileNumberForPurchase[] = titles.map((item) => ({
            kind: 'scottish-title',
            reference: item.titleNumber,
        }));

        return this.rosApi.purchase(folder, titleNumbers)
            .pipe(
                switchMap((url) => this.getPurchaseStatus(url)),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 409) {
                        this.handleRepeatPurchaseError();

                        return EMPTY;
                    }

                    return throwError(() => error);
                }),
            );
    }

    public registryUnavailable(): Observable<void> {
        const dialogRef = this.dialog.open(AlertOkDialogComponent, {
            panelClass: 'error-alert-dialog',
            width: '400px',
            data: {
                ...ROS_IS_UNAVAILABLE,
                button: 'Got it',
            },
        });

        return dialogRef.afterClosed();
    }

    private handleRepeatPurchaseError(): void {
        this.logger.warn('An attempt to buy purchased title twice');
        this.dialog.open(AlertOkDialogComponent, {
            panelClass: 'error-alert-dialog',
            width: '400px',
            data: {
                ...ROS_REPEAT_PURCHASING,
                button: 'Got it',
            },
        });
    }
}
