import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { GeoJsonFeatureMap, IMapBounds, ITitleInfo, ShortTitleInfo } from '../../types';
import { Observable, Subject } from 'rxjs';
import { GeoJSONFeature, LngLat } from 'maplibre-gl';
import { mapSearchMaxZoomFeatureVisibility } from '@constants';

export type MapSearchState = {
    isContentSidebarVisible: boolean;
    isFiltersVisible: boolean;
    zoom: number;
    center?: LngLat;
    markerPosition?: LngLat;
    isFreeholdsOn: boolean;
    isLeaseholdsOn: boolean;
    featuresMap: GeoJsonFeatureMap;
    sidePanelTitles: ShortTitleInfo[];
    selectedFeatures: GeoJSONFeature[];
    temporaryHighlightedTitleNumber?: string;
    permanentlyHighlightedTitleNumber?: string;
    predefinedTitleNumberForHighlight?: string;
    bounds?: IMapBounds;
    details?: ITitleInfo;
    focusedFeatures?: GeoJSONFeature[];
    isSelectionUnderPinBlocked: boolean;
    stateBeforeFocusing?: { zoom: number; center: LngLat };
    previousShowTitleId: string;
    isSidePanelLoading: boolean;
    isMapComponentInitialized: boolean;
}

function createInitialState(): MapSearchState {
    return {
        isContentSidebarVisible: false,
        isFiltersVisible: false,
        zoom: 5,
        center: new LngLat(-3.5273437499999893, 54.71905799874775),
        isFreeholdsOn: true,
        isLeaseholdsOn: true,
        details: null,
        previousShowTitleId: null,
        temporaryHighlightedTitleNumber: null,
        permanentlyHighlightedTitleNumber: null,
        predefinedTitleNumberForHighlight: null,
        featuresMap: {},
        sidePanelTitles: [],
        selectedFeatures: [],
        isSidePanelLoading: false,
        isMapComponentInitialized: false,
        isSelectionUnderPinBlocked: false,
    };
}

@Injectable()
@StoreConfig({ name: 'map-search', resettable: true })
export class MapSearchStore extends Store<MapSearchState> {
    private readonly locationChange = new Subject<{ location: LngLat; zoom?: number }>();

    constructor() {
        super(createInitialState());
        this.updateFiltersStateDependOnZoom();
    }

    public navigateTo(point: LngLat, zoom?: number): void {
        this.locationChange.next({ location: point, zoom });
    }

    public getLocationChanged(): Observable<{ location: LngLat; zoom?: number }> {
        return this.locationChange.asObservable();
    }

    private updateFiltersStateDependOnZoom(): void {
        const zoom = this.getValue().zoom;
        const isFiltersVisible = zoom >= mapSearchMaxZoomFeatureVisibility;

        this.update({ isFiltersVisible });
    }
}
