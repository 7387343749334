import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { FolderDetails } from '@core/types';
import { Subject } from 'rxjs';

export type NoticeFolderState = FolderDetails & {
    id: string;
};

function createInitialState(): NoticeFolderState {
    return {
        id: '',
        projectName: '',
        matterNumber: '',
    };
}

@Injectable()
@StoreConfig({ name: 'notice-folder', resettable: true })
export class NoticeFolderStore extends Store<NoticeFolderState> {
    public readonly onFolderInitCanceled$ = new Subject<void>();

    constructor() {
        super(createInitialState());
    }
}
