import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ICollectionTableUI } from '@core/types';

@Injectable({
    providedIn: 'root',
})
export class HttpQueryParamsService {

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
    ) {
    }

    public buildCollectionParams(ui: ICollectionTableUI): HttpParams {
        let params = new HttpParams();

        if (ui.search) {
            params = params.set('q', ui.search);
        }
        if (ui.page) {
            const startIndex = (ui.page.pageIndex * ui.page.pageSize);
            params = params.set('start', startIndex.toString());
            params = params.set('limit', ui.page.pageSize.toString());

            if (ui.page.sort && ui.page.order) {
                const order = ui.page.order === 'asc' ? 'asc' : 'dsc';
                params = params.set('sort', ui.page.sort);
                params = params.set('order', order);
            }
        }

        return params;
    }

    public addRedirectToQueryParams(
        { isOtherParamsIncluded }: { isOtherParamsIncluded: boolean } | undefined = { isOtherParamsIncluded: true },
    ): [key: string] {
        const queryParams = isOtherParamsIncluded ? this.getQueryParams() : {} as [key: string];

        if (!queryParams['redirect']) {
            const searchParams = isOtherParamsIncluded ? location.search : '';
            queryParams['redirect'] = `${location.pathname}${searchParams}`;
        }

        return queryParams;
    }

    public getQueryParams(): [key: string] {
        const httpParams = new HttpParams({ fromString: window.location.search });
        const queryParams = httpParams.keys().reduce((paramsObject, paramKey) => {
            paramsObject[paramKey] = httpParams.get(paramKey);

            return paramsObject;
        }, {} as [key: string]);

        return queryParams;
    }

    public addTemporaryParamToUrl(keyName: string, value: string, timeout: number | undefined = 10000): void {
        this.addParam(keyName, value);
        setTimeout(() => this.removeParam(keyName), timeout);
    }

    public async addParam(key: string, value: string): Promise<void> {
        const params = { ...this.route.snapshot.queryParams, [key]: value };
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
        });
    }

    public async removeParam(key: string): Promise<void> {
        const params = { ...this.route.snapshot.queryParams };
        delete params[key];

        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
        });
    }
}
