import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';

import { LightingLayoutsService } from '../../services/lighting-layouts.service';

import { OnboardingContent, OnboardingOverlayRef } from '../../types/overlay-config.type';
import { onboardingDialogData } from '../../tokens/onboarding-overlay.token';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { animationTimings } from '../../constants/animation-timing.constant';

@Component({
    selector: 'avl-onboarding-overlay',
    templateUrl: './onboarding-overlay.component.html',
    styleUrls: ['./onboarding-overlay.component.scss'],
    animations: [
        trigger('slideContent', [
            state('void', style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })),
            state('enter', style({ transform: 'none', opacity: 1 })),
            state('leave', style({ transform: 'none', opacity: 0 })),
            transition('* => *', animate(animationTimings)),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingOverlayComponent implements OnInit, OnDestroy {
    public animationState: 'void' | 'enter' | 'leave' = 'enter';
    public animationStateChanged = new EventEmitter<AnimationEvent>();
    private highlightElement: ElementRef;

    constructor(
        public overlayRef: OnboardingOverlayRef,
        public renderer: Renderer2,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly lightingLayoutsService: LightingLayoutsService,
        @Inject(onboardingDialogData)
        public content: OnboardingContent,
    ) {
    }

    @HostListener('click')
    public onClick(): void {
        if (!this.content.backdropClickSkip) {
            this.close();
        }
    }

    public ngOnInit(): void {
        this.highlightElement = this.lightingLayoutsService.getLightedElement(this.content.highlightElement);
        this.setLighting();

        if (this.highlightElement && !this.content.backdropClickSkip) {
            this.renderer.setStyle(this.highlightElement.nativeElement, 'pointer-events', 'none');
            this.renderer.listen(this.highlightElement.nativeElement, 'click', () => {
                this.close();
            });
        }
    }

    public ngOnDestroy(): void {
        this.resetLighting();
    }

    public close(): void {
        this.overlayRef.close();
    }

    public onAnimationStart(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    public onAnimationDone(event: AnimationEvent): void {
        this.animationStateChanged.emit(event);
    }

    public startExitAnimation(): void {
        this.animationState = 'leave';
        this.changeDetectorRef.markForCheck();
    }

    private setLighting(): void {
        if (this.highlightElement) {
            this.renderer.addClass(this.highlightElement.nativeElement, 'global-onboarding__elevate');
        }
    }

    private resetLighting(): void {
        if (this.highlightElement) {
            this.renderer.removeStyle(this.highlightElement.nativeElement, 'pointer-events');
            this.renderer.removeClass(this.highlightElement.nativeElement, 'global-onboarding__elevate');
        }
    }
}
