import { Component, OnInit } from '@angular/core';
import { LoggerService } from '@services';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'avl-imanage-login',
    templateUrl: './imanage-login.component.html',
    styleUrls: ['./imanage-login.component.scss'],
})
export class ImanageLoginComponent implements OnInit {
    private codeParam = '';
    private state = '';

    constructor(
        private readonly log: LoggerService,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        this.log.info('imanage-login.component.ts: constructor');
    }

    public ngOnInit(): void {
        this.log.info('imanage-login.component.ts: ngOnInit');
        this.getParams();
    }

    public onClose(): void {
        window.opener.postMessage({ popup: true }, location.origin);
        // window.opener.postMessage({code: this.codeParam, state: this.state}, location.origin);
        window.close();
    }

    public getParams(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.codeParam = params.code;
            this.state = params.state;

            if (this.codeParam) {
                // Delay for 5 seconds to allow the users to see what happened, and not be surprised.
                window.opener.postMessage({ code: this.codeParam, state: this.state }, location.origin);
                this.log.info('sending the code to window');
                setTimeout(() => {
                    this.log.info('sending the close message');
                    window.opener.postMessage({ popup: true }, location.origin);
                    window.close();
                }, 5000);
            } else {
                // Some kind of problem related to imanage occurred probably the redirect URL is wrong
                this.log.error('A problem related to redirected source (iManage) occurred');
            }
        });
    }
}
