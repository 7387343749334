import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, concatMap, filter, first, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { ISchedule, IScheduleItem, IScheduleKey, ISchedulePurchaseParams, IScheduleType, IScheduleTypeInfo } from '../types';
import { SchedulesApi } from '../api';
import { SchedulesStore } from '../store';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { LoggerService } from '@services';
import { SOMETHING_GONE_WRONG } from '@constants';
import { MatDialog } from '@angular/material/dialog';

type PurchaseProgress = {
    [key: string]: {
        percent: number;
        isError?: boolean;
    };
};

@Injectable()
export class SchedulesService {
    private readonly schedulePurchaseProgress$ = new BehaviorSubject<PurchaseProgress>({});
    private readonly startLinkedDocumentsAnalysis$ = new Subject<void>();
    private purchasesProgress: PurchaseProgress = {};
    private testIncrement = 8;

    constructor(
        private readonly schedulesApi: SchedulesApi,
        private readonly schedulesStore: SchedulesStore,
        private readonly matDialog: MatDialog,
        private readonly log: LoggerService,
    ) {
    }

    public linkedDocumentsAnalysisStarted$(): Observable<void> {
        return this.startLinkedDocumentsAnalysis$.asObservable();
    }

    public startLinkedDocumentsAnalysis(): void {
        this.startLinkedDocumentsAnalysis$.next();
    }

    public getSchedulesList(folderId: string): Observable<IScheduleTypeInfo[]> {
        this.schedulesStore.setLoading(true);

        return this.schedulesApi.getSchedulesTypeList(folderId)
            .pipe(
                switchMap((types: IScheduleType[]) => {
                    this.log.info('getSchedulesTypeList.switchMap', types);
                    const requests = [];

                    types.forEach((scheduleType: IScheduleType) => {
                        this.log.info('getScheduleTypeInfo', scheduleType.url);
                        requests.push(
                            this.schedulesApi.getScheduleTypeInfo(scheduleType.url)
                                .pipe(
                                    map((info: IScheduleTypeInfo) => ({ id: scheduleType.key, ...scheduleType, ...info })),
                                ),
                        );
                    });

                    return requests.length ? forkJoin(requests) : [];
                }),
                catchError((error) => {
                    this.schedulesStore.setLoading(false);
                    this.schedulesStore.set([]);

                    return throwError(error);
                }),
            )
            .pipe(
                tap((schedules: ISchedule[]) => {
                    this.schedulesStore.setLoading(false);
                    this.schedulesStore.set(schedules);
                    this.log.info('schedules are set', schedules);
                }),
                catchError((error) => {
                    this.schedulesStore.setLoading(false);
                    this.schedulesStore.set([]);

                    return of(error);
                }),
            );
    }

    public getSingleScheduleList(folderId: string, scheduleKey: string): Observable<IScheduleTypeInfo[]> {
        this.schedulesStore.setLoading(true);

        return this.schedulesApi.getSchedulesTypeList(folderId).pipe(
            switchMap((types: IScheduleType[]) => {
                const requests = [];

                types.forEach((scheduleType: IScheduleType) => {
                    if (scheduleType.key === scheduleKey) {
                        requests.push(
                            this.schedulesApi.getScheduleTypeInfo(scheduleType.url)
                                .pipe(
                                    map((info: IScheduleTypeInfo) => ({ id: scheduleType.key, ...scheduleType, ...info })),
                                ),
                        );
                    }
                });

                return requests.length ? forkJoin(requests) : [];
            }),
            catchError((error) => {
                this.schedulesStore.setLoading(false);
                return of(throwError(error));
            }),
        )
            .pipe(tap((schedules: ISchedule[]) => {
                this.schedulesStore.setLoading(false);

                schedules.forEach((aSchedule: ISchedule) => {
                    if (aSchedule.key === scheduleKey) {
                        this.schedulesStore.upsert(aSchedule.id, { ...aSchedule });
                    }
                });
            }));
    }

    public startSchedulePurchase(
        schedule: ISchedule,
        folderId: string,
        allSchedule: ISchedule,
        selectedItems: IScheduleItem[],
    ): Observable<any> {
        const kind = schedule.key;

        if (kind === 'linked-documents') {
            schedule = JSON.parse(JSON.stringify(schedule));
            if (selectedItems.length > 0) {
                schedule.items = selectedItems;
            } else {
                let schItems = schedule.items;
                schItems = schItems.filter((mitem) => mitem.isAvailable === true && mitem.offerPurchase === true);
                schedule.items = schItems;
            }
        }

        this.purchasesProgress[schedule.key] = { percent: 9 };
        this.schedulePurchaseProgress$.next(this.purchasesProgress);
        const params: ISchedulePurchaseParams[] = (schedule.items || [])
            .map((item) => {
                const id = item.id;
                const reference = item.reference || item.titleNumber;
                const documentType = item.documentType;
                const documentTypeCode = item.documentTypeCode;
                const documentDate = item.documentDate;
                const entryNumbers = item.entryNumbers;
                const filedUnder = item.filedUnder;
                const linkedDocuments = {
                    ...(documentType && { documentType }),
                    ...(documentTypeCode && { documentTypeCode }),
                    ...(documentDate && { documentDate }),
                    ...(entryNumbers && { entryNumbers }),
                    ...(filedUnder && { filedUnder }),
                };

                return { kind, ...(reference && { reference }), ...(id && { id }), ...linkedDocuments };
            });

        return this.schedulesApi.startSchedulePurchase(folderId, kind, params)
            .pipe(
                map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
                concatMap((url: string) => this.getSchedulePurchaseStatus(url, schedule, allSchedule)),
                catchError((error) => {
                    this.purchasesProgress[schedule.key] = { percent: 100, isError: true };

                    this.handleStatusCode(error);

                    return throwError(error);
                }),
            );
    }

    // function to get the linked document items
    public getListOfLinkedDocumentItems(folderId: string): Observable<HttpResponse<IScheduleTypeInfo>> {
        const scheduleKey = 'linked-documents';
        this.purchasesProgress[scheduleKey] = { percent: 9 };
        this.schedulePurchaseProgress$.next(this.purchasesProgress);

        return timer(1, 3000).pipe(
            switchMap(() => this.schedulesApi.getFiledCopiesDialogInfoItems(scheduleKey, folderId)),
            tap((response: HttpResponse<null>) => {
                // Get the percentage of progress
                this.testIncrement = this.testIncrement + 6;
                let progress: any = this.testIncrement; // response.headers.get('progress%');

                if (response.status === 200) {
                    progress = 100;
                }

                const percent = parseFloat(progress) || (response.status === 200 ? 100.0 : 10.0);
                this.purchasesProgress[scheduleKey] = { percent };
                this.schedulePurchaseProgress$.next(this.purchasesProgress);
            }),
            takeWhile((response: HttpResponse<any>) => response.status < 400),
            filter((response: HttpResponse<any>) => response.status === 200 && !!response.body),
            // filter((response: HttpResponse<any>) => this.testIncrement > 20 && !!response.body),
            first(),
            // take(3),
            catchError((error) => {
                this.purchasesProgress[scheduleKey] = { percent: 100, isError: true };
                this.handleStatusCode(error);

                return throwError(error);
            }),
        );
    }

    public getSchedulePreviewByKey(key: IScheduleKey): string {
        // return `assets/images/schedule-previews/title-plans.png`; // Until we have the documents for all plans
        return `assets/images/schedule-previews/${key}.png`;
    }

    public getSchedulePurchaseProgress(): Observable<PurchaseProgress> {
        return this.schedulePurchaseProgress$.asObservable();
    }

    private getSchedulePurchaseStatus(url: string, schedule: ISchedule, allSchedule: ISchedule): Observable<void> {
        return timer(1, 3000)
            .pipe(
                switchMap(() => this.schedulesApi.getSchedulePurchaseStatus(url)),
                tap((response: HttpResponse<null>) => {
                    const progress = response.headers.get('progress%');
                    const percent = parseFloat(progress) || (response.status === 200 ? 100.0 : 10.0);
                    this.purchasesProgress[schedule.key] = { percent };
                    this.schedulePurchaseProgress$.next(this.purchasesProgress);
                }),
                takeWhile((response: HttpResponse<any>) => response.status < 400),
                filter((response: HttpResponse<any>) => response.status === 200 && !!response.body),
                map((response: HttpResponse<any>) => {
                    const errors = (response.body || []).filter((elem) => elem.isError);
                    const errLength = errors.length;

                    if (schedule.key === 'linked-documents') {
                        // Modify the purchased data
                        const slctItems = schedule.items;
                        const allItems = allSchedule.items;

                        if (allItems.length > 0) {
                            allItems.forEach((_, i) => {
                                if (slctItems.length > 0) {
                                    slctItems.forEach((sitem) => {
                                        if (sitem.id === allItems[i].id) {
                                            allItems[i].offerPurchase = false;
                                        }
                                    });
                                }
                            });
                        }

                        // this.schedulesStore.update(schedule.id, {isUpdated: true, items: allItems});
                        this.log.info('getSchedulePurchaseStatus + status 200. allSchedule', allSchedule);
                        this.schedulesStore.upsert(schedule.id, {
                            ...allSchedule,
                            items: allItems,
                            hasError: !!errLength,
                            ...(!!errLength && { errors }),
                            ...(((schedule.items || []).length === errLength && (!(schedule.isUpdated && (schedule.items || []).length === 0))) && { isEmpty: true }),
                        });
                    } else {
                        this.log.info('getSchedulePurchaseStatus + status 200. schedule', schedule);
                        this.schedulesStore.upsert(schedule.id, {
                            ...schedule,
                            allPurchased: true,
                            hasError: !!errLength,
                            ...(!!errLength && { errors }),
                            ...(((schedule.items || []).length === errLength && (!(schedule.isUpdated && (schedule.items || []).length === 0))) && { isEmpty: true }),
                        });
                    }
                }),
                first(),
                catchError((error) => {
                    this.purchasesProgress[schedule.key] = { percent: 100, isError: true };

                    this.handleStatusCode(error);

                    return throwError(error);
                }),
            );
    }

    private handleStatusCode(error: { status: number } | undefined): void {
        const status = error && error.status;
        let message = '';
        let isAlertSkipped = false;

        switch (status) {
            case 503:
                message = 'Our connection to HM Land Registry is currently unavailable. Please try again during working hours.';
                break;
            case 400:
                message = 'Something\'s gone wrong. Please clear your temporary internet files and try again.';
                break;
            case 401:
            case 403:
                message = 'Your account doesn\'t seem to be authorised to do this. Please try again or contact our support live chat.';
                break;
            case 402:
                message = 'You\'ve run out of credit. Please contact your head of innovation or LegalTech for more information.';
                break;
            case 409:
                message = 'Another transaction of this project is in progress. '
                + 'Please wait until the other transaction is complete to avoid purchasing the same items twice.';
                break;
            case 416:
                isAlertSkipped = true;
                break;
            default:
                message = SOMETHING_GONE_WRONG.message;
                break;
        }

        if (isAlertSkipped) {
            return;
        }

        this.matDialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: {
                title: 'Oops!',
                message,
            },
        });
    }
}
