<div class="container">
    <p class="content">{{ data }}</p>
    <button
        type="button"
        class="close-button"
        (click)="dismiss()"
    >
        <mat-icon svgIcon="close-white"></mat-icon>
    </button>
</div>
