import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ReportsApi {
    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public load(url: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
    }
}
