import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { LightingLayoutsService } from '../../onboarding/services';

@Directive({
    selector: '[avlElevate]',
})
export class ElevateDirective implements OnInit {

    @Input()
    public name: string;

    constructor(
        private readonly element: ElementRef,
        private readonly lightingLayoutsService: LightingLayoutsService,
    ) {
    }

    public ngOnInit(): void {
        this.lightingLayoutsService.registerLightedElement(this.name, this.element);
    }
}
