<div class="collection__container">
    <h4>Add schedules</h4>
    <div class="line"></div>
    <div class="collection__wrapper">
        <avl-search
            placeholder="Search for report schedules to add or browse below"
            (searchChanged)="onSearch($event)"
        ></avl-search>
    </div>
    <avl-schedules-table
        [loading]="schedulesLoading$ | async"
        [finishedPurchases]="finishedPurchases"
        [schedules]="currentSchedules"
        (action)="onSchedulesAction($event)"
    ></avl-schedules-table>
</div>

<div class="controls-container">
    <button
        class="avl-btn avl-btn--blue-border avl-btn--wide schedules-btn"
        routerLink="/title/upload"
        [disabled]="isNextButtonDisabled()"
        [queryParams]="folderId && {fid: folderId} || {}"
    >
        Back
    </button>
    <button
        avlElevate
        class="avl-btn avl-btn--blue avl-btn--wide schedules-btn"
        name="next-btn"
        (click)="onOpenGenerateReportDialog()"
        [disabled]="isNextButtonDisabled()"
    >
        Next
    </button>
</div>
