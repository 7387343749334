import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@services';
import { Credentials } from '@auth/types/credentials.type';
import { AuthService } from '@auth/services/auth.service';
import { regexes } from '@constants';


@Component({
    selector: 'avl-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ChangePasswordComponent implements OnInit {
    public changeForm: FormGroup;
    public changeError: string;
    public failedChange = false;
    public isPasswordVisible = false;
    public isNewPasswordVisible = false;
    public isConfirmPasswordVisible = false;
    public succeedSend = false;
    public succeedMessage = 'The password was successfully changed, click here to login.';

    constructor(
        private readonly authService: AuthService,
        private readonly formBuilder: FormBuilder,
        private readonly log: LoggerService,
    ) {
    }

    public get emailControl(): AbstractControl {
        return this.changeForm.get('email');
    }

    public get passwordControl(): AbstractControl {
        return this.changeForm.get('password');
    }

    public get newPasswordControl(): AbstractControl {
        return this.changeForm.get('newPassword');
    }

    public get confirmNewPasswordControl(): AbstractControl {
        return this.changeForm.get('confirmNewPassword');
    }

    public ngOnInit(): void {
        this.changeForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.pattern(regexes.email)]],
            password: [null, Validators.required],
            newPassword: [null, [Validators.required]],
            confirmNewPassword: [null, [Validators.required]],
        });
    }

    public getEmailErrorMessage(): string {
        return this.emailControl.hasError('required')
            ? 'An email is required'
            : this.emailControl.hasError('pattern')
                ? 'Not a valid email'
                : '';
    }

    public onTryAgain(): void {
        this.failedChange = false;
        this.changeError = null;
        this.succeedSend = false;
    }

    public async change(): Promise<void> {
        this.failedChange = false;
        this.changeError = null;

        if (this.changeForm.invalid) {
            this.log.info('changeForm invalid', this.changeForm);
            return;
        }

        const credential: Credentials = this.changeForm.value;
        if (credential.newPassword !== credential.confirmNewPassword) {
            this.failedChange = true;
            this.changeError = 'The new and confirm passwords do not match.';
            this.log.info(this.changeError);
            return;
        }

        this.authService.changePassword(credential.email, credential.password, credential.newPassword)
            .then((value) => {
                this.log.info('Nice, it worked!', value);
                this.authService.logout().then(() => {
                    this.succeedSend = true;
                });
            })
            .catch((err) => {
                this.log.info('Something went wrong:', err.message);
                this.failedChange = true;
                this.changeError = err.message;
            });
    }
}
