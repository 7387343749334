<mat-sidenav-container
    class="sidenav"
>
    <mat-sidenav
        mode="over"
        #sidenav
    >
        <avl-sidenav
            [counters]="counters$"
            (createNewProject)="createNewProject()"
            (closeSideNav)="sidenav.close()"
        ></avl-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="notice-container">
            <avl-header
                [logo]="logo"
                [showHamburgerMenu]="true"
                [homePagePath]="routingNotice"
                [folderIsNotCreated]="folderIsNotCreated$ | async"
                [folderDetails]="folderDetails$ | async"
                (projectDetailsOpened)="onFolderDetailsOpen()"
                (newFolderCreated)="createNewProject();"
                (sideNavToggled)="localSidenavCounters(); sidenav.toggle()"
            ></avl-header>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
