import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@auth/services/auth.service';
import { LoggerService } from '@services';
import { Credentials, defaultCredentials } from '@auth/types/credentials.type';

@Component({
    selector: 'avl-reset-password',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SignupComponent implements OnInit {
    public model: Credentials = defaultCredentials();
    public invalidCode = false;
    public buttonText = 'Go to login';
    public isPasswordVisible = false;
    public isConfirmPasswordVisible = false;
    public succeedSend = false;
    public succeedTitle = 'Account created';
    public succeedMessage = 'You successfully signed up, click here to login.';
    private code: string;
    private data: any;

    constructor(
        private readonly authService: AuthService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly log: LoggerService,
    ) {
    }

    public ngOnInit(): void {
        this.data = this.route.snapshot.data;
        this.log.info('ngOnInit', this.data);

        if (this.data && this.data.actionCode) {
            this.model.email = this.data.actionCode.email;
            this.code = this.data.actionCode.code;

            if (!this.data.actionCode.valid) {
                this.model.hasError = true;
                this.model.errorMessage = this.data.actionCode.errorMessage;
                this.invalidCode = true;
            }
        } else {
            this.model.hasError = true;
            this.model.errorMessage = 'Invalid reset code, please check the link that has been sent to your email again.';
            this.invalidCode = true;
        }
        this.model.newPassword = null;
    }

    public onTryAgain(): void {
        this.succeedSend = false;
        this.model.hasError = false;
        this.model.errorMessage = null;

        if (this.invalidCode) {
            this.router.navigate(['/login']);
        }
    }

    public submitSignup(): void {
        this.model.hasError = false;
        this.model.errorMessage = null;

        if (!this.model.newPassword) {
            this.log.info('this.signupForm.invalid');
            this.model.hasError = true;
            this.model.errorMessage = 'A password is required.';
            this.buttonText = 'Try again';
            this.ref.detectChanges();
            return;
        }

        if (this.model.newPassword !== this.model.confirmNewPassword) {
            this.model.hasError = true;
            this.model.errorMessage = 'The password and the confirmation do not match.';
            this.log.info(this.model.errorMessage);
            this.buttonText = 'Try again';
            this.ref.detectChanges();
            return;
        }

        this.authService.signup(this.code, this.model.newPassword)
            .then((value) => {
                this.log.info('Signup worked!', value);
                this.succeedSend = true;
            })
            .catch((err) => {
                this.log.info('Something went wrong:', err.message);
                this.model.hasError = true;
                this.model.errorMessage = err.message;
                this.ref.detectChanges();
            });
    }
}
