<div *ngIf="schedule?.errors as errors">
    <div
        *ngIf="errors.length === 1"
        class="error-alert error-alert--single"
        [@fadeRowAnimation]=""
    >
        <div class="error-alert__icon">
            <mat-icon svgIcon="alert"></mat-icon>
        </div>
        <p class="error-alert__text">
            <b>{{errors[0].reference}}: </b>{{ errors[0].message }}
        </p>
    </div>
    <div
        *ngIf="errors.length > 1"
        class="error-alert__multiple"
    >
        <mat-expansion-panel class="error-alert__multiple-panel">
            <mat-expansion-panel-header
                collapsedHeight="45px"
                expandedHeight="45px"
            >
                <mat-icon
                    class="error-alert__icon"
                    svgIcon="alert"
                ></mat-icon>
                <p class="error-alert__text">
                    Oops! Obtaining schedules
                    for {{ counter[schedule.isEmpty ? 0 : errors.length - 1 > 10 ? 10 : errors.length] }}
                    of the titles were unsuccessful. Click for more information.
                </p>
            </mat-expansion-panel-header>
            <mat-panel-description *ngFor="let error of errors; trackBy: trackByErrors">
                <div class="error-alert__icon">
                    <mat-icon svgIcon="alert"></mat-icon>
                </div>
                <p class="error-alert__text">
                    <b>{{error.reference}}: </b>{{ error.message }}
                </p>
            </mat-panel-description>
        </mat-expansion-panel>
    </div>
</div>
