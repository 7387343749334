import { Component, Input } from '@angular/core';

@Component({
    selector: 'avl-loading-progress-bar',
    templateUrl: './loading-progress-bar.component.html',
    styleUrls: ['./loading-progress-bar.component.scss']
})
export class LoadingProgressBarComponent {

  @Input()
    public value = 0;

  @Input()
  public fileName = '';
}
