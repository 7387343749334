import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchRegistry } from '../../enums/search-register.enum';
import { RegistrySearchType } from '@enums';
import { Subscription } from 'rxjs';
import { ProfileService } from '@services';
import { LandRegistryDialogQuery, LandRegistryDialogService } from '../../store/land-registry-dialog';

@Component({
    selector: 'avl-search-tools-side-menu',
    templateUrl: './search-tools-side-menu.component.html',
    styleUrls: ['./search-tools-side-menu.component.scss'],
})
export class SearchToolsSideMenuComponent implements OnInit, OnDestroy {
    public readonly searchRegistryType = SearchRegistry;

    @Input()
    public searchRegistry: SearchRegistry = SearchRegistry.hmlr;

    @Output()
    public searchModalOpened = new EventEmitter<RegistrySearchType>();

    @Output()
    public searchRegistryChanged = new EventEmitter<SearchRegistry>();

    public isRosEnabled: boolean;
    private readonly subscriptions = new Subscription();

    constructor(
        private readonly profileService: ProfileService,
        private readonly landRegistryDialogService: LandRegistryDialogService,
        private readonly landRegistryDialogQuery: LandRegistryDialogQuery,
    ) {
    }

    public ngOnInit(): void {
        const sub = this.profileService.isRosEnabled$.asObservable()
            .subscribe((isRosEnabled) => this.isRosEnabled = isRosEnabled);

        this.subscriptions.add(sub);
        this.switchRegistryIfRosDisabled();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public openMap(): void {
        this.searchModalOpened.emit(RegistrySearchType.map);
    }

    public openCompanyNameSearch(): void {
        this.searchModalOpened.emit(RegistrySearchType.ownership);
    }

    public openTitleNumberSearch(): void {
        this.searchModalOpened.emit(RegistrySearchType.titleNumber);
    }

    public changeSearchRegistry(isRos: boolean): void {
        const registry = isRos ? SearchRegistry.ros : SearchRegistry.hmlr;

        this.searchRegistry = registry;
        this.searchRegistryChanged.emit(registry);
    }

    public isToggleChecked(): boolean {
        return this.searchRegistry === SearchRegistry.ros;
    }

    private switchRegistryIfRosDisabled(): void {
        const sub = this.profileService.isRosEnabled$
            .subscribe((isRosEnabled) => {
                const isRos = this.landRegistryDialogQuery.getValue().registry === SearchRegistry.ros;

                if (!isRosEnabled && isRos) {
                    this.landRegistryDialogService.changeSearchRegistry(SearchRegistry.hmlr);
                }
            });
        this.subscriptions.add(sub);
    }
}
