import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CompileState, CompileStore } from './compile.store';

@Injectable()
export class CompileQuery extends Query<CompileState> {

    constructor(protected store: CompileStore) {
        super(store);
    }

    public isEmailWasShown(): boolean {
        return this.getValue().isEmailWasShown;
    }
}
