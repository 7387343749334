import { Injectable } from '@angular/core';
import { MapSearchStore } from './map-search.store';
import { CacheService, LoggerService, UrlParamsService } from '@services';
import { Observable, throwError } from 'rxjs';
import { ITitleInfo } from '../../types';
import { LandRegistrySearchSource } from '../../enums/land-registry-search-source.enum';
import { catchError, shareReplay } from 'rxjs/operators';
// Don't simplify: it causes circular dependency issue
import { HmLandRegistryService } from '../../services/land-registry';

@Injectable()
export class MapSearchService {

    constructor(
        private readonly store: MapSearchStore,
        private readonly urlParams: UrlParamsService,
        private readonly hmLandRegistryService: HmLandRegistryService,
        private readonly titleDetailsCacheService: CacheService<ITitleInfo[]>,
        private readonly log: LoggerService,
    ) {
    }

    public resetTitleData(): void {
        this.store.update({
            details: null,
            focusedFeatures: null,
            stateBeforeFocusing: null,
            isSelectionUnderPinBlocked: false,
            temporaryHighlightedTitleNumber: null,
        });
        this.urlParams.removeParams(['titleNumber']);
    }

    public getTitleDetails(folderId: string, titleNumber: string, { isCacheEnabled = false }: { isCacheEnabled?: boolean } = {}): Observable<ITitleInfo[]> {
        const cacheKey = `fid/${folderId}/title-number/${titleNumber}`;
        const observable = this.hmLandRegistryService.search(folderId, 'title-number', titleNumber, LandRegistrySearchSource.mapSearch)
            .pipe(
                catchError((error) => {
                    this.log.error(error);

                    return throwError(error);
                }),
            );

        return isCacheEnabled
            ? this.titleDetailsCacheService.findOrSet(cacheKey, observable.pipe(shareReplay(1)))
            : observable;
    }
}
