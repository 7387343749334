import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PurchasedFilesState, PurchasedFilesStore } from './purchased-files.store';
import { Observable } from 'rxjs';
import { IPagination } from '@core/types';

@Injectable()
export class PurchasedFilesQuery extends QueryEntity<PurchasedFilesState> {

    constructor(protected store: PurchasedFilesStore) {
        super(store);
    }

    public getLoading(): Observable<boolean> {
        return this.selectLoading();
    }

    public getPagination(): IPagination {
        const { currentPage, totalCount, totalPages } = this.getValue();
        return { currentPage, totalCount, totalPages };
    }
}
