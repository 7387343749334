import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

import { ITitleInfo } from 'app/titles/types';

import { TableRowsAmountService } from '@services';
import { ICollectionHeightOffset } from '@core/types';
import { Column } from '../../constants';
import { animate, state, style, transition, trigger } from '@angular/animations';

const resultsTableHeightOffset: ICollectionHeightOffset = {
    header: 155 + 60,
    tableHeader: 36,
    tableHeaderColumns: 44,
    paginator: 0,
    footerButton: 78,
};

@Component({
    selector: 'avl-land-registry-results-table',
    templateUrl: './land-registry-results-table.component.html',
    styleUrls: ['./land-registry-results-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0', minHeight: '0', padding: 0 })),
            state('expanded', style({ height: '*', display: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class LandRegistryResultsTableComponent implements OnInit, OnChanges {
    public selection = new SelectionModel<ITitleInfo>(true, []);
    public titlesSource = new MatTableDataSource<ITitleInfo>([]);
    public tableRowsAmount: number;
    public expandedDescription?: string = null;

    @Input()
    public isLoading: boolean;

    @Input()
    public titles: ITitleInfo[];

    @Input()
    public columns: Column[] = [];

    @Input()
    public selectedTitles: ITitleInfo[] = [];

    @Output()
    public selectionChanged = new EventEmitter<ITitleInfo[]>();

    public columnNames: string[] = [];
    public columnWidths: string[] = [];
    public columnWidthsMap: { [key: string]: string } = {};

    constructor(
        private readonly tableRowsAmountService: TableRowsAmountService,
    ) {
    }

    public ngOnInit(): void {
        this.calculateRowsAmount();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const columns = changes['columns']?.currentValue as Column[] | undefined;

        this.resetExpandedRow();

        if (columns) {
            this.columnNames = columns.map((column) => column.name);
            this.columnWidths = columns.map((column) => column.width);
            this.columnWidthsMap = {};
            columns.forEach((column) => this.columnWidthsMap[column.name] = column.width);
            return;
        }

        this.titlesSource.data = this.titles;
        this.selection = new SelectionModel<ITitleInfo>(true, this.selectedTitles);
    }

    public joinIfArray(what3Words: string | string[]): string {
        return Array.isArray(what3Words)
            ? what3Words?.join(' ')
            : what3Words;
    }

    public isAllSelected(): boolean {
        const selectedNumbers = this.selection.selected.map((file) => file.titleNumber);
        const sourceNumbers = this.titlesSource.data.map((file) => file.titleNumber);

        return sourceNumbers.every((id) => selectedNumbers.includes(id));
    }

    public masterToggle(): void {
        this.isAllSelected()
            ? this.deselect(...this.titlesSource.data)
            : this.titlesSource.data.forEach((row) => !this.isSelected(row) && this.selection.select(row));
        this.selectionChanged.emit(this.selection.selected);
    }

    public toggleSelection(value: ITitleInfo): void {
        this.isSelected(value)
            ? this.deselect(value)
            : this.selection.select(value);
        this.selectionChanged.emit(this.selection.selected);
    }

    public isSelected(file: ITitleInfo): boolean {
        return !!this.selection.selected.find((selectedFile) => selectedFile.titleNumber === file.titleNumber);
    }

    public onExpandRightDescriptionClick(item: ITitleInfo | null): void {
        const rightDescription = item.rightDescription;
        this.expandedDescription = rightDescription === this.expandedDescription ? null : rightDescription;
    }

    private calculateRowsAmount(): void {
        this.tableRowsAmount = this.tableRowsAmountService
            .calculateRowsAmount(resultsTableHeightOffset, 65);
    }

    private deselect(...files: ITitleInfo[]): void {
        files.forEach((file) => {
            const originFile = this.selection.selected.find((selectedFile) => selectedFile.titleNumber === file.titleNumber);
            if (originFile) {
                this.selection.deselect(originFile);
            }
        });
    }

    private resetExpandedRow(): void {
        this.expandedDescription = null;
    }
}
