import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ICollectionHeightOffset, ICollectionPageEvent, IPagination } from '@core/types';
import { Router } from '@angular/router';
import { FoldersTableComponent } from '../folders-table/folders-table.component';
import { MinimalBookmark } from '../../types/minimal-bookmark.type';
import { BookmarkQuery, BookmarkService, CompileService } from '../../states';
import { TableRowsAmountService } from '@services';

const foldersTableHeightOffset: ICollectionHeightOffset = {
    header: 64,
    tableHeader: 88,
    paginator: 56,
    footerButton: 52,
    tableHeaderColumns: 44,
};

@Component({
    selector: 'avl-previous-folders',
    templateUrl: './previous-folders.component.html',
    styleUrls: ['./previous-folders.component.scss'],
})
export class PreviousFoldersComponent implements OnInit {
    @ViewChild(FoldersTableComponent, { static: false })
    public foldersTableComponent: FoldersTableComponent;

    public bookmarks$: Observable<MinimalBookmark[]>;
    public loading$: Observable<boolean>;
    public pagination: IPagination = { totalCount: 0 };
    public defaultParams: ICollectionPageEvent;

    constructor(
        private readonly router: Router,
        private readonly bookmarkQuery: BookmarkQuery,
        private readonly bookmarkService: BookmarkService,
        private readonly tableRowsAmountService: TableRowsAmountService,
        private readonly compileService: CompileService,
    ) {
    }

    public ngOnInit(): void {
        const rowsPerPage = this.tableRowsAmountService.calculateRowsAmount(foldersTableHeightOffset);
        this.defaultParams = {
            pageIndex: 0,
            pageSize: rowsPerPage,
        };

        this.bookmarkService.load(this.defaultParams);
        this.bookmarks$ = this.bookmarkQuery.get();
        this.loading$ = this.bookmarkQuery.isLoading();
        this.bookmarkQuery.getTotalCount$()
            .subscribe((value) => this.pagination.totalCount = value);
    }

    public onSearchChanged(value: string): void {
        const params = { page: this.defaultParams, search: value };

        this.bookmarkService.search(params);
        this.foldersTableComponent.resetToFirstPage();
    }

    public onFolderOpened(folderId: string): void {
        void this.router.navigate(['lease/upload'], { queryParams: { fid: folderId } });
    }

    public onPageChanged(params: ICollectionPageEvent): void {
        this.bookmarkService.sort(params);
    }

    public onReportDownloaded(bookmark: MinimalBookmark): void {
        const folderId = bookmark.id;
        const reportLink = bookmark.reportUri;

        this.compileService.downloadReport(folderId, reportLink);
    }
}
