import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LngLat, LngLatLike, Map, RequestTransformFunction, StyleSpecification } from 'maplibre-gl';

@Component({
    selector: 'avl-maplibre',
    templateUrl: './maplibre.component.html',
    styleUrls: ['./maplibre.component.scss'],
})
export class MaplibreComponent implements OnInit, OnChanges {
    @Input()
    public style: string | StyleSpecification;

    @Input()
    public zoom?: number;

    @Input()
    public maxZoom?: number;

    @Input()
    public minZoom?: number;

    @Input()
    public center?: LngLatLike;

    @Input()
    public transformRequest?: RequestTransformFunction;

    @Output()
    public mapLoad = new EventEmitter<Map>();

    @Output()
    public zoomEnd = new EventEmitter<number>();

    @Output()
    public moveEnd = new EventEmitter<LngLat>();

    @Output()
    public mapResize = new EventEmitter<void>();

    @Output()
    public mapError = new EventEmitter<ErrorEvent>();

    private map: Map;

    public ngOnInit(): void {
        this.mapInitialisation();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const zoom = !changes['zoom']?.isFirstChange() && changes['zoom']?.currentValue;
        const center = !changes['center']?.isFirstChange() && changes['center']?.currentValue;

        if (zoom) {
            this.map.zoomTo(zoom);
        }
        if (center) {
            this.map.setCenter(center);
        }
    }

    private mapInitialisation(): void {
        const mapContainer = document.getElementById('map');
        this.map = new Map({
            container: mapContainer,
            style: this.style,
            zoom: this.zoom,
            maxZoom: this.maxZoom,
            minZoom: this.minZoom,
            center: this.center,
            dragRotate: false,
            antialias: true,
            transformRequest: this.transformRequest,
        });

        this.listenZoomEnd();
        this.listenResize();
        this.listenLoad();
        this.listenMoveEnd();
        this.listenError();
    }

    private listenZoomEnd(): void {
        this.map.on('zoomend', () => {
            const currentZoom = this.map.getZoom();
            this.zoomEnd.emit(currentZoom);
        });
    }

    private listenResize(): void {
        this.map.on('resize', () => {
            this.mapResize.emit();
        });
    }

    private listenLoad(): void {
        const callback = (): void => {
            clearTimeout(timer);
            this.mapLoad.emit(this.map);
        };
        const timer = setTimeout(() => {
            this.map.off('load', callback);
            this.mapLoad.emit(this.map);
        }, 500);

        this.map.on('load', callback);
    }

    private listenMoveEnd(): void {
        this.map.on('moveend', () => {
            const currentCenter = this.map.getCenter();
            this.moveEnd.emit(currentCenter);
        });
    }

    private listenError(): void {
        this.map.on('error', (error) => {
            this.mapError.emit(error);
        });
    }
}
