import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { SearchResultsState, SearchResultsStore } from './search-results.store';
import { Observable } from 'rxjs';

import { ITitleInfo } from 'app/titles/types';

@Injectable()
export class SearchResultsQuery extends QueryEntity<SearchResultsState> {

    constructor(protected store: SearchResultsStore) {
        super(store);
    }

    public getLoading(): Observable<boolean> {
        return this.selectLoading();
    }

    public getTitleInfos(): Observable<ITitleInfo[]> {
        return this.selectAll({
            filterBy: (entity) => !entity.isError,
        });
    }

    public getErrorTitleInfos(): Observable<ITitleInfo[]> {
        return this.selectAll({
            filterBy: (entity) => !!entity.isError,
        });
    }

    public isEmpty(): boolean {
        return this.getAll().length < 1;
    }
}
