import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CompilingStatus } from '../../types/compiling-status.type';
import { FolderQuery } from '../folders';

export type CompileState = CompilingStatus & {
    processId: string;
    isEmailWasShown: boolean;
};

function createInitialState(): CompileState {
    return {
        processId: '',
        progress: 0,
        filesCompiled: 0,
        totalFiles: 0,
        isFinished: false,
        isSuccess: false,
        isFailure: false,
        isEmailToastShouldBeShown: false,
        isPartialSuccess: false,
        title: '',
        message: [],
        isEmailWasShown: false,
        linkMessage: '',
        linkToReport: '',
    };
}

@Injectable()
@StoreConfig({ name: '[leases] compile', resettable: true })
export class CompileStore extends Store<CompileState> {
    private readonly compilationInProgressKey = 'folder_of_compilation_in_progress';

    constructor(private readonly folderQuery: FolderQuery) {
        super(createInitialState());
    }

    public setLoading(loading?: boolean): void {
        super.setLoading(loading);

        this.setCompilationInProgress(loading);
    }

    public setCompilationInProgress(isInProgress: boolean): void {
        const valueForStore = isInProgress ? this.folderQuery.getId() : '';
        localStorage.setItem(this.compilationInProgressKey, valueForStore);
    }

    public isCompilationInProgress(): boolean {
        const folderIdFromStorage = localStorage.getItem(this.compilationInProgressKey);
        const currentFolderId = this.folderQuery.getId();

        return folderIdFromStorage === currentFolderId && currentFolderId !== '';
    }
}

