import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ICollectionHeightOffset, ICollectionPageEvent, IPagination } from '@core/types';
import { Router } from '@angular/router';
import { TableRowsAmountService } from '@services';
import { FoldersTableComponent } from '../folders-table/folders-table.component';
import { Bookmark } from '../../types';
import { BookmarksQuery } from '../../store/bookmarks/bookmarks.query';
import { BookmarksService } from '../../store/bookmarks/bookmarks.service';

const foldersTableHeightOffset: ICollectionHeightOffset = {
    header: 64,
    tableHeader: 88,
    paginator: 56,
    footerButton: 52,
    tableHeaderColumns: 44,
};

@Component({
    selector: 'avl-previous-folders',
    templateUrl: './previous-folders.component.html',
    styleUrls: ['./previous-folders.component.scss'],
})
export class PreviousFoldersComponent implements OnInit {
    @ViewChild(FoldersTableComponent, { static: false })
    public foldersTableComponent: FoldersTableComponent;

    public bookmarks$: Observable<Bookmark[]>;
    public loading$: Observable<boolean>;
    public pagination: IPagination = { totalCount: 0 };
    public defaultParams: ICollectionPageEvent;

    constructor(
        private readonly router: Router,
        private readonly bookmarkQuery: BookmarksQuery,
        private readonly bookmarksService: BookmarksService,
        private readonly tableRowsAmountService: TableRowsAmountService,
    ) {
    }

    public ngOnInit(): void {
        const rowsPerPage = this.tableRowsAmountService.calculateRowsAmount(foldersTableHeightOffset);
        this.defaultParams = {
            pageIndex: 0,
            pageSize: rowsPerPage,
        };

        this.bookmarksService.load(this.defaultParams);
        this.bookmarks$ = this.bookmarkQuery.selectAll();
        this.loading$ = this.bookmarkQuery.selectLoading();
        this.bookmarkQuery.select('amount')
            .subscribe((value) => this.pagination.totalCount = value);
    }

    public onSearchChanged(value: string): void {
        const params = { page: this.defaultParams, search: value };

        this.bookmarksService.search(params);
        this.foldersTableComponent.resetToFirstPage();
    }

    public onFolderOpened(folderId: string): void {
        this.router.navigate(['notice/upload'], { queryParams: { fid: folderId } });
    }

    public onPageChanged(params: ICollectionPageEvent): void {
        this.bookmarksService.sort(params);
    }
}
