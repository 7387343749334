import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FailedDocumentsState, FailedDocumentsStore } from './failed-documents.store';

@Injectable()
export class FailedDocumentsQuery extends QueryEntity<FailedDocumentsState> {
    constructor(protected store: FailedDocumentsStore) {
        super(store);
    }
}
