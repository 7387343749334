import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MinimalDocument } from '../types/minimal-document.type';

@Injectable()
export class DocumentApi {

    constructor(private readonly http: HttpClient) {
    }

    public getCreateEndpointPath(folderId: string): string {
        return `/api/filed-documents/document/${folderId}/add`;
    }

    public get(folderId: string, documentId: string): Observable<MinimalDocument> {
        return this.http.get<MinimalDocument>(`/api/filed-documents/status/${folderId}/${documentId}`);
    }

    public delete(folderId: string, documentId: string): Observable<void> {
        return this.http.delete<void>(`/api/filed-documents/document/${folderId}/${documentId}`);
    }
}
