import { LandRegistrySearchSource } from '../../enums/land-registry-search-source.enum';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { ITitleInfo, LandRegistryApi, mapTitleInfo } from '../../types';
import { delay, expand, filter, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { PurchasedTitleDetails } from '../../types/purchased-title-details.type';

export class BaseLandRegistryService {

    constructor(private readonly api: LandRegistryApi) {
    }

    public search(folderId: string, kind: string, query: string, source: LandRegistrySearchSource = LandRegistrySearchSource.default, contentUrl$?: Subject<string> | undefined): Observable<ITitleInfo[]> {
        let linkToContent = '';

        return this.api.getContentLocation(folderId, kind, query, source)
            .pipe(
                switchMap((contentUrl) => {
                    linkToContent = contentUrl;
                    contentUrl$?.next(contentUrl);

                    return this.api.getContentByLocation(contentUrl);
                }),
                expand((response) =>
                    response.status === 202
                        ? this.api.getContentByLocation(linkToContent).pipe(delay(1000))
                        : EMPTY,
                ),
                filter((result) => result.status === 200),
                take(1),
                map((response) => (response.body || []).map((title) => mapTitleInfo(title))),
            );
    }

    public refresh(folderId: string, contentUrl$: Subject<string>): Observable<ITitleInfo[]> {
        let linkToContent = '';

        return this.api.refresh(folderId)
            .pipe(
                switchMap((contentUrl) => {
                    linkToContent = contentUrl;
                    contentUrl$.next(contentUrl);

                    return this.api.getContentByLocation(contentUrl);
                }),
                expand((response) =>
                    response.status === 202
                        ? this.api.getContentByLocation(linkToContent).pipe(delay(1000))
                        : of(response),
                ),
                takeWhile((response) => response.status === 200, true),
                map((response) => (response.body || []).map((title) => mapTitleInfo(title))),
            );
    }

    public getPurchaseStatus(url: string): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        const purchaseStatus$ = this.api.getPurchaseStatus(url);

        return purchaseStatus$.pipe(
            expand((response) => {
                return response.status === 202
                    ? purchaseStatus$.pipe(delay(3000))
                    : EMPTY;
            }),
        );
    }

    public getSearchResults(url: string): Observable<HttpResponse<ArrayBuffer>> {
        const contentInExcelUrl = url.replace('/search/', '/search-to-excel/');

        return this.api.getSearchResultsExcel(contentInExcelUrl);
    }
}
