import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'avl-not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
    public title = 'Page not found';
    public description = 'Opps! The page you are looking for is not found.';
    public backButtonText = 'Back to Home';
    public isBackButtonVisible = true;
    public previousPageUrl?: string = null;

    constructor(
        private readonly route: ActivatedRoute,
    ) {
    }

    public ngOnInit(): void {
        const data = this.route.snapshot.data;

        this.title = data['title'] || this.title;
        this.description = data['description'] || this.description;
        this.backButtonText = data['backButtonText'] || this.backButtonText;
        this.previousPageUrl = history.state['previousPageUrl'] || this.previousPageUrl;

        if (data['isBackButtonVisible'] === false) {
            this.isBackButtonVisible = false;
        }
    }

    public back(): void {
        if (window.opener || !this.previousPageUrl) {
            window.close();
        } else {
            window.location.href = this.previousPageUrl;
        }
    }

}
