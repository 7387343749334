import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'avl-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA)
        public data: { title: string; message: string },
    ) {
    }
}
