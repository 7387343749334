import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { LandRegistryPurchaseEntity } from '../../../enums/land-registry-purchase-entity.enum';

@Component({
    selector: 'avl-purchase-titles-button',
    templateUrl: './purchase-titles-button.component.html',
    styleUrls: ['./purchase-titles-button.component.scss'],
})
export class PurchaseTitlesButtonComponent {
    @Input()
    public counter: number;

    @Input()
    public price: number;

    @Input()
    public purchaseEntity: LandRegistryPurchaseEntity;

    @Input()
    public purchaseAction: Subscription | boolean;

    @Output()
    public titlesPurchased = new EventEmitter<void>();
}
