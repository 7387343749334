export enum FirebaseError {
    internetError = 'auth/internal-error',
    userNotFount = 'auth/user-not-found',
    networkRequestFailed = 'auth/network-request-failed',
}

export const firebaseRequestErrors = [
    FirebaseError.internetError,
    FirebaseError.networkRequestFailed,
];
