import { OverlayRef } from '@angular/cdk/overlay';

import { OnboardingOverlayComponent } from '../components/onboarding-overlay/onboarding-overlay.component';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export type IOverlayConfig = {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
    content?: OnboardingContent;
}

export type OnboardingContent = {
    type: 'splash' | 'character' | 'medal';
    cardText?: string[];
    cardAction?: string;
    cardWidth?: string;
    positionX?: 'center' | 'left' | 'right';
    positionY?: 'center' | 'top' | 'bottom';
    sourcePath?: string;
    highlightElement?: string;
    backdropClickSkip?: boolean;
}

export class OnboardingOverlayRef {

    public componentInstance: OnboardingOverlayComponent;
    private readonly beforeClose = new Subject<void>();
    private readonly afterClosed = new Subject<void>();

    constructor(private readonly overlayRef: OverlayRef) {
    }

    public close(): void {
        this.componentInstance.animationStateChanged.pipe(
            filter((event: any) => event.phaseName === 'start'),
            take(1),
        ).subscribe(() => {
            this.beforeClose.next();
            this.beforeClose.complete();
            this.overlayRef.detachBackdrop();
        });

        this.componentInstance.animationStateChanged.pipe(
            filter((event: any) => event.phaseName === 'done' && event.toState === 'leave'),
            take(1),
        ).subscribe(() => {
            this.overlayRef.detach();
            this.overlayRef.dispose();
            this.afterClosed.next();
            this.afterClosed.complete();
        });

        this.componentInstance.startExitAnimation();
    }

    public onAfterClose(): Observable<void> {
        return this.afterClosed.asObservable();
    }
}
