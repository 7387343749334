export type Compare<T> = (a: T, b: T) => boolean;

export class PreviousValueComparator<T> {
    private previousValue: T;

    public setPreviousValue(value: T): void {
        this.previousValue = value;
    }

    public compareAndSaveCurrentValue(value: T, compareFunction: Compare<T>): boolean {
        const isValuesEquals = this.compare(value, compareFunction);

        this.setPreviousValue(value);

        return isValuesEquals;
    }

    public compare(value: T, compareFunction: Compare<T>): boolean {
        if (!this.previousValue && value) {
            return false;
        }

        return compareFunction(this.previousValue, value);
    }
}
