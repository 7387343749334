import { Injectable } from '@angular/core';

import { OnboardingOverlayService } from './onboarding-overlay.service';
import { OnboardingOverlayRef } from '../types/overlay-config.type';
import { OnboardingApi } from '../api/onboarding.api';

import {
    addSchedule,
    characterWelcome,
    downloadReport,
    firstDownloadReportMedal,
    firstUploadMedal,
    giveAnotherTry,
    mainMenu,
    projectDetails,
    purchaseOptions,
    purchaseTraining,
    reviewTitles,
    scheduleSuccessfullyWithReport,
    scheduleSuccessfullyWithoutReport,
    searchField,
    showAiMagic,
    splashScreen,
    startAnalyzing,
    startDragging,
    startNewProject,
    uploadMoreTitles,
    waitingForReportDownload,
} from '../constants/onboarding-steps.constant';

import { skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { OnboardingBranch } from '../enums/onboarding-branch.enum';

@Injectable({ providedIn: 'root' })

export class OnboardingManageService {
    public isActive = false;
    public activeOverlayRef: OnboardingOverlayRef;
    public pulsedButton = new Subject<boolean>();
    public isDisabledNextButton$ = new BehaviorSubject<boolean>(true);
    public createProjectBtnDisabled$ = new BehaviorSubject<boolean>(false);
    public closed$ = new Subject();
    public isSchedulesNavigate = false;
    public isShowReport = false;

    private readonly onboardingActive$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly onboardingApi: OnboardingApi,
        private readonly onboardingOverlayService: OnboardingOverlayService,
    ) {
    }

    public isOnboardingActive(): Observable<boolean> {
        return this.onboardingActive$.asObservable();
    }

    public stop(): void {
        if (this.activeOverlayRef) {
            this.activeOverlayRef.close();
            this.closed$.next('close');
        }
    }

    public start(): void {
        this.isActive = true;
        this.onboardingActive$.next(true);
        this.createProjectBtnDisabled$.next(true);

        this.activeOverlayRef = this.onboardingOverlayService.open(splashScreen);
        this.activeOverlayRef.onAfterClose()
            .pipe(
                take(1),
                takeUntil(this.closed$),
                switchMap(() => this.onboardingOverlayService.open(characterWelcome).onAfterClose()),
                tap(() => this.startDragging()),
            )
            .subscribe();
    }

    public makeOnboardingVisible(): Observable<void> {
        return forkJoin([
            this.onboardingApi.resetOnboarding(),
            this.onboardingApi.resetBranchToUnseen(OnboardingBranch.all),
        ])
            .pipe(
                take(1),
                tap(() => this.start()),
                switchMap(() => this.onboardingApi.onboardingShown()),
            );
    }


    public startDragging(): void {
        this.activeOverlayRef = this.onboardingOverlayService.open(startDragging);
        this.pulsedButton.next(true);
    }

    public closeActiveOverlay(): void {
        if (this.activeOverlayRef && this.isActive) {
            this.activeOverlayRef.close();
        }
    }

    public enterProjectDetails(): void {
        if (!this.isActive) {
            return;
        }

        this.activeOverlayRef = this.onboardingOverlayService.open(projectDetails);
    }

    public giveAnotherTry(): void {
        if (!this.isActive) {
            return;
        }

        this.pulsedButton.next(false);

        this.activeOverlayRef = this.onboardingOverlayService.open(giveAnotherTry);
    }

    public showMedalFirstUpload(): void {
        if (!this.isActive) {
            return;
        }

        this.pulsedButton.next(false);

        const medalRef = this.onboardingOverlayService.open(firstUploadMedal);
        medalRef.onAfterClose()
            .pipe(take(1))
            .subscribe(() => this.reviewTitles());
    }

    public reviewTitles(): void {
        this.activeOverlayRef = this.onboardingOverlayService.open(reviewTitles);
        this.activeOverlayRef.onAfterClose()
            .pipe(take(1))
            .subscribe(() => this.uploadMoreTitles());
    }

    public uploadMoreTitles(): void {
        this.activeOverlayRef = this.onboardingOverlayService.open(uploadMoreTitles);
        this.activeOverlayRef.onAfterClose()
            .pipe(take(1))
            .subscribe(() => this.startAnalysing());
    }

    public startAnalysing(fromSchedule: boolean | undefined = false): void {
        if (fromSchedule) {
            this.onboardingActive$.next(true);
            this.addSchedule();
        } else {
            this.activeOverlayRef = this.onboardingOverlayService.open(startAnalyzing);
            this.isDisabledNextButton$.next(false);
            if (!this.isSchedulesNavigate) {
                this.activeOverlayRef.onAfterClose()
                    .pipe(take(1))
                    .subscribe(() => this.showAIMagic());
            }
        }
    }

    public addSchedule(): void {
        this.activeOverlayRef = this.onboardingOverlayService.open(addSchedule);
        this.activeOverlayRef.onAfterClose()
            .pipe(take(1))
            .subscribe(() => this.scheduleSuccessfully());
        this.isSchedulesNavigate = false;
    }

    public scheduleSuccessfully(): void {
        this.pulsedButton.next(false);
        this.activeOverlayRef = this.onboardingOverlayService
            .open(this.isShowReport ? scheduleSuccessfullyWithReport : scheduleSuccessfullyWithoutReport);
        this.isDisabledNextButton$.next(false);
        if (this.isShowReport) {
            this.activeOverlayRef.onAfterClose()
                .pipe(take(1))
                .subscribe(() => this.showAIMagic());
        } else {
            this.isActive = false;
            this.onboardingActive$.next(false);
        }
    }

    public showAIMagic(): void {
        this.activeOverlayRef = this.onboardingOverlayService.open(showAiMagic);
        this.activeOverlayRef.onAfterClose()
            .pipe(take(1))
            .subscribe(() => this.selectReport());
    }

    public selectReport(): void {
        this.activeOverlayRef = this.onboardingOverlayService.open(downloadReport);
    }

    public waitingForReportDownload(): void {
        this.activeOverlayRef = this.onboardingOverlayService.open(waitingForReportDownload);
        this.activeOverlayRef.onAfterClose()
            .pipe(take(1))
            .subscribe(() => this.showMedalOnFirstReport());
    }

    public showMedalOnFirstReport(): void {
        if (!this.isActive) {
            return;
        }

        const medalRef = this.onboardingOverlayService.open(firstDownloadReportMedal);
        medalRef.onAfterClose()
            .pipe(take(1))
            .subscribe(() => this.startNewProject());
    }

    public startNewProject(): void {
        this.activeOverlayRef = this.onboardingOverlayService.open(startNewProject);
        this.createProjectBtnDisabled$.next(false);

        this.activeOverlayRef.onAfterClose().subscribe(() => {
            this.isActive = false;
            this.onboardingActive$.next(false);
            this.isDisabledNextButton$.next(false);
        });
    }

    public showMainMenuStep(): Observable<void> {
        return this.onboardingApi.checkBranchSeen(OnboardingBranch.sideMenu)
            .pipe(
                skipWhile((isBranchSeen) => !isBranchSeen),
                switchMap(() => this.onboardingApi.setBranchAsSeen(OnboardingBranch.sideMenu)),
                switchMap(() => this.onboardingOverlayService.open(mainMenu).onAfterClose()),
            );
    }

    public showPurchaseOptionsStep(): Observable<void> {
        return this.onboardingApi.checkBranchSeen(OnboardingBranch.purchaseOptions)
            .pipe(
                skipWhile((isBranchSeen) => !isBranchSeen),
                switchMap(() => this.onboardingApi.setBranchAsSeen(OnboardingBranch.purchaseOptions)),
                tap(() => {
                    this.isActive = true;
                    this.activeOverlayRef = this.onboardingOverlayService.open(purchaseOptions);
                }),
            );
    }

    public showLandRegistryStep(): Observable<void> {
        return this.onboardingApi.checkBranchSeen(OnboardingBranch.landRegistry)
            .pipe(
                skipWhile((isBranchSeen) => !isBranchSeen),
                switchMap(() => this.onboardingApi.setBranchAsSeen(OnboardingBranch.landRegistry)),
                tap(() => {
                    this.isActive = true;
                }),
            );
    }

    public showAddScheduleStep(): Observable<any> {
        return this.onboardingApi.checkBranchSeen(OnboardingBranch.addSchedules)
            .pipe(
                skipWhile((isBranchSeen) => !isBranchSeen),
                switchMap(() => this.onboardingApi.setBranchAsSeen(OnboardingBranch.addSchedules)),
                tap(() => {
                    this.isActive = true;
                }),
            );
    }

    public showSearchFieldStep(): Observable<void> {
        this.isActive = true;
        this.activeOverlayRef = this.onboardingOverlayService.open(searchField);
        return this.activeOverlayRef.onAfterClose();
    }

    public destroyOnboarding(): void {
        this.closeActiveOverlay();
        this.isActive = false;
        this.onboardingActive$.next(false);
    }

    public showPurchaseTraining(): void {
        this.isActive = true;
        this.onboardingActive$.next(true);
        this.activeOverlayRef = this.onboardingOverlayService.open(purchaseTraining);
    }
}
