import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { NotifyCountersState, NotifyCountersStore } from './notify-counters.store';

@Injectable()
export class NotifyCountersQuery extends Query<NotifyCountersState> {
    constructor(protected store: NotifyCountersStore) {
        super(store);
    }
}
