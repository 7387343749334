<button
    class="dialog__close"
    mat-dialog-close="close"
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>
<h4 mat-dialog-title>Enter Project Details</h4>
<form
    [formGroup]="folderDetailsForm"
    (submit)="confirm()"
>
    <mat-dialog-content>
        <div
            class="form-group"
            [class.has-error]="isProjectNameDirtyAndInvalid()"
        >
            <input
                type="text"
                class="form-control"
                name="projectName"
                placeholder="Project Name"
                autocomplete="off"
                formControlName="projectName"
            />
            <mat-icon
                class="form-icon"
                svgIcon="user"
            ></mat-icon>
        </div>
        <div
            class="form-group"
            [class.has-error]="isMatterNumberDirtyAndInvalid()"
        >
            <input
                type="text"
                class="form-control"
                name="matterNumber"
                placeholder="Client / Matter Number"
                autocomplete="off"
                formControlName="matterNumber"
            />
            <mat-icon
                class="form-icon"
                svgIcon="file-chart-rounded"
            ></mat-icon>
        </div>

        <div
            class="folder-details-dialog__error-message"
            [class.has-error]="!!getErrorMessage()"
        >
            <p>{{ getErrorMessage() }}</p>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button
            class="avl-btn avl-btn--dark-purple avl-btn--wide"
            [disabled]="folderDetailsForm.invalid"
        >
            Let’s Go
        </button>
    </div>
</form>
