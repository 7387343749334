import { Component, Input } from '@angular/core';
import { fadeRowAnimation } from 'app/core/animations/fade.animation';
import { ISchedule } from 'app/titles/types';
import { IPurchasedFileBase } from '../../../../titles/types';

@Component({
    selector: 'avl-schedule-error-alert',
    templateUrl: './schedule-error-alert.component.html',
    styleUrls: ['./schedule-error-alert.component.scss'],
    animations: [fadeRowAnimation],
})
export class ScheduleErrorAlertComponent {
    public counter = ['all', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'more than ten'];

    @Input()
    public schedule: ISchedule;

    public trackByErrors(index: string, error: IPurchasedFileBase): string {
        return error.reference;
    }
}
