import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { AuthService } from '@auth/services/auth.service';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private readonly authService: AuthService) {
    }

    public intercept(request: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!environment.authIntercept) {
            return next.handle(request);
        }
        if (!this.authService.firebaseAuth.idToken) {
            return next.handle(request);
        }

        return this.authService.firebaseAuth.idToken.pipe(
            take(1),
            switchMap((token) => {
                request = request.clone({
                    setHeaders: {
                        authorization: `Bearer ${token}`,
                    },
                });
                this.authService.maybeUpdateTokenCookie(token);
                return next.handle(request);
            }),
        );
    }
}
