import { Order } from '@datorama/akita/src/lib/sort';
import { OrderType } from '@enums';
import { IDocument } from './document.type';

export enum SortType {
    manual = 'manual',
    type = 'type',
    reference = 'reference',
    date = 'date',
    address = 'address',
    tenure = 'tenure',
}

export type DocumentsSortToMap = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    document_ids: string[];
    sort: SortType;
    order: OrderType;
}

export type DocumentsSort = {
    documentIds: string[];
    sort: SortType;
    order: Order;
}

export function mapDocumentsSort(sort: DocumentsSort): DocumentsSortToMap {
    return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        document_ids: sort.documentIds,
        sort: sort.sort,
        order: convertToOrderType(sort.order),
    };
}

export function convertToOrderType(order: Order): OrderType {
    return order === Order.ASC ? OrderType.asc : OrderType.dsc;
}

export function convertOrderTypeToOrder(order: OrderType): Order {
    return order === OrderType.asc ? Order.ASC : Order.DESC;
}

export function convertToSortType(documentField: keyof IDocument): SortType {
    switch (documentField) {
        case 'type':
            return SortType.type;
        case 'titleNumber':
            return SortType.reference;
        case 'issueDate':
            return SortType.date;
        case 'address':
            return SortType.address;
        case 'ownership':
            return SortType.tenure;
        default:
            return SortType.manual;
    }
}

export function convertSortTypeToColumnName(sortType: SortType): string {
    switch (sortType) {
        case SortType.type:
            return 'type';
        case SortType.reference:
            return 'titleNumber';
        case SortType.date:
            return 'issueDate';
        case SortType.address:
            return 'address';
        case SortType.tenure:
            return 'ownership';
        case SortType.manual:
        default:
            return '';
    }
}
