import { Injectable } from '@angular/core';

import { FolderApi } from '../api';

import { FailedDocumentsService, FolderStore, MapSearchStore } from '../store';

import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { IDocument, ImanageParams } from '../types';
import { ProfileService } from '@services';
import { FolderDetails, IResponseStatus } from '@core/types';

@Injectable()
export class FolderService {

    constructor(
        private readonly folderApi: FolderApi,
        private readonly folderStore: FolderStore,
        private readonly profileService: ProfileService,
        private readonly mapSearchStore: MapSearchStore,
        private readonly failedDocumentsService: FailedDocumentsService,
    ) {
    }

    public createFolder(): Observable<string> {
        return this.folderApi.createFolder()
            .pipe(
                tap((folderId) => this.folderStore.update({ id: folderId })),
                tap(() => this.mapSearchStore.reset()),
            );
    }

    public getCurrentFolder(id: string): Observable<IResponseStatus<IDocument>> {
        return this.folderApi.getFolder(id)
            .pipe(
                tap((response) => {
                    this.folderStore.update({ id: response.id });

                    const failedDocuments = response.documents.filter((doc) => doc.isError && doc.messages?.length);
                    this.failedDocumentsService.setDocuments(failedDocuments);
                }),
            );
    }

    public createProject(folderId: string, project: FolderDetails): Observable<FolderDetails> {
        return this.folderApi.createProject(folderId, project)
            .pipe(
                switchMap(() => this.updateProjectStatus(folderId)),
            );
    }

    public updateProjectStatus(folderId: string): Observable<FolderDetails> {
        return this.getProjectDetails(folderId)
            .pipe(
                tap(({ matterNumber, projectName }) => {
                    this.folderStore.update((state) => ({ ...state, matterNumber, projectName }));
                }),
            );
    }

    public projectAlreadyCreated(folderId: string): Observable<boolean> {
        const isHmlrEnabled = this.profileService.isHmlrEnabled$.getValue();

        return !isHmlrEnabled ? of(true) : this.getIsProjectDetailsExist(folderId);
    }

    public resetFolderStore(): void {
        this.folderStore.reset();
    }


    // Login to Imanage
    public logInToImanage(data: any, agentUrl: string | undefined = '', agentSecret: string | undefined = ''): Observable<any> {
        return this.folderApi.logInToImanage(data, agentUrl, agentSecret);
    }

    public checkIfAuthorised(): Observable<any> {
        return this.folderApi.checkIfAuthorised();
    }

    public getDialogTokenCall(agentUrl: string, agentSecret: string, authorization: string): Observable<any> {
        return this.folderApi.getDialogTokenCall(agentUrl, agentSecret, authorization);
    }

    public downloadFiles(folderId: string, confData: ImanageParams, data: any): Observable<any> {
        return this.folderApi.downloadFiles(folderId, confData, data);
    }

    public isDownloadFinished(confData: ImanageParams, taskId: string): Observable<any> {
        return this.folderApi.isDownloadFinished(confData, taskId);
    }

    public downloadFilesToUi(confData: ImanageParams, contentLocation: string): Observable<any> {
        return this.folderApi.downloadFilesToUi(confData, contentLocation);
    }

    public sendThemToAvail(folderId: string, formData: any): Observable<any> {
        return this.folderApi.sendThemToAvail(folderId, formData);
    }

    public sendImanageDataToAvail(folderId: string, data: any): Observable<any> {
        return this.folderApi.sendImanageDataToAvail(folderId, data);
    }

    public checkImanageConfiguration(): Observable<any> {
        return this.folderApi.checkImanageConfiguration();
    }

    private getIsProjectDetailsExist(folderId: string): Observable<boolean> {
        return this.folderApi.getIsProjectDetailsExist(folderId);
    }

    private getProjectDetails(folderId: string): Observable<FolderDetails> {
        return this.folderApi.getProjectDetails(folderId);
    }
}
