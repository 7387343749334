<div
    *ngIf="(uploadState$ | async) as uploadState"
    class="notice-area"
    avlFileDrop
    [class.scaled]="uploadState.showPercents || uploadState.completed"
    [class.error]="uploadState.isError"
    [class.file-over]="isFileOverArea"
    [uploader]="fileUploader"
    (fileOver)="isFileOverArea = $event"
>
    <div class="notice-area__main">
        <div
            *ngIf="!uploadState.isError else errorImage"
            class="notice-area__image"
            [class.file-over]="isFileOverArea"
            [class.completed]="uploadState.completed"
            [class.uploading]="uploadState.showPercents || uploadState.completed"
        >
            <div class="loading-spinner">
                <avl-spinner
                    [diameter]="48"
                    [isBackgroundCircleVisible]="true"
                    [primaryColor]="getLoadingColors(uploadState, isFileOverArea).primary"
                    [secondaryColor]="getLoadingColors(uploadState, isFileOverArea).secondary"
                ></avl-spinner>
                <mat-icon
                    class="loading-spinner__arrow"
                    svgIcon="notice-loading-arrow"
                ></mat-icon>
            </div>
        </div>
        <ng-template #errorImage>
            <div class="notice-area__image notice-area__image--error">
                <mat-icon svgIcon="notice-error"></mat-icon>
            </div>
        </ng-template>
        <div
            class="notice-area__default"
            [class.fade-out-down]="uploadState.showPercents || uploadState.completed"
        >
            <h5 class="notice-area__title">{{ title }}</h5>
            <p class="notice-area__description">or click to import file</p>
            <label class="notice-area__upload-btn avl-btn">
                <input
                    class="notice-area__upload-input"
                    type="file"
                    accept="application/pdf"
                    avlFilePicker
                    [uploader]="fileUploader"
                >
                <span class="notice-area__upload-text">Choose file</span>
            </label>
        </div>
        <button
            *ngIf="uploadState.isError || uploadState.completed"
            class="notice-area__cancel"
            (click)="removeDocument()"
        >
            <svg
                class="notice-area__cancel-icon"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59
                       7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                />
            </svg>
        </button>
    </div>
    <avl-notice-progress
        [state]="uploadState"
        [messages]="document?.messages"
        [percent]="uploadPercentage$ | async"
    ></avl-notice-progress>
</div>
