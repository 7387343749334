import { Injectable } from '@angular/core';
import { ICollectionHeightOffset } from '@core/types';

@Injectable({
    providedIn: 'root',
})
export class TableRowsAmountService {

    public calculateRowsAmount(pageOffset: ICollectionHeightOffset, rowHeight: number | undefined = 49): number {
        const tableHeight = window.innerHeight
            - pageOffset.header
            - pageOffset.tableHeader
            - pageOffset.paginator
            - pageOffset.footerButton
            - pageOffset.tableHeaderColumns;
        const realRowsAmount = Math.floor(tableHeight / rowHeight);
        const minimalRowsAmount = 5;

        return realRowsAmount > minimalRowsAmount ? realRowsAmount : minimalRowsAmount;
    }
}
