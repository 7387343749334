import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FolderService } from 'app/titles/services';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { ImanageShowIframe } from 'app/titles/types';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LoggerService } from '@services';
import { environment } from '@env/environment';

@Injectable()
export class ImanageService {
    public readonly defaultImanageConfig = {
        imanageUrl: '',
        customerId: '',
        dialogToken: '',
        agentUrl: '',
        agentSecret: '',
    };

    public showDirectIframe$ = new BehaviorSubject<ImanageShowIframe>({ showIframe: false, data: { ...this.defaultImanageConfig } });
    public isAgentSessionExpired = false;

    private windowRef: Window = null;
    private previousUrl = '';
    private imanageCode = '';

    constructor(
        private readonly cookieService: CookieService,
        private readonly folderService: FolderService,
        private readonly dialog: MatDialog,
        private readonly log: LoggerService,
    ) {
    }

    public showImanageDialog(): void {
        // Check the Safari browser
        const checkClientBrowser = this.checkBrowser();
        if (checkClientBrowser === true) {
            return;
        }

        this.showDirectIframe$.next({ showIframe: false, data: { ...this.defaultImanageConfig } });

        this.folderService.checkIfAuthorised()
            .subscribe((authConfigurationResponse) => {
                if (authConfigurationResponse.status !== 200) {
                    return;
                }

                this.folderService.checkImanageConfiguration()
                    .subscribe((authConfiguration) => {
                        if (!authConfiguration.client_id) {
                            return;
                        }

                        let userClientId = environment.imanageConfig.clientId;
                        let userRedirectUri = environment.imanageConfig.redirectUri;
                        let userImanageUrl = environment.imanageConfig.imanageUrl;
                        const userAgentSecret = authConfiguration.agent_secret;
                        const userAgentUrl = authConfiguration.agent_url;
                        const authorizationEndpoint = '/auth/oauth2/authorize?';

                        if (environment.production) {
                            userClientId = authConfiguration.client_id;
                            userRedirectUri = authConfiguration.redirect_url;
                            userImanageUrl = authConfiguration.imanage_url;
                        }

                        // Check if the token is valid
                        this.log.info('imanage.service.agentAuthorization');
                        const agentAuth = this.cookieService.get('agentAuthorization');
                        this.folderService.getDialogTokenCall(userAgentUrl, userAgentSecret, agentAuth)
                            .pipe(
                                catchError((error) => {
                                    if (error.status === 401 || error.status === 407) {
                                        if (agentAuth !== '') {
                                            this.isAgentSessionExpired = true;
                                            this.cookieService.delete('agentAuthorization', '/');
                                        }

                                        if (userImanageUrl !== null && userClientId && userRedirectUri) {
                                            this.loginImanageWithPopup(userImanageUrl, authorizationEndpoint, userClientId, userRedirectUri);
                                        }
                                    }

                                    return of();
                                }),
                            )
                            .subscribe((tkRes) => {
                                if (tkRes) {
                                    this.showDirectIframe$.next({
                                        showIframe: true,
                                        data: {
                                            imanageUrl: userImanageUrl,
                                            customerId: tkRes.customerId,
                                            dialogToken: tkRes.dialogToken,
                                            agentUrl: userAgentUrl,
                                            agentSecret: userAgentSecret,
                                        },
                                    });
                                }
                            });
                    });
            });
    }

    public loginImanageWithPopup(imanageUrl: string, authorizationEndpoint: string, clientId: string, redirectUri: string): void {
        const redirectUrl = `${imanageUrl}${authorizationEndpoint}response_type=code&client_id=${clientId}`
            + `&redirect_uri=${redirectUri}&scope=user`;
        // In case the user have 2 dual screen setup___
        const popupWindowDesiredWidth = 750;
        const popupWindowDesiredHeight = 750;
        //  TODO: fix to const width = window.innerWidth || document.documentElement.clientWidth || screen.width;
        //        const height = window.innerHeight || document.documentElement.clientHeight || screen.height;
        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
        const systemZoom = width / window.innerWidth;
        const popupLeft = (width - popupWindowDesiredWidth) / 2 / systemZoom + dualScreenLeft;
        const popupTop = (height - popupWindowDesiredHeight) / 2 / systemZoom + dualScreenTop;
        this.log.info('popupLeft', popupLeft);
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        ,left=${popupLeft},top=${popupTop}, width=${popupWindowDesiredWidth / systemZoom},height=${popupWindowDesiredHeight / systemZoom}`;

        // ___In case the user have 2 dual screen setup
        if (this.windowRef === null || this.windowRef.closed) {
            this.windowRef = window.open(redirectUrl, 'child', params);
        } else if (this.previousUrl !== redirectUrl) {
            this.windowRef = window.open(redirectUrl, 'child', params);
            this.windowRef.focus();
        } else {
            this.windowRef.focus();
        }

        window.addEventListener('message', this.receiveMessage.bind(this), false);
        this.previousUrl = redirectUrl;
    }

    public receiveMessage(evt: any): void {
        if (evt.origin !== location.origin) {
            return;
        }

        // If the event data have the code key then we can proceed.
        if (evt.data.code) {
            this.imanageCode = evt.data.code;
            this.log.info('received the code, attempting to obtain agent-auth');
            this.loginToImanageWithAgent(this.imanageCode);
        } else if (evt.data.popup) {
            this.log.info('pop up is closed, opening the iframe');
            this.showImanageDialog();
        }
    }

    public parseJwt(token: string): any {
        // For getting the email from the token.
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            window.atob(base64).split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join(''),
        );

        return JSON.parse(jsonPayload);
    }

    // Log in to imanage and get the code.
    public loginToImanageWithAgent(code: string): void {
        // We get agentAuthorization so that we can get the dialogToken in the future.
        this.folderService.checkImanageConfiguration()
            .subscribe((res: any) => {
                let clientId = environment.imanageConfig.clientId;
                let redirectUri = environment.imanageConfig.redirectUri;
                const agentSecret = res.agent_secret;
                const agentUrl = res.agent_url;

                if (environment.production) {
                    clientId = res.client_id;
                    redirectUri = res.redirect_url;
                }

                if (!(code && clientId && redirectUri)) {
                    this.log.info('Variables unset');
                    return;
                }

                const tokenInfo = this.parseJwt(this.cookieService.get('auth'));
                const loginBodyJson = JSON.stringify({
                    code,
                    email: tokenInfo.email,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    redirect_uri: redirectUri,
                });
                const imanageAgentAuthorization = this.cookieService.get('agentAuthorization');
                this.log.info('Imanage Agent Authorization: ', imanageAgentAuthorization, 'isAgentAuthExpired', this.isAgentSessionExpired);

                if (imanageAgentAuthorization !== '' && !this.isAgentSessionExpired) {
                    // this.proceedImanageLogin();
                    return;
                }

                this.isAgentSessionExpired = false;
                this.folderService.logInToImanage(loginBodyJson, agentUrl, agentSecret)
                    .pipe(
                        catchError((error) => {
                            if (error.status === 400 || error.status === 401) {
                                let input = '';

                                if (error.error.input !== undefined) {
                                    const inputArr = (error.error.input).split('!=');
                                    if (inputArr) {
                                        input = inputArr[1];
                                    }
                                }

                                const dialogText = {
                                    title: error.error.status,
                                    message: 'Your Email does not match with the iManage Email.'
                                        + ' Please log-in to iManage with ' + input,
                                    button: 'Close',
                                };
                                const dialogRef = this.dialog.open(AlertOkDialogComponent, {
                                    panelClass: 'report-dialog',
                                    width: '400px',
                                    data: dialogText,
                                    // data: { title: error.error.status, message: error.error.input, button: 'Close' },
                                });
                                dialogRef.afterClosed().subscribe(() => {
                                    this.isAgentSessionExpired = true;
                                    this.cookieService.delete('agentAuthorization', '/');
                                });
                            }

                            return of();
                        }),
                    )
                    .subscribe((response) => {
                        if (response) {
                            this.cookieService.set('agentAuthorization', response.authorization, 1, '/', null, true, 'Lax');

                            /*
                              // Pass the data to the component.
                              this.imanageAuthParamsData = {
                                authorization: response.authorization,
                                agentUrl: this.agentUrl,
                                agentSecret: this.agentSecret
                              };
                              // Now we can proceed to log in and afterwards display the iframe.
                              if (this.imanageAuthParamsData.authorization) {
                                // this.showImanageIframe();
                                this.proceedImanageLogin();
                              }
                            */
                        }
                    });
            });
    }

    private checkBrowser(): boolean {
        if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
            const dialogRef = this.dialog.open(AlertOkDialogComponent, {
                panelClass: 'report-dialog',
                width: '400px',
                data: {
                    title: 'Safari does not support iManage',
                    message: 'Please open Avail in Chrome, Edge or Firefox',
                    button: 'Close',
                },
            });
            dialogRef.afterClosed().subscribe();

            return true;
        } else {
            return false;
        }
    }
}
