import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FileUploader } from '../classes/file-uploader.class';

/**
 * @example
 * <div
 *              avlFileDrop
 *              [uploader]="fileUploader"
 *              (fileOver)="isFileOver = $event"
 *              (fileDrop)="isFileDropped = true"
 *
 *              class="drop-area"
 *              [class.file-over]="isFileOver"
 *              [class.file-dropped]="isFileDropped"
 * ></div>
 */
@Directive({
    selector: '[avlFileDrop]',
})
export class FileDropDirective {
    /**
     * FileUploader instance
     */
    @Input()
    public uploader: FileUploader;

    /**
     * emits <b>true</b> when files are over the target element
     *
     * emits <b>false</b> when files are out of the target element or dropped on it
     */
    @Output()
    public fileOver: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * emits event when files are dropped on the target element
     */
    @Output()
    public fileDrop: EventEmitter<void> = new EventEmitter<void>();

    /**
     * @ignore
     */
    private isFileOver = false;

    /**
     * @ignore
     */
    constructor(private readonly element: ElementRef) {
    }

    /**
     * Handler of 'drop' event
     */
    @HostListener('drop', ['$event'])
    public onDrop(event: any): void {
        const transfer = FileDropDirective.getTransfer(event);

        if (!transfer) {
            return;
        }

        FileDropDirective.preventAndStop(event);
        this.uploader.addFiles(transfer.files);
        this.fileOver.emit(false);
        this.fileDrop.emit();
        this.isFileOver = false;
    }

    /**
     * Handler of 'dragover' event
     */
    @HostListener('dragover', ['$event'])
    public onDragOver(event: any): void {
        const transfer = FileDropDirective.getTransfer(event);

        if (!FileDropDirective.hasFiles(transfer.types)) {
            return;
        }

        transfer.dropEffect = 'copy';
        FileDropDirective.preventAndStop(event);

        if (!this.isFileOver) {
            this.fileOver.emit(true);
        }

        this.isFileOver = true;
    }

    /**
     * Handler of 'dragleave' event
     */
    @HostListener('dragleave', ['$event'])
    public onDragLeave(event: any): any {
        if (event.currentTarget !== this.element.nativeElement) {
            return;
        }

        FileDropDirective.preventAndStop(event);
        this.fileOver.emit(false);
        this.isFileOver = false;
    }

    /**
     * @ignore
     */
    private static getTransfer(event: any): any {
        return event.dataTransfer
            ? event.dataTransfer
            : event.originalEvent.dataTransfer; // jQuery fix;
    }

    /**
     * @ignore
     */
    private static preventAndStop(event: any): any {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * @ignore
     */
    private static hasFiles(types: any): any {
        if (!types) {
            return false;
        }

        if (types.indexOf) {
            return types.indexOf('Files') !== -1;
        } else if (types.contains) {
            return types.contains('Files');
        }

        return false;
    }
}
