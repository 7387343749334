import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentsSortToMap } from '../types';

@Injectable()
export class DocumentApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public removeDocument(folderId: string, documentId: string): Observable<void> {
        return this.http.delete<void>(`/api/titles/document/${folderId}/${documentId}`);
    }

    public sort(folderId: string, sort: DocumentsSortToMap): Observable<void> {
        return this.http.post<void>(`/api/titles/folder/${folderId}/document-sort`, sort);
    }

}
