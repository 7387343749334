import { Injectable } from '@angular/core';
import { SideNavCountersStore } from './side-nav-counters.store';
import { BookmarksApi } from '../../api/bookmarks.api';

@Injectable()
export class SideNavCountersService {

    constructor(
        private readonly sideNavCountersStore: SideNavCountersStore,
        private readonly bookmarksApi: BookmarksApi,
    ) {
    }

    public load(): void {
        this.bookmarksApi.getActive()
            .subscribe((statistic) => {
                this.sideNavCountersStore.update({
                    previousActiveProjects: statistic.active,
                });
            });
    }
}
