import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'avl-select-client-dialog',
    templateUrl: './select-client-dialog.component.html',
    styleUrls: ['./select-client-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectClientDialogComponent implements OnInit {
    public form: FormGroup;

    constructor(
        private readonly dialogRef: MatDialogRef<SelectClientDialogComponent>,
    ) {
    }

    public ngOnInit(): void {
        this.form = new FormGroup({
            type: new FormControl(),
        });
    }

    public downloadReport(): void {
        const { type } = this.form.value;
        this.dialogRef.close(type);
    }
}
