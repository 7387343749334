import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class RefreshService {
    private readonly refreshSource$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    public onRefresh(): Observable<boolean> {
        return this.refreshSource$.asObservable();
    }

    public resetRefresh(): void {
        this.refreshSource$.next(null);
    }

    public tryRefresh(lastDate: string): void {
        this.refreshSource$.next(this.isMoreOneDay(lastDate));
    }

    private isMoreOneDay(lastDate: string): boolean {
        const previousDate = new Date(lastDate);
        const currentDate = new Date();
        const timeDifferent = (currentDate.getTime() - previousDate.getTime()) / (1000 * 3600 * 24);

        return timeDifferent > 1;
    }
}
