import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { OnboardingContent } from '../../types/overlay-config.type';

const AUTO_CLOSED_TIME = 3200;

@Component({
    selector: 'avl-medal',
    templateUrl: './medal.component.html',
    styleUrls: ['./medal.component.scss'],
})
export class MedalComponent implements OnInit {

    @Input()
    public options: OnboardingContent;

    @Output()
    public medalClose = new EventEmitter<void>();

    public medalAnimateConfig: any;
    private medalAnimation: any;

    constructor(
        private readonly ngZone: NgZone,
    ) {
    }

    public ngOnInit(): void {
        this.medalAnimateConfig = {
            path: this.options.sourcePath,
            renderer: 'svg',
            autoplay: false,
            loop: false,
        };

        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.medalClose.emit();
            }, AUTO_CLOSED_TIME);
        });
    }

    public handleAnimation(animation: any): void {
        this.medalAnimation = animation;
        this.showMedal();
    }

    public showMedal(): void {
        this.medalAnimation.setDirection(1);
        this.medalAnimation.play();
    }
}
