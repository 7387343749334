import { Query } from '@datorama/akita';
import { FolderState, FolderStore } from './folder.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FolderDetails } from '@core/types';

@Injectable()
export class FolderQuery extends Query<FolderState> {

    public folderId$ = this.select('id');

    constructor(protected store: FolderStore) {
        super(store);
    }

    public folderCreated(): boolean {
        return !!this.getValue().id;
    }

    public getFolderId(): string {
        return this.getValue().id;
    }

    public selectProjectDetails(): Observable<FolderDetails> {
        return this.select((state) => {
            const { projectName, matterNumber } = state;
            return { projectName, matterNumber };
        });
    }

    public getProjectDetails(): FolderDetails {
        const { projectName, matterNumber } = this.getValue();
        return { projectName, matterNumber };
    }
}
