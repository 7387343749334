import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvailToolKey } from '@enums';
import { Observable } from 'rxjs';
import { SideNavCounters } from '../../types/side-nav-counters.type';

@Component({
    selector: 'avl-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
    public readonly tools = AvailToolKey;

    @Input()
    public counters: Observable<SideNavCounters>;

    @Output()
    public closeSideNav = new EventEmitter();

    @Output()
    public createNewProject = new EventEmitter();

    public onCreateNewProject(): void {
        this.createNewProject.emit();
        this.closeSideNav.emit();
    }

    public onClose(): void {
        this.closeSideNav.emit();
    }
}
