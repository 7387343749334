import { Query } from '@datorama/akita';
import { NoticeFolderState, NoticeFolderStore } from './notice-folder.store';
import { Injectable } from '@angular/core';
import { FolderDetails } from '@core/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NoticeFolderQuery extends Query<NoticeFolderState> {
    public folderId$ = this.select('id');

    constructor(protected store: NoticeFolderStore) {
        super(store);
    }

    public getDetails(): FolderDetails {
        const folderData = this.getValue();

        return {
            projectName: folderData.projectName,
            matterNumber: folderData.matterNumber,
        };
    }

    public getDetails$(): Observable<FolderDetails> {
        return this.select()
            .pipe(
                map((folderData) => ({
                    projectName: folderData.projectName,
                    matterNumber: folderData.matterNumber,
                })),
            );
    }

    public onFolderInitCanceled(): Observable<void> {
        return this.store.onFolderInitCanceled$.asObservable();
    }
}
