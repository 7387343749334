/* eslint-disable @typescript-eslint/naming-convention */
export type Logo = {
    defaultLogo: boolean;
    icon?: string;
    iconWidth?: number;
    iconHeight?: number;
}

export type LogoToMap = {
    default_logo: boolean;
    icon?: string;
    icon_width?: number;
    icon_height?: number;
}

export function mapLogo(logo: LogoToMap): Logo {
    return {
        defaultLogo: logo.default_logo ?? true,
        icon: logo.icon,
        iconWidth: logo.icon_width,
        iconHeight: logo.icon_height,
    };
}
