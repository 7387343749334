import { Routes } from '@angular/router';
import { NotFoundPageComponent } from '@shared/components/not-found-page/not-found-page.component';
import { SelectAvailableToolResolver } from './core/resolvers/select-available-tool.resolver.service';
import { ExternalRedirectComponent } from '@shared/components/external-redirect/external-redirect.component';
import { AuthGuard } from '@auth/guards';
import { PdfViewerComponent } from '@shared/components/pdf-viewer/pdf-viewer.component';
import { NoSubscriptionsComponent } from '@shared/components/no-subscriptions/no-subscriptions.component';

export const appRoutes: Routes = [
    {
        path: 'view',
        component: ExternalRedirectComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'avail-policies',
        children: [
            {
                path: 'privacy-policy',
                component: PdfViewerComponent,
                data: {
                    documentUrl: 'assets/documents/Privacy%20Policy%20-%20Platform.pdf',
                },
            },
        ],
    },
    {
        path: 'title-document-not-found',
        component: NotFoundPageComponent,
        data: {
            title: 'Document Not Found',
            description: 'This document has been deleted or moved.<br>You can go back to the folder if it still exists by clicking the button below:',
            backButtonText: 'Back to Folder',
        },
    },
    {
        path: 'zero-subscriptions',
        component: NoSubscriptionsComponent,
    },
    {
        path: '**',
        resolve: [SelectAvailableToolResolver],
        component: NotFoundPageComponent,
        data: {
            title: 'Page Not Found',
            description: 'This url is undefined in our system. Try to use another one',
            isBackButtonVisible: false,
        },
    },
];
