import { FileValidationErrors, IFileUploaderOptions } from '../types';
import { FileItem } from '../classes/file-item.class';
import { getFileMimeClassMatch } from './get-file-mime-class-match.validator';

/**
 * @param {FileItem} file
 * @param {IFileUploaderOptions} options
 * @returns {FileValidationErrors} validation error
 */
export function validateFileOnAdd(
    file: FileItem,
    {
        filePicker,
        customHandlers,
    }: IFileUploaderOptions,
): FileValidationErrors {
    const customValidator = customHandlers.customFileValidator;

    if (filePicker.allowedMimeClass.length) {
        const fileMimeClass = getFileMimeClassMatch(file.fileAsObject.sourceFile);

        if (!filePicker.allowedMimeClass.includes(fileMimeClass)) {
            return FileValidationErrors.validationMimeClassNotMatched;
        }
    }

    if (filePicker.allowedFileExtension.length) {
        const nameChunks = file.fileAsObject.name.split('.');
        const fileExtension = nameChunks[nameChunks.length - 1].toLowerCase();

        if (!filePicker.allowedFileExtension.includes(fileExtension)) {
            return FileValidationErrors.validationFileExtensionNotMatched;
        }
    }

    if (filePicker.maxFileSize && file.fileAsObject.size > filePicker.maxFileSize) {
        return FileValidationErrors.validationMaxFileSizeExceeded;
    }

    if (customValidator && !customValidator(file)) {
        return FileValidationErrors.validationCustomFailure;
    }

    return null;
}
