import { Injectable } from '@angular/core';
import { BookmarkStore } from './bookmark.store';
import { BookmarksApi } from '../../api';
import { ICollectionPageEvent, ICollectionTableUI } from '@core/types';
import { BookmarksList } from '../../types/bookmarks-list.type';

@Injectable()
export class BookmarkService {

    constructor(
        private readonly bookmarkStore: BookmarkStore,
        private readonly apiService: BookmarksApi,
    ) {
    }

    public load(params: ICollectionPageEvent): void {
        this.bookmarkStore.setLoading(true);
        this.apiService.getAll({
            start: params.pageIndex,
            limit: params.pageSize,
            sort: params.sort,
            order: params.order,
        })
            .subscribe({
                next: (bookmarksList) => this.set(bookmarksList),
                error: (error) => this.finishUploadWithError(error),
            });
    }

    public search(params: ICollectionTableUI): void {
        const page = params.page;
        const query = params.search;

        this.bookmarkStore.setLoading(true);
        this.apiService.getAll({
            start: page.pageIndex,
            limit: page.pageSize,
            sort: page.sort,
            order: page.order,
            query,
        })
            .subscribe({
                next: (bookmarksList) => this.set(bookmarksList),
                error: (error) => this.finishUploadWithError(error),
            });
    }

    public sort(params: ICollectionPageEvent): void {
        this.bookmarkStore.setLoading(true);

        this.apiService.getAll({
            start: params.pageIndex * params.pageSize,
            limit: params.pageSize,
            sort: params.sort,
            order: params.order,
        })
            .subscribe({
                next: (bookmarksList) => this.set(bookmarksList),
                error: (error) => this.finishUploadWithError(error),
            });
    }

    public set(bookmarksList: BookmarksList): void {
        this.bookmarkStore.set(bookmarksList.bookmarks);
        this.bookmarkStore.update({ totalCount: bookmarksList.totalBookmarks });
    }

    public finishUploadWithError(error: unknown): void {
        this.bookmarkStore.setLoading(false);
        this.bookmarkStore.setError(error);
    }
}
