<div
    class="card"
    [ngClass]="{'card_selected': isTitleNumberHighlighted}"
>
    <div class="card-info">
        <div class="info-value info-value--uppercase">{{ titleNumber }}</div>
        <div class="info-value">{{ titleTenure }}</div>
    </div>
    <div class="buttons">
        <button
            class="avl-btn avl-btn--gray button"
            type="button"
            (click)="infoButtonClick.emit()"
            [disabled]="isBasketLoading | async"
        >
            Info
        </button>
        <button
            class="avl-btn avl-btn--blue button"
            type="button"
            [ngClass]="{
                'avl-btn--green': isInBasket,
                'avl-btn--blue': !isInBasket
            }"
            [promiseBtn]="isBasketLoading | async"
            (click)="basketToggle()"
        >
            <span *ngIf="!isInBasket; else tickIcon">
                Add
            </span>
            <ng-template #tickIcon>
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.1213 16.3642C9.7308 15.9737 9.09763 15.9737
                        8.70711 16.3642C8.31658 16.7547 8.31658 17.3879 8.70711 17.7784L12.9497
                        22.021C13.3403 22.4116 13.9734 22.4116 14.364 22.021C14.7545 21.6305 14.7545
                        20.9973 14.364 20.6068L10.1213 16.3642Z"
                        fill="white"
                    />
                    <path
                        d="M24.2634 12.1213C24.6539 11.7308 24.6539 11.0976 24.2634 10.7071C23.8729
                        10.3166 23.2397 10.3166 22.8492 10.7071L12.9497 20.6066C12.5591 20.9971 12.5591 21.6303
                        12.9497 22.0208C13.3402 22.4113 13.9734 22.4113 14.3639 22.0208L24.2634 12.1213Z"
                        fill="white"
                    />
                </svg>
            </ng-template>
        </button>
    </div>
</div>
