<div class="container">
    <div
        *ngIf="isBackgroundCircleVisible"
        class="background-circle"
        [ngStyle]="{
          'width.px': diameter,
          'height.px': diameter,
          'border': strokeWidth + 'px solid ' + secondaryColor
        }"
    ></div>
    <ng-template #circlePart>
        <div
            [ngStyle]="{
                'border': strokeWidth + 'px solid',
                'border-color': primaryColor + ' transparent transparent transparent'
            }"
        ></div>
    </ng-template>
    <div
        class="spinner"
        [ngStyle]="{
            'width.px': diameter,
            'height.px': diameter
        }"
    >
        <template [ngTemplateOutlet]="circlePart"></template>
        <template [ngTemplateOutlet]="circlePart"></template>
        <template [ngTemplateOutlet]="circlePart"></template>
        <template [ngTemplateOutlet]="circlePart"></template>
    </div>
</div>
