<mat-list class="sidenav__content">
    <img
        class="sidenav__head-bg"
        src="/assets/images/avail-lease-sidenav-bg.png"
        alt="avail lease sidenav background"
    >
    <div class="sidenav__buttons">
        <button
            class="sidenav__list-item sidenav__list-btn"
            mat-list-item
            (click)="onCreateNewFolder()"
        >
            <mat-icon
                class="sidenav__button-icon"
                svgIcon="plus-box-without-paddings"
            ></mat-icon>
            <span>New Project</span>
        </button>
        <a
            class="sidenav__list-item position-relative"
            mat-list-item
            routerLink="previous-projects"
            (click)="onClose()"
        >
            <mat-icon
                class="sidenav__button-icon"
                svgIcon="file-chart-rounded"
            ></mat-icon>
            <span>Previous Projects</span>
            <span class="counter-box">{{ (counters | async)?.previousActiveProjects || 0 }}</span>
        </a>
    </div>

    <avl-other-tools
        class="sidenav__apps"
        [excludeTool]="tools.lease"
    ></avl-other-tools>
</mat-list>
