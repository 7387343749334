import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { MapLandRegistryListItemComponent } from './item.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        MapLandRegistryListItemComponent
    ],
    declarations: [
        MapLandRegistryListItemComponent
    ]
})
export class MapLandRegistryListItemModule {}
