<div
    *ngIf="isSidebarOpened"
    class="content-sidebar"
    data-testid="map-search-sidebar"
    @fadeInLeft
>
    <ng-container *ngTemplateOutlet="closeBtn"></ng-container>

    <div
        *ngIf="isLoading"
        class="loading-container"
    >
        <img
            class="details-loading-image"
            src="./assets/images/loading-abstract-animation.gif"
            alt="loading title details"
        >
        <div>
            Loading Title...
        </div>
    </div>

    <avl-map-land-registry-list
        *ngIf="!detailsData && !isLoading"
        [list]="list"
        (showDetails)="showRegistryDetails($event)"
        (itemClicked)="onTitleClicked($event)"
        (itemHovered)="onTitleHovered($event)"
        (itemUnhovered)="onTitleUnhovered()"
    ></avl-map-land-registry-list>

    <avl-map-land-registry-detail
        *ngIf="detailsData && !isLoading"
        [title]="detailsData"
        (backButtonClicked)="onEmitToBack()"
    ></avl-map-land-registry-detail>
</div>

<ng-template #closeBtn>
    <div
        class="content-sidebar-close"
        (click)="closeSidebar()"
    >
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.7071 1.29297L1.29288 10.7072L10.7071 1.29297Z"
                fill="#96969E"
            />
            <path
                d="M1.29288 1.29297L10.7071 10.7072M10.7071 1.29297L1.29288 10.7072"
                stroke="#96969E"
                stroke-linecap="round"
            />
        </svg>
    </div>
</ng-template>
