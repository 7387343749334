<div class="collection__table-container reports-table">
    <div class="collection__table-wrap">
        <table
            class="collection__table"
            mat-table
            matSort
            matSortDisableClear
            [trackBy]="trackReports"
            [dataSource]="reportsSource"
        >
            <!-- Document Column -->
            <ng-container matColumnDef="projectName">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    Project Name
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                >
                    {{ element.projectName || '-' }}
                </td>
            </ng-container>

            <!-- Client/Matter Column -->
            <ng-container matColumnDef="matter">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    Client / Matter
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                >
                    {{ element.matterNumber || '-' }}
                </td>
            </ng-container>

            <!-- Reference Column -->
            <ng-container matColumnDef="createdAt">
                <th
                    mat-header-cell
                    mat-sort-header
                    *matHeaderCellDef
                    [class.sortable]="sort.active === 'createdAt'"
                >
                    Created
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class.sortable]="sort.active === 'createdAt'"
                >
                    {{ (element.createdAt | date:'d MMMM y') || '-' }}
                </td>
            </ng-container>

            <!-- Date Purchased Column -->
            <ng-container matColumnDef="lastOpenedAt">
                <th
                    mat-header-cell
                    mat-sort-header
                    *matHeaderCellDef
                    [class.sortable]="sort.active === 'lastOpenedAt'"
                >
                    Last Updated
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class.sortable]="sort.active === 'lastOpenedAt'"
                >
                    {{ (element.lastOpenedAt | date:'d MMMM y') || '-' }}
                </td>
            </ng-container>

            <!-- Report Column -->
            <ng-container matColumnDef="report">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                ></th>
                <td
                    mat-cell
                    *matCellDef="let row"
                >
                    <button
                        class="reports-table__report-btn avl-btn avl-btn--dark-green"
                        (click)="downloadReport(row.id)"
                    >
                        Report
                    </button>
                </td>
            </ng-container>

            <!-- Open Column -->
            <ng-container matColumnDef="open">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                ></th>
                <td
                    mat-cell
                    *matCellDef="let row"
                >
                    <button
                        class="reports-table__open-btn avl-btn avl-btn--blue"
                        (click)="openProject(row.id, row.lastOpenedAt)"
                    >
                        Open
                    </button>
                </td>
            </ng-container>
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
                mat-row
                [hidden]="loading"
                *matRowDef="let row; columns: displayedColumns;"
            ></tr>
        </table>
        <avl-table-loading-placeholder
            *ngIf="loading"
            [columns]="displayedColumns"
            [size]="tableRowsAmount"
        ></avl-table-loading-placeholder>
    </div>
    <avl-table-no-data-disclaimer
        *ngIf="!loading && !reports.length"
        [iconName]="'file-chart-grey'"
        [message]="'No generated reports'"
    ></avl-table-no-data-disclaimer>
    <mat-paginator
        [length]="pagination.totalCount"
        [class.hidden]="!reports.length"
        [pageSize]="tableRowsAmount"
        [hidePageSize]="true"
    ></mat-paginator>
</div>
