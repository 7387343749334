import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ISchedule, IScheduleAction } from '../../types';

type IScheduleColumns = {
    key: string;
    header: string;
    cell?: (ISchedule) => string;
}

@Component({
    selector: 'avl-schedules-table',
    templateUrl: './schedules-table.component.html',
    styleUrls: ['./schedules-table.component.scss'],
})
export class SchedulesTableComponent {
    public dataSource = new MatTableDataSource<ISchedule>();
    public schedulesColumns: IScheduleColumns[] = [
        { key: 'icon', header: '', cell: (row: ISchedule) => this.getIcon(row.provider) },
        { key: 'type', header: 'Type', cell: (row: ISchedule) => row.type },
        { key: 'provider', header: 'Source', cell: (row: ISchedule) => row.provider },
        {
            key: 'cost', header: 'Cost', cell: (row: ISchedule) => {
                const cost = row.unitCost;
                return (!cost || cost === '£0.00') ? 'Free' : (cost + ' Each');
            },
        },
        { key: 'description', header: 'Description', cell: (row: ISchedule) => row.description },
        { key: 'preview', header: '' },
        { key: 'purchase', header: '' },
        { key: 'errors', header: '' },
    ];

    public displayedColumns: string[] = this.schedulesColumns.reduce((acc, curr) => {
        const key = curr.key;
        if (key !== 'errors') {
            acc.push(key);
        }
        return acc;
    }, []);

    @Input()
    public loading = false;

    public getListSchedules = [];

    @Input()
    public finishedPurchases: { [key: string]: boolean } = {};

    @Output()
    public action = new EventEmitter<{ schedule: ISchedule; action: IScheduleAction }>();

    @Input()
    public set schedules(schedules: ISchedule[]) {
        if (schedules) {
            this.dataSource.data = schedules;
            this.getListSchedules = schedules;
        }
    }

    public onAction(data: { schedule: ISchedule; action: IScheduleAction }): void {
        this.action.emit(data);
    }

    public trackBySources(index: number, schedule: ISchedule): string {
        return schedule.id;
    }

    public trackByColumns(index: number, column: IScheduleColumns): string {
        return column.key;
    }

    public getIcon(provider: string): string {
        switch (provider) {
            case 'Companies House':
                return 'companies-house';
            case 'Ordnance Survey':
                return 'ordnance-survey';
            case 'Local Councils and Others':
                return 'planning';
            case 'UK Government':
                return 'epc';
            case 'Avail AI':
            case 'AI Analysis':
            case 'Generative AI':
                return 'title-register-analysis';
            case 'HM Land Registry':
            default:
                return 'title-register';
        }
    }
}
