import { ID } from '@datorama/akita';
import { Message } from '@core/types';
import { IDataPoints } from '../../notices/types';

export type IDocument = {
    id: ID;
    dataPoints: IDataPoints;
    address: string;
    fileName: string;
    issueDate: Date;
    isError: boolean;
    isAccepted: boolean;
    isProcessed: boolean;
    messages: Message[];
    titleNumber: string;
    ownership: string;
    type: string;
    source: DocumentSource;
}

export enum DocumentSource {
    landRegistry = 'land-registry',
    scotlis = 'scotlis',
    upload = 'upload',
}

export type ImanageParams = {
    authorization: string;
    agentUrl: string;
    agentSecret: string;
}


export type ImanageShowIframe = {
    showIframe: boolean;
    data: ImanageIframeData;
}

export type ImanageIframeData = {
    imanageUrl: string;
    customerId: string;
    dialogToken: string;
    agentUrl: string;
    agentSecret: string;
}
