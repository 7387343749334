import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { iif, Observable, of } from 'rxjs';
import { GeoJson, IMapBounds, mapPlaceIdInfo, PlaceIdInfo, PlaceIdInfoToMap } from '../types';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { CacheService, HttpClientUtilsService } from '@services';

@Injectable()
export class GeoSearchApi {

    constructor(
        private readonly http: HttpClient,
        private readonly placeIdInfoCacheService: CacheService<PlaceIdInfo>,
        private readonly httpUtils: HttpClientUtilsService,
    ) {
    }

    public geoSearch(
        bounds?: IMapBounds,
        options?: {
            isFreeholdOn?: boolean;
            isLeaseholdOn?: boolean;
            includeTitles?: string[];
            excludeTitles?: string[];
            zoom?: number;
        },
    ): Observable<GeoJson> {
        const retryDelay = 200;
        const retryMaxAttempts = 5;

        const { isFreeholdOn, isLeaseholdOn, includeTitles, excludeTitles, zoom } = {
            ...{
                isFreeholdOn: true,
                isLeaseholdOn: true,
                includeTitles: <string[]> [],
                excludeTitles: <string[]> [],
                zoom: 17,
            },
            ...options,
        };
        const tenure = isFreeholdOn && 'freehold' || isLeaseholdOn && 'leasehold';
        const isFilterOn = isFreeholdOn !== isLeaseholdOn;
        const params = isFilterOn
            ? { ...bounds, tenure }
            : { ...bounds };
        let urlAndQueryParams = `/api/mapping/geo-search?zoom=${zoom}`;

        if (bounds) {
            urlAndQueryParams += `&latitude_ne=${params.latitude_ne}`
                + `&latitude_sw=${params.latitude_sw}`
                + `&longitude_ne=${params.longitude_ne}`
                + `&longitude_sw=${params.longitude_sw}`;
        }

        return this.http.post<GeoJson | []>(
            urlAndQueryParams, {
                include: includeTitles.length ? includeTitles : undefined,
                exclude: excludeTitles.length ? excludeTitles : undefined,
            })
            .pipe(
                mergeMap((value) =>
                    iif(
                        () => Array.isArray(value),
                        of({
                            type: 'FeatureCollection',
                            features: [],
                        }),
                        of(value as GeoJson),
                    ),
                ),
                this.httpUtils.repeatIfServerError(retryMaxAttempts, retryDelay),
            );
    }

    public getDetailsByPlaceId(placeId: string, { isCacheEnabled = false }: { isCacheEnabled?: boolean } = {}): Observable<PlaceIdInfo> {
        const url = `/api/mapping/address/place-details/${placeId}`;
        const observable = this.http.get<PlaceIdInfoToMap>(url)
            .pipe(
                map((infoData) => mapPlaceIdInfo(infoData)),
            );

        return isCacheEnabled
            ? this.placeIdInfoCacheService.findOrSet(url, observable.pipe(shareReplay(1)))
            : observable;
    }
}
