export class PromiseRetry<ResultType> {
    public readonly retryRequiredErrors: string[] = [];
    public readonly delay: number = 2000;
    public readonly attempts: number = 3;

    public promise: () => Promise<ResultType>;
    private attemptsSpent = 0;
    private lastError?: unknown;
    private errorEvent?: (error: unknown) => void;


    constructor(
        promise: () => Promise<ResultType>,
        options: {
            delay?: number;
            attempts?: number;
            retryRequiredErrors?: string[];
        } = {},
    ) {
        this.promise = promise;
        this.delay = options.delay || this.delay;
        this.attempts = options.attempts || this.attempts;
        this.retryRequiredErrors = options.retryRequiredErrors || this.retryRequiredErrors;
    }

    public addEventErrorListener(listener: (error: unknown) => void): void {
        this.errorEvent = listener;
    }

    public removeEventErrorListener(): void {
        this.errorEvent = null;
    }

    public async run(): Promise<ResultType> {
        let isRetryRequired: boolean;

        do {
            isRetryRequired = false;

            try {
                return await this.promise();
            } catch (error) {
                const isRetryRequiredError = this.retryRequiredErrors.includes(error.code);

                this.lastError = error;
                this.errorEvent?.(error);

                if (isRetryRequiredError) {
                    isRetryRequired = true;
                    await new Promise((resolve) => setTimeout(resolve, this.delay));
                }
            }

            this.useAttempt();
        } while (isRetryRequired && this.isAttemptExists());

        return Promise.reject(this.lastError);
    }

    private useAttempt(): void {
        this.attemptsSpent++;
    }

    private isAttemptExists(): boolean {
        return this.attemptsSpent <= this.attempts;
    }
}
