import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FileUploader, FileUploaderEventTypes, FileUploaderService, IFileUploaderOptions } from 'app/blocks/file-uploader';
import { INoticeDocument, IUploadState } from 'app/notices/types';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { fileUploaderConfig } from './file-uploader.config';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { REMOVE_NOTICE_CONFIRM_DATA, UNSUPPORTED_FILE_TYPE } from 'app/core/constants';
import { LoggerService } from '@services';
import { NoticeService } from '../../services/notice.service';
import { NoticeDocType } from '../../enums';

@Component({
    selector: 'avl-notice-area',
    templateUrl: './notice-area.component.html',
    styleUrls: ['./notice-area.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoticeAreaComponent implements OnInit, OnChanges {
    @Input()
    public document: INoticeDocument;

    @Input()
    public title: string;

    @Input()
    public type: string;

    @Input()
    public folderId: string;

    @Input()
    public cancelUploadEvent: Observable<void>;

    @Output()
    public fileAdded = new EventEmitter<void>();

    @Output()
    public uploadingStarted = new EventEmitter<{ fileName: string; uploadId: string }>();

    @Output()
    public fileUploaded = new EventEmitter<{ fileId: string; uploadId: string }>();

    @Output()
    public fileRemoved = new EventEmitter<string>();

    @HostBinding('class.uploaded')
    public isFileUploaded = false;

    public fileUploader: FileUploader;
    public fileUploaderOptions: IFileUploaderOptions;
    public isFileOverArea = false;
    public uploadPercentage$ = new BehaviorSubject(0);
    public uploadState$ = new BehaviorSubject<IUploadState>({
        showPercents: false,
        fileName: '',
        isError: false,
        completed: false,
        canceled: false,
    });

    private readonly uploadCompleted$ = new Subject();

    constructor(
        private readonly fileUploaderService: FileUploaderService,
        private readonly dialog: MatDialog,
        private readonly log: LoggerService,
        private readonly noticeService: NoticeService,
    ) {
    }

    public ngOnInit(): void {
        this.fileUploaderOptions = fileUploaderConfig;
        this.fileUploader = this.fileUploaderService.init(this.fileUploaderOptions);

        if (this.folderId) {
            this.fileUploader.updateUploaderUrl(`/api/notice/document/${this.folderId}?doc_type=${this.type}`);
        }

        this.addListeners();

        this.uploadState$
            .pipe(
                tap((state: IUploadState) => {
                    this.isFileUploaded = state.completed || state.showPercents;
                }),
            )
            .subscribe();

        this.cancelUploadEvent?.subscribe(() => {
            this.fileUploader.removeAll();
        });
    }

    public ngOnChanges(): void {
        if (!this.folderId) {
            this.uploadState$.next({
                showPercents: false,
                isError: false,
                fileName: '',
                completed: false,
                canceled: false,
            });

            this.uploadCompleted$.next(false);
        }

        if (this.folderId && this.fileUploader) {
            this.fileUploader.updateUploaderUrl(`/api/notice/document/${this.folderId}?doc_type=${this.type}`);
            this.fileUploader.uploadAll();
        }

        if (this.document) {
            this.uploadState$.next({
                showPercents: !this.document.isProcessed,
                isError: this.document.isError,
                fileName: this.document.fileName,
                completed: this.document.isProcessed,
                canceled: false,
            });

            if (this.document.isProcessed) {
                this.uploadCompleted$.next(true);
                this.uploadPercentage$.next(0);
            }
        }
    }

    public generatePercents(): Subscription {
        const source = timer(100, 40);

        return source.pipe(
            take(100),
            takeUntil(this.uploadCompleted$),
            tap((currentPercent) => {
                this.uploadPercentage$.next(currentPercent);
            }),
        )
            .subscribe();
    }

    public removeDocument(): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent,
            { panelClass: 'confirm-dialog', data: REMOVE_NOTICE_CONFIRM_DATA });

        dialogRef.afterClosed()
            .pipe(
                tap((isRemoved) => {
                    if (isRemoved) {
                        if (this.document && this.document.id) {
                            this.fileRemoved.emit(this.document.id);
                        }

                        this.uploadState$.next({
                            showPercents: false,
                            isError: false,
                            fileName: '',
                            completed: false,
                            canceled: false,
                        });

                        this.uploadCompleted$.next(false);
                        this.fileUploader.removeAll();
                    }
                }),
            )
            .subscribe();
    }

    public getLoadingColors(state: IUploadState, isFileOver: boolean): { primary: string; secondary: string } {
        const isUploading = state.showPercents;
        const isCompleted = state.completed;
        const defaultColors = {
            primary: 'transparent',
            secondary: '#bbb',
        };

        if (isCompleted) {
            return {
                primary: 'transparent',
                secondary: '#5ac39f',
            };
        }

        if (isUploading) {
            return {
                primary: '#ec9c42',
                secondary: '#ec9c424c',
            };
        }

        if (isFileOver) {
            return {
                primary: 'transparent',
                secondary: '#ec9c424c',
            };
        }

        return defaultColors;
    }

    private addListeners(): void {
        this.fileUploader.errors$
            .pipe(
                tap((error) => {
                    const documentName = error.file.fileAsObject.name;
                    const fileType = this.type === NoticeDocType.lease ? 'leases' : 'titles';

                    this.handleUnsupportedType(documentName, fileType);
                    this.log.error(error);
                }),
            )
            .subscribe();

        this.fileUploader.events$
            .pipe(
                map((event) => {
                    if (event.type === FileUploaderEventTypes.fileAdded) {
                        this.folderId ? this.fileUploader.uploadAll() : this.fileAdded.next();
                    }

                    if (event.type === FileUploaderEventTypes.fileUploadingStarted) {
                        const file = event.file;
                        const uploadId = file.uuid;
                        const fileName = file ? file.fileAsObject.name : '';

                        this.uploadingStarted.emit({ uploadId, fileName });
                        this.uploadState$.next({
                            showPercents: true,
                            isError: false,
                            fileName: fileName,
                            completed: false,
                            canceled: false,
                        });
                        this.generatePercents();
                    }

                    if (event.type === FileUploaderEventTypes.fileUploadingSucceeded) {
                        const fileId = event.response?.body?.[0] || '';
                        const uploadId = event.file.uuid;

                        this.uploadPercentage$.next(99);
                        this.fileUploaded.next({ fileId, uploadId });
                    }

                    if (event.type === FileUploaderEventTypes.fileUploadingFailed) {
                        this.uploadState$.next({
                            showPercents: false,
                            isError: true,
                            fileName: event.file ? event.file.fileAsObject.name : '',
                            completed: true,
                            canceled: false,
                        });
                    }
                }),
            )
            .subscribe();
    }

    private handleUnsupportedType(documentName: string, fileType: string): void {
        const error = { ...UNSUPPORTED_FILE_TYPE };
        error.title = error.title.replace('{document_name}', documentName);
        error.message = error.message.replace('{file_type}', fileType);

        this.noticeService.openAlertDialog(error);
    }
}
