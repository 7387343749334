<button
    type="button"
    class="dialog__close"
    mat-dialog-close
>
    <mat-icon svgIcon="close"></mat-icon>
</button>
<div class="alert-dialog">
    <div class="alert-dialog__icon">
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 2L26 26"
                stroke="white"
                stroke-width="4"
                stroke-linecap="round"
            />
            <path
                d="M2 26L26 2"
                stroke="white"
                stroke-width="4"
                stroke-linecap="round"
            />
        </svg>
    </div>
    <ng-container *ngIf="data; else defaultMessage">
        <p class="alert-dialog__title">{{ data.title }}</p>
        <p class="alert-dialog__subtitle">{{ data.message }}</p>
    </ng-container>
    <ng-template #defaultMessage>
        <p class="alert-dialog__title">Oh no!</p>
        <p class="alert-dialog__subtitle">
            You seem to be offline. Please try this again once you’ve reconnected to the internet.
        </p>
    </ng-template>
</div>
