import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'avl-external-redirect',
    templateUrl: './external-redirect.component.html',
    styleUrls: ['./external-redirect.component.scss'],
})
export class ExternalRedirectComponent implements OnInit {

    constructor(
        private readonly route: ActivatedRoute,
    ) {
    }

    public ngOnInit(): void {
        window.location.href = this.route.snapshot.queryParamMap.get('url');
    }
}
