<div
    *ngIf="!failedSend && !succeedSend"
    class="auth-page__form-title"
>
    <h2>Reset your password</h2>
</div>
<form
    *ngIf="!failedSend && !succeedSend"
    class="auth-page__form"
    [formGroup]="loginForm"
    (ngSubmit)="login()"
>
    <p>
        Enter your email address and we will send you instructions on how to create a new password
    </p>
    <div class="form-field">
        <label>Email</label>
        <input
            tabindex="1"
            name="username"
            value=""
            type="text"
            placeholder="example@avail.com"
            autofocus
            autocomplete="off"
            formControlName="email"
            [ngClass]="{'error': emailControl?.touched && emailControl?.invalid}"
        />
        <mat-icon [svgIcon]="emailControl?.touched && emailControl?.invalid ? 'error' : 'envelope'"></mat-icon>
        <mat-error *ngIf="emailControl?.touched && emailControl?.invalid">{{ getEmailErrorMessage() }}</mat-error>
    </div>
    <button
        class="avl-btn avl-btn--blue avl-btn--wide"
        tabindex="2"
        name="reset"
        value="reset"
        type="submit"
        [disabled]="loginForm?.invalid"
    >
        Reset
    </button>
    <div class="form-field row">
        <span>Return to the </span>
        <a
            tabindex="3"
            [routerLink]="['/login']"
        >
            Log in page
        </a>
    </div>
</form>

<avl-error-form
    *ngIf="failedSend"
    [message]="sendError"
    (tryAgain)="onTryAgain()"
></avl-error-form>
<avl-succeed-form
    *ngIf="succeedSend"
    [message]="succeedMessage"
    (resend)="onTryAgain()"
></avl-succeed-form>
