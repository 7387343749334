import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ResourceCheckerService {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public checkDocumentExistence(url: string): Observable<boolean> {
        return this.http.head(url, { observe: 'response' })
            .pipe(
                map((response) => {
                    const isResponseSuccess = response.status >= 200 && response.status < 300;

                    return isResponseSuccess;
                }),
                catchError(() => of(false)),
            );
    }
}
