import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmptyCallback, OnChange } from '@core/types';

@Component({
    selector: 'avl-toggle-control',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ToggleComponent),
        multi: true,
    }],
})
export class ToggleComponent implements ControlValueAccessor {
    @Input()
    public text: string;

    @Input()
    public disabled = false;

    @Input()
    public idName: string;

    public isChecked = true;

    private onChange: OnChange;
    private onBlur: EmptyCallback;

    public writeValue(obj: boolean): void {
        this.isChecked = obj;
    }

    public registerOnChange(fn: OnChange): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: EmptyCallback): void {
        this.onBlur = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onChanged(event: Event): void {
        const input = event?.target as HTMLInputElement;

        this.isChecked = !!input?.checked;
        this.onChange(this.isChecked);
    }
}
