<form
    class="form"
    [class.disable-events]="isFormDisabled"
    [formGroup]="searchForm"
    (ngSubmit)="submit()"
>
    <mat-form-field>
        <mat-select
            panelClass="expanded"
            formControlName="kind"
            [disableOptionCentering]="true"
            (selectionChange)="onKindSelectionChange()"
        >
            <mat-option
                *ngFor="let type of searchTypes"
                [value]="type.value"
            >
                {{ type.label }} <span *ngIf="type.isBeta">&#x1D2E;&#x1D31;&#x1D40;&#x1D2C;</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="search-container">
        <label class="label">
            <input
                #input
                class="input"
                type="text"
                cdkFocusInitial
                [placeholder]="searchHints.get(searchForm.value.kind)"
                formControlName="query"
                [matAutocomplete]="autocomplete"
            >
            <mat-autocomplete
                class="autocomplete"
                #autocomplete="matAutocomplete"
                (optionSelected)="suggestionSelected($event)"
            >
                <div
                    *ngIf="isMapSearchType()"
                    data-testid="search-autocomplete"
                >
                    <div *ngIf="isSuggestionVisible(); else noResultMessage">
                        <mat-optgroup
                            *ngFor="let suggestionGroup of suggestions$ | async"
                            [label]="suggestionGroup.typeName"
                        >
                            <mat-option
                                *ngFor="let suggestion of suggestionGroup.suggestions"
                                [value]="suggestion.value"
                            >
                                <p
                                    avlHighlight
                                    [query]="input.value"
                                    [text]="suggestion.value"
                                    classToApply="selected"
                                >
                                </p>
                            </mat-option>
                        </mat-optgroup>
                    </div>

                    <ng-template #noResultMessage>
                        <mat-option disabled>
                            {{ isSuggestionsLoading() ? 'Loading...' : 'No titles or addresses found'}}
                        </mat-option>
                    </ng-template>
                </div>
            </mat-autocomplete>
        </label>
        <button
            #searchButton
            data-testid="search-button"
            class="avl-btn avl-btn--blue search-button"
            [disabled]="!isSubmitEnabled()"
        >
            <mat-icon svgIcon="icon-search-white"></mat-icon>
        </button>
    </div>
</form>
