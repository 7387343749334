<div class="container">
    <h2 class="auth-page__link-item">We've successfully connected your Avail account to iManage!</h2>
    <br>
    <h2 class="auth-page__link-item">
        This window should disappear in a few seconds, but if it doesn't, just click on
        the magic button below and everything will be set up ready to go.
    </h2>
    <br>
    <br>
    <br>
    <button
        class="avl-btn avl-btn--blue avl-btn--wide margin-top"
        (click)="onClose()"
    >
        Back To Avail
    </button>
</div>
