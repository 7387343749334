import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchedulesService } from '../../../services';
import { IScheduleKey } from '../../../types';

type ISchedulePreviewData = {
    key: IScheduleKey;
    title: string;
}

@Component({
    selector: 'avl-download-schedule-preview-dialog',
    templateUrl: './download-schedule-preview-dialog.component.html',
    styleUrls: ['./download-schedule-preview-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadSchedulePreviewDialogComponent implements OnInit {
    public path: string;
    public title: string;

    constructor(
        private readonly schedulesService: SchedulesService,
        @Inject(MAT_DIALOG_DATA) public data: ISchedulePreviewData,
    ) {
    }

    public ngOnInit(): void {
        this.path = this.getImgPath(this.data.key);
        this.title = this.data.title;
    }

    public getImgPath(key: IScheduleKey): string {
        return this.schedulesService.getSchedulePreviewByKey(key);
    }
}
