import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { finalize, tap } from 'rxjs/operators';
import { LoggerService } from '@services';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';


@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    constructor(private readonly log: LoggerService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.production) {
            return next.handle(req);
        }
        const started = Date.now();
        let ok: string;

        // extend server response observable with logging
        return next.handle(req)
            .pipe(
                tap(
                    // Succeeds when there is a response; ignore other events
                    (event) => ok = event instanceof HttpResponse ? `succeeded: ${event.status}` : '',
                    // Operation failed; error is an HttpErrorResponse
                    (error) => ok = `failed: ${error.status}`,
                ),
                // Log when response observable either completes or errors
                finalize(() => {
                    const elapsed = Date.now() - started;
                    const msg = `LOG:-> ${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
                    this.log.info(msg);
                }),
            );
    }
}
