<button
    class="clear-btn-styles close-button"
    type="button"
    (click)="close()"
>
    <mat-icon
        class="close-icon"
        svgIcon="close-white"
    ></mat-icon>
</button>
<p class="text">{{ text }}</p>
