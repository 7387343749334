<div class="auth-page">
    <div class="auth-page__left-column-authenticate"></div>
    <div class="auth-page__right-column">
        <div class="auth-page__form-wrap">
            <mat-icon svgIcon="logo"></mat-icon>
            <router-outlet></router-outlet>
        </div>
        <div class="auth-page__links">
            <!--
                  <a href="https://www.avail.ai/termsandconditions.html"
                     class="auth-page__link-item"
                     target="_blank">
                     Terms and Conditions
                  </a>
            -->
            <a
                href="https://www.avail.ai/privacy-policy"
                class="auth-page__link-item"
                target="_blank"
            >
                Privacy Policy
            </a>
        </div>
    </div>
</div>
