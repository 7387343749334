import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { App, Logo, Plan } from '@core/types';
import { environment } from '@env/environment';
import { ProfileApi } from '@api';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    public isHmlrEnabled$: BehaviorSubject<boolean>;
    public isRosEnabled$: BehaviorSubject<boolean>;
    public isImanageEnabled$: BehaviorSubject<boolean>;
    public logo$: BehaviorSubject<Logo>;
    public apps$: BehaviorSubject<App[] | null>;
    public plan$: BehaviorSubject<Plan>;

    constructor(
        private readonly profileApi: ProfileApi,
    ) {
        this.initState();
    }

    public clear(): void {
        this.initState();
    }

    public ensureProfile(): Observable<string> {
        return this.profileApi.ensureProfile();
    }

    public shouldShowOnboarding(): Observable<boolean> {
        return this.profileApi.shouldShowOnboarding().pipe(
            map((response) => (response || 'true').trim() === 'true'));
    }

    public onboardingShown(): Observable<string> {
        return this.profileApi.onboardingShown();
    }

    public getLogo(): Observable<Logo> {
        return this.logo$.asObservable();
    }

    public loadConfig(): void {
        this.profileApi.getConfig()
            .pipe(
                tap((config) => {
                    this.isImanageEnabled$.next(environment.forceImanageEnabled || !!config.imanageConfig?.imanageUrl);
                    this.isHmlrEnabled$.next(config.isHmlrEnabled);
                    this.isRosEnabled$.next(environment.isRosEnabled && config.isRosEnabled);
                    this.plan$.next(config.plan);
                    this.apps$.next(config.apps ?? []);

                    if (config.theme?.icon && config.theme?.iconWidth && config.theme?.iconHeight) {
                        this.logo$.next({ ...config.theme, defaultLogo: false });
                    }
                }),
                catchError(() => of(false)),
            )
            .subscribe();
    }

    private initState(): void {
        this.isHmlrEnabled$ = new BehaviorSubject<boolean>(false);
        this.isRosEnabled$ = new BehaviorSubject<boolean>(false);
        this.isImanageEnabled$ = new BehaviorSubject<boolean>(false);
        this.logo$ = new BehaviorSubject<Logo>({ defaultLogo: true });
        this.apps$ = new BehaviorSubject<App[] | null>(null);
        this.plan$ = new BehaviorSubject<Plan>({
            organisation: '',
            plan: '',
            apps: [],
            matterNumber: {
                pattern: '.+',
                errorMessage: 'Matter Number is required',
            },
            projectName: {
                pattern: '.+',
                errorMessage: 'Project Name is required',
            },
        });
    }
}
