import { NEVER, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { routingSchedules } from '@constants';
import { SchedulesService } from '../services';
import { IScheduleTypeInfo } from '../types';
import { LoggerService } from '@services';

@Injectable()
export class SchedulesResolve implements Resolve<any> {

    constructor(
        private readonly router: Router,
        private readonly schedulesService: SchedulesService,
        private readonly log: LoggerService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<IScheduleTypeInfo[]> {
        const id = route.queryParams.fid;

        if (id) {
            return this.schedulesService
                .getSchedulesList(id)
                .pipe(
                    catchError((error: Error) => {
                        this.log.warn(error);
                        this.router.navigate([routingSchedules]);

                        return NEVER;
                    }),
                );
        } else {
            this.router.navigate(['title']);
        }
    }
}
