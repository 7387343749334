import { Injectable } from '@angular/core';
import { BookmarksStore } from './bookmarks.store';
import { ICollectionPageEvent, ICollectionTableUI } from '@core/types';
import { Bookmarks } from '../../types';
import { Observer } from 'rxjs';
import { BookmarksApi } from '../../api/bookmarks.api';

@Injectable()
export class BookmarksService {

    constructor(
        private readonly bookmarksStore: BookmarksStore,
        private readonly api: BookmarksApi,
    ) {
    }

    public load(params: ICollectionPageEvent): void {
        this.bookmarksStore.setLoading(true);
        this.api.getAll({
            start: params.pageIndex,
            limit: params.pageSize,
            sort: params.sort,
            order: params.order,
        })
            .subscribe(this.handleBookmarksResponse());
    }

    public search(params: ICollectionTableUI): void {
        const page = params.page;
        const query = params.search;

        this.bookmarksStore.setLoading(true);
        this.api.getAll({
            start: page.pageIndex,
            limit: page.pageSize,
            sort: page.sort,
            order: page.order,
            query,
        })
            .subscribe(this.handleBookmarksResponse());
    }

    public sort(params: ICollectionPageEvent): void {
        this.bookmarksStore.setLoading(true);
        this.api.getAll({
            start: params.pageIndex * params.pageSize,
            limit: params.pageSize,
            sort: params.sort,
            order: params.order,
        })
            .subscribe(this.handleBookmarksResponse());
    }

    private handleBookmarksResponse(): Observer<Bookmarks> {
        return {
            next: (bookmarks: Bookmarks) => {
                this.bookmarksStore.set(bookmarks.bookmarks);
                this.bookmarksStore.update({ amount: bookmarks.amount });
            },
            error: (error) => this.bookmarksStore.setError(error),
            complete: () => this.bookmarksStore.setLoading(false),
        };
    }
}
