import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';

const TIME_FOR_CLOSE = 3000;

@Component({
    selector: 'avl-done-dialog',
    templateUrl: './done-dialog.component.html',
    styleUrls: ['./done-dialog.component.scss'],
})
export class DoneDialogComponent implements OnInit {
    constructor(
        private readonly dialogRef: MatDialogRef<DoneDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string,
    ) {
    }

    public ngOnInit(): void {
        timer(TIME_FOR_CLOSE).subscribe(() => {
            this.dialogRef.close();
        });
    }
}
