<button
    type="button"
    class="dialog__close"
    mat-dialog-close
>
    <mat-icon svgIcon="close"></mat-icon>
</button>
<div
    class="confirm-dialog__icon"
    [ngClass]="['red', 'remove-icon']"
></div>
<p class="confirm-dialog__title">{{ data.title}}</p>
<p
    *ngIf="data.message"
    class="confirm-dialog__subtitle"
>
    {{ data.message}}
</p>
<div class="confirm-dialog__button-group">
    <button
        class="avl-btn"
        [ngClass]="'avl-btn--blue'"
        [mat-dialog-close]="true"
        type="button"
    >
        {{data.button || 'Reload'}}
    </button>
</div>
