<div
    *ngIf="isLoading"
    class="page-spinner__loading-box"
>
    <div class="page-spinner__loading">
        <avl-spinner [isBackgroundCircleVisible]="true"></avl-spinner>
    </div>
</div>

<embed
    *ngIf="!isLoading"
    [src]="documentUrl"
    type="application/pdf"
    width="100%"
    height="100%"
/>
