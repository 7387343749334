import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FolderQuery } from '../../../store';
import { DataService } from '../../../services';
import { MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from '@services';

@Component({
    selector: 'avl-filed-copies-dialog',
    templateUrl: './filed-copies-dialog.component.html',
    styleUrls: ['./filed-copies-dialog.component.scss'],
})
export class FiledCopiesDialogComponent implements OnInit {
    // displayedColumns: string[] = ['parentTitle', 'description', 'type', 'filedUnder', 'location', 'documentDate', 'add'];
    public displayedColumns: string[] = ['reference', 'type', 'filedUnder', 'location', 'documentDate', 'add'];
    public selection = new SelectionModel(true, []);
    public dataSource;
    public folderId: string;
    public itemsPriceArr = [];
    public allItemsArray;
    public disableClose;
    public totalCost = 0;
    public unitCost = 0;
    public selectedItems = [];
    public costCurrency = '£';
    public itemAdded = false;

    private showAvilable = false;

    constructor(
        private readonly dataService: DataService,
        private readonly folderQuery: FolderQuery,
        private readonly dialogRef: MatDialogRef<FiledCopiesDialogComponent>,
        private readonly logger: LoggerService,
    ) {
    }

    public ngOnInit(): void {
        this.folderId = this.folderQuery.getFolderId();
        this.loadSchedulesList();
    }

    public isAllSelected(): boolean {
        let status = false;

        if (this.dataSource.length > 0) {
            const avlItems = this.dataSource.filter((item) => item.isAvailable === true && item.offerPurchase === true);
            status = avlItems.length === this.selectedItems.length;
        }

        return status;
    }

    public masterToggle(): void {
        const selectStatus = this.isAllSelected();
        selectStatus
            ? this.toggleSelect(true)
            : this.toggleSelect(false);
    }

    public toggleSelect(status: boolean | undefined = true): void {
        if (this.dataSource.length > 0) {
            const avlItems = this.dataSource.filter((item) => item.isAvailable === true && item.offerPurchase === true);
            if (avlItems.length > 0) {
                avlItems.forEach((sitem) => {
                    sitem.isSelected = status;
                    this.addItem(sitem);
                });
            }
        }
    }

    public addItem(event: any): void {
        event.isSelected = !event.isSelected;
        const regex = /^(\D*)(.*)/;
        const costAmount = +regex.exec(event.cost)[2];
        this.costCurrency = regex.exec(event.cost)[1];
        this.isAllSelected();
        if (event.isSelected) {
            this.itemsPriceArr[event.id] = costAmount;
            this.totalCost += costAmount;
        } else {
            this.totalCost -= costAmount;
            this.itemsPriceArr[event.id] = 0;
        }
        if (this.itemsPriceArr.length > 0) {
            this.itemAdded = true;
        }
        // calculate total cost of items
        let allTotalCost = 0;
        for (const key in this.itemsPriceArr) {
            if (key) {
                const value = this.itemsPriceArr[key];
                allTotalCost += value;
            }
        }
        const selectedItemss = this.dataSource.filter((item) => item.isSelected === true);
        let lnkIsEmpty = true;
        if (selectedItemss.length > 0) {
            lnkIsEmpty = false;
        }
        this.dataService.sendSelectedLinkedDocData({
            selectedItems: selectedItemss,
            costCurrency: this.costCurrency,
            costAmount: allTotalCost.toFixed(2),

            isEmpty: lnkIsEmpty,
            unitCost: this.unitCost,
        });
    }

    public onClickShowAvilable(): void {
        this.showAvilable = !this.showAvilable;
        if (this.showAvilable) {
            this.dataSource = this.dataSource.filter((item) => item.isAvailable === true);
        } else {
            this.loadSchedulesList();
        }
    }

    public saveAddFileCopy(): void {
        this.logger.info('saveAddFileCopy in filed-copies-component');
        this.dialogRef.close();
    }

    private loadSchedulesList(): void {
        // this.reportApi.getFiledCopiesDialogInfo('linked-documents', this.folderId).subscribe((res) => {
        this.dataService.linkedDoc.subscribe((res) => {
            this.dataSource = res.selectedItems;
            // get selected items
            this.allItemsArray = [];
            this.dataService.linkedDocSelected.subscribe((item) => {
                this.selectedItems = item.selectedItems;
                this.totalCost = item.costAmount;
                this.unitCost = item.unitCost;
                this.itemAdded = true;
            });
            for (let i = 0; i < this.dataSource.length; i++) {
                // store ids of all items
                this.allItemsArray.push(this.dataSource[i].id);
                this.dataSource[i].customId = i + 1;
                this.dataSource[i].isSelected = false;

                if (this.selectedItems.length > 0) {
                    this.selectedItems.forEach((sitem) => {
                        if (sitem.id === this.dataSource[i].id) {
                            // if (sitem.isAvailable === true) {
                            this.dataSource[i].isSelected = true;
                            this.dataSource[i].cost = this.unitCost;
                            const regex = /^(\D*)(.*)/;
                            const sCost = +regex.exec(sitem.cost)[2];
                            // const sCost = + regex.exec(unitcost)[2];
                            this.itemsPriceArr[sitem.id] = sCost;
                            // }
                        }
                    });
                }
            }
        });
    }
}
