/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

export const isDebugMode = !environment.production || environment.forceLogLevelDebug;

type Noop = (...message: any) => any;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const noop = (message: any): any => { /* ... */
};

/**
 * Adapted from here: https://robferguson.org/blog/2017/09/09/a-simple-logging-service-for-angular-4/
 */
@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    public get info(): Noop {
        if (isDebugMode) {
            return console.info.bind(console);
        }
        return noop;
    }

    public get warn(): Noop {
        if (isDebugMode) {
            return console.warn.bind(console);
        }
        return noop;
    }

    public get error(): Noop {
        if (isDebugMode) {
            return console.error.bind(console);
        }
        return noop;
    }

    public invokeConsoleMethod(type: string, args?: any): void {
        const logFn: () => void = (console)[type] || console.log || noop;
        logFn.apply(console, [args]);
    }
}
