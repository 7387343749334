<div class="toggle-btn">
    <input
        type="checkbox"
        [id]="idName"
        [checked]="isChecked"
        [disabled]="disabled"
        (change)="onChanged($event)"
    >
    <label [for]="idName"></label>
    <div class="toggle-btn-text">
        {{text}}
    </div>
</div>
