/* eslint-disable @typescript-eslint/naming-convention */
export type ImanageParams = {
    authorization?: string;
    agentUrl: string;
    agentSecret: string;
    clientId: string;
    imanageUrl: string;
    redirectUrl: string;
}

export type ImanageParamsToMap = {
    'agent_secret': string;
    'agent_url': string;
    'client_id': string;
    'imanage_url': string;
    'redirect_url': string;
}

export function mapImanageParams(params: ImanageParamsToMap): ImanageParams {
    return {
        agentSecret: params['agent_secret'],
        agentUrl: params['agent_url'],
        clientId: params['client_id'],
        imanageUrl: params['imanage_url'],
        redirectUrl: params['redirect_url'],
    };
}
