import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { MapLandRegistryDetailComponent } from './detail.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [
        MapLandRegistryDetailComponent,
    ],
    declarations: [
        MapLandRegistryDetailComponent,
    ],
})
export class MapLandRegistryModule {
}
