import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { ICollectionPageEvent, IPagination } from '@core/types';
import { MatTableDataSource } from '@angular/material/table';
import { merge, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { Bookmark } from '../../types';

@Component({
    selector: 'avl-folders-table',
    templateUrl: './folders-table.component.html',
    styleUrls: ['./folders-table.component.scss'],
})
export class FoldersTableComponent implements OnChanges, AfterViewInit, OnDestroy {
    public readonly displayedColumns = ['project-name', 'matter-number', 'created-at', 'last-opened-at', 'open'];

    @Input()
    public loading: boolean;

    @Input()
    public bookmarks: Bookmark[];

    @Input()
    public pagination: IPagination;

    @Input()
    public pageSize: number;

    @Output()
    public pageChanged = new EventEmitter<ICollectionPageEvent>();

    @Output()
    public folderOpened = new EventEmitter<string>();

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    public sort: MatSort;

    public source = new MatTableDataSource<Bookmark>([]);
    private updateSubscription$: Subscription;

    public ngOnChanges(): void {
        this.source.data = this.bookmarks;
    }

    public ngAfterViewInit(): void {
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.setupPageChangeListener();
    }

    public ngOnDestroy(): void {
        this.updateSubscription$.unsubscribe();
    }

    public openFolder(bookmark: Bookmark): void {
        this.folderOpened.emit(bookmark.id);
    }

    public resetToFirstPage(): void {
        this.paginator.pageIndex = 0;
        this.paginator.firstPage();
    }

    public trackReports(index: number, item: Bookmark): string {
        return `${item.id}`;
    }

    private setupPageChangeListener(): void {
        this.updateSubscription$ = merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => {
                    this.pageChanged.emit({
                        pageIndex: this.paginator.pageIndex,
                        pageSize: this.paginator.pageSize,
                        sort: this.sort.active,
                        order: this.sort.direction,
                    });
                }),
            )
            .subscribe();
    }
}
