import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'avl-table-loading-placeholder',
    templateUrl: './table-loading-placeholder.component.html',
    styleUrls: ['./table-loading-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableLoadingPlaceholderComponent {

    @Input()
    public columns: string[];

    @Input()
    public columnWidths: string[] = [];

    @Input()
    public size: number;

    @Input()
    public rowHeight: string;

    public arrayRows(): number[] {
        return new Array(this.size);
    }
}
