import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'avl-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

    public documentUrl: SafeUrl;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly sanitizer: DomSanitizer,
    ) { }

    public ngOnInit(): void {
        this.activatedRoute.data.subscribe((data) => {
            this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data['documentUrl']);
        });
    }

}
