<div class="land-registry-dialog__header">
    <button
        type="button"
        class="dialog__close"
        mat-dialog-close
    >
        <mat-icon
            class="close-icon"
            svgIcon="close"
        ></mat-icon>
    </button>

    <ng-container *ngIf="isRosEnabled$ | async; then landRegistrySelector else hmlrTitle"></ng-container>

    <ng-template #landRegistrySelector>
        <mat-select
            class="title-search-registry-select"
            panelClass="search-registry-select"
            [value]="(searchRegistry$ | async).id"
            [disableOptionCentering]="false"
            [disabled]="isLoading$ | async"
            (selectionChange)="onLandRegistryChanged($event.value)"
        >
            <mat-select-trigger>
                <div class="registry-selector__option">
                    <div class="background-white-circle">
                        <mat-icon [svgIcon]="(searchRegistry$ | async).icon"></mat-icon>
                    </div>
                    <div class="registry-selector__text-container land-registry-dialog__title">
                        <p>{{ (searchRegistry$ | async).name }}</p>
                    </div>
                </div>
            </mat-select-trigger>

            <mat-option
                *ngFor="let registry of searchRegistries"
                [value]="registry.id"
            >
                <div class="registry-selector__option">
                    <mat-icon [svgIcon]="registry.icon"></mat-icon>
                    <div class="registry-selector__text-container land-registry-dialog__title">
                        <p>{{ registry.name }}</p>
                    </div>
                </div>
            </mat-option>
        </mat-select>
    </ng-template>

    <ng-template #hmlrTitle>
        <h4 class="land-registry-dialog__title">HM Land Registry</h4>
    </ng-template>
</div>

<avl-land-registry-search
    class="land-registry-search"
    [isLoading]="isLoading$ | async"
    [searchType]="searchType"
    [typeOptions]="(searchRegistry$ | async).searchTypes"
    [searchHints]="landRegistriesSearchHints[(searchRegistry$ | async).id]"
    (searchSubmitted)="onSearchChanged($event)"
    (searchTypeChanged)="onSearchTypeChanged($event)"
></avl-land-registry-search>
<div
    *ngIf="isMapSearch(searchType)"
    @fadeRowAnimation
    class="land-registry-dialog__map-results"
>
    <avl-land-registry-map-search></avl-land-registry-map-search>
</div>

<div
    *ngIf="!this.isMapSearch(searchType) && ((isLoading$ | async) || !isResultsTableHidden)"
    class="land-registry-dialog__results"
    @fadeRowAnimation
>
    <div class="land-registry-dialog__results-container">
        <div class="land-registry-dialog__results-btn-container">
            <button
                matRipple
                class="land-registry-dialog__download-button"
                [disabled]="!(searchResultsUrl$ | async)?.length"
                (click)="downloadSearchResults()"
            >
                <span>Results</span>
                <mat-icon
                    class="land-registry-dialog__download-icon"
                    svgIcon="download"
                ></mat-icon>
            </button>
        </div>
        <div
            *ngIf="errorTitles$ | async as errorTitles"
            class="land-registry-dialog__error-container"
        >
            <avl-multiple-error-alert
                [titles]="errorTitles"
                (documentRemoved)="onTitleErrorRemove($event)"
            ></avl-multiple-error-alert>
        </div>
        <avl-land-registry-results-table
            [titles]="resultTitles$ | async"
            [columns]="resultTableColumns$ | async"
            [isLoading]="isLoading$ | async"
            [selectedTitles]="titlesFromBasket$ | async"
            (selectionChanged)="onSelectedTitles($event)"
        ></avl-land-registry-results-table>
    </div>
</div>

<avl-purchase-titles-button
    @fadeRowAnimation
    *ngIf="isMapSearch(searchType) || (isLoading$ | async) || !isResultsTableHidden"
    [counter]="titlesCount$ | async"
    [price]="totalPrice$ | async"
    [purchaseAction]="purchaseLoading$ | async"
    [purchaseEntity]="getPurchaseEntity()"
    (titlesPurchased)="onTitlesPurchased()"
></avl-purchase-titles-button>
