/* eslint-disable @typescript-eslint/naming-convention */
export type MatchedSubstring = {
    length: number;
    offset: number;
};

export type AddressSearchResult = {
    description: string;
    matchedSubstrings: MatchedSubstring[];
    placeId: string;
};

export type AddressSearchResultToMap = {
    description: string;
    matched_substrings: MatchedSubstring[];
    place_id: string;
};

export function mapAddressSearchResult(result: AddressSearchResultToMap): AddressSearchResult {
    return {
        description: result.description,
        matchedSubstrings: result.matched_substrings,
        placeId: result.place_id,
    };
}
