import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subscription, timer } from 'rxjs';
import { catchError, delayWhen, map, retryWhen, take, takeWhile, tap } from 'rxjs/operators';
import { IFileItem, IFileUploaderOptions } from '../types';
import { FileUploaderHttpRequestManager } from './file-uploader-http-request-manager.service';

const API_POLLING_INTERVAL = 500;
const MAX_UPLOAD_ATTEMPTS = 3;

@Injectable()
export class FileUploaderHttpClient extends FileUploaderHttpRequestManager {

    /**
     * @ignore
     */
    constructor(private readonly httpClient: HttpClient) {
        super();
    }

    /**
     * @param {IFileItem} file
     * @param {IFileUploaderOptions} options  uploader options
     * @param {(file: IFileItem, progress: number) => void} onProgress  callback for progress event
     * @param {(file: IFileItem, response: any) => void} onSuccess  callback for success event
     * @param {(file: IFileItem, error: any) => void} onError  callback for error event
     * @returns {Subscription}
     *
     * send request for file uploading
     */
    public sendRequest(
        file: IFileItem,
        options: IFileUploaderOptions,
        onProgress: (file: IFileItem, progress: number) => void,
        onSuccess: (file: IFileItem, response: any) => void,
        onError: (file: IFileItem, error: any) => void,
    ): Subscription {
        let attempt = 1;
        const isErrorRepeatable = (error: HttpErrorResponse): boolean => error.status === 503 || error.status === 504;

        return this.createHttpRequest(file, options)
            .pipe(
                retryWhen((errors) =>
                    errors.pipe(
                        takeWhile((error: HttpErrorResponse) => {
                            const isRepeatableError = isErrorRepeatable(error);
                            if (!isRepeatableError) {
                                onError(file, error);
                            }

                            return isRepeatableError;
                        }),
                        tap(() => attempt++),
                        delayWhen(() => timer(API_POLLING_INTERVAL)),
                        take(MAX_UPLOAD_ATTEMPTS),
                    ),
                ),
                map((event: any) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            onProgress(file, FileUploaderHttpClient.getProgressValue(event));
                            break;
                        case HttpEventType.Response:
                            onSuccess(file, event);
                            break;
                    }
                }),
                catchError((error) => {
                    if (attempt >= MAX_UPLOAD_ATTEMPTS || (error.ok === false && !isErrorRepeatable(error))) {
                        onError(file, error);
                    }
                    return of(null);
                }),
            )
            .subscribe();
    }

    /**
     * @param {Subscription} request
     * @returns {void}
     *
     * cancels request subscription
     */
    public cancelRequest(request: Subscription): void {
        request.unsubscribe();
    }

    /**
     * @ignore
     */
    private createHttpRequest(file: IFileItem, options: IFileUploaderOptions): Observable<any> {
        const requestBody = FileUploaderHttpClient.getRequestBody(file.fileAsObject, options.uploader.fileAlias);

        return this.httpClient
            .post(
                options.uploader.url,
                requestBody,
                {
                    headers: FileUploaderHttpClient.getHeaders(options.uploader.customHeaders),
                    reportProgress: true,
                    observe: 'events',
                    withCredentials: options.uploader.withCredentials,
                },
            );
    }

    /**
     * @ignore
     */
    private static getHeaders(customHeaders?: { [key: string]: string }): HttpHeaders {
        if (!customHeaders || !Object.keys(customHeaders).length) {
            return new HttpHeaders();
        }

        return new HttpHeaders(customHeaders);
    }
}
