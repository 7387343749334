import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, SubscriptionLike } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'avl-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
    public searchControl: FormControl;

    @Input()
    public placeholder: string;

    @Output()
    public searchChanged = new EventEmitter<string>();

    private searchControlSubscription: SubscriptionLike = Subscription.EMPTY;

    public ngOnInit(): void {
        this.searchControl = new FormControl();
        this.setupFormListeners();
    }

    public ngOnDestroy(): void {
        this.searchControlSubscription.unsubscribe();
    }

    private setupFormListeners(): void {
        this.searchControlSubscription = this.searchControl.valueChanges
            .pipe(debounceTime(500))
            .subscribe((result: string) => this.searchChanged.emit(result));
    }
}
