<div class="report-dialog">
    <img
        src="../../../../../../assets/images/success.svg"
        alt="Lease report generation success"
    >
    <div class="report-dialog__info">
        <h3 class="report-dialog__title">{{ title }}</h3>
        <p class="report-dialog__description">
            {{ messageBeforeLink }}
            <a
                class="report-dialog__link"
                (click)="downloadReport()"
            >{{ linkMessage }}</a>
            {{ messageAfterLink }}
        </p>
    </div>
</div>
