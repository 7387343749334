import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DocumentState, DocumentStore } from './document.store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MinimalDocument } from '../../types/minimal-document.type';

@Injectable()
export class DocumentQuery extends QueryEntity<DocumentState> {

    constructor(protected store: DocumentStore) {
        super(store);
    }

    public get(): Observable<MinimalDocument[]> {
        return this.selectAll();
    }

    public getById(id: string): Observable<MinimalDocument> {
        return this.selectAll().pipe(
            map((documents) =>
                documents.find((document) => document.id === id),
            ),
        );
    }

    public isEmpty(): boolean {
        return this.getCount() === 0;
    }
}
