import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { OnboardingContent } from '../../types/overlay-config.type';


@Component({
    selector: 'avl-character-view',
    templateUrl: './character-view.component.html',
    styleUrls: ['./character-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CharacterViewComponent implements OnInit, OnDestroy {
    @Input()
    public options: OnboardingContent;

    public characterAnimateConfig: any;
    private characterAnimation: any;

    public ngOnInit(): void {
        this.characterAnimateConfig = {
            path: this.options.sourcePath,
            renderer: 'svg',
            autoplay: false,
            loop: false,
        };
    }

    public ngOnDestroy(): void {
        this.characterAnimation.destroy();
    }

    public handleAnimation(animation: any): void {
        this.characterAnimation = animation;
        this.showCharacter();
    }

    public showCharacter(): void {
        this.characterAnimation.setDirection(1);
        this.characterAnimation.play();
    }

    public getPositionClass(): string {
        return this.options.positionX + '-' + this.options.positionY;
    }
}
