<div
    class="other-options"
    [class.other-options__large-paddings]="!isRosEnabled"
>
    <header class="other-options__header">
        <avl-fixed-toggle
            *ngIf="isRosEnabled"
            class="tools-toggle"
            (toggled)="changeSearchRegistry($event)"
            [isChecked]="isToggleChecked()"
        >
            <div
                unchecked-label
                class="tools-toggle__label"
            >
                <mat-icon
                    class="tools-toggle__label-icon"
                    svgIcon="hmlr-b&w"
                ></mat-icon>
                HMLR
            </div>
            <div
                checked-label
                class="tools-toggle__label"
            >
                <mat-icon
                    class="tools-toggle__label-icon"
                    svgIcon="ros-b&w"
                ></mat-icon>
                ROS
            </div>
        </avl-fixed-toggle>
        <h3 class="other-options__title">Other Options</h3>
        <p
            [ngSwitch]="searchRegistry"
            class="other-options__subtitle"
        >
            <span *ngSwitchCase="searchRegistryType.hmlr">
                Don’t have your titles? Either select them on a map, carry out a PN1, or search the Land Registry
            </span>
            <span *ngSwitchCase="searchRegistryType.ros">
                Don’t have your titles? Search them the Land Registry
            </span>
        </p>
    </header>

    <div
        [ngSwitch]="searchRegistry"
        class="tools-container"
    >
        <ng-container *ngSwitchCase="searchRegistryType.hmlr">
            <div
                class="option-card"
                [class.option-card__max-height]="!isRosEnabled"
            >
                <div class="option-card__new-label"></div>

                <div class="option-card__img-container">
                    <img
                        class="option-card__img"
                        src="../../../../assets/icons/icon_map_search.svg"
                        alt="Map search"
                    >
                </div>
                <button
                    class="option-card__button avl-btn"
                    type="button"
                    (click)="openMap()"
                >
                    Find on map
                </button>
            </div>

            <div
                class="option-card"
                [class.other-options__large-paddings]="!isRosEnabled"
            >
                <div class="option-card__img-container">
                    <img
                        class="option-card__img"
                        src="../../../../assets/icons/icon_coat_of_arms.svg"
                        alt="Coat of arms"
                    >
                </div>
                <button
                    class="option-card__button avl-btn"
                    type="button"
                    (click)="openCompanyNameSearch()"
                >
                    Do company PN1 search
                </button>
            </div>

            <div
                class="option-card"
                [class.other-options__large-paddings]="!isRosEnabled"
            >
                <div class="option-card__img-container">
                    <img
                        class="option-card__img"
                        src="../../../../assets/icons/icon_land_registry_colorless.svg"
                        alt="Colorless land registry"
                    >
                </div>
                <button
                    class="option-card__button avl-btn"
                    type="button"
                    (click)="openTitleNumberSearch()"
                >
                    Search land registry
                </button>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="searchRegistryType.ros">
            <div
                *ngIf="isRosEnabled"
                class="option-card"
            >
                <div class="option-card__new-label"></div>

                <div class="option-card__img-container">
                    <img
                        class="option-card__img"
                        src="../../../../assets/icons/icon_ros_logo.svg"
                        alt="ROS icon"
                    >
                </div>
                <button
                    class="option-card__button avl-btn"
                    type="button"
                    (click)="openTitleNumberSearch()"
                >
                    Search ROS
                </button>
            </div>
        </ng-container>
    </div>
</div>
