import { NEVER, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';

import { DocumentsService, FolderService } from '../services';
import { IResponseStatus } from '@core/types';
import { IDocument } from '../types';


@Injectable()
export class TitlesResolve implements Resolve<IResponseStatus<IDocument>> {

    constructor(
        private readonly folderService: FolderService,
        private readonly documentsService: DocumentsService,
        private readonly router: Router,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<IResponseStatus<IDocument>> {
        const id = route.queryParams.fid;
        if (id) {
            return this
                .folderService
                .getCurrentFolder(id)
                .pipe(
                    catchError(() => {
                        this.router.navigate(['title']);

                        return NEVER;
                    }),
                    switchMap(() => this.documentsService.getLoadedDocuments()),
                );
        }
    }
}
