import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { MapSearchState, MapSearchStore } from './map-search.store';
import { Observable } from 'rxjs';
import { mapSearchMaxZoom, mapSearchMaxZoomFeatureVisibility, mapSearchMinZoom } from '@constants';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { GeoJSONFeature, LngLat } from 'maplibre-gl';
import { ShortTitleInfo } from '../../types';
import { PreviousValueComparator } from '../../utils/previous-value-comparator.util';

@Injectable()
export class MapSearchQuery extends Query<MapSearchState> {
    private readonly previousValueComparator = new PreviousValueComparator<
        Pick<MapSearchState, 'selectedFeatures' | 'permanentlyHighlightedTitleNumber'>
    >();

    constructor(
        protected store: MapSearchStore,
    ) {
        super(store);
    }

    public getLocationChanged(): Observable<{ location: LngLat; zoom?: number }> {
        return this.store.getLocationChanged();
    }

    public isZoomApplicable(): boolean {
        return this.getValue().zoom >= mapSearchMaxZoomFeatureVisibility;
    }

    public zoomIsUpdated(): Observable<number> {
        return this.select('zoom');
    }

    public isSidePanelLoading(): Observable<boolean> {
        return this.select('isSidePanelLoading')
            .pipe(
                debounceTime(50),
                distinctUntilChanged(),
            );
    }

    public getUniqueSelectedTitles(): Observable<ShortTitleInfo[]> {
        return this.select('sidePanelTitles')
            .pipe(
                map((selectedTitles) => {
                    const uniqueTitles: ShortTitleInfo[] = [];

                    selectedTitles.forEach((titleInfo) => {
                        const isFeatureAlreadyIncluded = uniqueTitles.some((el) => el.titleNumber === titleInfo.titleNumber);
                        if (!isFeatureAlreadyIncluded) {
                            uniqueTitles.push(titleInfo);
                        }
                    });

                    return uniqueTitles;
                }),
            );
    }

    public getMarkerPosition(): Observable<LngLat | null> {
        return this.select(['markerPosition', 'isMapComponentInitialized'])
            .pipe(
                map(() => this.getValue().markerPosition),
            );
    }

    public highlightingAreUpdated(): Observable<void> {
        return this.select(['temporaryHighlightedTitleNumber', 'permanentlyHighlightedTitleNumber'])
            .pipe(map(() => void 0));
    }

    public sidePanelContentIsUpdated(isDistinctUntilChangeEnabled: boolean | undefined = false): Observable<void> {
        return this.select(['permanentlyHighlightedTitleNumber', 'selectedFeatures'])
            .pipe(
                filter((value) =>
                    !isDistinctUntilChangeEnabled
                        ? true
                        : !this.previousValueComparator.compareAndSaveCurrentValue(value, (prev, curr) => {
                            const isHighlightedTitleNumberChanged = prev.permanentlyHighlightedTitleNumber !== curr.permanentlyHighlightedTitleNumber;
                            const isSelectedFeatureArraysLengthDiff = prev.selectedFeatures.length !== curr.selectedFeatures.length;

                            if (isHighlightedTitleNumberChanged || isSelectedFeatureArraysLengthDiff) {
                                return false;
                            }

                            const polyIdsOfPreviousArray = prev.selectedFeatures.map<string>((el) => el?.properties?.poly_id);
                            const polyIdsOfCurrentArray = curr.selectedFeatures.map<string>((el) => el?.properties?.poly_id);
                            const isSelectedFeaturesChanged = polyIdsOfPreviousArray.some((value, index) => value !== polyIdsOfCurrentArray[index]);

                            return !isSelectedFeaturesChanged;
                        }),
                ),
                map(() => void 0),
            );
    }

    public filtersAreUpdated(): Observable<void> {
        return this.select(['isLeaseholdsOn', 'isFreeholdsOn', 'featuresMap', 'zoom'])
            .pipe(map(() => void 0));
    }

    public isFiltersVisible$(): Observable<boolean> {
        return this.select('isFiltersVisible');
    }

    public featuresUpdated(): Observable<void> {
        return this.select(['featuresMap'])
            .pipe(map(() => void 0));
    }

    public focusedFeaturesUpdated(): Observable<GeoJSONFeature[]> {
        return this.select('focusedFeatures')
            .pipe(
                filter((el) => !!el),
            );
    }

    public getCenter(): LngLat | null {
        return this.store.getValue().center;
    }

    public isTitleNumberPermanentlyHighlighted(titleNumber: string): Observable<boolean> {
        return this.select('permanentlyHighlightedTitleNumber')
            .pipe(
                map((highlightedTitleNumber) => highlightedTitleNumber === titleNumber),
            );
    }

    public isMaxZoomAchieved(): Observable<boolean> {
        return this.select('zoom')
            .pipe(
                map((zoom) => zoom >= mapSearchMaxZoom),
            );
    }

    public isMinZoomAchieved(): Observable<boolean> {
        return this.select('zoom')
            .pipe(
                map((zoom) => zoom <= mapSearchMinZoom),
            );
    }

    public isSelectedMultipleFeatures(): Observable<boolean> {
        return this.select('selectedFeatures')
            .pipe(
                map((selectedTitles) => {
                    const uniqueTitleNumbers = {};

                    selectedTitles.forEach((titleInfo) => {
                        const titleNumber = titleInfo.properties.title_number;
                        uniqueTitleNumbers[titleNumber] = titleInfo;
                    });

                    return Object.keys(uniqueTitleNumbers).length > 1;
                }),
            );
    }
}
