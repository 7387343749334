import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { IGeneratedReport } from 'app/titles/types';

import { takeUntil, tap } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';

import { TableRowsAmountService } from '@services';
import { ICollectionHeightOffset, ICollectionPageEvent, IPagination } from '@core/types';

const reportsTableHeightOffset: ICollectionHeightOffset = {
    header: 64,
    tableHeader: 88,
    paginator: 56,
    footerButton: 52,
    tableHeaderColumns: 44,
};

@Component({
    selector: 'avl-reports-table',
    templateUrl: './reports-table.component.html',
    styleUrls: ['./reports-table.component.scss'],
})
export class ReportsTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input()
    public loading: boolean;

    @Input()
    public reports: IGeneratedReport[];

    @Input()
    public pagination: IPagination;

    @Output()
    public pageChanged = new EventEmitter<ICollectionPageEvent>();

    @Output()
    public projectOpened = new EventEmitter<{ id: string; lastOpenedAt: string }>();

    @Output()
    public reportDownloaded = new EventEmitter<string>();

    @ViewChild(MatSort, { static: true })
    public sort: MatSort;

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    public displayedColumns: string[] = ['projectName', 'matter', 'createdAt', 'lastOpenedAt', 'report', 'open'];
    public reportsSource = new MatTableDataSource<IGeneratedReport>([]);
    public tableRowsAmount: number;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private readonly tableRowsAmountService: TableRowsAmountService,
    ) {
    }

    public ngOnInit(): void {
        this.calculateRowsAmount();
        this.resetToFirstPage();
    }

    public ngOnChanges(): void {
        this.reportsSource.data = this.reports;
    }

    public ngAfterViewInit(): void {
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.setupPageChangeListener();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    public openProject(id: string, lastOpenedAt: string): void {
        this.projectOpened.emit({ id, lastOpenedAt });
    }

    public downloadReport(id: string): void {
        this.reportDownloaded.emit(id);
    }

    public resetToFirstPage(): void {
        this.paginator.pageIndex = 0;
        this.paginator.firstPage();
        this.pageChanged.emit({
            pageIndex: 0,
            pageSize: this.tableRowsAmount,
        });
    }

    public trackReports(index: number, item: IGeneratedReport): string {
        return `${item.id}`;
    }

    private calculateRowsAmount(): void {
        this.tableRowsAmount = this.tableRowsAmountService
            .calculateRowsAmount(reportsTableHeightOffset);
    }

    private setupPageChangeListener(): void {
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                takeUntil(this.destroy$),
                tap(() => {
                    this.pageChanged.emit({
                        pageIndex: this.paginator.pageIndex,
                        pageSize: this.paginator.pageSize,
                        sort: this.sort.active,
                        order: this.sort.direction,
                    });
                }))
            .subscribe();
    }
}
