import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FormValidators {

    public static fieldsMatchValidator(fieldA: string, fieldB: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const fieldAController = control.get(fieldA);
            const fieldBController = control.get(fieldB);

            if (!fieldAController || !fieldBController) {
                return null;
            }

            const fieldAValue = fieldAController.value;
            const fieldBValue = fieldBController.value;

            if (fieldAValue !== fieldBValue) {
                return { fieldsMismatch: true };
            } else {
                return null;
            }
        };
    }

}
