import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { HttpQueryParamsService } from '@services';
import { AuthService } from '@auth/services';
import { MatDialog } from '@angular/material/dialog';


@Injectable()
export class AuthorisationInterceptor implements HttpInterceptor {

    constructor(
        private readonly router: Router,
        private readonly queryParamsService: HttpQueryParamsService,
        private readonly authService: AuthService,
        private readonly matDialog: MatDialog,
    ) {
    }

    public intercept(req: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!environment.authIntercept) {
            return next.handle(req);
        }

        return next.handle(req)
            .pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.httpErrorHandler(error, req);
                    }

                    return throwError(error);
                }),
            );
    }

    private httpErrorHandler(error: HttpErrorResponse, request: HttpRequest<any>): void {
        if (error.status === 401) {
            const requestUrl = request.url;

            this.authService.firebaseAuth.currentUser
                .then(async (user) => {
                    if (user) {
                        if (!request.url.includes('api/imanage/agent/dialog-token')) {
                            await user.getIdToken(true);
                            window.location.reload();
                        }
                    } else {
                        this.matDialog.closeAll();

                        const isIncludeAgentToken = requestUrl.includes('api/imanage/agent/dialog-token');
                        if (!isIncludeAgentToken) {
                            this.authService.showReauthenticateSnackbar();
                        }

                        const queryParams = this.queryParamsService.addRedirectToQueryParams();
                        this.router.navigate(['/login'], { queryParams });
                    }
                });
        } else if (error.status === 403 && error.error.message === 'Access Denied') {
            this.authService.logout()
                .then(() => {
                    localStorage.clear();
                    this.matDialog.closeAll();
                    this.router.navigate(['/login']);
                });
        }
    }
}
