import { Component, Input, ViewEncapsulation } from '@angular/core';
import { INoticeMessage, IUploadState } from 'app/notices/types';

@Component({
    selector: 'avl-notice-progress',
    templateUrl: './notice-progress.component.html',
    styleUrls: ['./notice-progress.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NoticeProgressComponent {
    @Input()
    public state: IUploadState;

    @Input()
    public percent: number;

    @Input()
    public messages: INoticeMessage[];

    public getErrorMessage(messages: INoticeMessage[]): string {
        if (messages?.length && messages[0].message) {
            return messages[0].message;
        } else {
            return 'Upload failed';
        }
    }
}
