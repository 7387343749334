import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NoticeDocumentsState, NoticeDocumentsStore } from './notice-documents.store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NoticeDocType } from '../../enums';
import { INoticeDocument } from '../../types';

@Injectable()
export class NoticeDocumentsQuery extends QueryEntity<NoticeDocumentsState> {
    constructor(protected store: NoticeDocumentsStore) {
        super(store);
    }

    public isLeaseAndTitleProcessed(): Observable<boolean> {
        return this.selectAll()
            .pipe(
                map((documents) => {
                    const uploadedDocuments = documents.filter((document) => document.isProcessed && !document.isError);
                    const documentTypes = uploadedDocuments.map((document) => document.type);
                    const isLeaseUploaded = documentTypes.includes(NoticeDocType.lease);
                    const isTitleRegisterUploaded = documentTypes.includes(NoticeDocType.landlord)
                        || documentTypes.includes(NoticeDocType.tenant);

                    return isLeaseUploaded && isTitleRegisterUploaded;
                }),
            );
    }

    public getMappedByType(): Observable<{ [p: string]: INoticeDocument }> {
        return this.selectAll()
            .pipe(
                map((documents) => {
                    const typeDocsMap = {};

                    documents.forEach((doc) => {
                        const type = doc.clientType ?? doc.type;

                        if (type.toString() !== 'unknown') {
                            typeDocsMap[type] = doc;
                        }
                    });

                    return typeDocsMap;
                }),
            );
    }

    public isEveryProcessed(): boolean {
        const amountOfUnprocessedDocs = this.getCount((document) => !document.isProcessed);

        return amountOfUnprocessedDocs === 0;
    }
}
