import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BookmarkState, BookmarkStore } from './bookmark.store';
import { Observable } from 'rxjs';
import { MinimalBookmark } from '../../types/minimal-bookmark.type';

@Injectable()
export class BookmarkQuery extends QueryEntity<BookmarkState> {

    constructor(protected store: BookmarkStore) {
        super(store);
    }

    public isLoading(): Observable<boolean> {
        return this.selectLoading();
    }

    public get(): Observable<MinimalBookmark[]> {
        return this.selectAll();
    }

    public getTotalCount$(): Observable<number> {
        return this.select('totalCount');
    }
}
