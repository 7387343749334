import { createAction, props } from '@datorama/akita-ng-effects';
import { ITitleInfo } from '../../types';
import { IMapBounds } from '../../types';
import { LngLat } from 'maplibre-gl';

const ACTION_NAME_TAG = '[Search Map]';

export const initUrlParams = createAction(`${ACTION_NAME_TAG} Init url params`);

export const updateMapZoom = createAction(
    `${ACTION_NAME_TAG} Update map zoom`,
    props<{ zoom: number }>(),
);

export const updateMapCenter = createAction(
    `${ACTION_NAME_TAG} Update map center`,
    props<{ center: LngLat }>(),
);

export const updateMapMarkerPosition = createAction(
    `${ACTION_NAME_TAG} Update map marker position`,
    props<{ point?: LngLat }>(),
);

export const updateMapFilter = createAction(
    `${ACTION_NAME_TAG} Update map filter`,
    props<{ isFreeholdsOn: boolean; isLeaseholdsOn: boolean }>(),
);

export const fetchMapGeoJson = createAction(
    `${ACTION_NAME_TAG} Fetch map GeoJSON`,
    props<{ bounds: IMapBounds }>(),
);

export const fetchTitleData = createAction(
    `${ACTION_NAME_TAG} Fetch title details data from API`,
    props<{ titleNumber: string }>(),
);

export const focusFeature = createAction(
    `${ACTION_NAME_TAG} Focus feature by title number`,
    props<{ titleNumber: string }>(),
);

export const restoreStateBeforeFocusing = createAction(`${ACTION_NAME_TAG} Restore state before focusing`);

export const updateTitleDetails = createAction(
    `${ACTION_NAME_TAG} Update selected title details`,
    props<ITitleInfo[]>(),
);

export const resetTitleData = createAction(
    `${ACTION_NAME_TAG} Reset titles details`,
);

export const clearSelectedFeatureList = createAction(`${ACTION_NAME_TAG} Clear list of selected features`);

export const toggleSidePanel = createAction(
    `${ACTION_NAME_TAG} Toggle side panel`,
    props<{ isVisible: boolean }>(),
);

export const highlightFeaturePermanently = createAction(
    `${ACTION_NAME_TAG} Highlight features`,
    props<{ titleNumber: string }>(),
);

export const highlightTitleTemporary = createAction(
    `${ACTION_NAME_TAG} Highlight feature temporary`,
    props<{ titleNumber: string }>(),
);

export const searchByAddress = createAction(
    `${ACTION_NAME_TAG} Search by address`,
    props<{ placeId: string }>(),
);

export const searchByTitleNumber = createAction(
    `${ACTION_NAME_TAG} Search by title number`,
    props<{ location: LngLat; titleNumber: string }>(),
);

export const loadTitleFeatureLocationAndSearchByTitleNumber = createAction(
    `${ACTION_NAME_TAG} Load title feature and search by title number`,
    props<{ titleNumber: string; markerPosition: { lat: number; lng: number } }>(),
);

export const fetchSelectedTitles = createAction(
    `${ACTION_NAME_TAG} Load all related titles by a title number or point`,
    props<{ titleNumber?: string; point?: LngLat }>(),
);

export const fetchOutsideFeatures = createAction(`${ACTION_NAME_TAG} Load all related polygons which are outside bounds`);

export const clearHighlightedFeatures = createAction(`${ACTION_NAME_TAG} Clear highlighted features`);

export const clearState = createAction(`${ACTION_NAME_TAG} Clear state`);

export const setMapComponentInitialized = createAction(`${ACTION_NAME_TAG} Map component is initialized`);
