/* eslint-disable @typescript-eslint/naming-convention */
import { LngLat } from 'maplibre-gl';

export type PlaceIdInfo = {
    formattedAddress: string;
    geometry: {
        location: LngLat;
        viewport: {
            northeast: LngLat;
            southwest: LngLat;
        };
    };
    name: string;
    placeId: string;
}

export type PlaceIdInfoToMap = {
    formatted_address: string;
    geometry: {
        location: LngLat;
        viewport: {
            northeast: LngLat;
            southwest: LngLat;
        };
    };
    name: string;
    place_id: string;
}

export function mapPlaceIdInfo(info: PlaceIdInfoToMap): PlaceIdInfo {
    return {
        formattedAddress: info.formatted_address,
        geometry: info.geometry,
        name: info.name,
        placeId: info.place_id,
    };
}
