import { Injectable } from '@angular/core';
import { DocumentStore } from './document.store';
import { catchError, delay, filter, finalize, first, map, repeat, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, from, Observable, of, Subject, timer } from 'rxjs';
import { FilesUploadedSnackbarComponent } from '@shared/components/files-uploaded-snackbar/files-uploaded-snackbar.component';
import { DocumentApi, FolderApi } from '../../api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentQuery } from './document.query';
import { FolderQuery } from '../folders';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SOMETHING_WENT_GONE_REFRESH_PAGE } from '@constants';
import { IResponseStatus } from '@core/types';
import { convertToMinimalDocument, FileMetadata } from '../../types/file-metadata.type';
import { MinimalDocument } from '../../types/minimal-document.type';
import { Order } from '@datorama/akita';
import { DocumentsSortOptions } from '../../components/documents-table/documents-table.component';
import { NotificationService } from '../../components/notification-center/services/notification.service';


@Injectable()
export class DocumentService {
    public static readonly loadingLastValue = 99;

    private readonly fileMetadataPollingDelayMs = 3000;
    private readonly isPollingActive$ = new Subject<boolean>();
    private readonly filesMetadata$ = new BehaviorSubject<IResponseStatus<FileMetadata>>(null);
    private filesMetadataPoll: Observable<IResponseStatus<FileMetadata>> | null;
    private alertDialog: null | MatDialogRef<AlertDialogComponent>;

    constructor(
        private readonly documentStore: DocumentStore,
        private readonly documentQuery: DocumentQuery,
        private readonly folderQuery: FolderQuery,
        private readonly folderApi: FolderApi,
        private readonly snackBar: MatSnackBar,
        private readonly dialog: MatDialog,
        private readonly apiService: DocumentApi,
        private readonly notificationCenter: NotificationService,
    ) {
    }

    public startUploading(temporaryFileId: string, fileName: string): void {
        this.documentStore.addLoadingDocument(temporaryFileId, fileName);
        this.documentStore.setLoading(true);
        this.startProgressBarsUpdating();
    }

    public cancelUpload(temporaryFileId: string): void {
        this.documentStore.remove(temporaryFileId);
        this.checkIsUploadingFinished();
    }

    public finishUploadWithSuccess(temporaryFileId: string, documentId: string): void {
        const attemptsCount = 15;

        this.documentStore.update(temporaryFileId, { loadingPercentage: DocumentService.loadingLastValue });
        this.filesMetadataPolling()
            .pipe(
                switchMap((folderFileStatus) => of(folderFileStatus.documents.find((doc) => doc.id === documentId))),
                take(attemptsCount),
                filter((newDocument) => !!newDocument && newDocument.isUploadFinished),
                first(),
                finalize(() => {
                    this.documentStore.remove(temporaryFileId);
                    this.checkIsUploadingFinished();
                }),
            )
            .subscribe((document) => {
                const isDocumentUploadedSuccess = document.isAccepted && !document.isError;
                if (isDocumentUploadedSuccess) {
                    this.documentStore.add(convertToMinimalDocument(document), { loading: true });
                } else {
                    this.notificationCenter.highlightBell();
                }
            });
    }

    public finishUploadWithFail(temporaryFileId: string): void {
        this.documentStore.remove(temporaryFileId);
        this.checkIsUploadingFinished();
        this.snackBar.dismiss();
    }

    public delete(documentId: string): void {
        const folderId = this.folderQuery.getId();

        this.documentStore.remove(documentId);
        this.apiService.delete(folderId, documentId).subscribe();
    }

    public resetState(): void {
        this.documentStore.reset();
    }

    public startProgressBarsUpdating(): void {
        this.isPollingActive$.next(true);
        this.startProgressBars();
    }

    public stopProgressBarsUpdating(): void {
        this.isPollingActive$.next(false);
    }

    public showUploadedSnackbar(filesAmount: number): void {
        this.snackBar.openFromComponent(FilesUploadedSnackbarComponent,
            {
                data: {
                    filesAmount,
                    messageForOneFile: '1 lease document',
                    messageForManyFiles: `${filesAmount} lease documents`,
                },
                panelClass: ['files-uploaded-snackbar', 'min-content'],
                duration: 6000,
            },
        );
    }

    public checkIsUploadingFinished(): void {
        const unfinishedFilesAmount = this.documentQuery.getCount((doc) => !doc.isUploadFinished);

        if (unfinishedFilesAmount === 0) {
            const uploadedFilesAmount = this.documentQuery.getCount((doc) => doc.isUploadFinished);

            this.documentStore.setLoading(false);
            this.showUploadedSnackbar(uploadedFilesAmount);
            this.stopProgressBarsUpdating();
        }
    }

    public filesMetadataPolling(): Observable<IResponseStatus<FileMetadata>> {
        this.startFileMetadataPoll();

        return this.filesMetadata$.asObservable()
            .pipe(
                filter((status) => !!status),
            );
    }

    public sort(documents: MinimalDocument[], options: DocumentsSortOptions): MinimalDocument[] {
        const sortBy = options.sortBy;
        const order = options.order;
        let sortFunction: (a: MinimalDocument, b: MinimalDocument) => number;

        switch (sortBy) {
            case 'pages':
                sortFunction = this.sortByPages;
                break;
            case 'uploadTime':
                sortFunction = this.sortByUploadTime;
                break;
            case 'uploadDate':
                sortFunction = this.sortByUploadDate;
                break;
            case 'size':
                sortFunction = this.sortBySize;
                break;
            case 'fileName':
            default:
                sortFunction = (a: MinimalDocument, b: MinimalDocument) => a.fileName.localeCompare(b.fileName);
        }

        documents = documents.sort(sortFunction);

        return order === Order.DESC ? documents.reverse() : documents;
    }

    public sortByUploading(document: MinimalDocument): number {
        return document.isUploadFinished ? -1 : 1;
    }

    private startProgressBars(): void {
        timer(100, 100)
            .pipe(
                takeUntil(this.isPollingActive$),
                switchMap(() => from(this.documentQuery.getAll().filter((doc) => !doc.isUploadFinished))),
                map((document) => {
                    const acceleration = 0.5 - document.loadingPercentage / 250;
                    const loadingPercentage = document.loadingPercentage < DocumentService.loadingLastValue
                        ? document.loadingPercentage + acceleration
                        : DocumentService.loadingLastValue;

                    this.documentStore.update(document.id, { loadingPercentage });
                }),
            )
            .subscribe();
    }

    private startFileMetadataPoll(): void {
        if (!this.filesMetadataPoll) {
            this.filesMetadataPoll = of({})
                .pipe(
                    map(() => this.folderQuery.getId()),
                    filter((folderId) => !!folderId),
                    switchMap((folderId) => this.folderApi.getFileStatus(folderId)),
                    tap((status) => this.filesMetadata$.next(status)),
                    delay(this.fileMetadataPollingDelayMs),
                    repeat(),
                    takeUntil(this.isPollingActive$),
                    catchError(() => {
                        this.openAlertDialog(SOMETHING_WENT_GONE_REFRESH_PAGE);

                        return EMPTY;
                    }),
                    finalize(() => this.filesMetadataPoll = null),
                );
            this.filesMetadataPoll.subscribe();
        }
    }

    private openAlertDialog(errorData?: { title: string; message: string }): void {
        const isDialogOpened = this.alertDialog && !!this.alertDialog.componentInstance;
        if (!isDialogOpened) {
            this.alertDialog = this.dialog.open(AlertDialogComponent, {
                panelClass: 'report-dialog',
                width: '400px',
                data: errorData,
            });
        }
    }

    private sortByUploadTime(a: MinimalDocument, b: MinimalDocument): number {
        const dateA = new Date(a.uploadedAt);
        const hoursA = dateA.getHours();
        const minutesA = dateA.getMinutes();

        const dateB = new Date(b.uploadedAt);
        const hoursB = dateB.getHours();
        const minutesB = dateB.getMinutes();

        return hoursA - hoursB || minutesA - minutesB;
    }

    private sortByUploadDate(a: MinimalDocument, b: MinimalDocument): number {
        const dateA = new Date(a.uploadedAt);
        const dateB = new Date(b.uploadedAt);

        const yearA = dateA.getFullYear();
        const monthA = dateA.getMonth();
        const dayA = dateA.getDate();

        const yearB = dateB.getFullYear();
        const monthB = dateB.getMonth();
        const dayB = dateB.getDate();

        return yearA - yearB || monthA - monthB || dayA - dayB;
    }

    private sortBySize(a: MinimalDocument, b: MinimalDocument): number {
        const sizeA = parseFloat(a.metadata.size);
        const sizeB = parseFloat(b.metadata.size);

        return Math.ceil(sizeA - sizeB);
    }

    private sortByPages(a: MinimalDocument, b: MinimalDocument): number {
        const pageA = Number(a.metadata.pages);
        const pageB = Number(b.metadata.pages);

        return pageA - pageB;
    }
}
