<div
    #container
    data-testid="documents-table"
    class="documents__container"
    [@tableAnimation]=""
>
    <ul class="documents__empty-list">
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
        <li class="documents__empty-row"></li>
    </ul>
    <table
        class="documents__table"
        matSort
        (matSortChange)="sortDocuments($event)"
        [matSortActive]="initialSort === 'type' ? 'document' : convertSortTypeToColumnName(initialSort)"
        [matSortDirection]="convertOrderTypeToOrder(initialSortDirection)"
    >
        <thead>
            <tr
                class="documents__row"
                [ngClass]="{ 'documents__row_dragdrop-off': !isDragDropEnabled }"
            >
                <th
                    *ngIf="isDragDropEnabled"
                    class="documents__col documents__col--head"
                ></th>
                <th class="documents__col documents__col--head">
                    Type
                </th>
                <th
                    class="documents__col documents__col--head"
                    [disabled]="loading"
                    mat-sort-header="document"
                >
                    Document
                </th>
                <th
                    class="documents__col documents__col--head"
                    [disabled]="loading"
                    mat-sort-header="titleNumber"
                >
                    Reference
                </th>
                <th
                    class="documents__col documents__col--head"
                    [disabled]="loading"
                    mat-sort-header="ownership"
                >
                    Tenure
                </th>
                <th
                    class="documents__col documents__col--head"
                    [disabled]="loading"
                    mat-sort-header="issueDate"
                >
                    Date
                </th>
                <th
                    class="documents__col documents__col--head"
                    [disabled]="loading"
                    disableClear
                    mat-sort-header="address"
                >
                    Address
                </th>
            </tr>
        </thead>
        <tbody
            cdkDropList
            (cdkDropListDropped)="changeOrder($event)"
            [cdkDropListDisabled]="!isDocumentsUploadingFinished || !isDragDropEnabled"
        >
            <ng-container *ngFor="let document of documents; trackBy: trackByDocuments">
                <tr
                    *ngIf="!document.isProcessed; else documentLine"
                    class="documents__row documents__row--loading"
                    [ngClass]="{ 'documents__row_dragdrop-off': !isDragDropEnabled }"
                >

                    <td
                        *ngIf="isDragDropEnabled"
                        class="documents__col reorder-col"
                    >
                        <div class="loading-box"></div>
                    </td>
                    <td class="documents__col type">
                        <div class="loading-box"></div>
                    </td>
                    <td class="documents__col document">
                        <div class="loading-box"></div>
                    </td>
                    <td class="documents__col title">
                        <div class="loading-box"></div>
                    </td>
                    <td class="documents__col tenure">
                        <div class="loading-box"></div>
                    </td>
                    <td class="documents__col date">
                        <div class="loading-box"></div>
                    </td>
                    <td class="documents__col address">
                        <div class="loading-box"></div>
                        <div class="loading-box square close"></div>
                    </td>
                </tr>
                <ng-template #documentLine>
                    <tr
                        class="documents__row"
                        [ngClass]="{ 'documents__row_dragdrop-off': !isDragDropEnabled }"
                        cdkDrag
                    >
                        <div
                            *cdkDragPreview
                            class="drag-drop__preview preview"
                            [style]="{'width': getPreviewWidth()}"
                        >
                            <mat-icon [svgIcon]="document.type"></mat-icon>
                            <span>{{ document.type | documentType }}</span>
                            <span>{{ document.titleNumber }}</span>
                            <span>{{ document.ownership }}</span>
                            <span>{{ document.issueDate ? (document.issueDate | date:'d MMMM y') : '' }}</span>
                            <span>{{ document.address }}</span>
                        </div>

                        <td
                            *cdkDragPlaceholder
                            colspan="7"
                            class="drag-drop__placeholder placeholder"
                        ></td>
                        <td
                            *ngIf="isDragDropEnabled"
                            class="documents__col reorder"
                        >
                            <mat-icon
                                cdkDragHandle
                                svgIcon="reorder"
                            ></mat-icon>
                        </td>
                        <td class="documents__col type">
                            <div class="documents__load-link">
                                <mat-icon
                                    class="document-icon"
                                    [svgIcon]="document.type"
                                ></mat-icon>
                                <button
                                    class="clear-btn-styles"
                                    (click)="openNewPageWithDocument(document.id)"
                                >
                                    <mat-icon
                                        class="download-icon"
                                        svgIcon="download"
                                    ></mat-icon>
                                </button>
                            </div>
                        </td>
                        <td class="documents__col document">
                            <span>{{ document.type | documentType }}</span>
                        </td>
                        <td class="documents__col title">
                            <span>{{ document.titleNumber }}</span>
                        </td>
                        <td
                            class="documents__col tenure"
                            [class.capitalize-first-letter]="document.ownership"
                        >
                            <span>{{ document.ownership }}</span>
                        </td>
                        <td class="documents__col date">
                            <span>{{ document.issueDate ? (document.issueDate | date:'d MMMM y') : '' }}</span>
                        </td>
                        <td class="documents__col address">
                            <span>{{ document.address }}</span>
                            <div
                                (click)="removeDocument(document.id, document.source)"
                                class="documents__col-remove"
                            >
                                <mat-icon
                                    class="remove-icon"
                                    svgIcon="close"
                                ></mat-icon>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </ng-container>
        </tbody>
    </table>
</div>
