<div class="report-progress">
    <img
        class="report-progress__image"
        src="../../../../../../assets/images/loading-abstract-animation.gif"
        alt="Lease report generating GIF"
    >
    <div class="report-progress__info">
        <h3 class="report-progress__title">{{ title$ | async }}</h3>
        <div class="report-progress__message-value">
            <p class="report-progress__message">{{ message$ | async }}</p>
            <p>{{ percentage$ | async }}%</p>
        </div>
    </div>
</div>
