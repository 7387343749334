import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorIntlService } from '@services';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: PaginatorIntlService },
    ],
})
export class CoreModule {
}
