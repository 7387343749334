import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ITitleInfo } from 'app/titles/types';
import { Column } from '../../constants';

export type SearchResultsState = EntityState<ITitleInfo> & {
    columns: Column[];
};

@Injectable()
@StoreConfig({ name: 'search-results', idKey: 'titleNumber', resettable: true })
export class SearchResultsStore extends EntityStore<SearchResultsState> {

    constructor() {
        super({
            loading: false,
            columns: [],
        });
    }
}

