import { MapSearchType } from '@enums';

export type MapSearchResult = {
    latitude: number;
    longitude: number;
    searchQuery: string;
    type: MapSearchType;
}

export function convertToMapSearchType(type: string): MapSearchType {
    switch (type) {
        case MapSearchType.titleNumber:
            return MapSearchType.titleNumber;
        case MapSearchType.address:
            return MapSearchType.address;
    }
}
