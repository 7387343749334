<div class="page">
    <img
        class="circuits"
        src="/assets/images/background-bottom-circuits.svg"
        alt="Background circuits"
    >
    <div class="status-code-section">
        <img
            class="logo"
            src="/assets/images/gray-logo.png"
            alt="App logo"
        >
        <div class="status-code__container">
            <div class="status-code">
                <div class="status-code__char">4</div>
                <div class="status-code__char">0</div>
                <div class="status-code__char">3</div>
            </div>
            <img
                class="status-code__girl"
                src="/assets/images/sad-b&w-girl.png"
                alt="Girl from hole"
            >
        </div>
    </div>
    <div class="content-section">
        <div class="title-description">
            <h3 class="title">You have no subscriptions</h3>
            <p class="description">
                It appears you’re not subscribed to any applications at the moment
            </p>
        </div>
        <button
            class="avl-btn avl-btn--dark button"
            (click)="showIntercom()"
        >
            Message us
        </button>
    </div>
</div>
