import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportAction } from '@core/types';


@Component({
    selector: 'avl-download-report-button',
    templateUrl: './download-report-button.component.html',
    styleUrls: ['./download-report-button.component.scss'],
})
export class DownloadReportButtonComponent {
    @Input()
    public value: ReportAction;

    @Input()
    public disableButton: boolean;

    @Input()
    public isCentered = false;

    @Output()
    public reportDownloaded = new EventEmitter<ReportAction>();

    public reportDownload(value: ReportAction): void {
        this.reportDownloaded.emit(value);
    }
}
