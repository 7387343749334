import { IFileUploaderOptions } from 'app/blocks/file-uploader';

export const fileUploaderConfig: IFileUploaderOptions = {
    uploader: {
        url: '',
        fileAlias: 'file',
        autoUpload: false,
    },
    filePicker: {
        allowedMultipleFiles: false,
        allowedMimeClass: ['pdf'],
        allowedFileExtension: ['pdf']
    },
    queue: {
        maxFilesAmount: 1,
        removeAfterUploadComplete: true,
    },
};
