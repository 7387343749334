<div [ngClass]="data.class">
    <button
        type="button"
        class="dialog__close"
        mat-dialog-close
    >
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    <div
        class="confirm-dialog__icon"
        [ngClass]="[data.color, data.icon]"
    ></div>
    <div class="confirm-dialog__content">
        <p class="confirm-dialog__title">{{ data.title }}</p>
        <p
            *ngIf="data.subtitle"
            class="confirm-dialog__subtitle"
        >
            {{ data.subtitle }}
        </p>
    </div>
    <div class="confirm-dialog__button-group">
        <button
            class="avl-btn avl-btn--gray"
            mat-dialog-close
            type="button"
        >
            {{ data.unConfirmButton }}
        </button>
        <button
            class="avl-btn"
            [ngClass]="'avl-btn--' + data.color"
            [mat-dialog-close]="true"
            type="button"
        >
            {{ data.confirmButton }}
        </button>
    </div>
</div>
