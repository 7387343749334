<ng-container *ngIf="!checkLinkedDocEmpty">
    <div
        *ngIf="percent !== 100; else btn"
        class="progress-bar"
    >
        <mat-progress-bar
            mode="determinate"
            [ngClass]="buttonColor"
            [value]="percent"
        ></mat-progress-bar>
        <span class="progres-label">{{ percent }}%</span>
    </div>

    <ng-template #btn>
        <button
            [id]="'progress-button-' + currentSchedule.id"
            [class]="'progress-bar avl-btn avl-btn--' + buttonColor"
            [style.width.px]="buttonWidth"
            [disabled]="isDisabled"
            (click)="onCheckTotalCost()"
        >
            <span
                class="progress-bar__content-wrapper"
                [ngClass]="{'lift-up': isLiftUp, 'lift-down': isLiftDown}"
            >
                <span>{{ buttonText }}</span>
                <mat-icon svgIcon="icon-check"></mat-icon>
            </span>
        </button>
    </ng-template>
</ng-container>

<ng-container *ngIf="!!checkLinkedDocEmpty">
    <button
        [class]="'button-flex-layout progress-bar avl-btn avl-btn--' + buttonColor"
        [style.width.px]="buttonWidth"
        [disabled]="isDisabled"
        (click)="onGetLinkedDocItems()"
    >
        <span class="float-left">
            <ng-container *ngIf="isLoading === true">
                <avl-spinner
                    *ngIf="currentSchedule.key === 'linked-documents' && currentSchedule.items.length === 0"
                    primaryColor="white"
                    diameter="20"
                ></avl-spinner>
            </ng-container>
        </span>
        <span>{{ buttonText }}</span>
    </button>
</ng-container>
