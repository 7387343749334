import { Injectable } from '@angular/core';
import { IFileAsObject, IFileItem, IFileUploaderOptions } from '../types';

@Injectable()
export abstract class FileUploaderHttpRequestManager {

    /**
     * @ignore
     */
    public abstract sendRequest(
        file: IFileItem,
        options: IFileUploaderOptions,
        onProgress: (file: IFileItem, progress: number) => void,
        onSuccess: (file: IFileItem, response: any) => void,
        onError: (file: IFileItem, error: any) => void,
    ): any;

    /**
     * @ignore
     */
    public abstract cancelRequest(request: any): void;

    /**
     * @ignore
     */
    protected static getProgressValue(event: any): number {
        if (!event || !event.total) {
            return 0;
        }

        return Math.round(100 * event.loaded / event.total);
    }

    /**
     * @ignore
     */
    protected static getRequestBody(file: IFileAsObject, alias: string): FormData {
        const form = new FormData();
        form.append(alias, file.sourceFile, file.name);

        return form;
    }
}
