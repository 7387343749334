import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { ICollectionPageEvent, IPagination } from '@core/types';
import { MatTableDataSource } from '@angular/material/table';
import { merge, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { MinimalBookmark } from '../../types/minimal-bookmark.type';

@Component({
    selector: 'avl-folders-table',
    templateUrl: './folders-table.component.html',
    styleUrls: ['./folders-table.component.scss'],
})
export class FoldersTableComponent implements OnChanges, AfterViewInit, OnDestroy {
    public readonly displayedColumns = ['project-name', 'matter-number', 'created-at', 'last-opened-at', 'leases', 'download', 'open'];

    @Input()
    public loading: boolean;

    @Input()
    public bookmarks: MinimalBookmark[];

    @Input()
    public pagination: IPagination;

    @Input()
    public pageSize: number;

    @Output()
    public pageChanged = new EventEmitter<ICollectionPageEvent>();

    @Output()
    public folderOpened = new EventEmitter<string>();

    @Output()
    public reportDownloaded = new EventEmitter<MinimalBookmark>();

    @ViewChild(MatPaginator, { static: true })
    public paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    public sort: MatSort;

    public source = new MatTableDataSource<MinimalBookmark>([]);
    private updateSubscription$: Subscription;

    public ngOnChanges(): void {
        this.source.data = this.bookmarks;
    }

    public ngAfterViewInit(): void {
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        this.setupPageChangeListener();
    }

    public ngOnDestroy(): void {
        this.updateSubscription$.unsubscribe();
    }

    public openFolder(bookmark: MinimalBookmark): void {
        this.folderOpened.emit(bookmark.id);
    }

    public downloadReport(bookmark: MinimalBookmark): void {
        this.reportDownloaded.emit(bookmark);
    }

    public isBookmarkReady(bookmark: MinimalBookmark): boolean {
        return bookmark.reportUri !== null;
    }

    public getTooltipMessage(bookmark: MinimalBookmark): string {
        if (this.isBookmarkReady(bookmark)) {
            return '';
        }

        const isPendingState = bookmark.state.toLowerCase() === 'pending';
        if (isPendingState) {
            return 'Your reports are still generating! Check your email or come back soon to download them.';
        }

        return 'You haven’t generated any reports for this project yet!';
    }

    public getDownloadButtonLabel(bookmark: MinimalBookmark): string {
        const defaultButtonLabel = 'Report';

        return this.isBookmarkReady(bookmark)
            ? defaultButtonLabel
            : bookmark.state || defaultButtonLabel;
    }

    public resetToFirstPage(): void {
        this.paginator.pageIndex = 0;
        this.paginator.firstPage();
    }

    public trackReports(index: number, item: MinimalBookmark): string {
        return `${item.id}`;
    }

    private setupPageChangeListener(): void {
        this.updateSubscription$ = merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => {
                    this.pageChanged.emit({
                        pageIndex: this.paginator.pageIndex,
                        pageSize: this.paginator.pageSize,
                        sort: this.sort.active,
                        order: this.sort.direction,
                    });
                }),
            )
            .subscribe();
    }
}
