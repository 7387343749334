import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { INotificationCounter } from 'app/titles/types';

export type NotifyCountersState = {
    purchased: INotificationCounter;
    bookmarks: INotificationCounter;
}

@Injectable()
@StoreConfig({ name: 'notify-counters' })
export class NotifyCountersStore extends Store<NotifyCountersState> {
    constructor() {
        super({});
    }
}

