import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'avl-succeed-form',
    templateUrl: './succeed-form.component.html',
    styleUrls: ['./succeed-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SucceedFormComponent {
    @Input()
    public formTitle = 'Check your email';

    @Input()
    public message = '';

    @Input()
    public isResendButton = true;

    @Output()
    public resend = new EventEmitter<void>();

    public onResend(): void {
        this.resend.emit();
    }
}
