<div
    class="drop-area__wrap"
    avlElevate
    name="drop-area"
>
    <div
        avlFileDrop
        class="drop-area"
        [class.file-over]="isFileOverArea"
        (fileOver)="isFileOverArea = $event"
        [uploader]="fileUploader"
    >

        <div class="drop-area__animated-content">
            <div
                class="drop-area__default animated"
                [ngClass]="isLoading ? 'fade-out-up' : 'fade-in-up'"
            >
                <mat-icon
                    class="drop-area__icon"
                    svgIcon="drop"
                ></mat-icon>
                <h5 class="drop-area__title">
                    <ng-content select="[title]"></ng-content>
                </h5>
                <p class="drop-area__description">
                    <ng-content select="[description]"></ng-content>
                </p>
                <div class="drop-area__actions">
                    <label
                        avlPulseButton
                        data-testid="file-upload-button"
                        class="drop-area__btn avl-btn"
                    >
                        <input
                            avlFilePicker
                            class="drop-area__upload-input"
                            type="file"
                            accept="application/pdf"
                            [uploader]="fileUploader"
                        >
                        <span class="drop-area__upload-text">
                            <ng-content select="[upload-label]"></ng-content>
                        </span>
                    </label>
                </div>
            </div>
            <div
                class="drop-area__in-progress animated"
                [ngClass]="[isLoading ? 'fade-in-up' : 'fade-out-up']"
            >
                <div class="drop-area__loading-box">
                    <mat-icon
                        class="drop-area__loading-icon"
                        svgIcon="arrow-progress"
                    ></mat-icon>
                    <avl-spinner [isBackgroundCircleVisible]="true"></avl-spinner>
                </div>
                <h5 class="drop-area__title">
                    <span [ngPlural]="getFilesAmount$() | async">
                        <ng-template ngPluralCase="=1">Uploading 1 file...</ng-template>
                        <ng-template ngPluralCase="other">
                          Uploading {{ getFilesAmount$() | async }} files...
                        </ng-template>
                    </span>
                </h5>
            </div>
        </div>
    </div>
</div>
