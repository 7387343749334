import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '@auth/services';
import { LoggerService } from '@services';

import { User } from '@auth/types/user.type';
import { regexes } from '@constants';

@Component({
    selector: 'avl-reset-password-req',
    templateUrl: './reset-password-request.component.html',
    styleUrls: ['./reset-password-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ResetPasswordRequestComponent implements OnInit {
    public loginForm: FormGroup;
    public sendError: string;
    public failedSend = false;
    public succeedSend = false;
    public succeedMessage = 'A link has been sent to your email address to allow you to choose another password. Please follow this to reset your login details.';

    constructor(
        private readonly authService: AuthService,
        private readonly formBuilder: FormBuilder,
        private readonly log: LoggerService,
    ) {
    }

    public get emailControl(): AbstractControl {
        return this.loginForm.get('email');
    }

    public ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.pattern(regexes.email)]],
        });
    }

    public async login(): Promise<void> {
        this.failedSend = false;
        this.succeedSend = false;
        this.sendError = null;
        if (this.loginForm.invalid) {
            this.failedSend = true;
            return;
        }
        const user = this.loginForm.value as User;
        this.authService.sendResetPasswordEmail(user.email)
            .then((value) => {
                this.log.info('Nice, it worked!', value);
                this.succeedSend = true;
            })
            .catch((err) => {
                this.log.info('Something went wrong:', err.message);
                this.failedSend = true;
                this.sendError = err.message;
            });
    }

    public getEmailErrorMessage(): string {
        return this.emailControl.hasError('required')
            ? 'An email is required'
            : this.emailControl.hasError('pattern')
                ? 'Not a valid email'
                : '';
    }

    public onTryAgain(): void {
        this.succeedSend = false;
        this.failedSend = false;
        this.sendError = null;
    }
}
