import { Component, Inject, Renderer2 } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export type FilesUploadedSnackbarData = {
    filesAmount: number;
    messageForOneFile: string;
    messageForManyFiles: string;
}

@Component({
    selector: 'avl-files-uploaded-snackbar',
    templateUrl: './files-uploaded-snackbar.component.html',
    styleUrls: ['./files-uploaded-snackbar.component.scss'],
})
export class FilesUploadedSnackbarComponent {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        public data: FilesUploadedSnackbarData,
        protected snackRef: MatSnackBarRef<FilesUploadedSnackbarComponent>,
        protected renderer: Renderer2,
    ) {
        renderer.listen('document', 'click', () => this.snackRef.dismiss());
    }
}
