<div
    class="info-form__wrap"
    [ngClass]="{'with-margin': !isTryAgainButtonVisible}"
>
    <mat-icon svgIcon="error"></mat-icon>
    <h2 class="error">Oops!</h2>
    <h3 [innerHTML]="message"></h3>
    <button
        *ngIf="isTryAgainButtonVisible"
        class="error"
        (click)="onTryAgain()"
    >
        {{ buttonText }}
    </button>
</div>
