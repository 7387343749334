import { Injectable } from '@angular/core';
import { CompileStore } from './compile.store';
import { ReportLoadingProgressComponent } from '../../components/report-generation/dialogs/report-loading-progress/report-loading-progress.component';
import { BehaviorSubject, NEVER, Observable, Subject, timer } from 'rxjs';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { CompilingStatus } from '../../types/compiling-status.type';
import { catchError, filter, finalize, map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { CompileApi } from '../../api';
import { FolderQuery, FolderService } from '../folders';
import { CompileQuery } from './compile.query';
import { InformationSnackbarComponent } from '../../components/report-generation/information-snackbar/information-snackbar.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { FileService } from '@services';
import { ReportGenerationSuccessComponent } from '../../components/report-generation/dialogs/report-generation-success/report-generation-success.component';
import {
    ReportGenerationPartialSuccessComponent,
} from '../../components/report-generation/dialogs/report-generation-partial-success/report-generation-partial-success.component';
import { ReportGenerationFailedComponent } from '../../components/report-generation/dialogs/report-generation-failed/report-generation-failed.component';
import { FILE_NOT_FOUND, SOMETHING_GONE_WRONG } from '@constants';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';

@Injectable()
export class CompileService {
    public readonly folderIdPlaceholder = '{folder_id}';
    private readonly pollingInterValMs = 3000;
    private readonly progressDialogCompletedAndVisibleMs = 1200;
    private readonly linkPlaceholderKey = '$report';

    private readonly progressTitle$ = new BehaviorSubject<string>('Loading...');
    private readonly progressMessages$ = new BehaviorSubject<string>('');
    private readonly progressPercent$ = new BehaviorSubject<number>(0);
    private readonly progressAcceleratorUnsubscribe$ = new Subject<void>();

    private loadingDialog?: MatDialogRef<ReportLoadingProgressComponent>;
    private emailSnackbar?: MatSnackBarRef<InformationSnackbarComponent>;

    constructor(
        private readonly compileStore: CompileStore,
        private readonly apiService: CompileApi,
        private readonly dialog: MatDialog,
        private readonly folderQuery: FolderQuery,
        private readonly fileService: FileService,
        private readonly compileQuery: CompileQuery,
        private readonly compileApi: CompileApi,
        private readonly folderService: FolderService,
        private readonly snackBar: MatSnackBar,
    ) {
    }

    public start(folderId: string): void {
        const isDetailsExist = this.folderQuery.isDetailsExist();

        if (isDetailsExist) {
            this.startCompile(folderId);
        } else {
            this.getFolderDetailsAndStartCompile(folderId);
        }
    }

    public checkFinish(): void {
        const isCompilationInProgress = this.compileStore.isCompilationInProgress();
        const folderId = this.folderQuery.getId();

        if (isCompilationInProgress) {
            this.startCompile(folderId);
        }
    }

    public downloadReport(folderId: string, reportLink: string): void {
        reportLink = reportLink.replace(this.folderIdPlaceholder, folderId);

        this.apiService.loadReport(reportLink)
            .pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 404) {
                            this.somethingWentWrong(FILE_NOT_FOUND.title, FILE_NOT_FOUND.message);
                            return NEVER;
                        }
                    }

                    this.somethingWentWrong();

                    return NEVER;
                }),
            )
            .subscribe((response) => this.fileService.download(response));
    }

    private finishWithSuccess(title: string, message: string, linkMessage: string, linkToReport: string, linkPlaceholder: string): void {
        this.completeLoadingDialog()
            .subscribe(() => {
                const folderId = this.folderQuery.getId();
                const dialog = this.dialog.open(ReportGenerationSuccessComponent, {
                    panelClass: 'report-dialog',
                    width: '400px',
                    data: {
                        title,
                        message,
                        linkMessage,
                        linkToReport,
                        linkPlaceholder,
                    },
                });

                dialog.afterClosed().subscribe((reportLink) => {
                    if (!!reportLink) {
                        this.downloadReport(folderId, reportLink);
                    }
                });

                this.downloadReport(folderId, linkToReport);
            });
    }

    private finishWithPartialSuccess(
        title: string,
        firstMessage: string,
        secondMessage: string,
        linkMessage: string,
        linkToReport: string,
        linkPlaceholder: string,
    ): void {
        this.completeLoadingDialog()
            .subscribe(() => {
                const folderId = this.folderQuery.getId();
                const dialog = this.dialog.open(ReportGenerationPartialSuccessComponent, {
                    panelClass: 'report-dialog',
                    width: '586px',
                    data: {
                        title,
                        firstMessage,
                        secondMessage,
                        linkMessage,
                        linkToReport,
                        linkPlaceholder,
                    },
                });

                dialog.afterClosed().subscribe((reportLink) => {
                    if (!!reportLink) {
                        this.downloadReport(folderId, reportLink);
                    }
                });

                this.downloadReport(folderId, linkToReport);
            });
    }

    private finishWithFail(title: string, message: string): void {
        this.completeLoadingDialog()
            .subscribe(() => {
                this.dialog.open(ReportGenerationFailedComponent, {
                    panelClass: 'report-dialog',
                    width: '400px',
                    data: {
                        title,
                        message,
                    },
                });
            });
    }

    private startCompile(folderId: string): void {
        this.compileStore.setLoading(true);
        this.compileApi.startCompile(folderId)
            .pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.httpErrorHandler(error);
                    } else {
                        this.somethingWentWrong();
                    }

                    return NEVER;
                }),
            )
            .subscribe((processId) => {
                this.compileStore.update({ processId, isEmailWasShown: false });
                this.getCompilingStatusPolling(folderId)
                    .pipe(
                        finalize(() => this.compileStore.setLoading(false)),
                    )
                    .subscribe((status) => this.finishCompiling(status));
            });
    }

    private getFolderDetailsAndStartCompile(folderId: string): void {
        this.folderService.getDetails()
            .subscribe((folderDetails) => {
                this.folderService.update(folderId, folderDetails);
                this.startCompile(folderId);
            });
    }

    private showLoadingDialog(): void {
        const title = this.progressTitle$.asObservable();
        const message = this.progressMessages$.asObservable();
        const percentage = this.progressPercent$.asObservable();

        this.loadingDialog = this.dialog.open(ReportLoadingProgressComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            disableClose: true,
            data: { title, message, percentage },
        });
    }

    private completeLoadingDialog(): Observable<void> {
        return new Observable((subscriber) => {
            const isDialogClosed = this.loadingDialog?.getState() !== MatDialogState.OPEN;

            if (isDialogClosed) {
                subscriber.next();
                subscriber.complete();
            } else {
                this.loadingDialog?.componentInstance?.complete();

                timer(this.progressDialogCompletedAndVisibleMs)
                    .pipe(
                        tap(() => this.loadingDialog?.close()),
                        tap(() => this.emailSnackbar?.dismiss()),
                    )
                    .subscribe(() => {
                        subscriber.next();
                        subscriber.complete();
                    });
            }
        });
    }

    private getCompilingStatusPolling(folderId: string): Observable<CompilingStatus> {
        return timer(0, this.pollingInterValMs)
            .pipe(
                map(() => this.compileStore.getValue().processId),
                switchMap((processId) => this.compileApi.getStatus(folderId, processId)),
                tap((status) => this.compileStore.update({ ...status })),
                tap((status) => this.updateLoadingDialog(status)),
                tap((status) => this.handleEmailToast(status.isEmailToastShouldBeShown && !status.isFinished)),
                filter((status) => status.isFinished),
                take(1),
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        this.httpErrorHandler(error);
                    } else {
                        this.somethingWentWrong();
                    }

                    return NEVER;
                }),
            );
    }

    private updateLoadingDialog(status: CompilingStatus): void {
        if (!status.isFinished) {
            const isDialogClosed = this.loadingDialog?.getState() !== MatDialogState.OPEN;
            if (isDialogClosed) {
                this.progressPercent$.next(0);
                this.showLoadingDialog();
            }

            this.progressTitle$.next(status.title);
            this.progressMessages$.next(status.message[0][0]);
            this.startCounterAccelerator(this.progressPercent$.getValue(), status.progress, this.pollingInterValMs);
        }
    }

    private startCounterAccelerator(start: number, goal: number, time: number): void {
        if (start >= goal) {
            return;
        }

        this.progressAcceleratorUnsubscribe$.next();

        const distance = goal - start;
        const speed = time / distance;

        timer(0, speed)
            .pipe(
                map((value) => value + start),
                takeWhile((value) => value <= goal),
                takeUntil(this.progressAcceleratorUnsubscribe$),
            )
            .subscribe((value) => this.progressPercent$.next(value));
    }

    private handleEmailToast(isEmailToastShouldBeShown: boolean): void {
        const isEmailWasShown = this.compileQuery.isEmailWasShown();

        if (isEmailToastShouldBeShown && !isEmailWasShown) {
            this.showEmailSnackbar();
            this.compileStore.update({ isEmailWasShown: true });
        }
    }

    private finishCompiling(status: CompilingStatus): void {
        if (!status.isFinished) {
            return;
        }

        const title = status.title;
        const linkToReport = status.linkToReport;
        const linkMessage = status.linkMessage;
        const firstMessage = status.message[0][0];
        const secondMessage = status.message[0][1];
        const linkPlaceholder = this.linkPlaceholderKey;

        if (status.isSuccess) {
            this.finishWithSuccess(
                title,
                firstMessage,
                linkMessage,
                linkToReport,
                linkPlaceholder,
            );
        } else if (status.isPartialSuccess) {
            this.finishWithPartialSuccess(
                title,
                firstMessage,
                secondMessage,
                linkMessage,
                linkToReport,
                linkPlaceholder,
            );
        } else {
            this.finishWithFail(title, firstMessage);
        }
    }

    private somethingWentWrong(title?: string, message?: string): void {
        const defaultTitle = SOMETHING_GONE_WRONG.title;
        const defaultMessage = SOMETHING_GONE_WRONG.message;

        this.dialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: {
                title: title || defaultTitle,
                message: message || defaultMessage,
            },
        });
    }

    private httpErrorHandler(httpError: HttpErrorResponse): void {
        const errorTitle = httpError.error.error;
        const errorMessage = httpError.error.message;

        this.somethingWentWrong(errorTitle, errorMessage);
    }

    private showEmailSnackbar(): void {
        this.emailSnackbar = this.snackBar.openFromComponent(InformationSnackbarComponent, {
            data: 'Our AI is analysing a lot of leases right now, so we’ll need a bit more time than '
                + 'normal. We’ll send you an email when it’s ready to download! You can close your browser '
                + 'if you want, or keep it open if you’re happy to wait 🥳',
            panelClass: ['snackbar-view'],
        });
    }
}
