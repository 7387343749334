<div class="notice-generation__steps">
    <div
        *ngFor="let item of reportSteps"
        #step
        class="notice-generation__step-item"
    >
        <div class="notice-generation__step-icon">
            <svg
                class="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
            >
                <circle
                    cx="16"
                    cy="16"
                    r="15"
                    stroke="#cccccc"
                    fill="none"
                />
                <circle
                    class="checkmark__circle"
                    cx="16"
                    cy="16"
                    r="15"
                    fill="none"
                />
                <path
                    class="checkmark__check"
                    fill="none"
                    d="M8.67693 16.7385L13.0462 21.1692L23.3231 10.8308"
                />
            </svg>
        </div>
        <div class="notice-generation__step-title">{{ item }}</div>
    </div>
</div>

<a
    #reportLink
    download
    class="hide"
></a>
