import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
    selector: '[avlHighlight]',
})
export class HighlightDirective implements OnChanges {
    @Input()
    public query = '';

    @Input()
    public text = '';

    @Input()
    public classToApply = '';

    constructor(
        private readonly elementRef: ElementRef,
        private readonly renderer: Renderer2,
    ) {
    }

    public ngOnChanges(): void {
        const searchedWords = this.query.trim().split(' ');
        const isSelectionAvailable = searchedWords.length && this.classToApply;

        const text = isSelectionAvailable ? this.getFormattedText(searchedWords) : this.text;
        this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', text);
    }

    private getFormattedText(searchedWords: string[]): string {
        const regExp = new RegExp(`(${ searchedWords.join('|') })`, 'gi');

        return this.text.replace(regExp, `<span class="${this.classToApply}">$1</span>`);
    }
}
