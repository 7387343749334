import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription, SubscriptionLike, timer } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { ISchedule, IScheduleAction } from '../../../types';
import { SchedulesService } from '../../../services';
import { LoggerService } from '@services';
import { MatDialog } from '@angular/material/dialog';
import { PURCHASE_A_LOT_OF_TITLES } from '@constants';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { schedulesCostThresholdForWarning } from '../../../constants';

type IButtonColor = 'blue' | 'dark-green' | 'yellow' | 'mid-gray';

@Component({
    selector: 'avl-schedule-purchases-button',
    templateUrl: './schedule-purchases-button.component.html',
    styleUrls: ['./schedule-purchases-button.component.scss'],
})
export class SchedulePurchasesButtonComponent implements OnDestroy, OnInit {
    @Output()
    public action = new EventEmitter<{ schedule: ISchedule; action: IScheduleAction }>();

    public buttonText = '';
    public buttonWidth = 207;
    public buttonColor: IButtonColor = 'blue';
    public isDisabled = false;
    public percent = 100;
    public isLiftUp = false;
    public isLiftDown = false;
    public currentSchedule: ISchedule;
    public checkLinkedDocEmpty = false;
    public isLoading = false;

    private readonly scheduleUpdated$ = new BehaviorSubject<boolean>(false);
    private isEmpty = false;
    private isAnimationShowing = false;
    private isRequestError = false;
    private timerSubscription: SubscriptionLike = Subscription.EMPTY;

    constructor(
        private readonly schedulesService: SchedulesService,
        private readonly logger: LoggerService,
        private readonly dialog: MatDialog,
    ) {
    }

    @Input()
    public set schedule(schedule: ISchedule) {
        if (schedule) {
            this.currentSchedule = schedule;
            this.isEmpty = schedule.isEmpty;
            this.isAnimationShowing ? this.scheduleUpdated$.next(true) : this.checkButtonState();
        }
    }

    public ngOnInit(): void {
        this.schedulesService.linkedDocumentsAnalysisStarted$()
            .subscribe(() => {
                const isCurrentButtonLinkedDocsAnalysis = this.currentSchedule.id === 'linked-documents-analysis';
                if (isCurrentButtonLinkedDocsAnalysis && !this.isDisabled) {
                    this.onCheckTotalCost();

                    const buttonElement = document.getElementById('progress-button-linked-documents-analysis');
                    buttonElement.scrollIntoView({ behavior: 'smooth' });
                }
            });
    }

    public ngOnDestroy(): void {
        this.timerSubscription.unsubscribe();
    }

    public onCheckTotalCost(): void {
        const totalCostString = this.currentSchedule && this.currentSchedule.totalCost;
        const totalCostNumber = totalCostString && parseFloat(totalCostString.slice(1));
        const isTotalCostTooMuch = !Number.isNaN(totalCostNumber) && totalCostNumber >= schedulesCostThresholdForWarning;

        if (isTotalCostTooMuch) {
            const data = {
                ...PURCHASE_A_LOT_OF_TITLES,
                subtitle: `You will incur a cost of ${totalCostString} on your client/matter file.`,
            };
            this.dialog.open(ConfirmDialogComponent,
                { panelClass: 'confirm-dialog', data },
            )
                .afterClosed()
                .subscribe((isConfirm: boolean) => {
                    if (!isConfirm) {
                        return;
                    }
                    this.startPurchase();
                });
        } else {
            this.startPurchase();
        }
    }

    // Function to get the linked documents items
    public onGetLinkedDocItems(): void {
        this.isRequestError = false;
        // this.isAnimationShowing = true;
        this.action.emit({ schedule: this.currentSchedule, action: 'getLinkedDocItems' });
        this.percent = 0;
        this.isLoading = true;
        this.isDisabled = true;

        this.schedulesService.getSchedulePurchaseProgress().subscribe((data) => {
            this.percent = data['linked-documents'].percent;
            // if (this.percent > 20) {
            if (this.percent === 100) {
                this.isLoading = false;
                this.isEmpty = false;
                this.percent = 100;
                return this.schedule;
            }
        });
    }

    private checkButtonState(): void {
        if (this.isRequestError) {
            return;
        }

        const avlForPurchase = this.currentSchedule.items.filter((item) => item.isAvailable === true && item.offerPurchase === true);
        this.isDisabled = this.currentSchedule.isDisabled;

        if (this.currentSchedule.isEmpty && this.currentSchedule.key !== 'linked-documents') {
            this.buttonColor = 'mid-gray';

            if (this.currentSchedule.key === 'linked-documents-analysis') {
                this.buttonText = 'Unlock First';
            } else {
                this.buttonText = 'Unavailable';
            }

            this.isDisabled = true;
        } else if (this.currentSchedule.allPurchased) {
            this.buttonText = 'Purchased';
            this.buttonColor = 'dark-green';
            this.isDisabled = true;
        } else if (this.currentSchedule.isComingSoon) {
            this.buttonWidth = 328;
            this.buttonText = 'Coming soon';
            this.buttonColor = 'mid-gray';
            this.isDisabled = true;
        } else if (this.currentSchedule.key === 'linked-documents' && avlForPurchase.length === 0 && this.currentSchedule.isUpdated === true) {
            this.buttonText = 'Purchased';
            this.buttonColor = 'dark-green';
            this.isDisabled = true;
        } else if (this.currentSchedule.key === 'linked-documents' && this.currentSchedule.isEmpty === true) {
            this.checkLinkedDocEmpty = true;
            this.buttonWidth = 328;
            this.buttonText = 'GET LIST OF FILED COPIES';
            this.buttonColor = 'blue';
            this.isDisabled = false;
            this.percent = 100;
            // } else if (this.currentSchedule.key === 'linked-documents' && this.currentSchedule.items.length === 0) {
        } else if (this.currentSchedule.key === 'linked-documents' && this.currentSchedule.items.length > 0 && this.currentSchedule.totalCost === '£0.00') {
            this.buttonText = 'SELECT TO PURCHASE';
            this.buttonColor = 'mid-gray';
            this.isDisabled = true;
            this.buttonWidth = 207;
        } else {
            this.checkLinkedDocEmpty = false;
            this.buttonWidth = 207;
            const isUpdated = this.currentSchedule.isUpdated;
            const cost = this.currentSchedule.totalCost;
            const isFree = !cost || cost === '£0.00';
            this.buttonText = `${isUpdated ? 'Update' : (isFree ? 'Add' : 'Purchase')} for ${isFree ? 'Free' : cost}`;
            this.buttonColor = isUpdated ? 'yellow' : 'blue';
        }
    }

    private errorHandling(): void {
        this.timerSubscription.unsubscribe();
        this.isRequestError = true;
        this.percent = 100;
        this.isDisabled = false;
    }

    private startPurchase(): void {
        this.isRequestError = false;
        this.isAnimationShowing = true;
        this.action.emit({ schedule: this.currentSchedule, action: 'startPurchase' });
        this.percent = 0;
        this.isDisabled = true;

        const slowTimer = combineLatest([
            this.schedulesService.getSchedulePurchaseProgress(),
            timer(500, 1000).pipe(takeWhile(() => this.percent <= 10)),
        ]);

        const fastTimer = combineLatest([
            timer(100, 40).pipe(takeWhile(() => this.percent < 100)),
            this.scheduleUpdated$,
            this.schedulesService.getSchedulePurchaseProgress(),
        ]);

        this.timerSubscription = slowTimer
            .pipe(
                tap(() => this.percent < 10 && this.percent++),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                tap(([progress, _]: [{ [key: string]: { percent: number; isError?: boolean } }, number]) => {
                    const obj = progress[this.currentSchedule.key];
                    this.logger.info('this.percent', this.percent);
                    this.logger.info('progress', obj.percent);
                    if (obj.isError) {
                        this.errorHandling();
                    }
                }),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                filter(([progress, _]: [{ [key: string]: { percent: number; isError?: boolean } }, number]) =>
                    this.percent === 10 || progress[this.currentSchedule.key].percent > 20),
                switchMap(() => fastTimer),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                map(([_, isUpdated, progress]: [number, boolean, { [key: string]: { percent: number; isError?: boolean } }]) => {
                    const obj = progress[this.currentSchedule.key];

                    if (obj.isError) {
                        this.errorHandling();
                    }

                    if (this.percent < 100 && this.percent <= obj.percent) {
                        this.percent++;
                    }

                    if (this.percent === 100 && isUpdated) {
                        this.buttonText = '100%';
                        this.buttonColor = this.isEmpty ? 'mid-gray' : 'dark-green';
                        this.isLiftUp = !this.isEmpty;
                        // new added to update the value after first purcahse
                        this.isAnimationShowing = false;

                        return true;
                    }

                    return;
                }),
                filter(Boolean),
                debounceTime(this.isEmpty ? 0 : 1500),
            )
            .subscribe({
                next: () => {
                    this.timerSubscription.unsubscribe();
                    this.checkButtonState();
                    this.isLiftDown = !this.isEmpty;
                    this.action.emit({ schedule: this.currentSchedule, action: 'stopPurchase' });
                },
                error: () => {
                    this.action.emit({ schedule: this.currentSchedule, action: 'stopPurchase' });
                },
            });
    }
}
