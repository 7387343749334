import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaplibreComponent } from './maplibre.component';


@NgModule({
    declarations: [
        MaplibreComponent,
    ],
    exports: [
        MaplibreComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class MaplibreModule {
}
