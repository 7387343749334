import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BookmarksState, BookmarksStore } from './bookmarks.store';

@Injectable()
export class BookmarksQuery extends QueryEntity<BookmarksState> {

    constructor(protected store: BookmarksStore) {
        super(store);
    }

}
