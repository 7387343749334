import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { OnboardingManageService } from '../../onboarding/services';
import { Subscription, SubscriptionLike } from 'rxjs';

@Directive({
    selector: '[avlPulseButton]',
})
export class PulseButtonDirective implements OnInit, OnDestroy {
    private pulseSubscription: SubscriptionLike = Subscription.EMPTY;

    constructor(
        private readonly onboarding: OnboardingManageService,
        private readonly renderer: Renderer2,
        private readonly element: ElementRef,
    ) {
    }

    public ngOnInit(): void {
        this.pulsingListener();
    }

    @HostListener('click')
    public onClick(): void {
        this.completePulsing();
    }

    public ngOnDestroy(): void {
        this.pulseSubscription.unsubscribe();
    }

    public completePulsing(): void {
        this.renderer.removeClass(this.element.nativeElement, 'pulsed-button');
    }

    private pulsingListener(): void {
        this.pulseSubscription = this.onboarding.pulsedButton.subscribe((value: boolean) => {
            value
                ? this.renderer.addClass(this.element.nativeElement, 'pulsed-button')
                : this.renderer.removeClass(this.element.nativeElement, 'pulsed-button');
        });
    }
}
