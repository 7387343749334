import { Injectable } from '@angular/core';
import { applyTransaction, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IGeneratedReport } from 'app/titles/types';
import { ICollectionTableUI, IPagination } from '@core/types';

export type GeneratedReportsState = EntityState<IGeneratedReport> & {
    ui: ICollectionTableUI;
}

const initialState = {
    loading: true,
    ui: {
        page: {
            pageIndex: 0,
            pageSize: 10,
        },
    },
};

@Injectable()
@StoreConfig({ name: 'generated-reports', resettable: true })
export class GeneratedReportsStore extends EntityStore<GeneratedReportsState> {
    constructor() {
        super(initialState);
    }

    public updateReports(collection: IGeneratedReport[], pagination: IPagination): void {
        applyTransaction(() => {
            this.set(collection);
            this.update(pagination);
        });
    }
}

