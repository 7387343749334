<div class="collection__table-container schedules-table">
    <div class="collection__table-wrap">
        <table
            data-testid="schedules-table"
            class="collection__table"
            mat-table
            multiTemplateDataRows
            [dataSource]="dataSource"
            [trackBy]="trackBySources"
        >
            <ng-container
                *ngFor="let column of schedulesColumns; trackBy: trackByColumns"
                [matColumnDef]="column.key"
            >
                <th
                    mat-header-cell
                    *matHeaderCellDef
                >
                    {{ column.header }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let row"
                >
                    <ng-container *ngIf="column.key === 'icon'; else other">
                        <mat-icon [svgIcon]="column.cell(row)"></mat-icon>
                    </ng-container>
                    <ng-template #other>
                        <span
                            *ngIf="column.header"
                            [innerHTML]="column.cell(row)"
                        ></span>
                        <ng-container *ngIf="row.key !== 'linked-documents'">
                            <button
                                *ngIf="column.key === 'preview' && !row.isComingSoon && row.hasPreview"
                                class="schedules-table__button avl-btn avl-btn--blue-border"
                                [disabled]="row.isDisabled"
                                (click)="onAction({schedule: row, action: 'showPreview'})"
                            >
                                Preview
                            </button>
                        </ng-container>

                        <ng-container *ngIf="row.key === 'linked-documents' && row.isEmpty === false">
                            <button
                                *ngIf="column.key === 'preview'"
                                class="schedules-table__button avl-btn avl-btn--blue-border"
                                (click)="onAction({schedule: row, action: 'showSelectLinkedDocument'})"
                            >
                                Select
                            </button>
                        </ng-container>

                        <avl-schedule-purchases-button
                            *ngIf="column.key === 'purchase'"
                            [schedule]="row"
                            (action)="onAction($event)"
                        ></avl-schedule-purchases-button>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td
                    mat-cell
                    class="expanded"
                    *matCellDef="let schedule"
                    [attr.colspan]="displayedColumns.length"
                >
                    <avl-schedule-error-alert [schedule]="schedule"></avl-schedule-error-alert>
                </td>
            </ng-container>
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns; let i = dataIndex;"
                avlElevate
                [name]="'schedules-table-row-' + i"
                [class.new]="row.isNew"
                [class.beta]="row.isBeta"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                [ngClass]="{ error: true, visible: row.hasError && finishedPurchases[row.key] }"
            ></tr>
        </table>
    </div>

    <avl-table-no-data-disclaimer
        *ngIf="!loading && !dataSource?.data?.length"
        iconName="file-chart-grey"
        message="No schedules"
    ></avl-table-no-data-disclaimer>
</div>
