import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {
    public readonly defaultLinkedDoc = {
        costCurrency: '£',
        costAmount: 0.00,
        isEmpty: true,
    };

    public readonly dataSource = new BehaviorSubject<string>('');
    public readonly dataRepSource = new BehaviorSubject<string>('');
    public readonly scheduleSelectedCountSource = new BehaviorSubject<number>(0);
    public readonly linkedDocSource = new BehaviorSubject<any>({ ...this.defaultLinkedDoc, selectedItems: [] });
    public readonly linkedDocSelectedSource = new BehaviorSubject<any>({ ...this.defaultLinkedDoc, selectedItems: [] });
    public readonly linkDocProgressSource = new BehaviorSubject<any>('');

    public linkedDoc = this.linkedDocSource.asObservable();
    public linkedDocSelected = this.linkedDocSelectedSource.asObservable();

    public sendData(data: any): void {
        this.dataSource.next(data);
    }

    public sendReportData(repdata: string): void {
        this.dataRepSource.next(repdata);
    }

    public sendSelectedScheduleCount(data: number): void {
        this.scheduleSelectedCountSource.next(data);
    }

    public sendLinkedDocData(data: any): void {
        this.linkedDocSource.next(data);
    }

    public sendSelectedLinkedDocData(data: any): void {
        this.linkedDocSelectedSource.next(data);
    }

    public sendLinkDocProgressSource(data: any): void {
        this.linkDocProgressSource.next(data);
    }
}
