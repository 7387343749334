import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { animationTimings } from 'app/onboarding/constants/animation-timing.constant';

@Component({
    selector: 'avl-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
    animations: [
        trigger('slideContent', [
            state('void', style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })),
            state('enter', style({ transform: 'none', opacity: 1 })),
            state('leave', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
            transition('* => *', animate(animationTimings)),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
})
export class SplashScreenComponent {

    @Output()
    public getStarted = new EventEmitter();

    public start(): void {
        this.getStarted.emit();
    }
}
