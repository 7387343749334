import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'avl-info-snackbar',
    templateUrl: './info-snackbar.component.html',
    styleUrls: ['./info-snackbar.component.scss'],
})
export class InfoSnackbarComponent {

    public text = '';

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        private readonly message: string,
        protected snackRef: MatSnackBarRef<InfoSnackbarComponent>,
    ) {
        this.text = this.message;
    }

    public close(): void {
        this.snackRef.dismiss();
    }
}
