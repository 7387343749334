import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

@Component({
    selector: 'avl-purchase-download-button',
    templateUrl: './purchase-download-button.component.html',
    styleUrls: ['./purchase-download-button.component.scss'],
})
export class PurchaseDownloadButtonComponent {
    @Input()
    public counter = 0;

    @Input()
    public downloadAction: Subscription;

    @Output()
    public filesDownloaded = new EventEmitter<void>();
}
