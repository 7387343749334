import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'documentFormat' })
export class DocumentFormatPipe implements PipeTransform {
    public transform(value: string): string {
        let format = '';
        switch (value) {
            case 'docx':
                format = 'Word';
                break;
            case 'xlsx':
                format = 'Excel';
                break;
            case 'zip':
                format = 'Zip';
                break;
            default:
                format = value;
                break;
        }

        return format;
    }
}
