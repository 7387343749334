<div class="previous-folders">
    <div class="collection__container">
        <div class="collection__head">
            <h2 class="collection__title avl-h2 previous-folders__title">Previous Projects</h2>
            <avl-search
                class="previous-folders__search"
                placeholder="Search projects"
                (searchChanged)="onSearchChanged($event)"
            ></avl-search>
        </div>
        <avl-folders-table
            [loading]="loading$ | async"
            [pageSize]="defaultParams.pageSize"
            [bookmarks]="bookmarks$ | async"
            (folderOpened)="onFolderOpened($event)"
            [pagination]="pagination"
            (pageChanged)="onPageChanged($event)"
        ></avl-folders-table>
    </div>
</div>
