import { Injectable } from '@angular/core';
import { GeoJsonFeatureMap } from '../types';
import { GeoJSONFeature } from 'maplibre-gl';

@Injectable()
export class FeatureUtilsService {

    public convertToMap(features: (GeoJSONFeature | GeoJSONFeature[])[]): GeoJsonFeatureMap {
        return features.reduce((acc, feature) => {
            const titleNumber = Array.isArray(feature)
                ? feature[0].properties.title_number
                : feature.properties.title_number;
            const previousMatchValue: (GeoJSONFeature | GeoJSONFeature[] | undefined) = acc[titleNumber];

            if (previousMatchValue) {
                const newValueInArray = Array.isArray(feature) ? feature : [feature];
                const previousValueInArray = Array.isArray(previousMatchValue) ? previousMatchValue : [previousMatchValue];
                acc[titleNumber] = [...previousValueInArray, ...newValueInArray];
            } else {
                acc[titleNumber] = feature;
            }

            return acc;
        }, {} as { [key: string]: GeoJSONFeature | GeoJSONFeature[] });
    }

    public convertToArray(feature: GeoJsonFeatureMap): GeoJSONFeature[] {
        const list: GeoJSONFeature[] = [];

        Object.values(feature).map((el) => Array.isArray(el) ? list.push(...el) : list.push(el));

        return list;
    }

    public joinUniqByPolyId(a: GeoJSONFeature[], b: GeoJSONFeature[]): GeoJSONFeature[] {
        const result: GeoJSONFeature[] = [...a];

        b.forEach((feature) => {
            const isAlreadyExists = result.some((el) => el.properties.poly_id === feature.properties.poly_id);
            if (!isAlreadyExists) {
                result.push(feature);
            }
        });

        return result;
    }

    public excludeCoordinates(array: number[][][][] | number[][][] | number[][]): number[][] {
        const isArrayInside = Array.isArray(array[0][0]);

        if (isArrayInside) {
            return this.excludeCoordinates(array[0] as number[][][] | number[][]);
        } else {
            return array as number[][];
        }
    }
}
