import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class UrlParamsService {

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly location: Location,
    ) {
    }

    public removeParams(params: string[]): void {
        const previousParams = this.extractParams();
        const filteredParams = params.reduce((params, nextParam) => {
            delete params[nextParam];

            return params;
        }, previousParams);

        this.commitUrl({ ...filteredParams });
    }

    public addParams(params: { [k: string]: string | number | boolean }): void {
        const previousParams = this.extractParams();
        this.commitUrl({ ...previousParams, ...params });
    }

    public extractParams(): { [k: string]: string } {
        const currentUrl = window.location.href;
        const paramMap = new HttpParams({ fromString: currentUrl.split('?')[1] });

        return paramMap.keys()
            .reduce((params, paramKey) => ({ ...params, [paramKey]: paramMap.get(paramKey) }), {} as { [k: string]: string });
    }

    private commitUrl(queryParams: { [k: string]: any }): void {
        const urlTree = this.router.createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams,
        });

        this.location.go(urlTree.toString());
    }
}
