<button
    data-testid="purchase-download-button"
    class="avl-btn avl-btn--blue avl-btn--wide purchase-download-button"
    type="button"
    [disabled]="!counter"
    [promiseBtn]="downloadAction"
    (click)="filesDownloaded.emit()"
>
    <span [ngPlural]="counter">
        <ng-template ngPluralCase="=0">Download</ng-template>
        <ng-template ngPluralCase="=1">Download 1 file</ng-template>
        <ng-template ngPluralCase="other">Download {{ counter }} files</ng-template>
    </span>
</button>
