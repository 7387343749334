<div class="purchase-page">
    <div class="collection__container">
        <div class="collection__head">
            <h2 class="collection__title avl-h2">Purchased Files</h2>
            <avl-search
                [placeholder]="'Search files'"
                (searchChanged)="onSearch($event)"
            ></avl-search>
        </div>
        <avl-purchased-files-table
            [loading]="loading$ | async"
            [files]="purchaseFiles$ | async"
            [pagination]="pagination"
            [resetSelection$]="resetSelection$"
            (pageChanged)="onPageChanged($event)"
            (selectedFiles)="onSelectedFiles($event)"
        ></avl-purchased-files-table>
    </div>
    <avl-purchase-download-button
        [counter]="(filesForDownload$ | async).length"
        [downloadAction]="downloadAction$"
        (filesDownloaded)="downloadFiles()"
    ></avl-purchase-download-button>
</div>
