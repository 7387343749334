import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FolderQuery, NotifyCountersState } from 'app/titles/store';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from '@services';
import { AvailToolKey } from '@enums';

@Component({
    selector: 'avl-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
    public readonly tools = AvailToolKey;

    @Input()
    public counters: NotifyCountersState;

    @Output()
    public newProjectCreated = new EventEmitter<void>();

    @Output()
    public closed = new EventEmitter<void>();

    constructor(private readonly folderQuery: FolderQuery,
        private readonly route: ActivatedRoute,
        private readonly log: LoggerService) {
    }

    public createNewProject(): void {
        this.newProjectCreated.emit();
        this.close();
    }

    public close(): void {
        this.closed.emit();
    }

    public getFolderId(): string {
        const routeSnapshot = this.route.snapshot;
        const folderId = routeSnapshot.queryParamMap.get('fid');

        return this.folderQuery.getFolderId() || folderId;
    }
}
