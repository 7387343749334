<mat-sidenav-container class="lease__sidenav">
    <mat-sidenav
        mode="over"
        #sidenav
    >
        <avl-sidenav
            [counters]="counters$"
            (createNewFolder)="onNewFolderCreated()"
            (closeSideNav)="sidenav.close()"
        >
        </avl-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="lease__content">
            <avl-header
                [logo]="leaseLogo"
                [homePagePath]="'leases'"
                [folderIsNotCreated]="folderIsNotCreated$ | async"
                [showHamburgerMenu]="true"
                [showNotificationBell]="true"
                [folderDetails]="folderDetails$ | async"
                (newFolderCreated)="onNewFolderCreated()"
                (notificationCenterOpened)="onNotificationCenterOpened()"
                (sideNavToggled)="loadCounters(); sidenav.toggle()"
                (projectDetailsOpened)="onFolderDetailsOpen()"
                [profileContextMenu]="profileMenu"
            >
                <mat-menu
                    #profileMenu="matMenu"
                    class="profile-menu"
                >
                    <mat-icon
                        avlElementOver
                        [template]="profileIconReference"
                        [yShift]="26"
                        svgIcon="triangle"
                        class="profile-menu__arrow"
                    ></mat-icon>
                    <button
                        *ngIf="!isProviderSso()"
                        mat-menu-item
                        (click)="logout()"
                    >
                        Log Out
                    </button>
                    <button
                        *ngIf="isProviderSso()"
                        mat-menu-item
                        (click)="logout()"
                    >
                        Log Out from Avail
                    </button>
                    <a
                        mat-menu-item
                        [style.line-height]="'32px'"
                        target="_blank"
                        href="/avail-policies/privacy-policy"
                    >
                        Privacy Policy
                    </a>
                    <button
                        *ngIf="!isEnvironmentProduction()"
                        type="button"
                        mat-menu-item
                        (click)="logoutStay()"
                    >
                        Clear Auth Session
                    </button>
                </mat-menu>
            </avl-header>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
