import { Injectable } from '@angular/core';
import { IMapBounds } from '../types';
import { LngLat } from 'maplibre-gl';

@Injectable()
export class MapService {
    public isPreviousBoundsIncludeNew(previousBounds: IMapBounds, newBounds: IMapBounds): boolean {
        const previousLatNe = previousBounds.latitude_ne;
        const previousLatSw = previousBounds.latitude_sw;
        const previousLongNe = previousBounds.longitude_ne;
        const previousLongSw = previousBounds.longitude_sw;

        const newLatNe = newBounds.latitude_ne;
        const newLatSw = newBounds.latitude_sw;
        const newLongNe = newBounds.longitude_ne;
        const newLongSw = newBounds.longitude_sw;

        return previousLatNe >= newLatNe
            && previousLongNe >= newLongNe
            && previousLatSw <= newLatSw
            && previousLongSw <= newLongSw;
    }

    public isPointsDifferent(a?: LngLat, b?: LngLat): boolean {
        const isPointAExists = !!a;
        const isPointBExists = !!b;
        const isLngDifferent = a?.lng !== b?.lng;
        const isLatDifferent = a?.lat !== b?.lat;

        return !isPointAExists || !isPointBExists || isLngDifferent || isLatDifferent;
    }
}
