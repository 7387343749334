import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'avl-show-imanage-dialog',
    templateUrl: './show-imanage-dialog.component.html',
    styleUrls: ['./show-imanage-dialog.component.scss'],
})
export class ShowImanageDialogComponent implements OnInit {
    public iframeLink = '';
    public displayIframe = true;
    public showSpinner = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: any,
    ) {
    }

    public ngOnInit(): void {
        this.iframeLink = this.data.iframeLink;
        this.data.showLoadingSpinner.subscribe((res) => {
            this.showSpinner = res;
        });
    }
}
