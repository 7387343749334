import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CacheService<T> {
    private readonly defaultKey = 'default';
    private readonly lifetimeInSeconds = 7 * 60; // 7 minutes;

    private cache: {
        [id: string]: {
            data: Observable<T>;
            createdAt: number;
        };
    } = {};

    public findOrSet(path: string, observable: Observable<T>): Observable<T> {
        let result = this.getValue(path);

        if (!result) {
            result = observable;
            this.setValue(observable, path);
        }

        return result;
    }

    public getValue(path?: string): Observable<T> | null {
        return this.cache[this.key(path)]?.data || null;
    }

    public setValue(value: Observable<T>, path?: string): void {
        const valueToSave = {
            data: value,
            createdAt: new Date().getTime(),
        };

        this.cache[this.key(path)] = valueToSave;
        this.clearOutdatedItems();
    }

    public clear(): void {
        this.cache = {};
    }

    public removeValue(path: string): void {
        delete this.cache[this.key(path)];
    }

    private key(path?: string): string {
        return path ? this.hashCode(path).toString() : this.defaultKey;
    }

    private hashCode(string: string): number {
        return string.split('')
            .reduce((acc, b) => {
                acc = ((acc << 5) - acc) + b.charCodeAt(0);

                return acc & acc;
            }, 0);
    }

    private clearOutdatedItems(): void {
        const expiredAt = new Date().getTime() - this.lifetimeInSeconds * 1000;

        for (const item in this.cache) {
            const createdAt = this.cache[item]?.createdAt;
            if (createdAt < expiredAt) {
                delete this.cache[item];
            }
        }
    }
}
