<div
    class="notice-area__progress-info"
    [class.is-visible]="state.showPercents || state.completed"
>
    <h2 class="notice-area__sub-title">{{ state.fileName }}</h2>
    <p
        *ngIf="state.isError"
        class="notice-area__percentage"
    >
        {{ getErrorMessage(messages) }}
    </p>
    <p
        *ngIf="state.completed && !state.isError"
        class="notice-area__percentage"
    >
        Uploaded successfully
    </p>
    <p
        *ngIf="!state.completed && !state.isError"
        class="notice-area__percentage"
    >
        Uploading {{ percent }}%
    </p>
</div>

<mat-progress-bar
    *ngIf="state.showPercents && !state.isError"
    class="notice-area__progress-bar notice-yellow"
    [mode]="'determinate'"
    [value]="percent"
></mat-progress-bar>
