<div class="character-view">
    <div class="character-view__lottie-container">
        <ng-lottie
            class="character-view__lottie"
            [ngClass]="getPositionClass()"
            [options]="characterAnimateConfig"
            (animationCreated)="handleAnimation($event)"
            [width]="'300px'"
            [height]="'300px'"
        ></ng-lottie>
    </div>
    <div
        class="character-view__card"
        [style.width]="options.cardWidth"
        [ngClass]="getPositionClass()"
    >
        <p
            *ngFor="let textItem of options.cardText"
            class="character-view__card-text"
            [innerHTML]="textItem"
        ></p>
        <button
            *ngIf="options?.cardAction"
            class="character-view__card-action"
            type="button"
            [innerHTML]="options.cardAction"
        ></button>
    </div>
</div>
