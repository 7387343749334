import { MinimalDocument } from './minimal-document.type';

export type FileMetadata = {
    id: string;
    fileName: string;
    isUploadFinished: boolean;
    uploadedAt: string;
    source: string;
    isProcessed: boolean;
    isAccepted: boolean;
    isError: boolean;
    metadata: {
        dateUploaded?: string;
        fileName?: string;
        pages?: number;
        size?: string;
    };
    additionalProperties: string;
}

export function convertToMinimalDocument(fileMetadata: FileMetadata): MinimalDocument {
    return {
        id: fileMetadata.id,
        fileName: fileMetadata.fileName,
        uploadedAt: fileMetadata.uploadedAt,
        type: 'PDF',
        source: fileMetadata.source,
        isProcessed: fileMetadata.isProcessed,
        isAccepted: fileMetadata.isAccepted,
        isUploadFinished: fileMetadata.isUploadFinished,
        metadata: {
            dateUploaded: fileMetadata.metadata.dateUploaded,
            fileName: fileMetadata.metadata.fileName,
            pages: fileMetadata.metadata.pages,
            size: fileMetadata.metadata.size,
        },
        signature: '',
        title: '',
        includedInReports: false,
        messages: [],
        dataPoints: {},
        loadingPercentage: 100,
    };
}
