import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentsService, FolderService } from '../../../services';
import { FolderQuery } from '../../../store';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OnboardingManageService } from '../../../../onboarding/services';
import { ProfileService } from '@services';
import { FolderDetails } from '@core/types';

@Component({
    selector: 'avl-project-details-dialog',
    templateUrl: './project-details-dialog.component.html',
    styleUrls: ['./project-details-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProjectDetailsDialogComponent implements OnInit {
    public projectDetailsForm: FormGroup;
    public projectCreated$: Subscription;
    public disableClose: boolean;
    public projectNameErrorMessage = 'Project Name is required';
    public matterNumberErrorMessage = 'Matter Number is required';

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly profileService: ProfileService,
        private readonly folderService: FolderService,
        private readonly documentsService: DocumentsService,
        private readonly folderQuery: FolderQuery,
        private readonly onboarding: OnboardingManageService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: FolderDetails,
        private readonly dialogRef: MatDialogRef<ProjectDetailsDialogComponent, 'error' | 'success' | 'close'>,
    ) {
    }

    public ngOnInit(): void {
        this.setupForm();
        this.preFillForm();
        this.handleEscapePress();

        this.onboarding.enterProjectDetails();
        this.disableClose = this.onboarding.isActive;
    }

    public confirmDetails(): void {
        const projectDetails: FolderDetails = this.projectDetailsForm.value;
        const folderId = this.folderQuery.getFolderId();
        this.onboarding.closeActiveOverlay();
        this.projectCreated$ = this.folderService.createProject(folderId, projectDetails)
            .pipe(
                switchMap(() => this.documentsService.getLoadedDocuments()),
            )
            .subscribe({
                next: () => this.dialogRef.close('success'),
                error: () => this.dialogRef.close('error'),
            });
    }

    public fieldHasError(fieldName: string): boolean {
        const control = this.projectDetailsForm.get(fieldName);

        return control.dirty && control.invalid;
    }

    private setupForm(): void {
        const projectNameValidator = [Validators.required];
        if (this.profileService.plan$.getValue() && this.profileService.plan$.getValue().projectName && this.profileService.plan$.getValue().projectName.pattern) {
            projectNameValidator.push(Validators.pattern(this.profileService.plan$.getValue().projectName.pattern));
            this.projectNameErrorMessage = this.profileService.plan$.getValue().projectName.errorMessage;
        }

        const matterNumberValidator = [Validators.required];
        if (this.profileService.plan$.getValue() && this.profileService.plan$.getValue().matterNumber && this.profileService.plan$.getValue().matterNumber.pattern) {
            matterNumberValidator.push(Validators.pattern(this.profileService.plan$.getValue().matterNumber.pattern));
            this.matterNumberErrorMessage = this.profileService.plan$.getValue().matterNumber.errorMessage;
        }

        this.projectDetailsForm = this.formBuilder.group({
            projectName: ['', projectNameValidator],
            matterNumber: ['', matterNumberValidator],
        });
    }

    private preFillForm(): void {
        if (this.data) {
            this.projectDetailsForm.setValue({ ...this.data });
        }
    }

    private handleEscapePress(): void {
        this.dialogRef.keydownEvents()
            .subscribe((event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    this.dialogRef.close('close');
                }
            });
    }
}
