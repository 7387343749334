import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '@shared/shared.module';
import { ToggleModule } from '@shared/components/toggle-control/toggle.module';
import { MapControlsComponent } from './map-controls.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ToggleModule,
        FormsModule
    ],
    exports: [
        MapControlsComponent
    ],
    declarations: [
        MapControlsComponent
    ]
})
export class MapControlsModule {}
