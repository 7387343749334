<div class="info-snack__content-background">
    <button
        type="button"
        class="clear-btn-styles info-snack__close-button"
    >
        <mat-icon
            svgIcon="close-white"
            (click)="close()"
        ></mat-icon>
    </button>
    <img
        class="info-snack__img"
        src="assets/images/information-girl.svg"
        alt="Information girl"
    >
    <div class="info-snack__message">
        {{ message }}
    </div>
</div>
