import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'documentType' })
export class DocumentTypePipe implements PipeTransform {
    public transform(value: string): string {
        let result = '';
        switch (value) {
            case 'title-register':
                result = 'Title Register';
                break;
            case 'title-plan':
                result = 'Title Plan';
                break;
            case 'scottish-title':
                result = 'ScotLIS Title';
                break;
            case 'scottish-title-plan':
                result = 'ScotLIS Title Plan';
                break;
            case 'linked-documents':
                result = 'Filed Copy';
                break;
            default:
                result = value;
                break;
        }

        return result;
    }
}
