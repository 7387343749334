import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { GeneratedReportsQuery } from '../../store';
import { GeneratedReportsService, RefreshService } from '../../services';
import { IGeneratedReport } from '../../types';

import { Observable } from 'rxjs';
import { ReportsTableComponent } from '../reports-table/reports-table.component';
import { PageEvent } from '@angular/material/paginator';
import { IPagination } from '@core/types';

@Component({
    selector: 'avl-reports-page',
    templateUrl: './reports-page.component.html',
    styleUrls: ['./reports-page.component.scss'],
})
export class ReportsPageComponent implements OnInit, OnDestroy {

    @ViewChild(ReportsTableComponent, { static: false })
    public purchasedTable: ReportsTableComponent;

    public reports$: Observable<IGeneratedReport[]>;
    public loading$: Observable<boolean>;
    public pagination: IPagination = { totalCount: 0 };

    constructor(
        private readonly router: Router,
        private readonly reportsQuery: GeneratedReportsQuery,
        private readonly reportsService: GeneratedReportsService,
        private readonly refreshService: RefreshService,
    ) {
    }

    public ngOnInit(): void {
        this.reports$ = this.reportsQuery.selectAll();
        this.loading$ = this.reportsQuery.getLoading();
    }

    public ngOnDestroy(): void {
        this.reportsService.resetState();
    }

    public onLoadReports(): void {
        this.reportsService.getReportsList(this.reportsQuery.getValue().ui)
            .subscribe(() => {
                this.pagination = this.reportsQuery.getPagination();
            });
    }

    public onSearch(value: string): void {
        this.reportsService.updateSearchUI(value);
        this.purchasedTable.resetToFirstPage();
    }

    public onPageChanged(page: PageEvent): void {
        this.reportsService.updatePaginationUI(page);
        this.onLoadReports();
    }

    public onProjectOpened({ id, lastOpenedAt }: { id: string; lastOpenedAt: string }): void {
        this.router.navigate(['title/upload'], { queryParams: { fid: id } });
        this.refreshService.tryRefresh(lastOpenedAt);
    }

    public onReportDownloaded(reportId: string): void {
        this.router.navigate(['title/upload'], {
            queryParams: { fid: reportId, download: true },
        });
    }
}
