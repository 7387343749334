import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '@auth/services/auth.service';
import { RoutingService } from '@auth/services/routing.service';

import { LoggerService, UrlParamsService } from '@services';
import { OnboardingManageService } from 'app/onboarding/services';

import { environment } from '@env/environment';
import { User } from '@auth/types/user.type';
import { regexes } from '@constants';
import { PromiseRetry } from '../../../blocks/file-uploader/helpers/promise-retry.class';
import { FirebaseError, firebaseRequestErrors } from '@auth/components/enums/firebase-errors.enum';

@Component({
    selector: 'avl-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class LoginComponent implements OnInit {
    public readonly isPopupLoginFlow = environment.signInPopup;
    public loginForm: FormGroup;
    public loginError: string;
    public failedLogin = false;
    public isSso = false;
    public isPasswordVisible = false;
    public isLoading = true;
    public isTryAgainButtonVisible = true;

    private readonly ssoProviderKey = 'ssoProviderName';
    private ssoProviderName = '';

    constructor(
        private readonly authService: AuthService,
        private readonly routingService: RoutingService,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly log: LoggerService,
        private readonly onboarding: OnboardingManageService,
        private readonly urlParams: UrlParamsService,
    ) {
    }

    public get emailControl(): AbstractControl {
        return this.loginForm.get('email');
    }

    public get passwordControl(): AbstractControl {
        return this.loginForm.get('password');
    }

    public ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: [null, [Validators.required, Validators.pattern(regexes.email)]],
            password: [null, Validators.required],
        });

        this.route.queryParamMap.subscribe((params: ParamMap) => {
            this.onboarding.stop();
            this.ssoProviderName = params.get('sso') || localStorage.getItem(this.ssoProviderKey);
            const isFirstTimeRedirection = params.get('firstTimeRedirection') !== 'false' ?? true;

            if (this.ssoProviderName) {
                this.isSso = true;

                if (isFirstTimeRedirection) {
                    this.urlParams.addParams({ sso: this.ssoProviderName, firstTimeRedirection: false });
                    localStorage.removeItem(this.ssoProviderKey);
                    this.tryToRedirectOnSignInPage();
                }
            }

            this.isLoading = false;
        });
    }

    public getEmailErrorMessage(): string {
        return this.emailControl.hasError('required')
            ? 'An email is required'
            : this.emailControl.hasError('pattern')
                ? 'Not a valid email'
                : '';
    }

    public async login(): Promise<void> {
        if (this.loginForm.invalid) {
            return;
        }

        this.isLoading = true;

        const user: User = this.loginForm.value;
        const isDevelopMode = environment.dev;
        const isBetaUser = user.email === 'beta';

        this.resetErrors();

        if (isDevelopMode && isBetaUser) {
            user.email = 'noreply@avail.ai';
        }

        const promiseRetryOptions = { retryRequiredErrors: firebaseRequestErrors };
        const promiseRetry = new PromiseRetry(() => this.authService.login(user.email, user.password), promiseRetryOptions);

        promiseRetry.addEventErrorListener(this.handleAuthError.bind(this));
        promiseRetry.run()
            .then(() => {
                this.routingService.navigateToHome(this.route.snapshot)
                    .catch(this.handleAuthError.bind(this));
            })
            .catch((error) => {
                this.isTryAgainButtonVisible = true;

                if (firebaseRequestErrors.includes(error.code)) {
                    this.loginError = 'Authorization request error. Try again later.';
                }
            })
            .finally(() => this.isLoading = false);
    }

    public resetErrors(): void {
        this.failedLogin = false;
        this.loginError = null;
    }

    public async openSsoModal(): Promise<void> {
        this.isLoading = true;

        const promiseRetryOptions = { retryRequiredErrors: firebaseRequestErrors };
        const promiseRetry = new PromiseRetry(
            () => this.authService.loginWithProvider(this.ssoProviderName),
            promiseRetryOptions,
        );

        promiseRetry.addEventErrorListener(this.handleAuthError.bind(this));
        promiseRetry.run()
            .then(() => localStorage.setItem(this.ssoProviderKey, this.ssoProviderName))
            .catch((error) => {
                this.isTryAgainButtonVisible = true;

                if (firebaseRequestErrors.includes(error.code)) {
                    this.loginError = 'Authorization request error. Try again later.';
                }
            })
            .finally(() => this.isLoading = false);
    }

    private tryToRedirectOnSignInPage(): void {
        if (!this.isPopupLoginFlow) {
            localStorage.setItem(this.ssoProviderKey, this.ssoProviderName);
            this.isLoading = true;
            this.authService.loginWithProvider(this.ssoProviderName)
                .catch(this.handleAuthError.bind(this))
                .finally(() => this.isLoading = false);
        }
    }

    private handleAuthError(error: { message: string; code: string; status?: number }): void {
        const isForbidden = error.status === 403;
        const contactUsMessage = 'Please contact us at <a href="mailto:hello@avail.ai">hello@avail.ai</a> if you believe this is wrong.';

        this.log.warn('Error during logging in:', error.message, error);
        this.failedLogin = true;
        this.isTryAgainButtonVisible = true;
        this.loginError = error.message;

        switch (error.code) {
            case FirebaseError.userNotFount:
                this.loginError = `We haven't been able to find an account linked to this email address. ${contactUsMessage}`;
                break;
            case FirebaseError.internetError:
            case FirebaseError.networkRequestFailed:
                this.loginError = 'Authorization request error. We are trying to repeat the request, please, wait.';
                this.isTryAgainButtonVisible = false;
                break;
        }

        if (isForbidden) {
            this.loginError = `Your account is disabled. ${contactUsMessage}`;
        }
    }
}
