import { animate, style, transition, trigger } from '@angular/animations';

export const tableAnimation
    = trigger('tableAnimation', [
        transition(':enter', [
            style({ height: 0, opacity: 0 }),
            animate('0.6s ease', style({ height: '*', opacity: 1 })),
        ]),
        transition(':leave', [
            style({ height: '*', opacity: 1 }),
            animate('0.6s ease', style({ height: 0, opacity: 0 })),
        ]),
    ]);
