import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { ID, Order } from '@datorama/akita';

import { tableAnimation } from 'app/core/animations/table-rows.animation';
import { MinimalDocument } from '../../types/minimal-document.type';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';

export type DocumentsSortBy = 'fileName' | 'uploadTime' | 'uploadDate' | 'size' | 'pages';

export type DocumentsSortOptions = {
    sortBy: DocumentsSortBy;
    order: Order;
}

@Component({
    selector: 'avl-documents-table',
    templateUrl: './documents-table.component.html',
    styleUrls: ['./documents-table.component.scss'],
    animations: [tableAnimation],
})
export class DocumentsTableComponent implements AfterViewInit {
    @Input()
    public isDragDropEnabled = false;

    @Input()
    public isLoading = true;

    @Output()
    public documentRemoved = new EventEmitter<MinimalDocument>();

    @Output()
    public documentLoadingCanceled = new EventEmitter<MinimalDocument>();

    @Output()
    public documentsSorted = new EventEmitter<DocumentsSortOptions>();

    public isDocumentsUploadingFinished = false;
    public rowWidth = 0;
    public dataSource: MinimalDocument[] = [];

    @ViewChild('table')
    public table: MatTable<MinimalDocument>;

    @ViewChild('container', { static: true })
    private readonly container: ElementRef;

    @Input()
    public set documents(documents: MinimalDocument[]) {
        this.dataSource = documents;
        this.isDocumentsUploadingFinished = documents.every((document) => document.isUploadFinished);
    }

    public ngAfterViewInit(): void {
        this.rowWidth = this.container.nativeElement.offsetWidth;
    }

    public sortDocuments(sort: Sort): void {
        const sortBy = sort.active as DocumentsSortBy;
        const order = sort.direction as Order;

        this.documentsSorted.emit({ sortBy, order });
    }

    public cancelLoading(document: MinimalDocument): void {
        this.documentLoadingCanceled.emit(document);
    }

    public removeDocument(document: MinimalDocument): void {
        this.documentRemoved.emit(document);
    }

    public getUploadDate(document: MinimalDocument): string {
        const date = new Date(document.uploadedAt);
        const year = date.getFullYear();
        const monthName = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
        const day = date.getDate().toString().padStart(2, '0');

        return `${day} ${monthName} ${year}`;
    }

    public getUploadTime(document: MinimalDocument): string {
        const date = new Date(document.uploadedAt);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${hours}:${minutes}`;
    }

    public changeOrder(event: CdkDragDrop<MinimalDocument[]>): void {
        moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
    }

    public getPreviewWidth(): string {
        return this.rowWidth - 50 + 'px';
    }

    public trackByDocuments(index: number, document: MinimalDocument): ID {
        return document.id;
    }
}
