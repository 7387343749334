<mat-icon
    avlElementOver
    data-testid="artificial-notification-center-bell"
    [template]="notificationBell"
    svgIcon="bell"
    class="artificial-bell"
    (click)="openNotificationDialog()"
></mat-icon>
<mat-icon
    avlElementOver
    [template]="notificationBell"
    [yShift]="-5"
    [xShift]="12"
    svgIcon="explanation-triangle"
    class="artificial-bell__explanation-triangle"
    (click)="openNotificationDialog()"
></mat-icon>

<div class="report-dialog">
    <img
        src="../../../../../../assets/images/warning.svg"
        alt="Lease report generation warning"
    >
    <div class="report-dialog__info">
        <h3 class="report-dialog__title">{{ title }}</h3>
        <div class="report-dialog__description-block">
            <p>
                {{ firstMessageBeforeLink }}
                <a
                    *ngIf="isFirstMessageContainLink"
                    class="report-dialog__link"
                    (click)="downloadReport()"
                >
                    {{ linkMessage }}
                </a>
                {{ firstMessageAfterLink }}
            </p>
            <p>
                {{ secondMessageBeforeLink }}
                <a
                    *ngIf="isSecondMessageContainLink"
                    class="report-dialog__link"
                    (click)="downloadReport()"
                >
                    {{ linkMessage }}
                </a>
                {{ secondMessageAfterLink }}
            </p>
        </div>
    </div>
</div>

