<div
    *ngIf="!invalidCode && !model.hasError && !succeedSend"
    class="auth-page__form-title"
>
    <h2>Create an account</h2>
</div>
<form
    *ngIf="!invalidCode && !model.hasError && !succeedSend"
    #signupForm="ngForm"
    class="auth-page__form"
    (ngSubmit)="submitSignup()"
    [ngFormOptions]="{ updateOn: 'blur' }"
>
    <div class="form-field">
        <label>Password</label>
        <input
            #newPassword="ngModel"
            tabindex="1"
            name="new-password"
            autofocus
            autocomplete="new-password"
            placeholder="Password"
            [ngClass]="{'error': model.newPassword !== null && !model.newPassword?.length}"
            [type]="isPasswordVisible ? 'text' : 'password'"
            [disabled]="invalidCode"
            [(ngModel)]="model.newPassword"
        />
        <mat-icon
            class="button"
            [svgIcon]="isPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isPasswordVisible = !isPasswordVisible"
        ></mat-icon>
        <mat-error *ngIf="model.newPassword !== null && !model.newPassword?.length">A password is required</mat-error>
    </div>
    <div class="form-field">
        <label>Password</label>
        <input
            #confirmNewPassword="ngModel"
            tabindex="2"
            autocomplete="new-password"
            name="confirm-new-password"
            placeholder="Confirm Password"
            [type]="isConfirmPasswordVisible ? 'text' : 'password'"
            [disabled]="invalidCode"
            [(ngModel)]="model.confirmNewPassword"
        />
        <mat-icon
            class="button"
            [svgIcon]="isConfirmPasswordVisible ? 'eye-slash' : 'eye'"
            (click)="isConfirmPasswordVisible = !isConfirmPasswordVisible"
        ></mat-icon>
    </div>
    <button
        class="avl-btn avl-btn--blue avl-btn--wide"
        tabindex="3"
        name="login"
        value="Log In"
        type="submit"
        [disabled]="invalidCode || !model.newPassword?.length"
    >
        SIGN UP
    </button>
    <div class="form-field row">
        <span>Already have an account?</span>
        <a
            tabindex="4"
            [routerLink]="['/login']"
        >
            Log in
        </a>
    </div>
</form>

<avl-error-form
    *ngIf="model.hasError"
    [message]="model.errorMessage"
    [buttonText]="buttonText"
    (tryAgain)="onTryAgain()"
></avl-error-form>
<avl-succeed-form
    *ngIf="succeedSend"
    [formTitle]="succeedTitle"
    [message]="succeedMessage"
    [isResendButton]="false"
    (resend)="onTryAgain()"
></avl-succeed-form>
