import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class FileService {

    public download(response: HttpResponse<ArrayBuffer>): void {
        const name = this.getFileNameFromDisposition(response.headers);
        const type = this.getContentType(response.headers);
        const blob = new Blob([response.body], { type: type || 'application/binary' });
        saveAs(blob, name);
    }

    private getFileNameFromDisposition(headers: HttpHeaders): string {
        const disposition = headers.get('Content-Disposition');
        return disposition.split('filename=')[1].replace(/['"]/g, '');
    }

    private getContentType(headers: HttpHeaders): string {
        return headers.get('Content-Type');
    }
}
