import { IFileAsObject } from '../types';

/**
 * creates Object with available properties from {File}
 */
export class FileAsObject implements IFileAsObject {
    /**
     * file size
     */
    public size: number;

    /**
     * file type
     */
    public type: string;

    /**
     * file name
     */
    public name: string;

    /**
     * file las modification date in seconds
     */
    public lastModified: number;

    /**
     * original {File} target
     */
    public sourceFile: File;

    /**
     * @param {File} file
     */
    constructor(file: File) {
        this.size = file.size;
        this.type = file.type;
        this.name = file.name;
        this.lastModified = file.lastModified;
        this.sourceFile = file;
    }
}

