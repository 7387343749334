<div class="banner">
    <p
        class="message"
        [innerHTML]="message"
    ></p>
    <a
        *ngIf="detailsUrl"
        [href]="detailsUrl"
        target="_blank"
        class="url-text"
    >
        More info
    </a>
</div>
