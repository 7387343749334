<button
    data-testid="land-registry-purchase-titles-button"
    class="avl-btn avl-btn--blue avl-btn--wide purchase-titles-button"
    type="button"
    [disabled]="!counter"
    [promiseBtn]="purchaseAction"
    (click)="titlesPurchased.emit()"
>
    <span [ngPlural]="counter">
        <ng-template ngPluralCase="=0">Purchase</ng-template>
        <ng-template ngPluralCase="=1">
            Purchase 1 {{ purchaseEntity }} for {{ price | currency:'GBP':'symbol' }}
        </ng-template>
        <ng-template ngPluralCase="other">
            Purchase {{ counter }} {{ purchaseEntity }}s for {{ price | currency:'GBP':'symbol' }}
        </ng-template>
    </span>
</button>
