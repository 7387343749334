import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'avl-report-loading-progress',
    templateUrl: './report-loading-progress.component.html',
    styleUrls: ['./report-loading-progress.component.scss']
})
export class ReportLoadingProgressComponent {

    public title$: Observable<string>;
    public percentage$: Observable<number>;
    public message$: Observable<string>;

    constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { title: Observable<string>; message: Observable<string>; percentage: Observable<number> },
    ) {
        this.title$ = data.title;
        this.percentage$ = data.percentage.pipe(
            filter((value) => value >= 0 && value <= 100),
        );
        this.message$ = data.message;
    }

    public complete(): void {
        this.percentage$ = of(100);
    }
}
