import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OnboardingApi {

    constructor(private readonly http: HttpClient) {
    }

    public checkBranchSeen(branch: string): Observable<boolean> {
        return this.http.get<boolean>(`/api/profile/onboarding/${branch}`);
    }

    public setBranchAsSeen(branch: string): Observable<void> {
        return this.http.put<void>(`/api/profile/onboarding/${branch}`, {});
    }

    public resetBranchToUnseen(branch: string): Observable<any> {
        return this.http.delete(`/api/profile/onboarding/${branch}`);
    }

    public shouldShowOnboarding(): Observable<any> {
        return this.http.get('/api/profile/onboarding', { responseType: 'text' });
    }

    public onboardingShown(): Observable<any> {
        return this.http.put('/api/profile/onboarding', null, { responseType: 'text' });
    }

    public resetOnboarding(): Observable<void> {
        return this.http.delete<void>('/api/profile/onboarding');
    }
}
