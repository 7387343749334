import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { AuthService } from '@auth/services';
import { LoggerService } from '@services';

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class ResetCodeResolver implements Resolve<any> {

    constructor(
        private readonly authService: AuthService,
        private readonly log: LoggerService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): any {
        const oobCode = route.queryParams.oobCode;
        const action = route.queryParams.mode;
        if (oobCode) {
            const auth = this.authService.firebaseAuth;

            return fromPromise(auth.verifyPasswordResetCode(oobCode))
                .pipe(
                    map((emailAddr) => {
                        if (emailAddr) {
                            return {
                                email: emailAddr,
                                code: oobCode,
                                mode: action,
                                errorMessage: null,
                                valid: true,
                            };
                        }
                        return this.errorActionCode('Invalid reset code, please check the link that has been sent to your email again.', action);
                    }),
                    catchError((err) => {
                        this.log.info('catchError', err);
                        return of(this.errorActionCode(err.message, action));
                    }),
                );
        }
        return of(this.errorActionCode('Invalid reset code, please check the link that has been sent to your email again.', action));
    }

    private errorActionCode(message: string, action: string): any {
        return {
            email: null,
            code: null,
            mode: action,
            errorMessage: message,
            valid: false,
        };
    }
}
