import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Order } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ActiveBookmarks, Bookmark, Bookmarks } from '../types';

@Injectable()
export class BookmarksApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public getAll(
        options?: {
            start?: number;
            limit?: number;
            query?: string;
            sort?: string;
            order?: SortDirection;
        },
    ): Observable<Bookmarks> {
        const { start = 0, limit = 50, query = '', sort = 'createdAt', order = Order.DESC } = { ...options };
        const params = new HttpParams()
            .set('start', `${start}`)
            .set('limit', `${limit}`)
            .set('q', query || '')
            .set('sort', sort || 'createdAt')
            .set('order', order === 'desc' || !order ? 'dsc' : order);

        return this.http.get<Bookmark[]>('/api/notice/bookmarks', { params, observe: 'response' })
            .pipe(
                map((response) => {
                    const bookmarks = response.body;
                    const amount = +response.headers.get('x-total-count') || bookmarks.length;

                    return {
                        bookmarks,
                        amount,
                    };
                }),
            );
    }

    public getActive(): Observable<ActiveBookmarks> {
        return this.http.get<ActiveBookmarks>('/api/notice/bookmarks/active');
    }
}
