import { Injectable } from '@angular/core';
import { IFileUploaderOptions } from '../types';
import { FileUploader } from '../classes/file-uploader.class';
import { FileUploaderHttpRequestManager } from './file-uploader-http-request-manager.service';

@Injectable()
export class FileUploaderService {

    constructor(
        private readonly http: FileUploaderHttpRequestManager,
    ) {
    }

    /**
     * @param {IFileUploaderOptions} options
     * @returns {FileUploader} instance of FileUploader class
     *
     * @example
     *  const options: IFileUploaderOptions = {
     *                    uploader: {
     *                      url: 'http://localhost:3000/api/upload-file',
     *                      fileAlias: '[files]', // alias for FormData
     *                      autoUpload: true, // will upload valid files immediately after adding
     *                      simultaneousUpload: true, // will upload multiple files at at time
     *                      simultaneousUploadQuantityLimit: 3, // limit of multiple files uploading
     *                      customHeaders: {
     *                        'My-Header': 'with-value'
     *                        },
     *                      withCredentials: false
     *                    },
     *                    filePicker: {
     *                      allowedMultipleFiles: true,
     *                      allowedMimeClass: ['image'], // allowed mime classes details in type MimeClassName, if empty - all are acceptable
     *                      allowedFileExtension: ['png', 'jpg'], // allowed file extensions, if empty - all extensions are acceptable
     *                      maxFileSize: 5 * 1024 * 1024 // 5Mb
     *                    },
     *                    queue: {
     *                      maxFilesAmount: 10, // max acceptable files quantity
     *                      removeOnValidationFail: false,
     *                      removeAfterUploadComplete: true,
     *                      removeOnMaxQueueSizeReach: true // will silently remove files above acceptable quantity
     *                    },
     *                    customHandlers: {
     *                      customFileValidator: function (fileItem: FileItem) { return false; }
     *                    }
     };
     *  const myFileUploader = serviceReference.init(options);
     */
    public init(options: IFileUploaderOptions = {}): FileUploader {
        return new FileUploader(options, this.http);
    }
}
