import { Message } from '@core/types';
import { RosRightType } from '../enums/ros-right-type.enum';

export type TitleInfoToMap = {
    isError: boolean;
    messages: Message[];
    cost: number;
    address: string;
    class: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    company_numbers: string;
    ownership: string;
    proprietors: string;
    titleNumber: string;
    what3words?: string;
    rightDescription?: string;
    rightType?: RosRightType;
    uprn?: string;
};

export type ITitleInfo = {
    isError: boolean;
    messages?: Message[];
    titleNumber: string;
    class: string;
    cost: number;
    ownership: string;
    companyNumbers: string;
    address: string;
    proprietors: string;
    what3words?: string;
    rightDescription?: string;
    rightType?: RosRightType;
    uprn?: string;
}

export type ITileNumberForPurchase = {
    kind: 'title-register' | 'scottish-title';
    reference: string;
}

export type ShortTitleInfo = {
    titleNumber: string;
    tenure: string;
    polyId: string[];
}

export function mapTitleInfo(title: TitleInfoToMap): ITitleInfo {
    return {
        titleNumber: title.titleNumber,
        companyNumbers: title.company_numbers,
        proprietors: title.proprietors,
        ownership: title.ownership,
        messages: title.messages,
        what3words: title.what3words,
        rightDescription: title.rightDescription,
        rightType: title.rightType,
        isError: title.isError,
        address: title.address,
        class: title.class,
        uprn: title.uprn,
        cost: title.cost,
    };
}
