import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';

import { SharedModule } from '@shared/shared.module';
import { leasesRoutes } from './leases.routing';
import { LeasesComponent } from './leases.component';
import { LeasesUploadComponent } from './components/leases-upload/leases-upload.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { DocumentsTableComponent } from './components/documents-table/documents-table.component';
import { LoadingProgressBarComponent } from './components/loading-progress-bar/loading-progress-bar.component';
import { BookmarksApi, CompileApi, DocumentApi, FolderApi } from './api';
import {
    BookmarkService,
    BookmarkQuery,
    BookmarkStore,
    CompileQuery,
    CompileService,
    CompileStore,
    DocumentQuery,
    DocumentService,
    DocumentStore,
    FolderQuery,
    FolderService,
    FolderStore,
    SideNavCountersQuery,
    SideNavCountersService,
    SideNavCountersStore,
} from './states';
import { LeasesResolver } from './resolvers/leases.resolver';
import { PreviousFoldersComponent } from './components/previous-folders/previous-folders.component';
import { ReportLoadingProgressComponent } from './components/report-generation/dialogs/report-loading-progress/report-loading-progress.component';
import { ReportGenerationSuccessComponent } from './components/report-generation/dialogs/report-generation-success/report-generation-success.component';
import {
    ReportGenerationPartialSuccessComponent,
} from './components/report-generation/dialogs/report-generation-partial-success/report-generation-partial-success.component';
import { ReportGenerationFailedComponent } from './components/report-generation/dialogs/report-generation-failed/report-generation-failed.component';
import { FoldersTableComponent } from './components/folders-table/folders-table.component';
import { InformationSnackbarComponent } from './components/report-generation/information-snackbar/information-snackbar.component';
import { NotificationsComponent } from './components/notification-center/dialogs/notifications/notifications.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(leasesRoutes),
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        SharedModule,
        MatExpansionModule,
        MatMenuModule,
        DragDropModule,
    ],
    declarations: [
        LeasesComponent,
        LeasesUploadComponent,
        SidenavComponent,
        DocumentsTableComponent,
        LoadingProgressBarComponent,
        PreviousFoldersComponent,
        FoldersTableComponent,
        ReportLoadingProgressComponent,
        ReportGenerationSuccessComponent,
        ReportGenerationPartialSuccessComponent,
        ReportGenerationFailedComponent,
        NotificationsComponent,
        InformationSnackbarComponent,
    ],
    providers: [
        FolderApi,
        DocumentApi,
        CompileApi,
        BookmarksApi,
        BookmarkService,
        FolderStore,
        FolderQuery,
        FolderService,
        DocumentStore,
        DocumentQuery,
        DocumentService,
        BookmarkStore,
        BookmarkQuery,
        LeasesResolver,
        PreviousFoldersComponent,
        FoldersTableComponent,
        CompileStore,
        CompileQuery,
        CompileService,
        SideNavCountersService,
        SideNavCountersQuery,
        SideNavCountersStore,
    ],
})
export class LeasesModule {
}
