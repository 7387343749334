import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/*
 * Bypasses the security trust for resource URLs
 * Usage:
 *   dp | firstNonNullValue
*/
@Pipe({ name: 'trustUrl' })
export class BypassSecurityTrustUrl implements PipeTransform {
    constructor(private readonly sanitizer: DomSanitizer) {
    }

    public transform(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
