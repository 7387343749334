<div
    class="splash-screen"
    [@slideContent]
>
    <img
        class="splash-screen__logo"
        src="assets/images/logo.png"
        alt="logo"
    >
    <p class="splash-screen__title">The real estate risk analysis platform</p>
    <button
        type="button"
        class="avl-btn splash-screen__btn"
        (click)="start()"
    >
        Get started
    </button>
</div>
