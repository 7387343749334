export type Credentials = {
    email: string;
    password: string;
    newPassword: string;
    confirmNewPassword: string;
    hasError: boolean;
    errorMessage: string | null;
}

export function defaultCredentials(): Credentials {
    return {
        email: '',
        password: '',
        newPassword: '',
        confirmNewPassword: '',
        hasError: false,
        errorMessage: null,
    };
}
