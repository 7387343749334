import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'avl-information-snackbar',
    templateUrl: './information-snackbar.component.html',
    styleUrls: ['./information-snackbar.component.scss'],
})
export class InformationSnackbarComponent {

    public message: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        private readonly data: string,
        private readonly snackBarRef: MatSnackBarRef<InformationSnackbarComponent>,
    ) {
        this.message = data;
    }

    public close(): void {
        this.snackBarRef.dismiss();
    }
}
