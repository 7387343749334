import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    clearSelectedFeatureList,
    fetchSelectedTitles,
    highlightFeaturePermanently,
    initUrlParams,
    MapSearchQuery,
    toggleSidePanel,
} from 'app/titles/store/map-search';
import { Actions } from '@datorama/akita-ng-effects';
import { MapSearchControls } from './map-controls/types/map-search-controls.type';
import { Observable, Subscription } from 'rxjs';
import { FeatureSelectionEvent, MapComponent } from './map/map.component';
import { GeoJSONFeature, LngLat } from 'maplibre-gl';

@Component({
    selector: 'avl-land-registry-map-search',
    templateUrl: './map-search.component.html',
    styleUrls: ['./map-search.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0,0,0)',
            })),
            state('out', style({
                transform: 'translate3d(100%, 0, 0)',
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out')),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandRegistryMapSearchComponent implements OnInit, OnDestroy {
    public center: LngLat;
    public zoom: number;
    public isMapLoading$: Observable<boolean>;

    @ViewChild(MapComponent)
    private readonly mapComponent?: MapComponent;

    private readonly sub = new Subscription();

    constructor(
        private readonly mapSearchQuery: MapSearchQuery,
        private readonly actions: Actions,
    ) {
    }

    public ngOnInit(): void {
        this.addSubscriptions([
            this.mapSearchQuery.getLocationChanged()
                .subscribe(({ location, zoom }) => this.mapComponent?.jumpTo(location, zoom)),
            this.mapSearchQuery.zoomIsUpdated()
                .subscribe((zoom) => this.zoom = zoom),
            this.mapSearchQuery.getMarkerPosition()
                .subscribe((point) => this.mapComponent?.pointOn(point || null)),
            this.mapSearchQuery.featuresUpdated()
                .subscribe(() => this.mapComponent?.renderFeatures()),
            this.mapSearchQuery.focusedFeaturesUpdated()
                .subscribe((features) => this.mapComponent?.jumpToFeature(features)),
            this.mapSearchQuery.highlightingAreUpdated()
                .subscribe(() => {
                    const temporaryHighlighted = this.mapSearchQuery.getValue().temporaryHighlightedTitleNumber;
                    const permanentlyHighlighted = this.mapSearchQuery.getValue().permanentlyHighlightedTitleNumber;
                    const isTitleExists = !!temporaryHighlighted || !!permanentlyHighlighted;

                    this.mapComponent?.highlightFeature(temporaryHighlighted || permanentlyHighlighted);
                    this.mapComponent?.updateSelectedFeaturesList(isTitleExists
                        ? [temporaryHighlighted || permanentlyHighlighted]
                        : []);
                }),
            this.mapSearchQuery.filtersAreUpdated()
                .subscribe(() => this.mapComponent?.updateFeaturesFilter()),
        ]);

        this.center = this.mapSearchQuery.getCenter();
        this.isMapLoading$ = this.mapSearchQuery.selectLoading();

        this.actions.dispatch(initUrlParams());
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public onControlsChanged(controls: MapSearchControls): void {
        this.zoom = controls.zoom;
    }

    public onMapClick({ features }: FeatureSelectionEvent): void {
        if (features.length) {
            const titleNumber = this.getTitleNumberOfFreeholdOrFirstFeature(features);
            this.actions.dispatch(highlightFeaturePermanently({ titleNumber: titleNumber }));
        }
    }

    public onFeaturesSelected({ features }: FeatureSelectionEvent): void {
        const predefinedTitleNumber = this.mapSearchQuery.getValue().predefinedTitleNumberForHighlight;
        const titleNumber = predefinedTitleNumber || this.getTitleNumberOfFreeholdOrFirstFeature(features);

        if (titleNumber) {
            this.actions.dispatch(fetchSelectedTitles({ titleNumber: titleNumber }));
            this.actions.dispatch(toggleSidePanel({ isVisible: true }));
        }

        if (features.length) {
            const isHighlightedTitleExists = !!this.mapSearchQuery.getValue().permanentlyHighlightedTitleNumber;
            if (!isHighlightedTitleExists) {
                this.actions.dispatch(highlightFeaturePermanently({ titleNumber: titleNumber }));
            }
        } else {
            this.actions.dispatch(clearSelectedFeatureList());
        }
    }

    private addSubscriptions(subscriptions: Subscription[]): void {
        subscriptions.forEach((subscription) => this.sub.add(subscription));
    }

    private getTitleNumberOfFreeholdOrFirstFeature(features: GeoJSONFeature[]): string {
        const freeholdFeature = features.find((feature) => feature.properties.tenure?.toLowerCase() === 'freehold');
        const feature = freeholdFeature ? freeholdFeature : features[0];

        return feature?.properties.title_number || '';
    }
}
