import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Bookmark } from '../../types';

export type BookmarksState = EntityState<Bookmark> & {
    amount: number;
};

@Injectable()
@StoreConfig({ name: '[notice] bookmarks', resettable: true })
export class BookmarksStore extends EntityStore<BookmarksState> {

    constructor() {
        super({ amount: 0 });
    }

}
