import { Injectable } from '@angular/core';

@Injectable()
export class ThemesService {

    private isOldTheme = true;

    public getIsOldTheme(): boolean {
        return this.isOldTheme;
    }

    public setIsOldTheme(isOldTheme: boolean): void {
        this.isOldTheme = isOldTheme;
    }
}
