<div
    class="drop-area__wrap"
    avlElevate
    name="drop-area"
>
    <div
        class="drop-area"
        [class.file-over]="isFileOverArea"
        avlFileDrop
        [uploader]="fileUploader"
        (fileOver)="isFileOverArea = $event"
    >
        <div class="drop-area__animated-content">
            <div
                class="drop-area__default animated"
                [ngClass]="(loading$ | async) ? 'fade-out-up' : 'fade-in-up'"
            >
                <mat-icon
                    class="drop-area__icon"
                    svgIcon="drop"
                ></mat-icon>
                <h5 class="drop-area__title">Drop Title Registers & Plans here</h5>
                <p class="drop-area__description">or click below to upload them</p>
                <div class="drop-area__actions">
                    <label
                        data-testid="file-upload-button"
                        avlPulseButton
                        class="drop-area__btn avl-btn avl-btn--dark"
                    >
                        <input
                            class="drop-area__upload-input"
                            type="file"
                            accept="application/pdf"
                            avlFilePicker
                            [uploader]="fileUploader"
                        >
                        <span class="drop-area__upload-text">Upload</span>
                    </label>
                    <button
                        class="avl-btn avl-btn--dark drop-area__btn"
                        type="button"
                        [ngStyle]="{textTransform: 'none'}"
                        [style.display]="(profileService.isImanageEnabled$ | async) ? '' : 'none'"
                        [disabled]="imanageDisabled || (onboardingActive$ | async)"
                        (click)="onImanageClick()"
                    >
                        iMANAGE
                    </button>
                    <button
                        *ngIf="isOldTheme"
                        class="avl-btn avl-btn--dark-green drop-area__btn"
                        type="button"
                        [style.display]="(profileService.isHmlrEnabled$ | async) ? '' : 'none'"
                        [disabled]="landRegistryDisabled || (onboardingActive$ | async)"
                        (click)="onLandRegistryClick()"
                    >
                        HM Land Registry
                    </button>
                </div>
            </div>
            <div
                class="drop-area__in-progress"
                [ngClass]="[isUploading ? 'animated' : '', (loading$ | async) ? 'fade-in-up' : 'fade-out-up']"
            >
                <div class="drop-area__loading-box">
                    <mat-icon
                        class="drop-area__loading-icon"
                        svgIcon="arrow-progress"
                    ></mat-icon>
                    <avl-spinner [isBackgroundCircleVisible]="true"></avl-spinner>
                </div>
                <h5 class="drop-area__title">
                    <span [ngPlural]="filesAmount">
                        <ng-template ngPluralCase="=1">Uploading 1 file...</ng-template>
                        <ng-template ngPluralCase="other">Uploading {{ filesAmount }} files...</ng-template>
                    </span>
                </h5>
            </div>
        </div>
    </div>
</div>
