import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type ReportGenerationSuccessData = {
    title: string;
    linkToReport: string;
    linkMessage: string;
    message: string;
    linkPlaceholder: string;
};

@Component({
    selector: 'avl-report-generation-success',
    templateUrl: './report-generation-success.component.html',
    styleUrls: ['./report-generation-success.component.scss'],
})
export class ReportGenerationSuccessComponent {

    public title: string;
    public linkToReport: string;
    public linkMessage: string;
    public messageBeforeLink: string;
    public messageAfterLink: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private readonly data: ReportGenerationSuccessData,
        private readonly dialogRef: MatDialogRef<ReportGenerationSuccessComponent>,
    ) {
        this.title = data.title;
        this.linkToReport = data.linkToReport;
        this.linkMessage = data.linkMessage;

        const message = data.message;
        const placeholder = data.linkPlaceholder;

        this.messageBeforeLink = message.split(placeholder)[0];
        this.messageAfterLink = message.split(placeholder)[1];
    }

    public downloadReport(): void {
        this.dialogRef.close(this.linkToReport);
    }
}
