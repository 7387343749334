import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IErrorsEmitPayload, IFileUploaderEvents, IFileUploaderOptions } from '../../../blocks/file-uploader';
import { filter, map, takeUntil } from 'rxjs/operators';
import { UploadDropAreaComponent } from '@shared/components/upload-drop-area/upload-drop-area.component';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CompileQuery, CompileService, DocumentQuery, DocumentService, FolderQuery, FolderService } from '../../states';
import { DocumentApi } from '../../api';
import { MinimalDocument } from '../../types/minimal-document.type';
import { REMOVE_LEASE_CONFIRM_DATA, SOMETHING_WENT_WRONG_DURING_FILES_UPLOADING, UNSUPPORTED_FILE_TYPE } from '@constants';
import { DocumentsSortOptions } from '../documents-table/documents-table.component';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';

@Component({
    selector: 'avl-leases-upload',
    templateUrl: './leases-upload.component.html',
    styleUrls: ['./leases-upload.component.scss'],
})
export class LeasesUploadComponent implements OnInit, OnDestroy {
    public documentsLoading$: Observable<boolean>;
    public documents$: Observable<MinimalDocument[]>;
    public documents: MinimalDocument[] = [];
    public destroy$ = new Subject<void>();
    public isLoading$: Observable<boolean>;

    @ViewChild(UploadDropAreaComponent, { static: true })
    private readonly dropAreaComponent?: UploadDropAreaComponent;

    private lastSort?: DocumentsSortOptions;

    constructor(
        private readonly documentQuery: DocumentQuery,
        private readonly documentService: DocumentService,
        private readonly folderQuery: FolderQuery,
        private readonly folderService: FolderService,
        private readonly documentApi: DocumentApi,
        private readonly dialog: MatDialog,
        private readonly compileService: CompileService,
        private readonly compileQuery: CompileQuery,
    ) {
    }

    public ngOnInit(): void {
        this.isLoading$ = this.compileQuery.selectLoading();
        this.documentsLoading$ = this.documentQuery.selectLoading();
        this.documents$ = this.documentQuery.selectAll();
        this.setupListeners();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    public onGenerateReport(): void {
        const currentFolderId = this.folderQuery.getId();
        this.compileService.start(currentFolderId);
    }

    public onBeforeUpload(amount: number): void {
        if (!amount) {
            return;
        }

        void this.setupListenerToStartUpload();
    }

    public getUploaderConfig(): IFileUploaderOptions {
        return {
            uploader: {
                fileAlias: 'file',
                autoUpload: false,
                simultaneousUpload: true,
                simultaneousUploadQuantityLimit: 20,
            },
            filePicker: {
                allowedMultipleFiles: true,
                allowedMimeClass: ['pdf'],
                allowedFileExtension: ['pdf'],
            },
            queue: {
                removeAfterUploadComplete: false,
            },
        };
    }

    public onFileUploadStarted(event: IFileUploaderEvents): void {
        const temporaryFileId = event.file.uuid;
        const fileName = event.file.fileAsObject.name;

        this.documentService.startUploading(temporaryFileId, fileName);
    }

    public onFileUploadingCanceled(event: IFileUploaderEvents): void {
        this.documentService.cancelUpload(event.file.uuid);
    }

    public onFileUploadingSucceeded(event: IFileUploaderEvents): void {
        const temporaryFileId = event.file.uuid;
        const documentId = event.response.body[0];

        this.documentService.finishUploadWithSuccess(temporaryFileId, documentId);
    }

    public onDocumentsSorted(options: DocumentsSortOptions): void {
        this.lastSort = options;
        this.documentQuery.selectAll()
            .pipe(
                map((documents) => this.documentService.sort(documents, options)),
                map((documents) => documents.sort((document) => this.documentService.sortByUploading(document))),
            )
            .subscribe((documents) => this.documents = documents);
    }

    public onUnsupportedFileAdded(error: IErrorsEmitPayload): void {
        const documentName = error.file.fileAsObject.name;
        const fileType = 'leases';
        this.handleUnsupportedType(documentName, fileType);
    }

    public onFileUploadingFailed(event?: IFileUploaderEvents): void {
        const error = event ? event.error : Error();
        const temporaryFileId = event ? event.file.uuid : '';
        const errorData = { ...SOMETHING_WENT_WRONG_DURING_FILES_UPLOADING };

        if (error.status === 413) {
            const fileName = event.file.fileAsObject.name || 'File';
            errorData.title = 'Too Large File';
            errorData.message = `${fileName} is too large to be a valid document.`;
        }

        this.folderService.openAlertDialog(errorData);
        this.documentService.finishUploadWithFail(temporaryFileId);
    }

    public onDocumentCanceled(document: MinimalDocument): void {
        this.dropAreaComponent.cancelUpload(document.id);
    }

    public onDocumentRemoved(document: MinimalDocument): void {
        const config = { panelClass: 'confirm-dialog', data: REMOVE_LEASE_CONFIRM_DATA };

        this.dialog.open(ConfirmDialogComponent, config)
            .afterClosed()
            .pipe(
                filter((isRemoved) => isRemoved),
            )
            .subscribe(() => this.documentService.delete(document.id));
    }

    public getUploadedDocumentsCount(): number {
        return this.documentQuery.getCount((doc) => doc.isUploadFinished);
    }

    private async setupListenerToStartUpload(): Promise<void> {
        const folderId = this.folderQuery.getId() || await this.folderService.create();
        this.startFilesUploading(folderId);
    }

    private startFilesUploading(folderId: string): void {
        const createEndpointUrl = this.documentApi.getCreateEndpointPath(folderId);
        this.dropAreaComponent.uploadAll(createEndpointUrl);
    }

    private setupListeners(): void {
        this.documentsLoading$.subscribe((isLoading) => {
            if (!isLoading) {
                this.dropAreaComponent.clearLoading();
            }
        });

        this.documents$
            .pipe(
                map((documents) =>
                    this.lastSort
                        ? this.documentService.sort(documents, this.lastSort)
                        : documents,
                ),
                map((documents) => documents.sort((document) => this.documentService.sortByUploading(document))),
                takeUntil(this.destroy$),
            )
            .subscribe((docs: MinimalDocument[]) => {
                this.documents = docs;
            });
    }

    private handleUnsupportedType(documentName: string, fileType: string): void {
        const error = { ...UNSUPPORTED_FILE_TYPE };
        error.title = error.title.replace('{document_name}', documentName);
        error.message = error.message.replace('{file_type}', fileType);

        this.openAlertDialog(error);
    }

    private openAlertDialog(errorData?: { title: string; message: string }): void {
        this.dialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: errorData,
        });
    }
}
