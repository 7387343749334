<div
    *ngIf="availableTools.length"
    class="wrapper"
>
    <div class="content">
        <p class="title">Other Tools</p>
        <div class="tools">
            <img
                *ngFor="let tool of availableTools"
                class="logo"
                [src]="tool.logoSrc"
                [alt]="tool.logoAlt"
                (click)="goToTool(tool.name)"
            >
        </div>
    </div>
</div>
