<div class="info-form__wrap auth-page__form">
    <h2>{{ formTitle }}</h2>
    <h3>{{ message }}</h3>
    <a
        class="info-form__link"
        [routerLink]="['/login']"
    >
        <button>Log in</button>
    </a>
    <div
        *ngIf="isResendButton"
        class="form-field row"
    >
        <span>Didn't get the email?</span>
        <a (click)="onResend()">Resend it</a>
    </div>
</div>
