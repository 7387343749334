import { LoggerService } from '@services';
import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { SentryErrorHandler } from '@sentry/angular-ivy';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    private readonly sentryErrorHandler: SentryErrorHandler;

    constructor(private readonly logger: LoggerService) {
        this.sentryErrorHandler = Sentry.createErrorHandler({ showDialog: false });
    }

    public handleError(error: unknown): void {
        this.sentryErrorHandler.handleError(error);
        this.handleChunkLoadError(error);
    }

    private handleChunkLoadError(error: unknown): void {
        const errorMarker = 'ChunkLoadError';
        const stringFromError = error.toString();

        if (stringFromError.includes(errorMarker)) {
            this.logger.error('ChunkLoadError occurred and was caught up. The page will be reloaded');
            location.reload();
        }
    }
}
