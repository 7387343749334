import { Component, Input } from '@angular/core';

@Component({
    selector: 'avl-warning-banner',
    templateUrl: './warning-banner.component.html',
    styleUrls: ['./warning-banner.component.scss'],
})
export class WarningBannerComponent {
    @Input()
    public message = 'Warning is undefined';

    @Input()
    public detailsUrl?: string = null;
}
